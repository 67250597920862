
import { IsMobile } from '@routezero-site/component/helper/breakpoint';
import { ContactSalesRequestRecogniser, LearnMoreButton } from '@routezero-site/component/helper/button';
import { CallToActionImageCardOnActive, HoverIsActiveRecogniser } from '@routezero-site/component/helper/active_action';
import { Pill } from '@routezero-site/component/helper/pill';
import usePromise from '@routezero-site/component/helper/promise';
import { useServicesContext } from '@routezero-site/component/helper/services';
import { DecarbonisationFeatureHeight, DecarbonisationFeatureProductNameBackgroundColor, LargeBorderRadiusPx, LargePaddingPx, LargeShadow, MedPaddingPx, PillHorizontalPadding, SmallBorderRadiusPx, SmallPaddingPx } from '@routezero-site/component/helper/theme';
import carbonBudgetsFeatureImage from '@routezero-site/component/page/home/decarbonisation-journey/media/carbonBudgets.jpg';
import competitionsFeatureImage from '@routezero-site/component/page/home/decarbonisation-journey/media/competitions.jpg';
import effortlessSustainabilityFeatureImage from '@routezero-site/component/page/home/decarbonisation-journey/media/effortlessSustainability.jpg';
import peerInsightsFeatureImage from '@routezero-site/component/page/home/decarbonisation-journey/media/peerInsights.jpg';
import scenarioModelFeatureImage from '@routezero-site/component/page/home/decarbonisation-journey/media/scenarioModel.jpg';
import smartTravelPolcyFeatureImage from '@routezero-site/component/page/home/decarbonisation-journey/media/smartTravelPolicy.jpg';
import { splitEvenOddIndices } from '@routezero-site/helper/iterable';
import { DecarbonisationFeature, DecarbonisationFeatureImageType } from '@routezero-site/service/decarbonisation-features/decarbonisation_features';

/**
 * @returns a single list of features for mobile, or multiple lists to be 
 *  displayed side-by-side on larger screens.
 */
function FeaturesColumns(features: DecarbonisationFeature[]): DecarbonisationFeature[][] {
  if (IsMobile()) {
    return [features];
  }
  // By splitting on even/odd indices, the ordering of features is consistent
  // vertically down the page.
  return splitEvenOddIndices(features);
}

/**
 * List of features to help companies decarbonise.
 */
export const DecarbonisationJourneyFeatures: React.FC = () => {
  const { decarbonisationFeaturesService } = useServicesContext();
  const [ data ] = usePromise(decarbonisationFeaturesService.features);
  const features = data ?? [];
  const featuresColumns = FeaturesColumns(features);
  
  return (
    <div style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      gap: MedPaddingPx(),
    }}>
      {featuresColumns.map((features, i) =>
        <FeaturesColumn
          key={i}
          columnIndex={i}
          columnCount={featuresColumns.length}
          features={features}
        />
      )}
    </div>
  );
};

interface FeaturesColumnProps {
  columnIndex: number
  columnCount: number
  features: DecarbonisationFeature[]
}

/**
 * A single column of features displayed one after the other. Shifted down
 * by an amount proportional to the horizontal index of this column.
 */
const FeaturesColumn: React.FC<FeaturesColumnProps> = (props) => {
  return (
    <div style={{
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      marginTop: props.columnIndex*DecarbonisationFeatureHeight()*0.3,
      gap: MedPaddingPx(),
      marginBottom: -DecarbonisationFeatureHeight()
    }}>
      {props.features.map((feature, i) => 
        <Feature key={i} {...feature}/>
      )}
      <PlaceholderFeature/>
    </div>
  );
};

/**  
 * Card showing an image representing a feature, with text underneath 
 * describing the feature.
 */
const Feature: React.FC<DecarbonisationFeature> = (props) => {
  const { analyticsService } = useServicesContext();
  return (
    <ContactSalesRequestRecogniser
      onClick={() => analyticsService.logFeatureLearnMore(props.analyticsType)}
    >
      <HoverIsActiveRecogniser>
        <CallToActionImageCardOnActive
          backgroundColor='white'
          imageSrc={featureImageSrc({ featureImageType: props.imageType })}
          imageAlt={props.productName}
          heightPx={DecarbonisationFeatureHeight()}
          imageGapPx={SmallPaddingPx()*2}
          cardBottomGapPx={SmallPaddingPx()*2}
          body={
            <div style={{
              padding: `0 ${LargePaddingPx() - PillHorizontalPadding()}px`,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              gap: SmallPaddingPx()
            }}>
              <Pill backgroundColor={DecarbonisationFeatureProductNameBackgroundColor()}>
                <p>
                  {props.productName}
                </p>
              </Pill>
              <div style={{
                padding: `0 ${PillHorizontalPadding()}px`
              }}>
                <h4 style={{paddingBottom: SmallPaddingPx()}}>
                  {props.title}
                </h4>
                <p>
                  {props.description}
                </p>
              </div>
            </div>
          }
          callToAction={
            <div style={{
              paddingLeft: LargePaddingPx()
            }}>
              <LearnMoreButton/> 
            </div>
          }
        />
      </HoverIsActiveRecogniser>
    </ContactSalesRequestRecogniser>
  );
};

/**  
 * Placeholder that mimics a feature card but with no content
 */
const PlaceholderFeature: React.FC = () => {
  return (
    <div style={{
      ...LargeShadow(),
      borderRadius: LargeBorderRadiusPx(),
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      height: `${DecarbonisationFeatureHeight()}px`,
      overflow: 'hidden', 
    }}>
      <div style={{
        width: '100%',
        height: '100%',
        padding: `${SmallPaddingPx()}px ${SmallPaddingPx()}px ${SmallPaddingPx()*2}px ${SmallPaddingPx()}px`,
        display: 'flex',
        justifyContent: 'center'
      }}>
        <div style={{
          backgroundColor: '#F0FBF8',
          borderRadius: SmallBorderRadiusPx(),
          height: '100%',
          width: '100%'
        }}/>
      </div>
    </div>
  );
};

/**
 * @returns path to the image to use for a given feature type.
 */
function featureImageSrc(args: { featureImageType: DecarbonisationFeatureImageType }): string {
  switch (args.featureImageType) {
    case "carbonBudgets": return carbonBudgetsFeatureImage;
    case "competitions": return competitionsFeatureImage;
    case "integratedBooking": return effortlessSustainabilityFeatureImage;
    case "peerInsights": return peerInsightsFeatureImage;
    case "scenarioModels": return scenarioModelFeatureImage;
    case "smartTravelPolicy": return smartTravelPolcyFeatureImage;
  }
}