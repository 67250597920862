"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfFlightAirport = instanceOfFlightAirport;
exports.FlightAirportFromJSON = FlightAirportFromJSON;
exports.FlightAirportFromJSONTyped = FlightAirportFromJSONTyped;
exports.FlightAirportToJSON = FlightAirportToJSON;
var LatLng_1 = require("./LatLng");
var Timezone_1 = require("./Timezone");
/**
 * Check if a given object implements the FlightAirport interface.
 */
function instanceOfFlightAirport(value) {
    var isInstance = true;
    isInstance = isInstance && "iataCode" in value;
    isInstance = isInstance && "latlng" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "cityName" in value;
    isInstance = isInstance && "iso2CountryCode" in value;
    isInstance = isInstance && "timezone" in value;
    return isInstance;
}
function FlightAirportFromJSON(json) {
    return FlightAirportFromJSONTyped(json, false);
}
function FlightAirportFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'iataCode': json['iataCode'],
        'latlng': (0, LatLng_1.LatLngFromJSON)(json['latlng']),
        'name': json['name'],
        'cityName': json['cityName'],
        'iso2CountryCode': json['iso2CountryCode'],
        'timezone': (0, Timezone_1.TimezoneFromJSON)(json['timezone']),
    };
}
function FlightAirportToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'iataCode': value.iataCode,
        'latlng': (0, LatLng_1.LatLngToJSON)(value.latlng),
        'name': value.name,
        'cityName': value.cityName,
        'iso2CountryCode': value.iso2CountryCode,
        'timezone': (0, Timezone_1.TimezoneToJSON)(value.timezone),
    };
}
