import { IsMobile, IsTabletOrDesktop, mediaStyle } from "@routezero-site/component/helper/breakpoint";
import usePromise from "@routezero-site/component/helper/promise";
import { useServicesContext } from "@routezero-site/component/helper/services";
import { MedPaddingPx, PricingPlanMainCardPrimaryBackgroundColor, PricingPlanMainCardSecondaryBackgroundColor, PricingPlanPriceCardPrimaryBackgroundColor, PricingPlanPriceCardSecondaryBackgroundColor, PricingPlanPrimaryColor, PricingPlanPrimaryCtaColor, PricingPlanPrimaryPriceColor, PricingPlanSecondaryColor } from "@routezero-site/component/helper/theme";
import { PricingPlanCard } from "@routezero-site/component/page/pricing/pricing-plans/pricing_plan_card";

export interface PricingPlanCardsProps {
  /**
   * On the carbon pricing example graph, the maximum number of tonnes of CO2
   * that the user can choose to "reduce".
   */
  exampleGraphMaxTonnesCO2Reduced: number
  /**
   * Default proportion of CO2 emissions reduced while the user is not mousing
   * over the graph.
   */
  defaultEmissionsReductionProportion: number
}

/**
 * Shows all pricing plans fetched from the pricing plan service.
 */
export const PricingPlanCards: React.FC<PricingPlanCardsProps> = (props) => {
  const { pricingPlansService } = useServicesContext();
  const [ data ] = usePromise(pricingPlansService.pricingPlans);
  const plans = data ?? [];

  const medPaddingPx = MedPaddingPx();

  const primaryColor = PricingPlanPrimaryColor();
  const primaryCtaColor = PricingPlanPrimaryCtaColor();
  const primaryPriceColor = PricingPlanPrimaryPriceColor();
  const primaryBackgroundColor = PricingPlanMainCardPrimaryBackgroundColor();
  const primaryPricingBackgroundColor = PricingPlanPriceCardPrimaryBackgroundColor();

  const secondaryColor = PricingPlanSecondaryColor();
  const secondaryBackgroundColor = PricingPlanMainCardSecondaryBackgroundColor();
  const secondaryPricingBackgroundColor = PricingPlanPriceCardSecondaryBackgroundColor();

  const secondaryPricingPlanCardWidth = IsTabletOrDesktop()
    ? `calc(50% - ${medPaddingPx*0.5}px)`
    : '100%';
  
  return (
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      ...mediaStyle(IsTabletOrDesktop(), {
        flexDirection: 'row',
        gap: MedPaddingPx(),
      }),
      ...mediaStyle(IsMobile(), {
        flexDirection: 'column',
        gap: MedPaddingPx(),
      }),
    }}>
      {/* First card is highlighted */}
      {plans.length >= 1 && 
      <PricingPlanCard
        pricingPlan={plans[0]}
        exampleGraphMaxTonnesCO2Reduced={props.exampleGraphMaxTonnesCO2Reduced}
        defaultEmissionsReductionProportion={props.defaultEmissionsReductionProportion}
        width='100%'
        nameAndDescriptionColor={primaryColor}
        ctaColor={primaryCtaColor}
        priceColor={primaryPriceColor}
        mainCardBackgroundColor={primaryBackgroundColor}
        priceCardBackgroundColor={primaryPricingBackgroundColor}
      />
      }
      {plans.slice(1).map((plan, i) => 
        <PricingPlanCard 
          key={i} 
          pricingPlan={plan}
          exampleGraphMaxTonnesCO2Reduced={props.exampleGraphMaxTonnesCO2Reduced}
          defaultEmissionsReductionProportion={props.defaultEmissionsReductionProportion}
          width={secondaryPricingPlanCardWidth}
          nameAndDescriptionColor={secondaryColor}
          ctaColor={secondaryColor}
          priceColor={secondaryColor}
          mainCardBackgroundColor={secondaryBackgroundColor}
          priceCardBackgroundColor={secondaryPricingBackgroundColor}
        />
      )}
    </div>
  );
};