"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfPlatformExternalLink = instanceOfPlatformExternalLink;
exports.PlatformExternalLinkFromJSON = PlatformExternalLinkFromJSON;
exports.PlatformExternalLinkFromJSONTyped = PlatformExternalLinkFromJSONTyped;
exports.PlatformExternalLinkToJSON = PlatformExternalLinkToJSON;
var ExternalLink_1 = require("./ExternalLink");
var PlatformExternalLinkType_1 = require("./PlatformExternalLinkType");
/**
 * Check if a given object implements the PlatformExternalLink interface.
 */
function instanceOfPlatformExternalLink(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "desktop" in value;
    isInstance = isInstance && "ios" in value;
    isInstance = isInstance && "android" in value;
    return isInstance;
}
function PlatformExternalLinkFromJSON(json) {
    return PlatformExternalLinkFromJSONTyped(json, false);
}
function PlatformExternalLinkFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, PlatformExternalLinkType_1.PlatformExternalLinkTypeFromJSON)(json['type']),
        'desktop': (0, ExternalLink_1.ExternalLinkFromJSON)(json['desktop']),
        'ios': (0, ExternalLink_1.ExternalLinkFromJSON)(json['ios']),
        'android': (0, ExternalLink_1.ExternalLinkFromJSON)(json['android']),
    };
}
function PlatformExternalLinkToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, PlatformExternalLinkType_1.PlatformExternalLinkTypeToJSON)(value.type),
        'desktop': (0, ExternalLink_1.ExternalLinkToJSON)(value.desktop),
        'ios': (0, ExternalLink_1.ExternalLinkToJSON)(value.ios),
        'android': (0, ExternalLink_1.ExternalLinkToJSON)(value.android),
    };
}
