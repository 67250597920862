import { smallFontClassName } from '@routezero-site/component/helper/global_style';
import { Pill } from '@routezero-site/component/helper/pill';
import { LargeBorderRadiusPx, LargeShadow, SmallPaddingPx } from '@routezero-site/component/helper/theme';
import { VimeoPlayer } from '@routezero-site/component/helper/vimeo_player';
import React from 'react';

export const TravelPlatformDemoVideo: React.FC = () => {
  return (
    <div style={{
      ...LargeShadow(),
      borderRadius: LargeBorderRadiusPx(),
      overflow: 'hidden',
      position: 'relative'
    }}>
      <VimeoPlayer
        uniqueTitle='DemoTravelPlatform'
        heightPercentageAspectRatio={56.75}
        videoId='903429627'
        videoIdHParam='f1f614d4fb'
        muted={true}
        autoplayWhenVisible={true}
        loop={true}
      />
      <div className={smallFontClassName} style={{
        position: 'absolute',
        color: '#4D90D5',
        top: SmallPaddingPx(),
        right: SmallPaddingPx(),
        fontWeight: 600,
      }}>
        <Pill backgroundColor='white' backgroundBlendMode='soft-light'>
          <p style={{
            color: '#555',
            mixBlendMode: 'color-burn'
          }}>
            50 sec demo
          </p>
        </Pill>
      </div>
    </div>
  );
};
