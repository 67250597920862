import { CursorProportionProvider, useCursorProportion } from "@routezero-site/component/helper/cursor_proportion";
import { smallFontClassName } from "@routezero-site/component/helper/global_style";
import { CircleCheckDuotoneIcon, CircleXMarkDuotoneIcon, CloudDownArrowSolidIcon, SparklesDuotoneIcon } from "@routezero-site/component/helper/icon";
import { CustomOdometer, CustomOdometerWithDelay } from "@routezero-site/component/helper/odometer";
import { CarbonPricingGraphBackgroundColor, CarbonPricingGraphCarbonSavingsColor, CarbonPricingGraphCompetitorBackgroundColor, CarbonPricingGraphCompetitorColor, CarbonPricingGraphConColor, CarbonPricingGraphExampleBusinessAsUsualForecastColor, CarbonPricingGraphExampleHistoricColor, CarbonPricingGraphPointSizePx, CarbonPricingGraphRouteZeroBackgroundColor, CarbonPricingGraphRouteZeroColor, FadedOpacity, IconSizePx, LargePaddingPx, LargeShadow, MedAnimationDurationSec, MedBorderRadiusPx, MedPaddingPx, SmallBorderRadiusPx, SmallIconSizePx, SmallPaddingPx } from "@routezero-site/component/helper/theme";
import { expMinusX, graphSampler, linear, mult, nullGt, nullLte } from "@routezero-site/helper/graph";
import { isWithinRange0To1, roundNearest } from "@routezero-site/helper/math";
import { CarbonPricingExampleGraphProducts, CarbonPricingGraphProduct, CarbonPricingGraphProductFeature, CarbonPricingGraphProductFeatureType, CarbonPricingGraphProductIconType } from "@routezero-site/service/pricing-plans/pricing_plans";
import { CategoryScale, Chart as ChartJS, Filler, Legend, LineElement, LinearScale, PointElement } from 'chart.js';
import { draw as drawPattern } from "patternomaly";
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Legend,
  Filler,
);

export interface CarbonPricingExampleGraphProps {
  /**
   * The maximum number of tonnes of CO2 the user can "reduce".
   */
  maxCarbonTonnesReduced: number
  /**
   * Default proportion of CO2 emissions reduced while the user is not mousing
   * over the graph.
   */
  defaultEmissionsReductionProportion: number
  graph: CarbonPricingExampleGraphProducts
}

/**
 * Interactive graph to show the user how carbon pricing works. The can change
 * emissions reduction (tonnes CO2) and see how this affects pricing for RZ
 * and any competing products (e.g. offsets).
 */
export const CarbonPricingExampleGraph: React.FC<CarbonPricingExampleGraphProps> = (props) => {
  return (
    // Add a margin proportion, so the user doesn't need to move their cursor
    // all the way to the edges 
    <CursorProportionProvider marginYProportion={0.1}>
      <GraphContainer {...props}/>
    </CursorProportionProvider>
  );
}

const GraphContainer: React.FC<CarbonPricingExampleGraphProps> = (props) => {
  const { xProp, yProp } = useCursorProportion();

  // If the user moves the cursor outside the graph area, resets the reduced
  // CO2 to a default value.
  function clampedProportion(x: number, y: number): number {
    if (!isWithinRange0To1(x) || !isWithinRange0To1(y)) {
      return props.defaultEmissionsReductionProportion;
    }
    return y;
  }

  const prop = clampedProportion(
    xProp ?? props.defaultEmissionsReductionProportion, 
    yProp ?? props.defaultEmissionsReductionProportion
  );
  
  return (
    <Graph 
      tonnesCo2Reduced={prop * props.maxCarbonTonnesReduced}
      proportionMaxCo2Reducted={prop}
      graph={props.graph}
    />
  );
};

interface GraphProps {
  /**
   * The absolute number of tonnes of CO2 "reduced".
   */
  tonnesCo2Reduced: number
  /**
   * The number of tonnes of CO2 reduced as a proportion of the maximum number 
   * of tonnes the user can choose.
   */
  proportionMaxCo2Reducted: number
  graph: CarbonPricingExampleGraphProducts
}

const Graph: React.FC<GraphProps> = (props) => {
  return (
    <div className={smallFontClassName} style={{
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: CarbonPricingGraphBackgroundColor(),
      width: '100%',
      height: '100%',
      borderRadius: MedBorderRadiusPx(),
      overflow: 'hidden'
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        gap: SmallPaddingPx(),
        padding: SmallPaddingPx()
      }}>
        <Co2ReducedCard tonnesCo2Reduced={props.tonnesCo2Reduced}/>
        <div style={{
          display: 'flex',
        }}>
          <GraphLegend/>
        </div>
      </div>
      <div style={{
        flexGrow: 1,
        // Bit hacky, ChartJS adds extra padding to the edges to account for 
        // labels. Changing the padding of the graph itself didn't make a difference.
        marginLeft: -8,
        marginRight: -8,
        // So the graph overlaps the tonnes CO2 card.
        marginTop: -LargePaddingPx(),
        // So the graph overlaps the product detail cards.
        marginBottom: -LargePaddingPx(),
      }}>
        <CarbonEmissionsOverTimeGraph
          emissionReductionProportion={props.proportionMaxCo2Reducted}
        />
      </div>
      <ProductDetailCards
        tonnesCo2Reduced={props.tonnesCo2Reduced}
        graph={props.graph}
      />
    </div>
  );
};

interface Co2ReducedCardProps {
  tonnesCo2Reduced: number
}

/**
 * The amount of CO2 that has been "reduced". Changes as the user interacts 
 * with the graph.
 */
const Co2ReducedCard: React.FC<Co2ReducedCardProps> = (props) => {
  return (
    <div style={{
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: `${SmallPaddingPx()}px ${MedPaddingPx()}px`,
      color: CarbonPricingGraphRouteZeroColor(),
      backgroundColor: CarbonPricingGraphRouteZeroBackgroundColor(),
      borderRadius: SmallBorderRadiusPx()
    }}>
      <div style={{
        position: 'absolute',
        top: SmallPaddingPx()*0.25,
        left: SmallPaddingPx()*0.5,
        opacity: FadedOpacity()
      }}>
        <CloudDownArrowSolidIcon sizePx={SmallIconSizePx()}/>
      </div>
      <CustomOdometer 
        value={Math.round(props.tonnesCo2Reduced)}
      />
      <p>
        tonnes CO₂e
      </p>
    </div>
  );
};

interface ProductDetailCardsProps {
  /** 
   * The tonnes of CO2 the user is "reducing". Updated when they interact
   * with the graph.
   */
  tonnesCo2Reduced: number
  graph: CarbonPricingExampleGraphProducts
}

/**
 * Row of cards showing RZ vs competitors.
 */
const ProductDetailCards: React.FC<ProductDetailCardsProps> = (props) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      gap: SmallPaddingPx(),
      padding: SmallPaddingPx()
    }}>
    <ProductDetailsCard
      tonnesCo2Reduced={props.tonnesCo2Reduced}
      product={props.graph.routeZeroProduct}
      color={CarbonPricingGraphRouteZeroColor()}
      backgroundColor={CarbonPricingGraphRouteZeroBackgroundColor()}
      delay={0}
    />
    {props.graph.competitorProducts.map((competitor, i) =>
      <ProductDetailsCard
        key={i}
        tonnesCo2Reduced={props.tonnesCo2Reduced}
        product={competitor}
        color={CarbonPricingGraphCompetitorColor()}
        backgroundColor={CarbonPricingGraphCompetitorBackgroundColor()}
        delay={(i+1)*70}
      />
    )}
    </div>
  );
};

interface ProductDetailsCardProps {
  /** 
   * The tonnes of CO2 the user is "reducing". Updated when they interact
   * with the graph.
   */
  tonnesCo2Reduced: number
  product: CarbonPricingGraphProduct
  color: React.CSSProperties['color']
  backgroundColor: React.CSSProperties['backgroundColor']
  /**
   * The delay in ms before the value shown on the odometer updates
   */
  delay: number
}

/**
 * Shows pricing and feature information about RouteZero or a competitior 
 * (e.g. carbon offsets). The price information is dependent on the example 
 * carbon amount the user is "reducing".
 */
const ProductDetailsCard: React.FC<ProductDetailsCardProps> = (props) => {
  const priceGbp = props.tonnesCo2Reduced * props.product.priceGbpPerTonneCO2;
  return (
    <div style={{
      ...LargeShadow(),
      flexGrow: 1,
      flexBasis: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: props.backgroundColor,
      color: props.color,
      borderRadius: SmallBorderRadiusPx(),
      gap: SmallPaddingPx(),
      padding: `${SmallPaddingPx()}px 0px ${SmallPaddingPx()*2}px 0px`,
    }}>
      <div style={{
        width: '100%',
        padding: `0px ${SmallPaddingPx()}px`
      }}>
        <ProductName 
          icon={props.product.icon} 
          name={props.product.name}
        />
      </div>
      <ProductPriceOdometer
        priceGbp={priceGbp}
        delay={props.delay}
      />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        gap: SmallPaddingPx(),
        width: '100%',
        padding: `0px ${MedPaddingPx()}px`,
      }}>
        {props.product.features.map((feature, i) => 
          <ProductFeature 
            key={i}
            feature={feature}
          />
        )}
      </div>
    </div>
  );
};

interface ProductPriceOdometerProps {
  /**
   * The price to display on the odometer
   */
  priceGbp: number
  /**
   * The delay in ms before the value shown on the odometer updates
   */
  delay: number
}

/**
 * Shows product price, e.g. £500 (implicitly to reduce X tonnes CO2)
 */
const ProductPriceOdometer: React.FC<ProductPriceOdometerProps> = (props) => {
  const roundedPrice = roundNearest({ value: props.priceGbp, nearest: 5 });
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    }}>
      <h4>
        £
      </h4>
      <CustomOdometerWithDelay
        value={roundedPrice}
        delay={props.delay}
      />
    </div>
  );
};

interface ProductNameProps {
  icon?: CarbonPricingGraphProductIconType
  name: string
}

const ProductName: React.FC<ProductNameProps> = (props) => {
  const icon = <ProductIcon icon={props.icon}/>;
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: SmallPaddingPx() * 0.5
    }}>
      <div style={{
        display: 'flex',
        opacity: FadedOpacity()
      }}> 
        {icon}
      </div>
      <p>
        {props.name}
      </p>
      {/* To center correctly */}
      <div style={{
        display: 'flex',
        opacity: 0.0
      }}> 
        {icon}
      </div>
    </div>
  );
};

interface ProductIconProps {
  icon?: CarbonPricingGraphProductIconType
}

const ProductIcon: React.FC<ProductIconProps> = (props) => {
  switch (props.icon) {
    case "sparkle": return <SparklesDuotoneIcon sizePx={SmallIconSizePx()}/>
    case undefined: return <></>;    
  }
};

interface ProductFeatureProps {
  feature: CarbonPricingGraphProductFeature
}

/**
 * Describes a feature of a product ("Low-cost"/"High-cost") and whether the 
 * feature is a pro or con.
 */
const ProductFeature: React.FC<ProductFeatureProps> = (props) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'start',
      width: '100%',
      gap: SmallPaddingPx()*0.5,
      color: props.feature.type === "con" ? CarbonPricingGraphConColor() : undefined
    }}>
      <ProductFeatureProConIcon type={props.feature.type}/>
      <p>
        {props.feature.name}
      </p>
    </div>
  );
};

interface ProductFeatureProConIconProps {
  type: CarbonPricingGraphProductFeatureType
}

/**
 * Check or cross icon depending on whether this is a pro or con.
 */
const ProductFeatureProConIcon: React.FC<ProductFeatureProConIconProps> = (props) => {
  switch (props.type) {
    case "pro": return <CircleCheckDuotoneIcon/>;
    case "con": return <CircleXMarkDuotoneIcon/>;
  }
};

/**
 * Shows color of lines for Forecast CO2 in scenarios for "business-as-usual" 
 * and when different carbon reduction strategies are used.
 */
const GraphLegend: React.FC = () => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      gap: SmallPaddingPx()
    }}>
      <GraphLegendItem
        name="Forecast CO₂e"
        color={CarbonPricingGraphExampleBusinessAsUsualForecastColor()}
      />
      <GraphLegendItem
        name="CO₂e reduction"
        color={CarbonPricingGraphRouteZeroBackgroundColor()}
      />
    </div>
  );
};

interface GraphLegendItemProps {
  color: React.CSSProperties['color']
  name: string
}

/**
 * Shows color next to a name, indicating the item on the graph.
 */
const GraphLegendItem: React.FC<GraphLegendItemProps> = (props) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      gap: SmallPaddingPx() * 0.5,
      alignItems: 'center'
    }}>
      <div style={{
        width: IconSizePx(),
        height: IconSizePx(),
        borderRadius: IconSizePx() * 0.25,
        backgroundColor: props.color
      }}/>
      <p>
        {props.name}
      </p>
    </div>
  );
};

interface CarbonEmissionsOverTimeGraphProps {
  /**
   * Number from 0 to 1 indicating whether the graph should show no-emission
   * reduction, or maximum emission reduction.
   */
  emissionReductionProportion: number
}

/**
 * Graph showing emissions over time in a "business-as-usual" scenario, and in
 * a scenario where emissions are reduced.
 */
const CarbonEmissionsOverTimeGraph: React.FC<CarbonEmissionsOverTimeGraphProps> = (props) => {
  // Arbitary choice of numbers here - it just looked nice :)
  const graphPoints = 8;
  const historicEmissionsThresholdX = 1;

  const businessAsUsualGraphBase = linear({
    p1: [0, 20],
    p2: [graphPoints, 25]
  });
  const historicEmissionsGraph = nullGt({
    graph: businessAsUsualGraphBase,
    thresholdX: historicEmissionsThresholdX
  });
  const forecastWithoutRouteZeroGraph = nullLte({
    graph: businessAsUsualGraphBase,
    thresholdX: historicEmissionsThresholdX
  });
  const forecaseEmissionsReductionGraph = nullLte({
    thresholdX: historicEmissionsThresholdX,
    graph: mult({
      graph1: businessAsUsualGraphBase,
      graph2: expMinusX({
        exponentFactor: props.emissionReductionProportion,
        shiftX: historicEmissionsThresholdX
      })
    })
  });
  const sample = graphSampler({
    minX: 0,
    maxX: graphPoints,
    incX: 1
  });

  const pointSize = CarbonPricingGraphPointSizePx();
  const dataStyle = {
    pointBorderWidth: pointSize,
    pointRadius: pointSize / 2,
    tension: 0.2,
  };

  return (
    <Line 
      data={{
        labels: Array.from(Array(graphPoints).keys()),
        datasets: [
          {
            // Labels required for when data updates.
            label: 'Historic',
            data: sample(historicEmissionsGraph),
            borderColor: CarbonPricingGraphExampleHistoricColor(),
            ...dataStyle
          },
          {
            label: 'ForecastBusinessAsUsual',
            data: sample(forecastWithoutRouteZeroGraph),
            borderColor: CarbonPricingGraphExampleBusinessAsUsualForecastColor(),
            ...dataStyle
          },
          {
            label: 'ForecastWithRouteZero',
            data: sample(forecaseEmissionsReductionGraph),
            borderColor: CarbonPricingGraphRouteZeroBackgroundColor(),
            fill: '-1',
            backgroundColor: [
              drawPattern("diagonal-right-left", 
                /* backgroundColor */ CarbonPricingGraphCarbonSavingsColor(),
                /* patternColor */ CarbonPricingGraphBackgroundColor(),
                /* size */ 30
              )
            ],
            ...dataStyle
          }
        ]
      }} 
      options={{
        maintainAspectRatio: false,
        scales: {
          x: {
            display: false,
            max: graphPoints,
            min: 0
          },
          y: {
            // display: false,
            // To ensure graph does not scale as data changes.
            beginAtZero: true,
            border: {
              display: false
            },
            ticks: {
              display: false,
            }
          }
        },
        animation: {
          duration: MedAnimationDurationSec()*1000
        },
        plugins: {
          tooltip: {
            enabled: false
          },
          legend: {
            display: false
          },
        }
      }}
    />
  );
};