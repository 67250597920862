"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookerDetailsBookingInputType = void 0;
exports.BookerDetailsBookingInputTypeFromJSON = BookerDetailsBookingInputTypeFromJSON;
exports.BookerDetailsBookingInputTypeFromJSONTyped = BookerDetailsBookingInputTypeFromJSONTyped;
exports.BookerDetailsBookingInputTypeToJSON = BookerDetailsBookingInputTypeToJSON;
/**
 *
 * @export
 */
exports.BookerDetailsBookingInputType = {
    BookerDetails: 'bookerDetails'
};
function BookerDetailsBookingInputTypeFromJSON(json) {
    return BookerDetailsBookingInputTypeFromJSONTyped(json, false);
}
function BookerDetailsBookingInputTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function BookerDetailsBookingInputTypeToJSON(value) {
    return value;
}
