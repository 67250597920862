
import { HoverIsActiveRecogniser } from "@routezero-site/component/helper/active_action";
import { IsMobile, IsTabletOrDesktop, mediaStyle } from "@routezero-site/component/helper/breakpoint";
import { ContactSalesPillButton, TextArrowRow } from "@routezero-site/component/helper/button";
import { PlaneDuotoneIcon, SizedIconProps, TagSolidIcon, TrainDuotoneIcon } from "@routezero-site/component/helper/icon";
import { IconTextColumn } from "@routezero-site/component/helper/icon_text_column";
import { useIntersectionObserver } from "@routezero-site/component/helper/intersection_observer";
import { RZRoutePlannerLink } from "@routezero-site/component/helper/links";
import { useNavigateToPage } from "@routezero-site/component/helper/navigation";
import { SectionContainer, SplitSection } from "@routezero-site/component/helper/section_container";
import { SectionMainText } from "@routezero-site/component/helper/section_main_text";
import { useServicesContext } from "@routezero-site/component/helper/services";
import { ExtraLargeIconSizePx, MedPaddingPx, SectionItemGapPx, SmallPaddingPx } from "@routezero-site/component/helper/theme";
import sectionBackgroundImage from "@routezero-site/component/page/home/call-to-action/media/background.jpg";
import React from "react";

const textColor = "#043D72";

/**
 * Call to action for the bottom of the home page encouraging the user to 
 * contact sales, see the pricing, or try the demo.
 */
export const CallToActionSection: React.FC = () => {
  const { analyticsService } = useServicesContext();
  
  const onVisible = async () => {
    if (ctaRef.current) {
      await analyticsService.logScrollToProductPageCallToAction();
    }
  };
  const ctaRef = useIntersectionObserver<HTMLDivElement>({
    callback: onVisible,
  });

  return (
    <SectionContainer
      backgroundImage={sectionBackgroundImage}
      color={textColor}
    >
      <SplitSection
        lhs={
          <SectionMainText
            fillWidth={true}
            color={textColor}
            subtitleBackgroundColor="#212121"
            subtitleBackgroundBlendMode="soft-light"
            header="Ready to start?"
            body="Contact us to explore how RouteZero can help your organisation drastically cut travel emissions."
            callToAction={
              <HoverIsActiveRecogniser>
                <ContactSalesPillButton 
                  color="white"
                  backgroundColor={textColor}
                  ref={ctaRef}
                />
              </HoverIsActiveRecogniser>
            }
          />
        }
        rhsTopPadding={MedPaddingPx()}
        rhs={
          <SecondaryCallToActions/>
        }
      />
    </SectionContainer>
  );
};

/**
 * The main call to action is to get the user to ask for a demo. The secondary
 * calls to action are to visit the pricing page, or try the public demo site.
 */
const SecondaryCallToActions: React.FC = () => {
  const navigate = useNavigateToPage();
  return (
    <div style={{
      display: 'flex',
      ...mediaStyle(IsTabletOrDesktop(), {
        flexDirection: 'row',
        gap: MedPaddingPx(),
      }),
      ...mediaStyle(IsMobile(), {
        flexDirection: 'column',
        gap: SectionItemGapPx()
      }),
    }}>
      <button onClick={() => navigate("pricing")}>
        <SecondaryCallToAction type="pricing"/>
      </button>
      <RZRoutePlannerLink>
        <SecondaryCallToAction type="tryForFree"/>
      </RZRoutePlannerLink>
    </div>
  );
};

type SecondaryCallToActionType
  = "pricing"
  | "tryForFree"

interface SecondaryCallToActionProps {
  type: SecondaryCallToActionType
}

/**
 * Other actions, besides contacting sales, that we'd like the user to take.
 * I.e. visiting pricing or trying out a demo.
 */
const SecondaryCallToAction: React.FC<SecondaryCallToActionProps> = (props) => {
  return (
    <HoverIsActiveRecogniser>
      <IconTextColumn
        style={{
          flexGrow: 1,
          flexBasis: 0,
          color: "white"
        }}
        icon={<SecondaryCtaIcon type={props.type}/>}
        title={secondaryCtaTitle(props.type)}
        body={secondaryCtaBodyText(props.type)}
      >
        <TextArrowRow 
          text={secondaryCtaButtonText(props.type)}
        />      
      </IconTextColumn>
    </HoverIsActiveRecogniser>
  );
};

function secondaryCtaTitle(type: SecondaryCallToActionType): string {
  switch (type) {
    case "pricing": return "Understand pricing";
    case "tryForFree": return "Try for free";
  }
}

function secondaryCtaBodyText(type: SecondaryCallToActionType): string {
  switch (type) {
    case "pricing": return "More cost effective than other emissions reductions.";
    case "tryForFree": return "Try out our free, public version of RouteZero Maps.";
  }
}

function secondaryCtaButtonText(type: SecondaryCallToActionType): string {
  switch (type) {
    case "pricing": return "Pricing";
    case "tryForFree": return "Plan a route";
  } 
}

interface SecondaryCtaIconProps {
  type: SecondaryCallToActionType
}

const SecondaryCtaIcon: React.FC<SecondaryCtaIconProps> = (props) => {
  const iconProps: SizedIconProps = { sizePx: ExtraLargeIconSizePx() };
  switch (props.type) {
    case "pricing": return <TagSolidIcon {...iconProps}/>
    case "tryForFree": return (
      <div style={{display: 'flex', gap: SmallPaddingPx()}}>
        <TrainDuotoneIcon {...iconProps}/>
        <PlaneDuotoneIcon {...iconProps}/>
      </div>
    )
  }
}