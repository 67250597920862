import { ArrowIcon } from "@routezero-site/component/helper/arrow";
import { ArrowButton } from "@routezero-site/component/helper/button";
import { useDebounce } from "@routezero-site/component/helper/debounce";
import { FadedOpacity, FastAnimationCurve, HoverScalePercentage as ActiveScalePercentage, LargeBorderRadiusPx, LargeShadow, MedAnimationDurationSec, ShortAnimationDurationSec, SmallBorderRadiusPx, SmallPaddingPx } from "@routezero-site/component/helper/theme";
import { PropsWithChildren, ReactNode, createContext, useContext, useEffect, useRef, useState } from "react";

export interface IsActiveContextProps {
  isActive: boolean
}

const IsActiveContext = createContext<IsActiveContextProps>({ 
  isActive: false 
});

/**
 * Retrieve whether a parent is "active" (e.g. hovered).
 */
export const useIsActiveContext = () => useContext(IsActiveContext);

export interface IsActiveProviderProps {
  isActive: boolean
  children: ReactNode
}

/**
 * Child components can make use of `useIsActiveContext` to recognise 
 * when a parent is "active", e.g. hovered.
 */
export const IsActiveProvider: React.FC<IsActiveProviderProps> = (props) => {
  return (
    <IsActiveContext.Provider value={{ isActive: props.isActive }}>
      {props.children}
    </IsActiveContext.Provider>
  );
};

export interface HoverIsActiveRecogniserProps {
  style?: React.CSSProperties
  /**
   * If defined, this amount of time will be waited before the hover event
   * is recognised and `onHover` is called.
   */
  debounceDelayMs?: number
  /**
   * Called when the child components are hovered.
   */
  onHover?: () => void
  children: ReactNode
}

/**
 * Recognises hover events and passes them down to children. Children can
 * access the hover state using `useIsActiveContext`.
 */
export const HoverIsActiveRecogniser: React.FC<HoverIsActiveRecogniserProps> = (props) => {
  const [isHovered, setIsHovered] = useState(false);

  const { start: startHover, stop: stopHover } = useDebounce({
    delayMs: props.debounceDelayMs,
    callback: () => {
      setIsHovered(true);
      props.onHover?.();
    }
  });

  return (
    <IsActiveProvider isActive={isHovered}>
      <div 
        style={{...props.style, cursor: 'pointer'}}
        onMouseEnter={startHover} 
        onMouseLeave={() => {
          stopHover();
          setIsHovered(false);
        }}
      >
        {props.children}
      </div>
    </IsActiveProvider>
  );
};

export interface AnimateScaleOnParentActiveProps {
  overrideIsActive?: boolean
  style?: React.CSSProperties
  children: ReactNode
}

/**
 * Animates the children if a parent component is "active" (e.g. hovered).
 */
export const AnimateScaleOnActive: React.FC<AnimateScaleOnParentActiveProps> = (props) => {
  const { isActive } = useIsActiveContext();
  const activePercentDelta = ActiveScalePercentage();
  const a = props.overrideIsActive ?? isActive;

  return (
    <div style={{
      ...props.style,
      width: '100%',
      height: '100%',
      // Not sure why this is off by 1px...
      transform: a ? `scale(100%)` : `scale(${100 + activePercentDelta}%) translateY(${activePercentDelta*0.5}%) translateY(-1px)`,
      transition: `transform ${MedAnimationDurationSec()}s ${FastAnimationCurve()}`
    }}>
      {props.children}
    </div>
  );
}

// Define the interface for the component props
export interface SlideUpRevealProps extends PropsWithChildren {
  gapPx?: number
  slideDistMultipler?: number
  style?: React.CSSProperties
}

/**
 * Shows the first components in the children, and then the component when
 * a parent is "active" (e.g. hovered).
 */
export const SlideUpRevealOnActive: React.FC<SlideUpRevealProps> = (props) => {
  const { isActive } = useIsActiveContext();
  const secondChildRef = useRef<HTMLDivElement>(null);
  const [secondChildHeight, setSecondChildHeight] = useState(0);

  useEffect(() => {
    if (secondChildRef.current) {
      setSecondChildHeight(secondChildRef.current.offsetHeight);
    }
  }, [secondChildRef]);

  // Ensure that children is an array with two elements
  if (!Array.isArray(props.children) || props.children.length < 2) {
    console.error(`SlideUpReveal requires two or more children, got ${props.children}`);
    return null;
  }

  // Slightly longer for a slight delay entering.
  const secondChildDurationSec = MedAnimationDurationSec() * 1.2;

  // The distance to translate by when not active.
  const slideDist = secondChildHeight * (props.slideDistMultipler ?? 1);

  return (
    <div style={{
      ...props.style,
      display: 'flex',
      flexDirection: 'column',
      gap: props.gapPx,
      transition: `transform 0${MedAnimationDurationSec()}s ${FastAnimationCurve()}`,
      transform: isActive ? `translateY(0)` : `translateY(${slideDist+(props.gapPx ?? 0)}px)`,
    }}>
      {props.children.slice(0, props.children.length-1)}
      <div ref={secondChildRef} style={{
        transform: isActive ? 'translateY(0)' : `translateY(${slideDist}px)`,
        opacity: isActive ? 1 : 0,
        transition: `transform ${secondChildDurationSec}s ${FastAnimationCurve()}, opacity ${secondChildDurationSec}s ${FastAnimationCurve()}`
      }}>
        {props.children.slice(props.children.length-1, props.children.length)}
      </div>
    </div>
  );
};


/**
 * Arrow icon that extends when a parent is "active" (e.g. hovered).
 */
export const AnimatedArrowOnActive: React.FC = () => {
  const { isActive } = useIsActiveContext();
  return (
    <ArrowIcon isActive={isActive}/>
  );
};

export interface CallToActionImageCardOnActiveProps {
  imageSrc: string
  imageAlt: string
  body: ReactNode
  callToAction: ReactNode
  backgroundColor: string
  /** 
   * Total height of the card. Required to ensure consistent sizing while 
   * elements on the card are resized/move.
   */
  heightPx: number
  /** Gap between the image and body/CTA */
  imageGapPx: number
  /** Gap between the CTA and the bottom of the card */
  cardBottomGapPx: number
}

/**
 * Card with an image at the top and text at the bottom. When "active" (e.g. 
 * hovered), the image moves and takes up less space, revealing a call to action.
 */
export const CallToActionImageCardOnActive: React.FC<CallToActionImageCardOnActiveProps> = (props) => {
  const { isActive } = useIsActiveContext();

  // Used to move the image upwards by the height of the call to action.
  const ctaRef = useRef<HTMLDivElement>(null);
  const [ctaHeight, setCtaHeight] = useState(0);

  // Used to measure the height of the image, so we can set/adjust the image 
  // height from the height it "wants" to be (filling width and maintaining
  // aspect ratio).
  const imageContainerRef = useRef<HTMLImageElement>(null);
  const [intermediateImageContainerHeight, setImageContainerHeight] = useState<string | null>(null);

  useEffect(() => {
    if (ctaRef.current) {
      setCtaHeight(ctaRef.current.offsetHeight);
    }
  }, [ctaRef]);

  function onImageLoad() {
    setImageContainerHeight(`${imageContainerRef.current?.offsetHeight ?? 0}px`);
  }

  function imageContainerHeight() {
    // The image container hasn't been rendered yet, and it's height hasn't 
    // been retrieved. We want React to layout the image with it's natural 
    // height, so it can be retieved, therefore, 'auto' is used.
    if (intermediateImageContainerHeight === null) {
      return 'auto';
    }
    // Once we've got the natural height of the image, we can make use of it.
    return isActive 
      ? `calc(${intermediateImageContainerHeight} - ${ctaHeight}px)` 
      // Not sure why it seems to be off by 1px...
      : `calc(${intermediateImageContainerHeight} - 1px)`;
  }

  return (
    <div style={{
      ...LargeShadow(),
      borderRadius: LargeBorderRadiusPx(),
      backgroundColor: props.backgroundColor,
      display: 'flex',
      flexDirection: 'column',
      height: props.heightPx,
      overflow: 'hidden', 
    }}>
      {/* 
      Extra div to avoid adding margin to img. By adding margin, instead of
      padding, we need to manually decrease the width of the image.
      */}
      <div ref={imageContainerRef} style={{
        width: '100%',
        height: imageContainerHeight(),
        padding: `${SmallPaddingPx()}px ${SmallPaddingPx()}px ${props.imageGapPx}px ${SmallPaddingPx()}px`,
        transition: `height ${MedAnimationDurationSec()}s ${FastAnimationCurve()}`,
        display: 'flex',
        justifyContent: 'center'
      }}>
        <img
          src={props.imageSrc} 
          alt={props.imageAlt}
          onLoad={onImageLoad}
          style={{
            width: '100%',
            borderRadius: SmallBorderRadiusPx(),
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
      </div>
      <div style={{
        width: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginBottom: props.cardBottomGapPx
      }}>
        {props.body}
        <div ref={ctaRef} style={{
          opacity: isActive ? 1 : 0,
          transform: isActive ? 'translateY(0)' : 'translateY(100%)',
          transition: `transform ${MedAnimationDurationSec()}s ${FastAnimationCurve()}, opacity ${MedAnimationDurationSec()}s ${FastAnimationCurve()}`
        }}>
          {props.callToAction}
        </div>
      </div>
    </div>
  );
};

export interface FadeOutOnActiveProps {
  children: ReactNode
}

/**
 * Fades out child components when the parent is "active" (e.g. hovered).
 */
export const FadeOutOnActive: React.FC<FadeOutOnActiveProps> = (props) => {
  const { isActive } = useIsActiveContext();

  return (
    <div style={{
      opacity: isActive ? FadedOpacity() : 1.0,
      transition: `opacity ${ShortAnimationDurationSec()}s`
    }}>
      {props.children}
    </div>
  );
};

/**
 * "Learn more" text, with an extending arrow.
 */
export const LearnMoreOnParentAction: React.FC = () => {
  return (
    <ArrowButton text="Learn more"/>
  )
};