"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfNearbyTrainStationResponse = instanceOfNearbyTrainStationResponse;
exports.NearbyTrainStationResponseFromJSON = NearbyTrainStationResponseFromJSON;
exports.NearbyTrainStationResponseFromJSONTyped = NearbyTrainStationResponseFromJSONTyped;
exports.NearbyTrainStationResponseToJSON = NearbyTrainStationResponseToJSON;
var TrainlineStation_1 = require("./TrainlineStation");
/**
 * Check if a given object implements the NearbyTrainStationResponse interface.
 */
function instanceOfNearbyTrainStationResponse(value) {
    var isInstance = true;
    isInstance = isInstance && "origins" in value;
    isInstance = isInstance && "destinations" in value;
    return isInstance;
}
function NearbyTrainStationResponseFromJSON(json) {
    return NearbyTrainStationResponseFromJSONTyped(json, false);
}
function NearbyTrainStationResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'origins': (json['origins'].map(TrainlineStation_1.TrainlineStationFromJSON)),
        'destinations': (json['destinations'].map(TrainlineStation_1.TrainlineStationFromJSON)),
    };
}
function NearbyTrainStationResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'origins': (value.origins.map(TrainlineStation_1.TrainlineStationToJSON)),
        'destinations': (value.destinations.map(TrainlineStation_1.TrainlineStationToJSON)),
    };
}
