"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfKeyValueJourney = instanceOfKeyValueJourney;
exports.KeyValueJourneyFromJSON = KeyValueJourneyFromJSON;
exports.KeyValueJourneyFromJSONTyped = KeyValueJourneyFromJSONTyped;
exports.KeyValueJourneyToJSON = KeyValueJourneyToJSON;
var Journey_1 = require("./Journey");
var KeyValueJourneyType_1 = require("./KeyValueJourneyType");
/**
 * Check if a given object implements the KeyValueJourney interface.
 */
function instanceOfKeyValueJourney(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "values" in value;
    isInstance = isInstance && "journey" in value;
    return isInstance;
}
function KeyValueJourneyFromJSON(json) {
    return KeyValueJourneyFromJSONTyped(json, false);
}
function KeyValueJourneyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, KeyValueJourneyType_1.KeyValueJourneyTypeFromJSON)(json['type']),
        'id': json['id'],
        'values': json['values'],
        'journey': (0, Journey_1.JourneyFromJSON)(json['journey']),
    };
}
function KeyValueJourneyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, KeyValueJourneyType_1.KeyValueJourneyTypeToJSON)(value.type),
        'id': value.id,
        'values': value.values,
        'journey': (0, Journey_1.JourneyToJSON)(value.journey),
    };
}
