import { FathomAnalyticsService } from "@routezero-site/service/analytics/fathom_analytics";
import { LocalCaseStudyStatsService } from "@routezero-site/service/case-study-stats/local_case_study_stats";
import { LocalCompetitiveEdgeFeaturesService } from "@routezero-site/service/competitive-edge-features/local_competitive_edge_features";
import { LocalCustomerQuotesService } from "@routezero-site/service/customer-quotes/local_customer_quotes";
import { LocalDecarbonisationFeaturesService } from "@routezero-site/service/decarbonisation-features/local_decarbonisation_features";
import { LiveLinksService } from "@routezero-site/service/links/live_links_service";
import { LocalPricingPlansService } from "@routezero-site/service/pricing-plans/local_pricing_plans";
import { LiveRecordProspectService } from "@routezero-site/service/record-prospect/live_record_prospect";
import { LocalScienceBackedFeaturesService } from "@routezero-site/service/science-backed-features/local_science_backed_features";
import { Services } from "@routezero-site/service/services/services";
import { LocalTravelPlatformFeaturesService } from "@routezero-site/service/travel-platform-features/local_travel_platform_features";

/**
 * Services used when the site is deployed.
 */
export class LiveServices implements Services {
  public travelPlatformFeaturesService = new LocalTravelPlatformFeaturesService();
  public decarbonisationFeaturesService = new LocalDecarbonisationFeaturesService();
  public scienceBackedFeaturesService = new LocalScienceBackedFeaturesService();
  public competitiveEdgeFeaturesService = new LocalCompetitiveEdgeFeaturesService();
  public customerQuotesService = new LocalCustomerQuotesService();
  public caseStudyStatsService = new LocalCaseStudyStatsService();
  public pricingPlansService = new LocalPricingPlansService();
  public recordProspectService = new LiveRecordProspectService();
  public linksService = new LiveLinksService();
  public analyticsService = new FathomAnalyticsService();
}