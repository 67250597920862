import { FooterSection } from "@routezero-site/component/helper/footer";
import { PricingPageFooterBackgroundColor, PricingPageFooterColor } from "@routezero-site/component/helper/theme";
import { PricingPlansSection } from "@routezero-site/component/page/pricing/pricing-plans/pricing_plans_section";

/**
 * Tells the user about the price to access RouteZero.
 */
export const PricingPage: React.FC = () => {
  return (
    <>
      <PricingPlansSection/>
      <FooterSection
        color={PricingPageFooterColor()}
        backgroundColor={PricingPageFooterBackgroundColor()}
      />
    </>
  );
};