import { IsMobile, IsTabletOrDesktop, mediaStyle } from "@routezero-site/component/helper/breakpoint";
import usePromise from "@routezero-site/component/helper/promise";
import { GlobeIcon } from "@routezero-site/component/helper/icon";
import { useServicesContext } from "@routezero-site/component/helper/services";
import { LargeBorderRadiusPx, LargePaddingPx, LargeShadow, MedPaddingPx, SmallPaddingPx } from "@routezero-site/component/helper/theme";
import { CompetitiveEdgeStat } from "@routezero-site/service/competitive-edge-features/competitive_edge_features";
import statBackgroundImage1 from "@routezero-site/component/page/home/competitive-edge/media/stat_background1.jpg";
import statBackgroundImage2 from "@routezero-site/component/page/home/competitive-edge/media/stat_background2.jpg";
import statBackgroundImage3 from "@routezero-site/component/page/home/competitive-edge/media/stat_background3.jpg";
import { AnimateScaleOnActive, HoverIsActiveRecogniser, SlideUpRevealOnActive } from "@routezero-site/component/helper/active_action";
import { smallFontClassName } from "@routezero-site/component/helper/global_style";

/**
 * Statistics showing how companies that value sustainability gain a 
 * competitive edge.
 */
export const CompetitiveEdgeStats: React.FC = () => {
  const { competitiveEdgeFeaturesService } = useServicesContext();
  const [ data ] = usePromise(competitiveEdgeFeaturesService.stats);
  const stats = data ?? [];
  
  return (
    <div style={{
      display: 'flex',
      gap: MedPaddingPx(),
      ...mediaStyle(IsTabletOrDesktop(), {
        flexDirection: 'row'
      }),
      ...mediaStyle(IsMobile(), {
        flexDirection: 'column'
      }),
    }}>
      {stats.map((stat, i) => 
        <StatCard
          key={i}
          stat={stat}
          backgroundImageSrc={statBackgroundImageSrc(i)}
        />
      )}
    </div>
  );
};

interface StatCardProps {
  backgroundImageSrc: string
  stat: CompetitiveEdgeStat
}

/**
 * Square card showing a large headline statistic, with body text and a link to the 
 * source for the statistic.
 */
const StatCard: React.FC<StatCardProps> = (props) => {
  return (
    <HoverIsActiveRecogniser style={{
      width: '100%',
      borderRadius: LargeBorderRadiusPx(),
      position: 'relative',
      overflow: 'hidden',
      ...LargeShadow(),
      ...mediaStyle(IsTabletOrDesktop(), {
        aspectRatio: '1/1',
      }),
      ...mediaStyle(IsMobile(), {
        paddingBottom: LargePaddingPx()
      }),
    }}>
      <a href={props.stat.sourceUrl} target="_blank" rel="noopener noreferrer">
        <AnimateScaleOnActive style={{
          zIndex: 0,
          position: 'absolute',
          backgroundColor: 'red'
        }}>
          <img
            src={props.backgroundImageSrc}
            alt={props.stat.headlineStat}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'center'
            }}
          />
        </AnimateScaleOnActive>
        <div style={{
          zIndex: 1,
          position: 'relative',
          width: '100%',
          height: '100%',
          color: 'white',
          padding: LargePaddingPx(),
        }}>
          <SlideUpRevealOnActive 
            gapPx={SmallPaddingPx()}
            style={{
              height: '100%'
            }}
          >
            <h2>
              {props.stat.headlineStat}
            </h2>
            <p style={{
              fontSize: '1.2rem',
              fontWeight: '500'
            }}>
              {props.stat.body}
            </p>
            {/* To position the source at the bottom */}
            <div style={{flexGrow: 1}}/>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              gap: SmallPaddingPx()
            }}>
              <GlobeIcon/>
              <p className={smallFontClassName}>
                {props.stat.sourceText}
              </p>
            </div>
          </SlideUpRevealOnActive>
        </div>
      </a>
    </HoverIsActiveRecogniser>
  );
};

function statBackgroundImageSrc(statIndex: number): string {
  if (statIndex % 3 === 0) {
    return statBackgroundImage1;
  } else if (statIndex % 3 === 1) {
    return statBackgroundImage2;
  } else {
    return statBackgroundImage3;
  }
}