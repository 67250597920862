import { SectionContainer } from '@routezero-site/component/helper/section_container';
import { SectionMainText } from '@routezero-site/component/helper/section_main_text';
import { CaseStudyVideo } from '@routezero-site/component/page/home/case-study/case_study_video';
import sectionBackgroundImage from '@routezero-site/component/page/home/media/sky.jpg';

const textColor = "#026173";

/**
 * A case study by Logicalis.
 */
export const CaseStudySection: React.FC = () => {
  return (
    <SectionContainer
      backgroundImage={sectionBackgroundImage}
      color={textColor}
    >
      <SectionMainText
        color={textColor}
        subtitleBackgroundColor="#212121"
        subtitleBackgroundBlendMode="soft-light"
        header="Built for businesses"
        subtitle="Enterprise-grade"
        body="RouteZero is trusted by world-leading organisations. Discover how our robust, enterprise-ready platform empowers organisations to reduce their emissions and travel costs while balancing existing business operations."
      />
      <CaseStudyVideo/>
    </SectionContainer>
  );
}
