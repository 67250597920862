"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfPassengerTicketNumbersTickets = instanceOfPassengerTicketNumbersTickets;
exports.PassengerTicketNumbersTicketsFromJSON = PassengerTicketNumbersTicketsFromJSON;
exports.PassengerTicketNumbersTicketsFromJSONTyped = PassengerTicketNumbersTicketsFromJSONTyped;
exports.PassengerTicketNumbersTicketsToJSON = PassengerTicketNumbersTicketsToJSON;
var PassengerTicketNumber_1 = require("./PassengerTicketNumber");
var PassengerTicketNumbersTicketsType_1 = require("./PassengerTicketNumbersTicketsType");
/**
 * Check if a given object implements the PassengerTicketNumbersTickets interface.
 */
function instanceOfPassengerTicketNumbersTickets(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "passengerTicketNumbers" in value;
    return isInstance;
}
function PassengerTicketNumbersTicketsFromJSON(json) {
    return PassengerTicketNumbersTicketsFromJSONTyped(json, false);
}
function PassengerTicketNumbersTicketsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, PassengerTicketNumbersTicketsType_1.PassengerTicketNumbersTicketsTypeFromJSON)(json['type']),
        'passengerTicketNumbers': (json['passengerTicketNumbers'].map(PassengerTicketNumber_1.PassengerTicketNumberFromJSON)),
    };
}
function PassengerTicketNumbersTicketsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, PassengerTicketNumbersTicketsType_1.PassengerTicketNumbersTicketsTypeToJSON)(value.type),
        'passengerTicketNumbers': (value.passengerTicketNumbers.map(PassengerTicketNumber_1.PassengerTicketNumberToJSON)),
    };
}
