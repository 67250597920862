"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfMoreDetailedStayRequest = instanceOfMoreDetailedStayRequest;
exports.MoreDetailedStayRequestFromJSON = MoreDetailedStayRequestFromJSON;
exports.MoreDetailedStayRequestFromJSONTyped = MoreDetailedStayRequestFromJSONTyped;
exports.MoreDetailedStayRequestToJSON = MoreDetailedStayRequestToJSON;
var InternalBookingMethodSpecific_1 = require("./InternalBookingMethodSpecific");
/**
 * Check if a given object implements the MoreDetailedStayRequest interface.
 */
function instanceOfMoreDetailedStayRequest(value) {
    var isInstance = true;
    isInstance = isInstance && "bookingMethod" in value;
    isInstance = isInstance && "adultCount" in value;
    isInstance = isInstance && "roomCount" in value;
    return isInstance;
}
function MoreDetailedStayRequestFromJSON(json) {
    return MoreDetailedStayRequestFromJSONTyped(json, false);
}
function MoreDetailedStayRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'bookingMethod': (0, InternalBookingMethodSpecific_1.InternalBookingMethodSpecificFromJSON)(json['bookingMethod']),
        'adultCount': json['adultCount'],
        'roomCount': json['roomCount'],
    };
}
function MoreDetailedStayRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'bookingMethod': (0, InternalBookingMethodSpecific_1.InternalBookingMethodSpecificToJSON)(value.bookingMethod),
        'adultCount': value.adultCount,
        'roomCount': value.roomCount,
    };
}
