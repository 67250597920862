import { ReactComponent as CambridgeLogoSvg } from '@routezero-site/component/helper/media/logo/cambridge_logo.svg';
import { ReactComponent as BristolUniLogoSvg } from '@routezero-site/component/helper/media/logo/university_of_bristol_logo.svg';
import { ReactComponent as LogicalisLogoSvg } from '@routezero-site/component/helper/media/logo/logicalis_logo.svg';
import { ReactComponent as RabLogoSvg } from '@routezero-site/component/helper/media/logo/rab_logo.svg';
import { ReactComponent as RouteZeroLogoSvg } from '@routezero-site/component/helper/media/logo/routezero_logo.svg';
import { ReactComponent as UkGovLogoSvg } from '@routezero-site/component/helper/media/logo/uk_gov_logo.svg';
import { ReactComponent as UkkaLogoSvg } from '@routezero-site/component/helper/media/logo/ukka_logo.svg';
import { ReactComponent as NERCLogoSvg } from '@routezero-site/component/helper/media/logo/nerc_logo.svg';
import { ReactComponent as LeonardoLogoSvg } from '@routezero-site/component/helper/media/logo/leonardo_logo.svg';
import { ReactComponent as CapgeminiLogoSvg } from '@routezero-site/component/helper/media/logo/capgemini_logo.svg';
import { ReactComponent as NhsLogoSvg } from '@routezero-site/component/helper/media/logo/nhs-logo.svg';
import cambridgeInstituteForSustainabilityPic from '@routezero-site/component/helper/media/logo/cambridge_institute_for_sustainability_logo.png';
import { CustomerLogoMaxSizePx, RouteZeroLogoSizePx } from "@routezero-site/component/helper/theme";
import { ReactNode } from "react";

interface CustomerLogoProps {
  /**
   * If provided, the width of the logo will be set to this. Otherwise, the 
   * logos will be sized according to the theme, and adjusted to ensure 
   * consistent sizing when logos are viewed next to each other.
   */
  width?: number
  style?: React.CSSProperties
}

export const RouteZeroLogo: React.FC = () => {
  return (
    <RouteZeroLogoSvg 
      stroke='currentColor'
      width={RouteZeroLogoSizePx()}
      height={RouteZeroLogoSizePx()}
    />
  );
};

export const RabLogo: React.FC<CustomerLogoProps> = (props) => {
  return (
    <CustomerLogoContainer 
      hasDefinedWidth={customerLogoHasDefinedWidth(props)}
      translateYPercent={-1.5}
    >
      <RabLogoSvg style={props.style} {...CustomerLogoArgs({ sizeMultiplier: 0.72, ...props })}/>
    </CustomerLogoContainer>
  );
};

export const CambridgeLogo: React.FC<CustomerLogoProps> = (props) => {
  return (
    <CustomerLogoContainer 
      hasDefinedWidth={customerLogoHasDefinedWidth(props)}
      translateYPercent={-2}
    >
      <CambridgeLogoSvg style={props.style} {...CustomerLogoArgs({ sizeMultiplier: 0.79, ...props })}/>
    </CustomerLogoContainer>
  );
};

export const LogicalisLogo: React.FC<CustomerLogoProps> = (props) => {
  return (
    <CustomerLogoContainer hasDefinedWidth={customerLogoHasDefinedWidth(props)}>
      <LogicalisLogoSvg style={props.style} {...CustomerLogoArgs({ sizeMultiplier: 1, ...props })}/>
    </CustomerLogoContainer>
  );
};

export const UkkaLogo: React.FC<CustomerLogoProps> = (props) => {
  return (
    <CustomerLogoContainer 
      hasDefinedWidth={customerLogoHasDefinedWidth(props)}
      translateYPercent={-1}
    >
      <UkkaLogoSvg style={props.style} {...CustomerLogoArgs({ sizeMultiplier: 0.65, ...props })}/>
    </CustomerLogoContainer>
  );
};

export const UkGovLogo: React.FC<CustomerLogoProps> = (props) => {
  return (
    <CustomerLogoContainer 
      hasDefinedWidth={customerLogoHasDefinedWidth(props)}
      translateYPercent={-6.5}
    >
      <UkGovLogoSvg style={props.style} {...CustomerLogoArgs({ sizeMultiplier: 0.9, ...props })}/>
    </CustomerLogoContainer>
  );
};

export const BristolUniLogo: React.FC<CustomerLogoProps> = (props) => {
  return (
    <CustomerLogoContainer 
      hasDefinedWidth={customerLogoHasDefinedWidth(props)}
    >
      <BristolUniLogoSvg style={props.style} {...CustomerLogoArgs({ sizeMultiplier: 1.0, ...props })}/>
    </CustomerLogoContainer>
  );
};

export const NERCLogo: React.FC<CustomerLogoProps> = (props) => {
  return (
    <CustomerLogoContainer 
      hasDefinedWidth={customerLogoHasDefinedWidth(props)}
    >
      <NERCLogoSvg style={props.style} {...CustomerLogoArgs({ sizeMultiplier: 1.0, ...props })}/>
    </CustomerLogoContainer>
  );
};

export const LeonardoLogo: React.FC<CustomerLogoProps> = (props) => {
  return (
    <CustomerLogoContainer 
      hasDefinedWidth={customerLogoHasDefinedWidth(props)}
    >
      <LeonardoLogoSvg style={props.style} {...CustomerLogoArgs({ sizeMultiplier: 1.0, ...props })}/>
    </CustomerLogoContainer>
  );
};

export const CapgeminiLogo: React.FC<CustomerLogoProps> = (props) => {
  return (
    <CustomerLogoContainer 
      hasDefinedWidth={customerLogoHasDefinedWidth(props)}
    >
      <CapgeminiLogoSvg style={props.style} {...CustomerLogoArgs({ sizeMultiplier: 1.0, ...props })}/>
    </CustomerLogoContainer>
  );
};

export const NhsLogo: React.FC<CustomerLogoProps> = (props) => {
  return (
    <CustomerLogoContainer 
      hasDefinedWidth={customerLogoHasDefinedWidth(props)}
      translateYPercent={-3}
    >
      <NhsLogoSvg style={props.style} {...CustomerLogoArgs({ sizeMultiplier: 0.51, ...props })}/>
    </CustomerLogoContainer>
  );
};

export const CambridgeInstituteForSustainabilityLogo: React.FC<CustomerLogoProps> = (props) => {
  return (
    <CustomerLogoContainer 
      hasDefinedWidth={customerLogoHasDefinedWidth(props)}
    >
      <img src={cambridgeInstituteForSustainabilityPic} width={props.width} style={props.style}/>
    </CustomerLogoContainer>
  );
};

interface CustomerLogoContainerProps {
  hasDefinedWidth: boolean
  translateYPercent?: number
  children: ReactNode
}

/**
 * Square box container for customer logos to ensure consistent size / spacing.
 */
const CustomerLogoContainer: React.FC<CustomerLogoContainerProps> = (props) => {
  if (props.hasDefinedWidth) {
    return (
      <>
        {props.children}
      </>
    );
  }
  return (
    <div style={{
      aspectRatio: '1/1',
      width: CustomerLogoMaxSizePx(),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transform: `translateY(${props.translateYPercent ?? 0}%)`
    }}>
      {props.children}
    </div>
  );
};

interface CustomerLogoPopsArgs extends CustomerLogoProps {
  sizeMultiplier: number
}

function CustomerLogoArgs(args: CustomerLogoPopsArgs) {
  if (args.sizeMultiplier > 1) {
    throw new Error(`Customer logo size multiplier must be less than or equal to 1`);
  }
  const maxSize = CustomerLogoMaxSizePx() * args.sizeMultiplier;
  return {
    fill: 'currentColor',
    width: args.width !== undefined ? args.width : maxSize,
    height: args.width !== undefined ? undefined : maxSize,
  };
}

function customerLogoHasDefinedWidth(props: CustomerLogoProps): boolean {
  return props.width !== undefined;
}