"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfDetailsResponse = instanceOfDetailsResponse;
exports.DetailsResponseFromJSON = DetailsResponseFromJSON;
exports.DetailsResponseFromJSONTyped = DetailsResponseFromJSONTyped;
exports.DetailsResponseToJSON = DetailsResponseToJSON;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the DetailsResponse interface.
 */
function instanceOfDetailsResponse(value) {
    var isInstance = true;
    return isInstance;
}
function DetailsResponseFromJSON(json) {
    return DetailsResponseFromJSONTyped(json, false);
}
function DetailsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
    };
}
function DetailsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
    };
}
