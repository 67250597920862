import { IsMobile, IsTabletOrDesktop, mediaStyle } from "@routezero-site/component/helper/breakpoint";
import { CircleDollarDuotoneIcon, ForwardDuotoneIcon, GearsDuotoneIcon, PeopleGroupDuotoneIcon } from "@routezero-site/component/helper/icon";
import { IconTextColumn } from "@routezero-site/component/helper/icon_text_column";
import usePromise from "@routezero-site/component/helper/promise";
import { useServicesContext } from "@routezero-site/component/helper/services";
import { ExtraLargeIconSizePx, LargePaddingPx, MedPaddingPx } from "@routezero-site/component/helper/theme";
import { CompetitiveEdgeFeature, CompetitiveEdgeFeatureIconType } from "@routezero-site/service/competitive-edge-features/competitive_edge_features";

export interface CompetitiveEdgeFeaturesProps {
  color: React.CSSProperties["color"]
}

/**
 * Tells the user about features of RouteZero that will provide a competitive
 * edge through adopting sustainability.
 */
export const CompetitiveEdgeFeatures: React.FC<CompetitiveEdgeFeaturesProps> = (props) => {
  const { competitiveEdgeFeaturesService } = useServicesContext();
  const [ data ] = usePromise(competitiveEdgeFeaturesService.features);
  const features = data ?? [];
  
  return (
    <div style={{
      display: 'flex',
      ...mediaStyle(IsTabletOrDesktop(), {
        flexDirection: 'row',
        gap: MedPaddingPx(),
      }),
      ...mediaStyle(IsMobile(), {
        flexDirection: 'column',
        gap: LargePaddingPx(),
      }),
    }}>
      {features.map((feature, i) => 
        <Feature
          key={i}
          color={props.color}
          feature={feature}
        />
      )}
    </div>
  );
};

interface FeatureProps {
  color: React.CSSProperties["color"]
  feature: CompetitiveEdgeFeature
}

/**
 * Tells the user about a feature of RouteZero that will provide a competitive
 * edge through adopting sustainability.
 */
const Feature: React.FC<FeatureProps> = (props) => {
  return (
    <IconTextColumn
      icon={<FeatureIcon icon={props.feature.icon}/>}
      title={props.feature.title}
      body={props.feature.description}
    />
  );
};

interface FeatureIconProps {
  icon: CompetitiveEdgeFeatureIconType
}

/**
 * Icon associated with a feature.
 */
const FeatureIcon: React.FC<FeatureIconProps> = (props) => {
  switch (props.icon) {
    case "coin": return <CircleDollarDuotoneIcon sizePx={ExtraLargeIconSizePx()}/>;
    case "fastForward": return <ForwardDuotoneIcon sizePx={ExtraLargeIconSizePx()}/>;
    case "gears": return <GearsDuotoneIcon sizePx={ExtraLargeIconSizePx()}/>;
    case "peopleGroup": return <PeopleGroupDuotoneIcon sizePx={ExtraLargeIconSizePx()}/>;
  }
};