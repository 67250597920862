"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TicketFromJSON = TicketFromJSON;
exports.TicketFromJSONTyped = TicketFromJSONTyped;
exports.TicketToJSON = TicketToJSON;
var CollectionRefTicket_1 = require("./CollectionRefTicket");
var ExternalLoginTicket_1 = require("./ExternalLoginTicket");
var PassengerTicketNumbersTickets_1 = require("./PassengerTicketNumbersTickets");
var PdfTicket_1 = require("./PdfTicket");
function TicketFromJSON(json) {
    return TicketFromJSONTyped(json, false);
}
function TicketFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'collectionRef':
            return __assign(__assign({}, (0, CollectionRefTicket_1.CollectionRefTicketFromJSONTyped)(json, true)), { type: 'collectionRef' });
        case 'externalLogin':
            return __assign(__assign({}, (0, ExternalLoginTicket_1.ExternalLoginTicketFromJSONTyped)(json, true)), { type: 'externalLogin' });
        case 'passengerTicketNumbers':
            return __assign(__assign({}, (0, PassengerTicketNumbersTickets_1.PassengerTicketNumbersTicketsFromJSONTyped)(json, true)), { type: 'passengerTicketNumbers' });
        case 'pdf':
            return __assign(__assign({}, (0, PdfTicket_1.PdfTicketFromJSONTyped)(json, true)), { type: 'pdf' });
        default:
            throw new Error("No variant of Ticket exists with 'type=".concat(json['type'], "'"));
    }
}
function TicketToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'collectionRef':
            return (0, CollectionRefTicket_1.CollectionRefTicketToJSON)(value);
        case 'externalLogin':
            return (0, ExternalLoginTicket_1.ExternalLoginTicketToJSON)(value);
        case 'passengerTicketNumbers':
            return (0, PassengerTicketNumbersTickets_1.PassengerTicketNumbersTicketsToJSON)(value);
        case 'pdf':
            return (0, PdfTicket_1.PdfTicketToJSON)(value);
        default:
            throw new Error("No variant of Ticket exists with 'type=".concat(value['type'], "'"));
    }
}
