"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfEachWaySearchRequest = instanceOfEachWaySearchRequest;
exports.EachWaySearchRequestFromJSON = EachWaySearchRequestFromJSON;
exports.EachWaySearchRequestFromJSONTyped = EachWaySearchRequestFromJSONTyped;
exports.EachWaySearchRequestToJSON = EachWaySearchRequestToJSON;
var runtime_1 = require("../runtime");
var InwardDateTime_1 = require("./InwardDateTime");
var OneWaySearchRequest_1 = require("./OneWaySearchRequest");
/**
 * Check if a given object implements the EachWaySearchRequest interface.
 */
function instanceOfEachWaySearchRequest(value) {
    var isInstance = true;
    isInstance = isInstance && "outwardRequest" in value;
    return isInstance;
}
function EachWaySearchRequestFromJSON(json) {
    return EachWaySearchRequestFromJSONTyped(json, false);
}
function EachWaySearchRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'outwardRequest': (0, OneWaySearchRequest_1.OneWaySearchRequestFromJSON)(json['outwardRequest']),
        'inwardDateTime': !(0, runtime_1.exists)(json, 'inwardDateTime') ? undefined : (0, InwardDateTime_1.InwardDateTimeFromJSON)(json['inwardDateTime']),
    };
}
function EachWaySearchRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'outwardRequest': (0, OneWaySearchRequest_1.OneWaySearchRequestToJSON)(value.outwardRequest),
        'inwardDateTime': (0, InwardDateTime_1.InwardDateTimeToJSON)(value.inwardDateTime),
    };
}
