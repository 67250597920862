"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.JourneyFromJSON = JourneyFromJSON;
exports.JourneyFromJSONTyped = JourneyFromJSONTyped;
exports.JourneyToJSON = JourneyToJSON;
var BookJourney_1 = require("./BookJourney");
var ChoiceJourney_1 = require("./ChoiceJourney");
var Co2eJourney_1 = require("./Co2eJourney");
var GroupJourney_1 = require("./GroupJourney");
var HiddenJourney_1 = require("./HiddenJourney");
var IdJourney_1 = require("./IdJourney");
var KeyValueJourney_1 = require("./KeyValueJourney");
var ManyJourney_1 = require("./ManyJourney");
var OfferJourney_1 = require("./OfferJourney");
var PriceJourney_1 = require("./PriceJourney");
var StopJourney_1 = require("./StopJourney");
var SwapJourney_1 = require("./SwapJourney");
var SwapPriceJourney_1 = require("./SwapPriceJourney");
var TravelJourney_1 = require("./TravelJourney");
var UnavailableJourney_1 = require("./UnavailableJourney");
function JourneyFromJSON(json) {
    return JourneyFromJSONTyped(json, false);
}
function JourneyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'book':
            return __assign(__assign({}, (0, BookJourney_1.BookJourneyFromJSONTyped)(json, true)), { type: 'book' });
        case 'choice':
            return __assign(__assign({}, (0, ChoiceJourney_1.ChoiceJourneyFromJSONTyped)(json, true)), { type: 'choice' });
        case 'co2e':
            return __assign(__assign({}, (0, Co2eJourney_1.Co2eJourneyFromJSONTyped)(json, true)), { type: 'co2e' });
        case 'group':
            return __assign(__assign({}, (0, GroupJourney_1.GroupJourneyFromJSONTyped)(json, true)), { type: 'group' });
        case 'hidden':
            return __assign(__assign({}, (0, HiddenJourney_1.HiddenJourneyFromJSONTyped)(json, true)), { type: 'hidden' });
        case 'id':
            return __assign(__assign({}, (0, IdJourney_1.IdJourneyFromJSONTyped)(json, true)), { type: 'id' });
        case 'keyValue':
            return __assign(__assign({}, (0, KeyValueJourney_1.KeyValueJourneyFromJSONTyped)(json, true)), { type: 'keyValue' });
        case 'many':
            return __assign(__assign({}, (0, ManyJourney_1.ManyJourneyFromJSONTyped)(json, true)), { type: 'many' });
        case 'offer':
            return __assign(__assign({}, (0, OfferJourney_1.OfferJourneyFromJSONTyped)(json, true)), { type: 'offer' });
        case 'price':
            return __assign(__assign({}, (0, PriceJourney_1.PriceJourneyFromJSONTyped)(json, true)), { type: 'price' });
        case 'stop':
            return __assign(__assign({}, (0, StopJourney_1.StopJourneyFromJSONTyped)(json, true)), { type: 'stop' });
        case 'swap':
            return __assign(__assign({}, (0, SwapJourney_1.SwapJourneyFromJSONTyped)(json, true)), { type: 'swap' });
        case 'swapPrice':
            return __assign(__assign({}, (0, SwapPriceJourney_1.SwapPriceJourneyFromJSONTyped)(json, true)), { type: 'swapPrice' });
        case 'travel':
            return __assign(__assign({}, (0, TravelJourney_1.TravelJourneyFromJSONTyped)(json, true)), { type: 'travel' });
        case 'unavailable':
            return __assign(__assign({}, (0, UnavailableJourney_1.UnavailableJourneyFromJSONTyped)(json, true)), { type: 'unavailable' });
        default:
            throw new Error("No variant of Journey exists with 'type=".concat(json['type'], "'"));
    }
}
function JourneyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'book':
            return (0, BookJourney_1.BookJourneyToJSON)(value);
        case 'choice':
            return (0, ChoiceJourney_1.ChoiceJourneyToJSON)(value);
        case 'co2e':
            return (0, Co2eJourney_1.Co2eJourneyToJSON)(value);
        case 'group':
            return (0, GroupJourney_1.GroupJourneyToJSON)(value);
        case 'hidden':
            return (0, HiddenJourney_1.HiddenJourneyToJSON)(value);
        case 'id':
            return (0, IdJourney_1.IdJourneyToJSON)(value);
        case 'keyValue':
            return (0, KeyValueJourney_1.KeyValueJourneyToJSON)(value);
        case 'many':
            return (0, ManyJourney_1.ManyJourneyToJSON)(value);
        case 'offer':
            return (0, OfferJourney_1.OfferJourneyToJSON)(value);
        case 'price':
            return (0, PriceJourney_1.PriceJourneyToJSON)(value);
        case 'stop':
            return (0, StopJourney_1.StopJourneyToJSON)(value);
        case 'swap':
            return (0, SwapJourney_1.SwapJourneyToJSON)(value);
        case 'swapPrice':
            return (0, SwapPriceJourney_1.SwapPriceJourneyToJSON)(value);
        case 'travel':
            return (0, TravelJourney_1.TravelJourneyToJSON)(value);
        case 'unavailable':
            return (0, UnavailableJourney_1.UnavailableJourneyToJSON)(value);
        default:
            throw new Error("No variant of Journey exists with 'type=".concat(value['type'], "'"));
    }
}
