"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfPricing = instanceOfPricing;
exports.PricingFromJSON = PricingFromJSON;
exports.PricingFromJSONTyped = PricingFromJSONTyped;
exports.PricingToJSON = PricingToJSON;
var runtime_1 = require("../runtime");
var DiscountReason_1 = require("./DiscountReason");
var LineItem_1 = require("./LineItem");
var PayLater_1 = require("./PayLater");
var Price_1 = require("./Price");
/**
 * Check if a given object implements the Pricing interface.
 */
function instanceOfPricing(value) {
    var isInstance = true;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "fullPrice" in value;
    isInstance = isInstance && "lineItems" in value;
    isInstance = isInstance && "discountReasons" in value;
    return isInstance;
}
function PricingFromJSON(json) {
    return PricingFromJSONTyped(json, false);
}
function PricingFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'price': (0, Price_1.PriceFromJSON)(json['price']),
        'fullPrice': (0, Price_1.PriceFromJSON)(json['fullPrice']),
        'lineItems': (json['lineItems'].map(LineItem_1.LineItemFromJSON)),
        'discountReasons': (json['discountReasons'].map(DiscountReason_1.DiscountReasonFromJSON)),
        'payLater': !(0, runtime_1.exists)(json, 'payLater') ? undefined : (0, PayLater_1.PayLaterFromJSON)(json['payLater']),
    };
}
function PricingToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'price': (0, Price_1.PriceToJSON)(value.price),
        'fullPrice': (0, Price_1.PriceToJSON)(value.fullPrice),
        'lineItems': (value.lineItems.map(LineItem_1.LineItemToJSON)),
        'discountReasons': (value.discountReasons.map(DiscountReason_1.DiscountReasonToJSON)),
        'payLater': (0, PayLater_1.PayLaterToJSON)(value.payLater),
    };
}
