"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfVanTransport = instanceOfVanTransport;
exports.VanTransportFromJSON = VanTransportFromJSON;
exports.VanTransportFromJSONTyped = VanTransportFromJSONTyped;
exports.VanTransportToJSON = VanTransportToJSON;
var VanDrivetrain_1 = require("./VanDrivetrain");
var VanSize_1 = require("./VanSize");
var VanTransportType_1 = require("./VanTransportType");
/**
 * Check if a given object implements the VanTransport interface.
 */
function instanceOfVanTransport(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "drivetrain" in value;
    isInstance = isInstance && "size" in value;
    return isInstance;
}
function VanTransportFromJSON(json) {
    return VanTransportFromJSONTyped(json, false);
}
function VanTransportFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, VanTransportType_1.VanTransportTypeFromJSON)(json['type']),
        'drivetrain': (0, VanDrivetrain_1.VanDrivetrainFromJSON)(json['drivetrain']),
        'size': (0, VanSize_1.VanSizeFromJSON)(json['size']),
    };
}
function VanTransportToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, VanTransportType_1.VanTransportTypeToJSON)(value.type),
        'drivetrain': (0, VanDrivetrain_1.VanDrivetrainToJSON)(value.drivetrain),
        'size': (0, VanSize_1.VanSizeToJSON)(value.size),
    };
}
