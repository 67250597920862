"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfAirportsRequest = instanceOfAirportsRequest;
exports.AirportsRequestFromJSON = AirportsRequestFromJSON;
exports.AirportsRequestFromJSONTyped = AirportsRequestFromJSONTyped;
exports.AirportsRequestToJSON = AirportsRequestToJSON;
var Location_1 = require("./Location");
/**
 * Check if a given object implements the AirportsRequest interface.
 */
function instanceOfAirportsRequest(value) {
    var isInstance = true;
    isInstance = isInstance && "origin" in value;
    isInstance = isInstance && "destination" in value;
    return isInstance;
}
function AirportsRequestFromJSON(json) {
    return AirportsRequestFromJSONTyped(json, false);
}
function AirportsRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'origin': (0, Location_1.LocationFromJSON)(json['origin']),
        'destination': (0, Location_1.LocationFromJSON)(json['destination']),
    };
}
function AirportsRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'origin': (0, Location_1.LocationToJSON)(value.origin),
        'destination': (0, Location_1.LocationToJSON)(value.destination),
    };
}
