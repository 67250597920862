"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfOrganisationFrontendDetails = instanceOfOrganisationFrontendDetails;
exports.OrganisationFrontendDetailsFromJSON = OrganisationFrontendDetailsFromJSON;
exports.OrganisationFrontendDetailsFromJSONTyped = OrganisationFrontendDetailsFromJSONTyped;
exports.OrganisationFrontendDetailsToJSON = OrganisationFrontendDetailsToJSON;
var runtime_1 = require("../runtime");
var Address_1 = require("./Address");
var CustomerLogo_1 = require("./CustomerLogo");
var Link_1 = require("./Link");
var Offset_1 = require("./Offset");
var SearchScreenTitlePosition_1 = require("./SearchScreenTitlePosition");
var SupportContactDetails_1 = require("./SupportContactDetails");
/**
 * Check if a given object implements the OrganisationFrontendDetails interface.
 */
function instanceOfOrganisationFrontendDetails(value) {
    var isInstance = true;
    isInstance = isInstance && "tabTitle" in value;
    isInstance = isInstance && "homepageUrl" in value;
    isInstance = isInstance && "showEnterpriseLandingLink" in value;
    isInstance = isInstance && "includeEScooterRoutes" in value;
    isInstance = isInstance && "bookingAllowed" in value;
    isInstance = isInstance && "originPlaceholder" in value;
    isInstance = isInstance && "destPlaceholder" in value;
    return isInstance;
}
function OrganisationFrontendDetailsFromJSON(json) {
    return OrganisationFrontendDetailsFromJSONTyped(json, false);
}
function OrganisationFrontendDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'tabTitle': json['tabTitle'],
        'homepageUrl': json['homepageUrl'],
        'homepageImageOffset': !(0, runtime_1.exists)(json, 'homepageImageOffset') ? undefined : (0, Offset_1.OffsetFromJSON)(json['homepageImageOffset']),
        'searchScreenTitlePosition': !(0, runtime_1.exists)(json, 'searchScreenTitlePosition') ? undefined : (0, SearchScreenTitlePosition_1.SearchScreenTitlePositionFromJSON)(json['searchScreenTitlePosition']),
        'customerLogo': !(0, runtime_1.exists)(json, 'customerLogo') ? undefined : (0, CustomerLogo_1.CustomerLogoFromJSON)(json['customerLogo']),
        'routeZeroLogoColor': !(0, runtime_1.exists)(json, 'routeZeroLogoColor') ? undefined : json['routeZeroLogoColor'],
        'link': !(0, runtime_1.exists)(json, 'link') ? undefined : (0, Link_1.LinkFromJSON)(json['link']),
        'contact': !(0, runtime_1.exists)(json, 'contact') ? undefined : (0, SupportContactDetails_1.SupportContactDetailsFromJSON)(json['contact']),
        'showEnterpriseLandingLink': json['showEnterpriseLandingLink'],
        'includeEScooterRoutes': json['includeEScooterRoutes'],
        'bookingAllowed': json['bookingAllowed'],
        'originPlaceholder': (0, Address_1.AddressFromJSON)(json['originPlaceholder']),
        'destPlaceholder': (0, Address_1.AddressFromJSON)(json['destPlaceholder']),
        'prefillOrigin': !(0, runtime_1.exists)(json, 'prefillOrigin') ? undefined : json['prefillOrigin'],
        'prefillDestination': !(0, runtime_1.exists)(json, 'prefillDestination') ? undefined : json['prefillDestination'],
    };
}
function OrganisationFrontendDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'tabTitle': value.tabTitle,
        'homepageUrl': value.homepageUrl,
        'homepageImageOffset': (0, Offset_1.OffsetToJSON)(value.homepageImageOffset),
        'searchScreenTitlePosition': (0, SearchScreenTitlePosition_1.SearchScreenTitlePositionToJSON)(value.searchScreenTitlePosition),
        'customerLogo': (0, CustomerLogo_1.CustomerLogoToJSON)(value.customerLogo),
        'routeZeroLogoColor': value.routeZeroLogoColor,
        'link': (0, Link_1.LinkToJSON)(value.link),
        'contact': (0, SupportContactDetails_1.SupportContactDetailsToJSON)(value.contact),
        'showEnterpriseLandingLink': value.showEnterpriseLandingLink,
        'includeEScooterRoutes': value.includeEScooterRoutes,
        'bookingAllowed': value.bookingAllowed,
        'originPlaceholder': (0, Address_1.AddressToJSON)(value.originPlaceholder),
        'destPlaceholder': (0, Address_1.AddressToJSON)(value.destPlaceholder),
        'prefillOrigin': value.prefillOrigin,
        'prefillDestination': value.prefillDestination,
    };
}
