import { routeZeroApi } from "@routezero-site/component/helper/routezero_api";
import { RecordProspectRequest, RecordProspectService } from "@routezero-site/service/record-prospect/record_prospect";

/**
 * Sends a request to the RZ backend asking for a demo - a member of the 
 * RZ team will be notified of the request.
 */
export class LiveRecordProspectService implements RecordProspectService {
  async requestDemo(request: RecordProspectRequest): Promise<void> {
    await routeZeroApi().v1ProspectPost({
      recordProspectRequest: request
    });
  }
}