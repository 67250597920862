"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TravellerGender = void 0;
exports.TravellerGenderFromJSON = TravellerGenderFromJSON;
exports.TravellerGenderFromJSONTyped = TravellerGenderFromJSONTyped;
exports.TravellerGenderToJSON = TravellerGenderToJSON;
/**
 * The gender of this traveller
 * @export
 */
exports.TravellerGender = {
    M: 'm',
    F: 'f'
};
function TravellerGenderFromJSON(json) {
    return TravellerGenderFromJSONTyped(json, false);
}
function TravellerGenderFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function TravellerGenderToJSON(value) {
    return value;
}
