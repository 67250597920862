"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfHistoricEvent = instanceOfHistoricEvent;
exports.HistoricEventFromJSON = HistoricEventFromJSON;
exports.HistoricEventFromJSONTyped = HistoricEventFromJSONTyped;
exports.HistoricEventToJSON = HistoricEventToJSON;
var runtime_1 = require("../runtime");
var HistoricEventType_1 = require("./HistoricEventType");
/**
 * Check if a given object implements the HistoricEvent interface.
 */
function instanceOfHistoricEvent(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "timestampMs" in value;
    return isInstance;
}
function HistoricEventFromJSON(json) {
    return HistoricEventFromJSONTyped(json, false);
}
function HistoricEventFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, HistoricEventType_1.HistoricEventTypeFromJSON)(json['type']),
        'vendorOrderReference': !(0, runtime_1.exists)(json, 'vendorOrderReference') ? undefined : json['vendorOrderReference'],
        'timestampMs': json['timestampMs'],
    };
}
function HistoricEventToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, HistoricEventType_1.HistoricEventTypeToJSON)(value.type),
        'vendorOrderReference': value.vendorOrderReference,
        'timestampMs': value.timestampMs,
    };
}
