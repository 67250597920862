"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfInternalBookingMethod = instanceOfInternalBookingMethod;
exports.InternalBookingMethodFromJSON = InternalBookingMethodFromJSON;
exports.InternalBookingMethodFromJSONTyped = InternalBookingMethodFromJSONTyped;
exports.InternalBookingMethodToJSON = InternalBookingMethodToJSON;
var InternalBookingMethodSpecific_1 = require("./InternalBookingMethodSpecific");
var InternalBookingMethodType_1 = require("./InternalBookingMethodType");
/**
 * Check if a given object implements the InternalBookingMethod interface.
 */
function instanceOfInternalBookingMethod(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "internal" in value;
    return isInstance;
}
function InternalBookingMethodFromJSON(json) {
    return InternalBookingMethodFromJSONTyped(json, false);
}
function InternalBookingMethodFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, InternalBookingMethodType_1.InternalBookingMethodTypeFromJSON)(json['type']),
        'internal': (0, InternalBookingMethodSpecific_1.InternalBookingMethodSpecificFromJSON)(json['internal']),
    };
}
function InternalBookingMethodToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, InternalBookingMethodType_1.InternalBookingMethodTypeToJSON)(value.type),
        'internal': (0, InternalBookingMethodSpecific_1.InternalBookingMethodSpecificToJSON)(value.internal),
    };
}
