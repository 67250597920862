"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ManyJourneyType = void 0;
exports.ManyJourneyTypeFromJSON = ManyJourneyTypeFromJSON;
exports.ManyJourneyTypeFromJSONTyped = ManyJourneyTypeFromJSONTyped;
exports.ManyJourneyTypeToJSON = ManyJourneyTypeToJSON;
/**
 *
 * @export
 */
exports.ManyJourneyType = {
    Many: 'many'
};
function ManyJourneyTypeFromJSON(json) {
    return ManyJourneyTypeFromJSONTyped(json, false);
}
function ManyJourneyTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function ManyJourneyTypeToJSON(value) {
    return value;
}
