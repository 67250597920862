import { FooterSection } from "@routezero-site/component/helper/footer";
import { ContactSalesFooterBackgroundColor, ContactSalesPageFooterColor } from "@routezero-site/component/helper/theme";
import { ContactSalesSection } from "@routezero-site/component/page/contact-sales/contact_sales_section";

/**
 * Form the user can fill out to request a demo.
 */
export function ContactSalesPage() {
  return (
    <div>
      <ContactSalesSection/>
      <FooterSection
        color={ContactSalesPageFooterColor()}
        backgroundColor={ContactSalesFooterBackgroundColor()}
      />
    </div>
  );
}
