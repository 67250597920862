"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTravelProviderPriceStrategies = instanceOfTravelProviderPriceStrategies;
exports.TravelProviderPriceStrategiesFromJSON = TravelProviderPriceStrategiesFromJSON;
exports.TravelProviderPriceStrategiesFromJSONTyped = TravelProviderPriceStrategiesFromJSONTyped;
exports.TravelProviderPriceStrategiesToJSON = TravelProviderPriceStrategiesToJSON;
var runtime_1 = require("../runtime");
var DuffelFlightsPriceStrategies_1 = require("./DuffelFlightsPriceStrategies");
var DuffelStaysPriceStrategies_1 = require("./DuffelStaysPriceStrategies");
var TrainlinePriceStrategies_1 = require("./TrainlinePriceStrategies");
/**
 * Check if a given object implements the TravelProviderPriceStrategies interface.
 */
function instanceOfTravelProviderPriceStrategies(value) {
    var isInstance = true;
    return isInstance;
}
function TravelProviderPriceStrategiesFromJSON(json) {
    return TravelProviderPriceStrategiesFromJSONTyped(json, false);
}
function TravelProviderPriceStrategiesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'trainline': !(0, runtime_1.exists)(json, 'trainline') ? undefined : (0, TrainlinePriceStrategies_1.TrainlinePriceStrategiesFromJSON)(json['trainline']),
        'duffelFlights': !(0, runtime_1.exists)(json, 'duffelFlights') ? undefined : (0, DuffelFlightsPriceStrategies_1.DuffelFlightsPriceStrategiesFromJSON)(json['duffelFlights']),
        'duffelStays': !(0, runtime_1.exists)(json, 'duffelStays') ? undefined : (0, DuffelStaysPriceStrategies_1.DuffelStaysPriceStrategiesFromJSON)(json['duffelStays']),
    };
}
function TravelProviderPriceStrategiesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'trainline': (0, TrainlinePriceStrategies_1.TrainlinePriceStrategiesToJSON)(value.trainline),
        'duffelFlights': (0, DuffelFlightsPriceStrategies_1.DuffelFlightsPriceStrategiesToJSON)(value.duffelFlights),
        'duffelStays': (0, DuffelStaysPriceStrategies_1.DuffelStaysPriceStrategiesToJSON)(value.duffelStays),
    };
}
