"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.IanaTimezoneType = void 0;
exports.IanaTimezoneTypeFromJSON = IanaTimezoneTypeFromJSON;
exports.IanaTimezoneTypeFromJSONTyped = IanaTimezoneTypeFromJSONTyped;
exports.IanaTimezoneTypeToJSON = IanaTimezoneTypeToJSON;
/**
 *
 * @export
 */
exports.IanaTimezoneType = {
    IanaTimezone: 'ianaTimezone'
};
function IanaTimezoneTypeFromJSON(json) {
    return IanaTimezoneTypeFromJSONTyped(json, false);
}
function IanaTimezoneTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function IanaTimezoneTypeToJSON(value) {
    return value;
}
