"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfDuffelStaysPriceStrategies = instanceOfDuffelStaysPriceStrategies;
exports.DuffelStaysPriceStrategiesFromJSON = DuffelStaysPriceStrategiesFromJSON;
exports.DuffelStaysPriceStrategiesFromJSONTyped = DuffelStaysPriceStrategiesFromJSONTyped;
exports.DuffelStaysPriceStrategiesToJSON = DuffelStaysPriceStrategiesToJSON;
var runtime_1 = require("../runtime");
var DuffelStaysFeeLocation_1 = require("./DuffelStaysFeeLocation");
var FixedValueAdjustment_1 = require("./FixedValueAdjustment");
var MarkupStrategy_1 = require("./MarkupStrategy");
var SubsidyStrategy_1 = require("./SubsidyStrategy");
/**
 * Check if a given object implements the DuffelStaysPriceStrategies interface.
 */
function instanceOfDuffelStaysPriceStrategies(value) {
    var isInstance = true;
    isInstance = isInstance && "markup" in value;
    isInstance = isInstance && "subsidy" in value;
    isInstance = isInstance && "paymentProviderFeesLocation" in value;
    isInstance = isInstance && "travelProviderFeesLocation" in value;
    return isInstance;
}
function DuffelStaysPriceStrategiesFromJSON(json) {
    return DuffelStaysPriceStrategiesFromJSONTyped(json, false);
}
function DuffelStaysPriceStrategiesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'markup': (0, MarkupStrategy_1.MarkupStrategyFromJSON)(json['markup']),
        'subsidy': (0, SubsidyStrategy_1.SubsidyStrategyFromJSON)(json['subsidy']),
        'maxUndercut': !(0, runtime_1.exists)(json, 'maxUndercut') ? undefined : (0, FixedValueAdjustment_1.FixedValueAdjustmentFromJSON)(json['maxUndercut']),
        'carbonPricePencePerTonne': !(0, runtime_1.exists)(json, 'carbonPricePencePerTonne') ? undefined : json['carbonPricePencePerTonne'],
        'paymentProviderFeesLocation': (0, DuffelStaysFeeLocation_1.DuffelStaysFeeLocationFromJSON)(json['paymentProviderFeesLocation']),
        'travelProviderFeesLocation': (0, DuffelStaysFeeLocation_1.DuffelStaysFeeLocationFromJSON)(json['travelProviderFeesLocation']),
    };
}
function DuffelStaysPriceStrategiesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'markup': (0, MarkupStrategy_1.MarkupStrategyToJSON)(value.markup),
        'subsidy': (0, SubsidyStrategy_1.SubsidyStrategyToJSON)(value.subsidy),
        'maxUndercut': (0, FixedValueAdjustment_1.FixedValueAdjustmentToJSON)(value.maxUndercut),
        'carbonPricePencePerTonne': value.carbonPricePencePerTonne,
        'paymentProviderFeesLocation': (0, DuffelStaysFeeLocation_1.DuffelStaysFeeLocationToJSON)(value.paymentProviderFeesLocation),
        'travelProviderFeesLocation': (0, DuffelStaysFeeLocation_1.DuffelStaysFeeLocationToJSON)(value.travelProviderFeesLocation),
    };
}
