import { useState } from "react"

export interface UseDebounceArgs {
  /**
   * Called if the user interacts for long enough.
   */
  callback: () => void
  /**
   * If the user stops interacting before this time expires, the callback
   * will not be called.
   */
  delayMs?: number
}

export interface UseDebounce {
  /**
   * The user started interacting. If the user does not stop interacting before
   * the delay (provided in arguments), then the callback will be called.
   */
  start: () => void
  /**
   * The user stopped interacting. The user needs to start interacting again 
   * before the callback can be called.
   */
  stop: () => void
}

/**
 * Waits a period of time before calling the callback, provided the user is 
 * still "interacting". If the user stops interacting, they need to start again
 * before the callback can be called.
 */
export function useDebounce(args: UseDebounceArgs): UseDebounce {
  const [delayHandler, setDelayHandler] = useState<NodeJS.Timeout | null>(null);

  return {
    start: () => {
      if (args.delayMs === undefined) {
        args.callback();
      } else {
        const handler = setTimeout(() => args.callback(), args.delayMs);
        setDelayHandler(handler);
      }
    },
    stop: () => {
      if (delayHandler !== null) {
        clearTimeout(delayHandler);
      }
    }
  }
}