"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTravellerProfileBase = instanceOfTravellerProfileBase;
exports.TravellerProfileBaseFromJSON = TravellerProfileBaseFromJSON;
exports.TravellerProfileBaseFromJSONTyped = TravellerProfileBaseFromJSONTyped;
exports.TravellerProfileBaseToJSON = TravellerProfileBaseToJSON;
var TravellerGender_1 = require("./TravellerGender");
var TravellerLoyaltyProgramme_1 = require("./TravellerLoyaltyProgramme");
var TravellerTitle_1 = require("./TravellerTitle");
/**
 * Check if a given object implements the TravellerProfileBase interface.
 */
function instanceOfTravellerProfileBase(value) {
    var isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "givenName" in value;
    isInstance = isInstance && "familyName" in value;
    isInstance = isInstance && "gender" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "phoneNumber" in value;
    isInstance = isInstance && "loyaltyProgrammes" in value;
    return isInstance;
}
function TravellerProfileBaseFromJSON(json) {
    return TravellerProfileBaseFromJSONTyped(json, false);
}
function TravellerProfileBaseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'title': (0, TravellerTitle_1.TravellerTitleFromJSON)(json['title']),
        'givenName': json['givenName'],
        'familyName': json['familyName'],
        'gender': (0, TravellerGender_1.TravellerGenderFromJSON)(json['gender']),
        'email': json['email'],
        'phoneNumber': json['phoneNumber'],
        'loyaltyProgrammes': (json['loyaltyProgrammes'].map(TravellerLoyaltyProgramme_1.TravellerLoyaltyProgrammeFromJSON)),
    };
}
function TravellerProfileBaseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'title': (0, TravellerTitle_1.TravellerTitleToJSON)(value.title),
        'givenName': value.givenName,
        'familyName': value.familyName,
        'gender': (0, TravellerGender_1.TravellerGenderToJSON)(value.gender),
        'email': value.email,
        'phoneNumber': value.phoneNumber,
        'loyaltyProgrammes': (value.loyaltyProgrammes.map(TravellerLoyaltyProgramme_1.TravellerLoyaltyProgrammeToJSON)),
    };
}
