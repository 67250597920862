"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCarbonPriceLineItem = instanceOfCarbonPriceLineItem;
exports.CarbonPriceLineItemFromJSON = CarbonPriceLineItemFromJSON;
exports.CarbonPriceLineItemFromJSONTyped = CarbonPriceLineItemFromJSONTyped;
exports.CarbonPriceLineItemToJSON = CarbonPriceLineItemToJSON;
var CarbonPriceLineItemType_1 = require("./CarbonPriceLineItemType");
var Co2eMethodology_1 = require("./Co2eMethodology");
var Price_1 = require("./Price");
/**
 * Check if a given object implements the CarbonPriceLineItem interface.
 */
function instanceOfCarbonPriceLineItem(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "carbonPricesPencePerTonne" in value;
    isInstance = isInstance && "emissionsKgCo2e" in value;
    isInstance = isInstance && "methodologies" in value;
    return isInstance;
}
function CarbonPriceLineItemFromJSON(json) {
    return CarbonPriceLineItemFromJSONTyped(json, false);
}
function CarbonPriceLineItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, CarbonPriceLineItemType_1.CarbonPriceLineItemTypeFromJSON)(json['type']),
        'price': (0, Price_1.PriceFromJSON)(json['price']),
        'carbonPricesPencePerTonne': json['carbonPricesPencePerTonne'],
        'emissionsKgCo2e': json['emissionsKgCo2e'],
        'methodologies': (json['methodologies'].map(Co2eMethodology_1.Co2eMethodologyFromJSON)),
    };
}
function CarbonPriceLineItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, CarbonPriceLineItemType_1.CarbonPriceLineItemTypeToJSON)(value.type),
        'price': (0, Price_1.PriceToJSON)(value.price),
        'carbonPricesPencePerTonne': value.carbonPricesPencePerTonne,
        'emissionsKgCo2e': value.emissionsKgCo2e,
        'methodologies': (value.methodologies.map(Co2eMethodology_1.Co2eMethodologyToJSON)),
    };
}
