import { ReactComponent as ChartSimpleSolid } from '@routezero-site/component/helper/media/icons/chart-simple-solid.svg';
import { ReactComponent as CloudArrowDownSolid } from '@routezero-site/component/helper/media/icons/cloud-arrow-down-solid.svg';
import { ReactComponent as BadgeCheckSolid } from '@routezero-site/component/helper/media/icons/badge-check-solid.svg';
import { ReactComponent as CircleInfoSolid } from '@routezero-site/component/helper/media/icons/circle-info-solid.svg';
import { ReactComponent as Dollar } from '@routezero-site/component/helper/media/icons/dollar.svg';
import { ReactComponent as GlobeSolid } from '@routezero-site/component/helper/media/icons/globe-solid.svg';
import { ReactComponent as LocationDotSolid } from '@routezero-site/component/helper/media/icons/location-dot-solid.svg';
import { ReactComponent as MobileScreenSolid } from '@routezero-site/component/helper/media/icons/mobile-screen-solid.svg';
import { ReactComponent as PhoneSolid } from '@routezero-site/component/helper/media/icons/phone-solid.svg';
import { ReactComponent as LocationDotDuotone } from '@routezero-site/component/helper/media/icons/location-dot-duotone.svg';
import { ReactComponent as ShieldCheckDuotone } from '@routezero-site/component/helper/media/icons/shield-check-duotone.svg';
import { ReactComponent as SparklesDuotone } from '@routezero-site/component/helper/media/icons/sparkles-duotone.svg';
import { ReactComponent as CircleDollarDuotone } from '@routezero-site/component/helper/media/icons/circle-dollar-duotone.svg';
import { ReactComponent as ForwardDuotone } from '@routezero-site/component/helper/media/icons/forward-duotone.svg';
import { ReactComponent as GearsDuotone } from '@routezero-site/component/helper/media/icons/gears-duotone.svg';
import { ReactComponent as PeopleGroupDuotone } from '@routezero-site/component/helper/media/icons/people-group-duotone.svg';
import { ReactComponent as TrophySolid } from '@routezero-site/component/helper/media/icons/trophy-solid.svg';
import { ReactComponent as TrainSolid } from '@routezero-site/component/helper/media/icons/train-solid.svg';
import { ReactComponent as TrainDuotone } from '@routezero-site/component/helper/media/icons/train-duotone.svg';
import { ReactComponent as PlaneDuotone } from '@routezero-site/component/helper/media/icons/plane-duotone.svg';
import { ReactComponent as CircleVideoSolid } from '@routezero-site/component/helper/media/icons/circle-video-sharp-solid.svg';
import { ReactComponent as ChartLineUpSolid } from '@routezero-site/component/helper/media/icons/chart-line-up-solid.svg';
import { ReactComponent as GearSolid } from '@routezero-site/component/helper/media/icons/gear-solid.svg';
import { ReactComponent as CircleCheckDuotone } from '@routezero-site/component/helper/media/icons/circle-check-duotone.svg';
import { ReactComponent as CircleXMarkDuotone } from '@routezero-site/component/helper/media/icons/circle-xmark-duotone.svg';
import { ReactComponent as TagSolid } from '@routezero-site/component/helper/media/icons/tag-solid.svg';
import { ReactComponent as ChevronLeftSolid } from '@routezero-site/component/helper/media/icons/chevron-left-solid.svg';
import { ReactComponent as ChevronRightSolid } from '@routezero-site/component/helper/media/icons/chevron-right-solid.svg';
import { IconSizePx } from '@routezero-site/component/helper/theme';

const IconProps: (args: { sizePx?: number }) => React.SVGProps<SVGSVGElement> = ({sizePx}) => {
  return {
    fill: 'currentColor',
    width: sizePx ?? IconSizePx(),
    height: sizePx ?? IconSizePx()
  };
};

export interface SizedIconProps {
  sizePx?: number
}

export const ChartSimpleSolidIcon: React.FC<SizedIconProps> = (props) => {
  return (
    <ChartSimpleSolid {...IconProps(props)}/>
  );
}

export const CloudDownArrowSolidIcon: React.FC<SizedIconProps> = (props) => {
  return (
    <CloudArrowDownSolid {...IconProps(props)}/>
  );
}

export const BadgeCheckSolidIcon: React.FC<SizedIconProps> = (props) => {
  return (
    <BadgeCheckSolid {...IconProps(props)}/>
  );
}

export const CircleInfoIcon: React.FC<SizedIconProps> = (props) => {
  return (
    <CircleInfoSolid {...IconProps(props)}/>
  );
}

export const DollarIcon: React.FC<SizedIconProps> = (props) => {
  return (
    <Dollar {...IconProps(props)}/>
  );
}

export const GlobeIcon: React.FC<SizedIconProps> = (props) => {
  return (
    <GlobeSolid {...IconProps(props)}/>
  );
}

export const LocationDotIcon: React.FC<SizedIconProps> = (props) => {
  return (
    <LocationDotSolid {...IconProps(props)}/>
  );
}

export const MobileScreenIcon: React.FC<SizedIconProps> = (props) => {
  return (
    <MobileScreenSolid {...IconProps(props)}/>
  );
}

export const PhoneIcon: React.FC<SizedIconProps> = (props) => {
  return (
    <PhoneSolid {...IconProps(props)}/>
  );
}

export const LocationDotDuotoneIcon: React.FC<SizedIconProps> = (props) => {
  return (
    <LocationDotDuotone {...IconProps(props)}/>
  );
}

export const ShieldCheckDuotoneIcon: React.FC<SizedIconProps> = (props) => {
  return (
    <ShieldCheckDuotone {...IconProps(props)}/>
  );
}

export const SparklesDuotoneIcon: React.FC<SizedIconProps> = (props) => {
  return (
    <SparklesDuotone {...IconProps(props)}/>
  );
}

export const CircleDollarDuotoneIcon: React.FC<SizedIconProps> = (props) => {
  return (
    <CircleDollarDuotone {...IconProps(props)}/>
  );
}

export const ForwardDuotoneIcon: React.FC<SizedIconProps> = (props) => {
  return (
    <ForwardDuotone {...IconProps(props)}/>
  );
}

export const GearsDuotoneIcon: React.FC<SizedIconProps> = (props) => {
  return (
    <GearsDuotone {...IconProps(props)}/>
  );
}

export const PeopleGroupDuotoneIcon: React.FC<SizedIconProps> = (props) => {
  return (
    <PeopleGroupDuotone {...IconProps(props)}/>
  );
}

export const TrophyIcon: React.FC<SizedIconProps> = (props) => {
  return (
    <TrophySolid {...IconProps(props)}/>
  );
}

export const TrainSolidIcon: React.FC<SizedIconProps> = (props) => {
  return (
    <TrainSolid {...IconProps(props)}/>
  );
}

export const TrainDuotoneIcon: React.FC<SizedIconProps> = (props) => {
  return (
    <TrainDuotone {...IconProps(props)}/>
  );
}

export const PlaneDuotoneIcon: React.FC<SizedIconProps> = (props) => {
  return (
    <PlaneDuotone {...IconProps(props)}/>
  );
}

export const VideoIcon: React.FC<SizedIconProps> = (props) => {
  return (
    <CircleVideoSolid {...IconProps(props)}/>
  );
}

export const ChartLineUpIcon: React.FC<SizedIconProps> = (props) => {
  return (
    <ChartLineUpSolid {...IconProps(props)}/>
  );
}

export const GearIcon: React.FC<SizedIconProps> = (props) => {
  return (
    <GearSolid {...IconProps(props)}/>
  );
}

export const CircleCheckDuotoneIcon: React.FC<SizedIconProps> = (props) => {
  return (
    <CircleCheckDuotone {...IconProps(props)}/>
  );
}

export const CircleXMarkDuotoneIcon: React.FC<SizedIconProps> = (props) => {
  return (
    <CircleXMarkDuotone {...IconProps(props)}/>
  );
}

export const TagSolidIcon: React.FC<SizedIconProps> = (props) => {
  return (
    <TagSolid {...IconProps(props)}/>
  );
}

export const ChevronLeftSolidIcon: React.FC<SizedIconProps> = (props) => {
  return (
    <ChevronLeftSolid {...IconProps(props)}/>
  );
}

export const ChevronRightSolidIcon: React.FC<SizedIconProps> = (props) => {
  return (
    <ChevronRightSolid {...IconProps(props)}/>
  );
}