"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdJourneyType = void 0;
exports.IdJourneyTypeFromJSON = IdJourneyTypeFromJSON;
exports.IdJourneyTypeFromJSONTyped = IdJourneyTypeFromJSONTyped;
exports.IdJourneyTypeToJSON = IdJourneyTypeToJSON;
/**
 *
 * @export
 */
exports.IdJourneyType = {
    Id: 'id'
};
function IdJourneyTypeFromJSON(json) {
    return IdJourneyTypeFromJSONTyped(json, false);
}
function IdJourneyTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function IdJourneyTypeToJSON(value) {
    return value;
}
