"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfIanaTimezone = instanceOfIanaTimezone;
exports.IanaTimezoneFromJSON = IanaTimezoneFromJSON;
exports.IanaTimezoneFromJSONTyped = IanaTimezoneFromJSONTyped;
exports.IanaTimezoneToJSON = IanaTimezoneToJSON;
var IanaTimezoneType_1 = require("./IanaTimezoneType");
/**
 * Check if a given object implements the IanaTimezone interface.
 */
function instanceOfIanaTimezone(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "timezone" in value;
    return isInstance;
}
function IanaTimezoneFromJSON(json) {
    return IanaTimezoneFromJSONTyped(json, false);
}
function IanaTimezoneFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, IanaTimezoneType_1.IanaTimezoneTypeFromJSON)(json['type']),
        'timezone': json['timezone'],
    };
}
function IanaTimezoneToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, IanaTimezoneType_1.IanaTimezoneTypeToJSON)(value.type),
        'timezone': value.timezone,
    };
}
