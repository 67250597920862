import { AnalyticsPricingPlanType, AnalyticsService, AnalyticsFeaturesType } from "./analytics";
import * as Fathom from 'fathom-client';

function pricingPlanEnumToString(pricingPlan: AnalyticsPricingPlanType): string {
  switch(pricingPlan){
    case AnalyticsPricingPlanType.payAsYouGo:
      return 'PayAsYouGo'
    case AnalyticsPricingPlanType.perUser:
      return 'PerUser'
    case AnalyticsPricingPlanType.custom:
      return 'Custom'
  }
}

function featureTypeEnumToString(featureType: AnalyticsFeaturesType): string {
  switch(featureType){
    case AnalyticsFeaturesType.smartTravelPolicy:
      return 'SmartTravelPolicy'
    case AnalyticsFeaturesType.competitions:
      return 'Competitions'
    case AnalyticsFeaturesType.carbonBudgets:
      return 'CarbonBudgets'
    case AnalyticsFeaturesType.integratedBooking:
      return 'IntegratedBooking'
    case AnalyticsFeaturesType.peerInsights:
      return 'PeerInsights'
    case AnalyticsFeaturesType.scenarioModels:
      return 'ScenarioModels'
  }
}

/**
 * Uses Fathom Analytics to avoid distracting the user with a cookie notice.
 * https://usefathom.com/
 */
export class FathomAnalyticsService implements AnalyticsService {
  async logScrollToProductPageCallToAction(): Promise<void> {
    Fathom.trackEvent('ScrollToProducePageCallToAction');
  }

  async logRoutePlannerVisit(): Promise<void> {
    Fathom.trackEvent('VisitRoutePlanner');
  }

  async logPricingPlanSelected(pricingPlan: AnalyticsPricingPlanType): Promise<void> {
    Fathom.trackEvent(`SelectedPricingPlan: ${pricingPlanEnumToString(pricingPlan)}`);
  }

  async logFeatureLearnMore(feature: AnalyticsFeaturesType): Promise<void> {
    Fathom.trackEvent(`SelectedFeatureLearnMore: ${featureTypeEnumToString(feature)}`);
  }

  async logContactSalesConversion(): Promise<void> {
    Fathom.trackEvent('ContactSalesConversion');
  }  
}