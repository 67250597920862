"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfDuffelService = instanceOfDuffelService;
exports.DuffelServiceFromJSON = DuffelServiceFromJSON;
exports.DuffelServiceFromJSONTyped = DuffelServiceFromJSONTyped;
exports.DuffelServiceToJSON = DuffelServiceToJSON;
/**
 * Check if a given object implements the DuffelService interface.
 */
function instanceOfDuffelService(value) {
    var isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "quantity" in value;
    return isInstance;
}
function DuffelServiceFromJSON(json) {
    return DuffelServiceFromJSONTyped(json, false);
}
function DuffelServiceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'quantity': json['quantity'],
    };
}
function DuffelServiceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'quantity': value.quantity,
    };
}
