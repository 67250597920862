"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTravelJourney = instanceOfTravelJourney;
exports.TravelJourneyFromJSON = TravelJourneyFromJSON;
exports.TravelJourneyFromJSONTyped = TravelJourneyFromJSONTyped;
exports.TravelJourneyToJSON = TravelJourneyToJSON;
var Travel_1 = require("./Travel");
var TravelJourneyType_1 = require("./TravelJourneyType");
/**
 * Check if a given object implements the TravelJourney interface.
 */
function instanceOfTravelJourney(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "travel" in value;
    return isInstance;
}
function TravelJourneyFromJSON(json) {
    return TravelJourneyFromJSONTyped(json, false);
}
function TravelJourneyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, TravelJourneyType_1.TravelJourneyTypeFromJSON)(json['type']),
        'travel': (0, Travel_1.TravelFromJSON)(json['travel']),
    };
}
function TravelJourneyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, TravelJourneyType_1.TravelJourneyTypeToJSON)(value.type),
        'travel': (0, Travel_1.TravelToJSON)(value.travel),
    };
}
