"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfOffset = instanceOfOffset;
exports.OffsetFromJSON = OffsetFromJSON;
exports.OffsetFromJSONTyped = OffsetFromJSONTyped;
exports.OffsetToJSON = OffsetToJSON;
/**
 * Check if a given object implements the Offset interface.
 */
function instanceOfOffset(value) {
    var isInstance = true;
    isInstance = isInstance && "dx" in value;
    isInstance = isInstance && "dy" in value;
    return isInstance;
}
function OffsetFromJSON(json) {
    return OffsetFromJSONTyped(json, false);
}
function OffsetFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dx': json['dx'],
        'dy': json['dy'],
    };
}
function OffsetToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dx': value.dx,
        'dy': value.dy,
    };
}
