"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfBookingFares = instanceOfBookingFares;
exports.BookingFaresFromJSON = BookingFaresFromJSON;
exports.BookingFaresFromJSONTyped = BookingFaresFromJSONTyped;
exports.BookingFaresToJSON = BookingFaresToJSON;
var JourneyConditions_1 = require("./JourneyConditions");
var JourneyFare_1 = require("./JourneyFare");
/**
 * Check if a given object implements the BookingFares interface.
 */
function instanceOfBookingFares(value) {
    var isInstance = true;
    isInstance = isInstance && "fares" in value;
    isInstance = isInstance && "conditions" in value;
    return isInstance;
}
function BookingFaresFromJSON(json) {
    return BookingFaresFromJSONTyped(json, false);
}
function BookingFaresFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'fares': (json['fares'].map(JourneyFare_1.JourneyFareFromJSON)),
        'conditions': (json['conditions'].map(JourneyConditions_1.JourneyConditionsFromJSON)),
    };
}
function BookingFaresToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'fares': (value.fares.map(JourneyFare_1.JourneyFareToJSON)),
        'conditions': (value.conditions.map(JourneyConditions_1.JourneyConditionsToJSON)),
    };
}
