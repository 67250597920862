import { useServicesContext } from "@routezero-site/component/helper/services";
import { ReactNode } from "react";

export interface RZRoutePlannerLinkProps {
  children?: ReactNode
}

/**
 * Opens a link to the RouteZero route planner when pressed.
 */
export const RZRoutePlannerLink: React.FC<RZRoutePlannerLinkProps> = (props) => {
  const { linksService, analyticsService } = useServicesContext();
  return (
    <a
      href={linksService.rzRoutePlannerUrl()} 
      onClick={analyticsService.logRoutePlannerVisit} 
      target="_blank" 
      rel="noopener noreferrer"
    >
      {props.children}
    </a>
  );
};