"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfOneWaySearchRequestBase = instanceOfOneWaySearchRequestBase;
exports.OneWaySearchRequestBaseFromJSON = OneWaySearchRequestBaseFromJSON;
exports.OneWaySearchRequestBaseFromJSONTyped = OneWaySearchRequestBaseFromJSONTyped;
exports.OneWaySearchRequestBaseToJSON = OneWaySearchRequestBaseToJSON;
var DiscountCardTuple_1 = require("./DiscountCardTuple");
var Location_1 = require("./Location");
var Luggage_1 = require("./Luggage");
var UserVehicle_1 = require("./UserVehicle");
/**
 * Check if a given object implements the OneWaySearchRequestBase interface.
 */
function instanceOfOneWaySearchRequestBase(value) {
    var isInstance = true;
    isInstance = isInstance && "origin" in value;
    isInstance = isInstance && "destination" in value;
    isInstance = isInstance && "waypoints" in value;
    isInstance = isInstance && "adultCount" in value;
    isInstance = isInstance && "discountCardCounts" in value;
    isInstance = isInstance && "luggage" in value;
    isInstance = isInstance && "userVehicle" in value;
    isInstance = isInstance && "avoidMicromobility" in value;
    return isInstance;
}
function OneWaySearchRequestBaseFromJSON(json) {
    return OneWaySearchRequestBaseFromJSONTyped(json, false);
}
function OneWaySearchRequestBaseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'origin': (0, Location_1.LocationFromJSON)(json['origin']),
        'destination': (0, Location_1.LocationFromJSON)(json['destination']),
        'waypoints': (json['waypoints'].map(Location_1.LocationFromJSON)),
        'adultCount': json['adultCount'],
        'discountCardCounts': (json['discountCardCounts'].map(DiscountCardTuple_1.DiscountCardTupleFromJSON)),
        'luggage': (0, Luggage_1.LuggageFromJSON)(json['luggage']),
        'userVehicle': (0, UserVehicle_1.UserVehicleFromJSON)(json['userVehicle']),
        'avoidMicromobility': json['avoidMicromobility'],
    };
}
function OneWaySearchRequestBaseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'origin': (0, Location_1.LocationToJSON)(value.origin),
        'destination': (0, Location_1.LocationToJSON)(value.destination),
        'waypoints': (value.waypoints.map(Location_1.LocationToJSON)),
        'adultCount': value.adultCount,
        'discountCardCounts': (value.discountCardCounts.map(DiscountCardTuple_1.DiscountCardTupleToJSON)),
        'luggage': (0, Luggage_1.LuggageToJSON)(value.luggage),
        'userVehicle': (0, UserVehicle_1.UserVehicleToJSON)(value.userVehicle),
        'avoidMicromobility': value.avoidMicromobility,
    };
}
