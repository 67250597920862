import { useIntersectionObserver } from "@routezero-site/component/helper/intersection_observer";
import { useScript } from "@routezero-site/component/helper/script";
import { useEffect } from "react";
import Player from '@vimeo/player';

export interface VimeoPlayerProps {
  /**
   * Unique title - required as using iframes.
   */
  uniqueTitle: string
  /**
   * Part of the ID of the video in Vimeo. Find this on Vimeo by going to embed, 
   * and finding the iframe src, e.g. https://player.vimeo.com/video/[VIDEO_ID]?h=[VIDEO_ID_H]
   */
  videoId: string
  /**
   * Part of the ID of the video in Vimeo. Find this on Vimeo by going to embed, 
   * and finding the iframe src, e.g. https://player.vimeo.com/video/[VIDEO_ID]?h=[VIDEO_ID_H]
   * The video ID is the "h" query parameter.
   */
  videoIdHParam?: string
  /**
   * The relationship between the width and height. E.g. 50% would mean the 
   * height is 50% of the width = 2:1 aspect ratio.
   */
  heightPercentageAspectRatio: number
  /**
   * Whether to start playing when the video is visible on the screen.
   */
  autoplayWhenVisible?: boolean
  /**
   * Whether the video should be muted initially.
   */
  muted?: boolean
  /**
   * Whether the video should loop after finishing.
   */
  loop?: boolean
  /**
   * Called when the video is played.
   */
  onPlay?: () => void
  /**
   * Called when the video is paused.
   */
  onPause?: () => void
}

/**
 * Plays a video hosted on Vimeo.
 */
export const VimeoPlayer: React.FC<VimeoPlayerProps> = (props) => {
  useScript('https://player.vimeo.com/api/player.js');

  const onVisible = (): void => {
    if (iframeRef.current && props.autoplayWhenVisible) {
      iframeRef.current.src += "&autoplay=1";
    }
  };
  const iframeRef = useIntersectionObserver<HTMLIFrameElement>({
    callback: onVisible, 
    // Callback called before the video is visible so initial loading screen
    // can be avoided - Vimeo shows a white screen after requesting to play
    // the video, while it is loading.
    options: { rootMargin: '200px' }
  });

  useEffect(() => {
    if (!iframeRef.current) { 
      return; 
    }

    const player = new Player(iframeRef.current);
    player.on('play', function() {
      props.onPlay?.();
    });
    player.on('pause', function() {
      props.onPause?.();
    });
  }, []);

  const muted = vimeoSrcOption(props.muted ?? false);
  const loop = vimeoSrcOption(props.loop ?? false);
  const hParam = props.videoIdHParam !== undefined ? `h=${props.videoIdHParam}` : '';

  return (
    <div style={{
      // Aspect ratio
      padding: `${props.heightPercentageAspectRatio}% 0 0 0`,
      position: 'relative'
    }}>
      <iframe
        ref={iframeRef}
        src={`https://player.vimeo.com/video/${props.videoId}?${hParam}&badge=0&autopause=0&player_id=0&app_id=58479&muted=${muted}&loop=${loop}`}
        allow="fullscreen; picture-in-picture"
        title={props.uniqueTitle}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      ></iframe>
    </div>
  );
};

function vimeoSrcOption(opt: boolean): string {
  return opt ? '1' : '0';
}