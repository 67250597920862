"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfRateCondition = instanceOfRateCondition;
exports.RateConditionFromJSON = RateConditionFromJSON;
exports.RateConditionFromJSONTyped = RateConditionFromJSONTyped;
exports.RateConditionToJSON = RateConditionToJSON;
/**
 * Check if a given object implements the RateCondition interface.
 */
function instanceOfRateCondition(value) {
    var isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "description" in value;
    return isInstance;
}
function RateConditionFromJSON(json) {
    return RateConditionFromJSONTyped(json, false);
}
function RateConditionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'title': json['title'],
        'description': json['description'],
    };
}
function RateConditionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'title': value.title,
        'description': value.description,
    };
}
