"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LineItemFromJSON = LineItemFromJSON;
exports.LineItemFromJSONTyped = LineItemFromJSONTyped;
exports.LineItemToJSON = LineItemToJSON;
var AirFareLineItem_1 = require("./AirFareLineItem");
var AirFareTaxLineItem_1 = require("./AirFareTaxLineItem");
var BookingFeeLineItem_1 = require("./BookingFeeLineItem");
var CarbonPriceLineItem_1 = require("./CarbonPriceLineItem");
var PaymentFeeLineItem_1 = require("./PaymentFeeLineItem");
var RailFareLineItem_1 = require("./RailFareLineItem");
var SeatingLineItem_1 = require("./SeatingLineItem");
var StayCostLineItem_1 = require("./StayCostLineItem");
var StayFeeLineItem_1 = require("./StayFeeLineItem");
var StayTaxLineItem_1 = require("./StayTaxLineItem");
var TicketDeliveryFeeLineItem_1 = require("./TicketDeliveryFeeLineItem");
function LineItemFromJSON(json) {
    return LineItemFromJSONTyped(json, false);
}
function LineItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'airFare':
            return __assign(__assign({}, (0, AirFareLineItem_1.AirFareLineItemFromJSONTyped)(json, true)), { type: 'airFare' });
        case 'airFareTax':
            return __assign(__assign({}, (0, AirFareTaxLineItem_1.AirFareTaxLineItemFromJSONTyped)(json, true)), { type: 'airFareTax' });
        case 'bookingFee':
            return __assign(__assign({}, (0, BookingFeeLineItem_1.BookingFeeLineItemFromJSONTyped)(json, true)), { type: 'bookingFee' });
        case 'carbonPrice':
            return __assign(__assign({}, (0, CarbonPriceLineItem_1.CarbonPriceLineItemFromJSONTyped)(json, true)), { type: 'carbonPrice' });
        case 'paymentFee':
            return __assign(__assign({}, (0, PaymentFeeLineItem_1.PaymentFeeLineItemFromJSONTyped)(json, true)), { type: 'paymentFee' });
        case 'railFare':
            return __assign(__assign({}, (0, RailFareLineItem_1.RailFareLineItemFromJSONTyped)(json, true)), { type: 'railFare' });
        case 'seating':
            return __assign(__assign({}, (0, SeatingLineItem_1.SeatingLineItemFromJSONTyped)(json, true)), { type: 'seating' });
        case 'stayCost':
            return __assign(__assign({}, (0, StayCostLineItem_1.StayCostLineItemFromJSONTyped)(json, true)), { type: 'stayCost' });
        case 'stayFee':
            return __assign(__assign({}, (0, StayFeeLineItem_1.StayFeeLineItemFromJSONTyped)(json, true)), { type: 'stayFee' });
        case 'stayTax':
            return __assign(__assign({}, (0, StayTaxLineItem_1.StayTaxLineItemFromJSONTyped)(json, true)), { type: 'stayTax' });
        case 'ticketDeliveryFee':
            return __assign(__assign({}, (0, TicketDeliveryFeeLineItem_1.TicketDeliveryFeeLineItemFromJSONTyped)(json, true)), { type: 'ticketDeliveryFee' });
        default:
            throw new Error("No variant of LineItem exists with 'type=".concat(json['type'], "'"));
    }
}
function LineItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'airFare':
            return (0, AirFareLineItem_1.AirFareLineItemToJSON)(value);
        case 'airFareTax':
            return (0, AirFareTaxLineItem_1.AirFareTaxLineItemToJSON)(value);
        case 'bookingFee':
            return (0, BookingFeeLineItem_1.BookingFeeLineItemToJSON)(value);
        case 'carbonPrice':
            return (0, CarbonPriceLineItem_1.CarbonPriceLineItemToJSON)(value);
        case 'paymentFee':
            return (0, PaymentFeeLineItem_1.PaymentFeeLineItemToJSON)(value);
        case 'railFare':
            return (0, RailFareLineItem_1.RailFareLineItemToJSON)(value);
        case 'seating':
            return (0, SeatingLineItem_1.SeatingLineItemToJSON)(value);
        case 'stayCost':
            return (0, StayCostLineItem_1.StayCostLineItemToJSON)(value);
        case 'stayFee':
            return (0, StayFeeLineItem_1.StayFeeLineItemToJSON)(value);
        case 'stayTax':
            return (0, StayTaxLineItem_1.StayTaxLineItemToJSON)(value);
        case 'ticketDeliveryFee':
            return (0, TicketDeliveryFeeLineItem_1.TicketDeliveryFeeLineItemToJSON)(value);
        default:
            throw new Error("No variant of LineItem exists with 'type=".concat(value['type'], "'"));
    }
}
