"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTransitDetails = instanceOfTransitDetails;
exports.TransitDetailsFromJSON = TransitDetailsFromJSON;
exports.TransitDetailsFromJSONTyped = TransitDetailsFromJSONTyped;
exports.TransitDetailsToJSON = TransitDetailsToJSON;
var runtime_1 = require("../runtime");
var Carrier_1 = require("./Carrier");
/**
 * Check if a given object implements the TransitDetails interface.
 */
function instanceOfTransitDetails(value) {
    var isInstance = true;
    return isInstance;
}
function TransitDetailsFromJSON(json) {
    return TransitDetailsFromJSONTyped(json, false);
}
function TransitDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'carrier': !(0, runtime_1.exists)(json, 'carrier') ? undefined : (0, Carrier_1.CarrierFromJSON)(json['carrier']),
        'lineShortName': !(0, runtime_1.exists)(json, 'lineShortName') ? undefined : json['lineShortName'],
        'lineName': !(0, runtime_1.exists)(json, 'lineName') ? undefined : json['lineName'],
        'headsign': !(0, runtime_1.exists)(json, 'headsign') ? undefined : json['headsign'],
    };
}
function TransitDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'carrier': (0, Carrier_1.CarrierToJSON)(value.carrier),
        'lineShortName': value.lineShortName,
        'lineName': value.lineName,
        'headsign': value.headsign,
    };
}
