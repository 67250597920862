"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTicketDeliveryFeeLineItem = instanceOfTicketDeliveryFeeLineItem;
exports.TicketDeliveryFeeLineItemFromJSON = TicketDeliveryFeeLineItemFromJSON;
exports.TicketDeliveryFeeLineItemFromJSONTyped = TicketDeliveryFeeLineItemFromJSONTyped;
exports.TicketDeliveryFeeLineItemToJSON = TicketDeliveryFeeLineItemToJSON;
var Price_1 = require("./Price");
var TicketDeliveryFeeLineItemType_1 = require("./TicketDeliveryFeeLineItemType");
/**
 * Check if a given object implements the TicketDeliveryFeeLineItem interface.
 */
function instanceOfTicketDeliveryFeeLineItem(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "price" in value;
    return isInstance;
}
function TicketDeliveryFeeLineItemFromJSON(json) {
    return TicketDeliveryFeeLineItemFromJSONTyped(json, false);
}
function TicketDeliveryFeeLineItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, TicketDeliveryFeeLineItemType_1.TicketDeliveryFeeLineItemTypeFromJSON)(json['type']),
        'price': (0, Price_1.PriceFromJSON)(json['price']),
    };
}
function TicketDeliveryFeeLineItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, TicketDeliveryFeeLineItemType_1.TicketDeliveryFeeLineItemTypeToJSON)(value.type),
        'price': (0, Price_1.PriceToJSON)(value.price),
    };
}
