"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfNullableOrganisationBackendDetails = instanceOfNullableOrganisationBackendDetails;
exports.NullableOrganisationBackendDetailsFromJSON = NullableOrganisationBackendDetailsFromJSON;
exports.NullableOrganisationBackendDetailsFromJSONTyped = NullableOrganisationBackendDetailsFromJSONTyped;
exports.NullableOrganisationBackendDetailsToJSON = NullableOrganisationBackendDetailsToJSON;
var runtime_1 = require("../runtime");
var RegisteredAddress_1 = require("./RegisteredAddress");
/**
 * Check if a given object implements the NullableOrganisationBackendDetails interface.
 */
function instanceOfNullableOrganisationBackendDetails(value) {
    var isInstance = true;
    return isInstance;
}
function NullableOrganisationBackendDetailsFromJSON(json) {
    return NullableOrganisationBackendDetailsFromJSONTyped(json, false);
}
function NullableOrganisationBackendDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'readableReference': !(0, runtime_1.exists)(json, 'readableReference') ? undefined : json['readableReference'],
        'registeredName': !(0, runtime_1.exists)(json, 'registeredName') ? undefined : json['registeredName'],
        'urlName': !(0, runtime_1.exists)(json, 'urlName') ? undefined : json['urlName'],
        'address': !(0, runtime_1.exists)(json, 'address') ? undefined : (0, RegisteredAddress_1.RegisteredAddressFromJSON)(json['address']),
    };
}
function NullableOrganisationBackendDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'readableReference': value.readableReference,
        'registeredName': value.registeredName,
        'urlName': value.urlName,
        'address': (0, RegisteredAddress_1.RegisteredAddressToJSON)(value.address),
    };
}
