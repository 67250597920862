"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExternalLinkFromJSON = ExternalLinkFromJSON;
exports.ExternalLinkFromJSONTyped = ExternalLinkFromJSONTyped;
exports.ExternalLinkToJSON = ExternalLinkToJSON;
var AndroidAppExternalLink_1 = require("./AndroidAppExternalLink");
var IosAppExternalLink_1 = require("./IosAppExternalLink");
var PlatformExternalLink_1 = require("./PlatformExternalLink");
var WebsiteExternalLink_1 = require("./WebsiteExternalLink");
function ExternalLinkFromJSON(json) {
    return ExternalLinkFromJSONTyped(json, false);
}
function ExternalLinkFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'androidApp':
            return __assign(__assign({}, (0, AndroidAppExternalLink_1.AndroidAppExternalLinkFromJSONTyped)(json, true)), { type: 'androidApp' });
        case 'iosApp':
            return __assign(__assign({}, (0, IosAppExternalLink_1.IosAppExternalLinkFromJSONTyped)(json, true)), { type: 'iosApp' });
        case 'platform':
            return __assign(__assign({}, (0, PlatformExternalLink_1.PlatformExternalLinkFromJSONTyped)(json, true)), { type: 'platform' });
        case 'website':
            return __assign(__assign({}, (0, WebsiteExternalLink_1.WebsiteExternalLinkFromJSONTyped)(json, true)), { type: 'website' });
        default:
            throw new Error("No variant of ExternalLink exists with 'type=".concat(json['type'], "'"));
    }
}
function ExternalLinkToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'androidApp':
            return (0, AndroidAppExternalLink_1.AndroidAppExternalLinkToJSON)(value);
        case 'iosApp':
            return (0, IosAppExternalLink_1.IosAppExternalLinkToJSON)(value);
        case 'platform':
            return (0, PlatformExternalLink_1.PlatformExternalLinkToJSON)(value);
        case 'website':
            return (0, WebsiteExternalLink_1.WebsiteExternalLinkToJSON)(value);
        default:
            throw new Error("No variant of ExternalLink exists with 'type=".concat(value['type'], "'"));
    }
}
