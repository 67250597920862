"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductMarketFitAnswer = void 0;
exports.ProductMarketFitAnswerFromJSON = ProductMarketFitAnswerFromJSON;
exports.ProductMarketFitAnswerFromJSONTyped = ProductMarketFitAnswerFromJSONTyped;
exports.ProductMarketFitAnswerToJSON = ProductMarketFitAnswerToJSON;
/**
 * Answers to the question "How would you feel if you could no longer use RouteZero?" https://justinjackson.ca/product-market-fit-survey
 * @export
 */
exports.ProductMarketFitAnswer = {
    NotDisappointed: 'notDisappointed',
    SomewhatDisappointed: 'somewhatDisappointed',
    VeryDisappointed: 'veryDisappointed'
};
function ProductMarketFitAnswerFromJSON(json) {
    return ProductMarketFitAnswerFromJSONTyped(json, false);
}
function ProductMarketFitAnswerFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function ProductMarketFitAnswerToJSON(value) {
    return value;
}
