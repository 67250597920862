"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfReviewStay = instanceOfReviewStay;
exports.ReviewStayFromJSON = ReviewStayFromJSON;
exports.ReviewStayFromJSONTyped = ReviewStayFromJSONTyped;
exports.ReviewStayToJSON = ReviewStayToJSON;
var ReviewData_1 = require("./ReviewData");
var ReviewStayType_1 = require("./ReviewStayType");
var Stay_1 = require("./Stay");
/**
 * Check if a given object implements the ReviewStay interface.
 */
function instanceOfReviewStay(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "reviewData" in value;
    isInstance = isInstance && "stay" in value;
    return isInstance;
}
function ReviewStayFromJSON(json) {
    return ReviewStayFromJSONTyped(json, false);
}
function ReviewStayFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, ReviewStayType_1.ReviewStayTypeFromJSON)(json['type']),
        'reviewData': (0, ReviewData_1.ReviewDataFromJSON)(json['reviewData']),
        'stay': (0, Stay_1.StayFromJSON)(json['stay']),
    };
}
function ReviewStayToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, ReviewStayType_1.ReviewStayTypeToJSON)(value.type),
        'reviewData': (0, ReviewData_1.ReviewDataToJSON)(value.reviewData),
        'stay': (0, Stay_1.StayToJSON)(value.stay),
    };
}
