"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfBaggageConditionDescription = instanceOfBaggageConditionDescription;
exports.BaggageConditionDescriptionFromJSON = BaggageConditionDescriptionFromJSON;
exports.BaggageConditionDescriptionFromJSONTyped = BaggageConditionDescriptionFromJSONTyped;
exports.BaggageConditionDescriptionToJSON = BaggageConditionDescriptionToJSON;
var BaggageAllowance_1 = require("./BaggageAllowance");
var BaggageConditionDescriptionType_1 = require("./BaggageConditionDescriptionType");
/**
 * Check if a given object implements the BaggageConditionDescription interface.
 */
function instanceOfBaggageConditionDescription(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "allowance" in value;
    return isInstance;
}
function BaggageConditionDescriptionFromJSON(json) {
    return BaggageConditionDescriptionFromJSONTyped(json, false);
}
function BaggageConditionDescriptionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, BaggageConditionDescriptionType_1.BaggageConditionDescriptionTypeFromJSON)(json['type']),
        'allowance': (0, BaggageAllowance_1.BaggageAllowanceFromJSON)(json['allowance']),
    };
}
function BaggageConditionDescriptionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, BaggageConditionDescriptionType_1.BaggageConditionDescriptionTypeToJSON)(value.type),
        'allowance': (0, BaggageAllowance_1.BaggageAllowanceToJSON)(value.allowance),
    };
}
