"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfEnableOnAccountBookingRequest = instanceOfEnableOnAccountBookingRequest;
exports.EnableOnAccountBookingRequestFromJSON = EnableOnAccountBookingRequestFromJSON;
exports.EnableOnAccountBookingRequestFromJSONTyped = EnableOnAccountBookingRequestFromJSONTyped;
exports.EnableOnAccountBookingRequestToJSON = EnableOnAccountBookingRequestToJSON;
/**
 * Check if a given object implements the EnableOnAccountBookingRequest interface.
 */
function instanceOfEnableOnAccountBookingRequest(value) {
    var isInstance = true;
    isInstance = isInstance && "customerStripeId" in value;
    return isInstance;
}
function EnableOnAccountBookingRequestFromJSON(json) {
    return EnableOnAccountBookingRequestFromJSONTyped(json, false);
}
function EnableOnAccountBookingRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'customerStripeId': json['customerStripeId'],
    };
}
function EnableOnAccountBookingRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'customerStripeId': value.customerStripeId,
    };
}
