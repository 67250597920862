
import { SmallPaddingPx, BorderWidthPx, MedPaddingPx, FadedOpacity } from "@routezero-site/component/helper/theme";
import React, { ReactNode } from "react";

export interface IconTextColumnProps {
  icon: ReactNode
  title: string
  body: string
  style?: React.CSSProperties
  children?: ReactNode
}

/**
 * Icon with title + body text below. Also decorated with a vertical line on 
 * the left of the text. 
 */
export const IconTextColumn: React.FC<IconTextColumnProps> = (props) => {
  const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    gap: SmallPaddingPx(),
      ...props.style
  };
  return (
    <div style={{
      ...containerStyle,
      gap: MedPaddingPx()
    }}>
      <div style={{
        ...containerStyle,
        gap: SmallPaddingPx()
      }}>
        <div style={{
          display: 'flex',
          paddingBottom: SmallPaddingPx(),
          paddingLeft: SmallPaddingPx()
        }}>
          {props.icon}
        </div>
        <p style={{
          borderLeft: `solid ${BorderWidthPx()}px currentColor`,
          lineHeight: 1.0,
          paddingLeft: SmallPaddingPx(),
          // To align text, minus border
          marginLeft: -BorderWidthPx()
        }}>
          <b>{props.title}</b>
        </p>
        <p style={{
          paddingLeft: SmallPaddingPx(),
          opacity: FadedOpacity()
        }}>
          {props.body}
        </p>
      </div>
      <div style={{
        ...containerStyle,
        gap: SmallPaddingPx(),
        paddingLeft: SmallPaddingPx(),
      }}>
        {props.children}
      </div>
    </div>
  );
};