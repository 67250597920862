"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchDateType = void 0;
exports.SearchDateTypeFromJSON = SearchDateTypeFromJSON;
exports.SearchDateTypeFromJSONTyped = SearchDateTypeFromJSONTyped;
exports.SearchDateTypeToJSON = SearchDateTypeToJSON;
/**
 * User can choose to either depart or arrive at a certain time.
 * @export
 */
exports.SearchDateType = {
    ArriveBy: 'arriveBy',
    DepartAfter: 'departAfter'
};
function SearchDateTypeFromJSON(json) {
    return SearchDateTypeFromJSONTyped(json, false);
}
function SearchDateTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function SearchDateTypeToJSON(value) {
    return value;
}
