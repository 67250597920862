"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.HistoricEventType = void 0;
exports.HistoricEventTypeFromJSON = HistoricEventTypeFromJSON;
exports.HistoricEventTypeFromJSONTyped = HistoricEventTypeFromJSONTyped;
exports.HistoricEventTypeToJSON = HistoricEventTypeToJSON;
/**
 *
 * @export
 */
exports.HistoricEventType = {
    BookingStarted: 'bookingStarted',
    BookingConfirmed: 'bookingConfirmed',
    BookingConfirmFailed: 'bookingConfirmFailed',
    TicketsReady: 'ticketsReady',
    TicketsFailed: 'ticketsFailed',
    TicketsSent: 'ticketsSent',
    TicketsSendFailed: 'ticketsSendFailed',
    PaymentHeld: 'paymentHeld',
    PaymentFailed: 'paymentFailed',
    PaymentCaptureFailed: 'paymentCaptureFailed',
    PaymentCancelFailed: 'paymentCancelFailed',
    PaymentUpdateOrderRefFailed: 'paymentUpdateOrderRefFailed',
    InvoiceSent: 'invoiceSent',
    InvoiceSendFailed: 'invoiceSendFailed'
};
function HistoricEventTypeFromJSON(json) {
    return HistoricEventTypeFromJSONTyped(json, false);
}
function HistoricEventTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function HistoricEventTypeToJSON(value) {
    return value;
}
