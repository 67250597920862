"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommissionSubsidyType = void 0;
exports.CommissionSubsidyTypeFromJSON = CommissionSubsidyTypeFromJSON;
exports.CommissionSubsidyTypeFromJSONTyped = CommissionSubsidyTypeFromJSONTyped;
exports.CommissionSubsidyTypeToJSON = CommissionSubsidyTypeToJSON;
/**
 *
 * @export
 */
exports.CommissionSubsidyType = {
    CommissionSubsidy: 'commissionSubsidy'
};
function CommissionSubsidyTypeFromJSON(json) {
    return CommissionSubsidyTypeFromJSONTyped(json, false);
}
function CommissionSubsidyTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function CommissionSubsidyTypeToJSON(value) {
    return value;
}
