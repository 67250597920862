"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BedType = void 0;
exports.BedTypeFromJSON = BedTypeFromJSON;
exports.BedTypeFromJSONTyped = BedTypeFromJSONTyped;
exports.BedTypeToJSON = BedTypeToJSON;
/**
 *
 * @export
 */
exports.BedType = {
    Single: 'single',
    Double: 'double',
    Queen: 'queen',
    King: 'king',
    Sofabed: 'sofabed',
    Unknown: 'unknown'
};
function BedTypeFromJSON(json) {
    return BedTypeFromJSONTyped(json, false);
}
function BedTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function BedTypeToJSON(value) {
    return value;
}
