import { smallFontClassName } from "@routezero-site/component/helper/global_style";
import { AnimatedArrowOnActive, FadeOutOnActive } from "@routezero-site/component/helper/active_action";
import { useNavigateToPage } from "@routezero-site/component/helper/navigation";
import { PillBorderRadiusPx, SmallPaddingPx } from "@routezero-site/component/helper/theme";
import { ReactNode } from "react";
import React from "react";

export interface TextButtonProps {
  text: string
  style?: React.CSSProperties
}

/**
 * Text with additional padding to make the hit area larger. Fades when hovered. 
 */
export const TextButton: React.FC<TextButtonProps> = (props) => {
  return (
    <FadeOutOnActive>
      <LargeHitArea>
        <p className={smallFontClassName} style={{
          ...props.style,
          fontWeight: '600',
        }}>
          {props.text}
        </p>
      </LargeHitArea>
    </FadeOutOnActive>
  );
};

export interface LinkTextButtonProps {
  href: string
  text: string
}

/**
 * Opens a link when pressed.
 */
export const LinkTextButton: React.FC<LinkTextButtonProps> = (props) => {
  return (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      <TextButton text={props.text}/>
    </a>
  );
};

export interface ArrowButtonProps {
  text: string
}

/**
 * Text with an arrow next to it.
 */
export const ArrowButton: React.FC<ArrowButtonProps> = (props) => {
  return (
    <FadeOutOnActive>
      <LargeHitArea>
        <TextArrowRow text={props.text}/>
      </LargeHitArea>
    </FadeOutOnActive>
  );
};

export interface ContactSalesRequestRecogniserProps {
  children: ReactNode,
  style?: React.CSSProperties
  onClick?: () => void
}

/**
 * When pressed, will request to go to the "contact sales" page.
 */
export const ContactSalesRequestRecogniser: React.FC<ContactSalesRequestRecogniserProps> = (props) => {
  const navigator = useNavigateToPage();
  return (
    <button 
      style={props.style} 
      onClick={() => {
        if (props.onClick !== undefined) {
          props.onClick();
        }
        navigator("contactSales");
      }}
    >
      {props.children}
    </button>
  );
};

export interface ContactSalesButtonProps {
  color?: React.CSSProperties["color"]
  backgroundColor: React.CSSProperties["backgroundColor"]
}

/**
 * Button in a pill container directing the user to contact sales.
 */
export const ContactSalesPillButton = React.forwardRef<HTMLDivElement, ContactSalesButtonProps> ((props, ref) => {
  return (
    <ContactSalesRequestRecogniser>
      <FadeOutOnActive>
        <PillButton backgroundColor={props.backgroundColor}>
          <TextArrowRow 
            text="Contact sales" 
            color={props.color}
            ref={ref}  
          />
        </PillButton>
      </FadeOutOnActive>
    </ContactSalesRequestRecogniser>
  );
});

/**
 * "Learn more" text, with an extending arrow.
 */
export const LearnMoreButton: React.FC = () => {
  return (
    <ArrowButton text="Learn more"/>
  )
};

export interface TextArrowProps {
  text: string
  color?: React.CSSProperties["color"]
}

export const TextArrowRow = React.forwardRef<HTMLDivElement, TextArrowProps> ((props, ref) => {
  return (
    <div ref={ref} className={smallFontClassName} style={{
      gap: SmallPaddingPx(),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontWeight: '600',
      color: props.color
    }}>
      <p>
        {props.text}
      </p>
      <AnimatedArrowOnActive/>
    </div>
  );
});

interface PillButtonProps {
  backgroundColor: React.CSSProperties["backgroundColor"]
  children: ReactNode
}

const PillButton: React.FC<PillButtonProps> = (props) => {
  return (
    <div style={{
      padding: `${SmallPaddingPx()*0.75}px ${SmallPaddingPx()*1.75}px`,
      gap: SmallPaddingPx(),
      backgroundColor: props.backgroundColor,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontWeight: '500',
      borderRadius: PillBorderRadiusPx(),
    }}>
      {props.children}
    </div>
  );
}

interface LargerHitAreaProps {
  children: ReactNode
}

const LargeHitArea: React.FC<LargerHitAreaProps> = (props) => {
  return (
    <div style={{
      padding: `${SmallPaddingPx()}px 0px` 
    }}>
      {props.children}
    </div>
  );
};