"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfIosAppExternalLink = instanceOfIosAppExternalLink;
exports.IosAppExternalLinkFromJSON = IosAppExternalLinkFromJSON;
exports.IosAppExternalLinkFromJSONTyped = IosAppExternalLinkFromJSONTyped;
exports.IosAppExternalLinkToJSON = IosAppExternalLinkToJSON;
var IosAppExternalLinkType_1 = require("./IosAppExternalLinkType");
/**
 * Check if a given object implements the IosAppExternalLink interface.
 */
function instanceOfIosAppExternalLink(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "urlScheme" in value;
    return isInstance;
}
function IosAppExternalLinkFromJSON(json) {
    return IosAppExternalLinkFromJSONTyped(json, false);
}
function IosAppExternalLinkFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, IosAppExternalLinkType_1.IosAppExternalLinkTypeFromJSON)(json['type']),
        'urlScheme': json['urlScheme'],
    };
}
function IosAppExternalLinkToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, IosAppExternalLinkType_1.IosAppExternalLinkTypeToJSON)(value.type),
        'urlScheme': value.urlScheme,
    };
}
