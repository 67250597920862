"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfFerryTransport = instanceOfFerryTransport;
exports.FerryTransportFromJSON = FerryTransportFromJSON;
exports.FerryTransportFromJSONTyped = FerryTransportFromJSONTyped;
exports.FerryTransportToJSON = FerryTransportToJSON;
var runtime_1 = require("../runtime");
var FerryTransportType_1 = require("./FerryTransportType");
var TransitDetails_1 = require("./TransitDetails");
/**
 * Check if a given object implements the FerryTransport interface.
 */
function instanceOfFerryTransport(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
function FerryTransportFromJSON(json) {
    return FerryTransportFromJSONTyped(json, false);
}
function FerryTransportFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, FerryTransportType_1.FerryTransportTypeFromJSON)(json['type']),
        'transitDetails': !(0, runtime_1.exists)(json, 'transitDetails') ? undefined : (0, TransitDetails_1.TransitDetailsFromJSON)(json['transitDetails']),
    };
}
function FerryTransportToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, FerryTransportType_1.FerryTransportTypeToJSON)(value.type),
        'transitDetails': (0, TransitDetails_1.TransitDetailsToJSON)(value.transitDetails),
    };
}
