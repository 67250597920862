"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfPriceStrategies = instanceOfPriceStrategies;
exports.PriceStrategiesFromJSON = PriceStrategiesFromJSON;
exports.PriceStrategiesFromJSONTyped = PriceStrategiesFromJSONTyped;
exports.PriceStrategiesToJSON = PriceStrategiesToJSON;
var runtime_1 = require("../runtime");
var FixedValueAdjustment_1 = require("./FixedValueAdjustment");
var MarkupStrategy_1 = require("./MarkupStrategy");
var SubsidyStrategy_1 = require("./SubsidyStrategy");
/**
 * Check if a given object implements the PriceStrategies interface.
 */
function instanceOfPriceStrategies(value) {
    var isInstance = true;
    isInstance = isInstance && "markup" in value;
    isInstance = isInstance && "subsidy" in value;
    return isInstance;
}
function PriceStrategiesFromJSON(json) {
    return PriceStrategiesFromJSONTyped(json, false);
}
function PriceStrategiesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'markup': (0, MarkupStrategy_1.MarkupStrategyFromJSON)(json['markup']),
        'subsidy': (0, SubsidyStrategy_1.SubsidyStrategyFromJSON)(json['subsidy']),
        'maxUndercut': !(0, runtime_1.exists)(json, 'maxUndercut') ? undefined : (0, FixedValueAdjustment_1.FixedValueAdjustmentFromJSON)(json['maxUndercut']),
        'carbonPricePencePerTonne': !(0, runtime_1.exists)(json, 'carbonPricePencePerTonne') ? undefined : json['carbonPricePencePerTonne'],
    };
}
function PriceStrategiesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'markup': (0, MarkupStrategy_1.MarkupStrategyToJSON)(value.markup),
        'subsidy': (0, SubsidyStrategy_1.SubsidyStrategyToJSON)(value.subsidy),
        'maxUndercut': (0, FixedValueAdjustment_1.FixedValueAdjustmentToJSON)(value.maxUndercut),
        'carbonPricePencePerTonne': value.carbonPricePencePerTonne,
    };
}
