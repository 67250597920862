"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FlightLoyaltyProgrammeType = void 0;
exports.FlightLoyaltyProgrammeTypeFromJSON = FlightLoyaltyProgrammeTypeFromJSON;
exports.FlightLoyaltyProgrammeTypeFromJSONTyped = FlightLoyaltyProgrammeTypeFromJSONTyped;
exports.FlightLoyaltyProgrammeTypeToJSON = FlightLoyaltyProgrammeTypeToJSON;
/**
 *
 * @export
 */
exports.FlightLoyaltyProgrammeType = {
    FlightLoyaltyProgramme: 'flightLoyaltyProgramme'
};
function FlightLoyaltyProgrammeTypeFromJSON(json) {
    return FlightLoyaltyProgrammeTypeFromJSONTyped(json, false);
}
function FlightLoyaltyProgrammeTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function FlightLoyaltyProgrammeTypeToJSON(value) {
    return value;
}
