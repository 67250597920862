"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VanSize = void 0;
exports.VanSizeFromJSON = VanSizeFromJSON;
exports.VanSizeFromJSONTyped = VanSizeFromJSONTyped;
exports.VanSizeToJSON = VanSizeToJSON;
/**
 * Class 1 is up to 1.305 tonnes, Class 2 is 1.305 - 1.74 tonnes, and Class 3 is 1.74 - 3.5 tonnes. Average is up to 3.5 tonnes.
 * @export
 */
exports.VanSize = {
    Class1: 'class1',
    Class2: 'class2',
    Class3: 'class3',
    Average: 'average'
};
function VanSizeFromJSON(json) {
    return VanSizeFromJSONTyped(json, false);
}
function VanSizeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function VanSizeToJSON(value) {
    return value;
}
