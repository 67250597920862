"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfPublicSiteRef = instanceOfPublicSiteRef;
exports.PublicSiteRefFromJSON = PublicSiteRefFromJSON;
exports.PublicSiteRefFromJSONTyped = PublicSiteRefFromJSONTyped;
exports.PublicSiteRefToJSON = PublicSiteRefToJSON;
var PublicSiteType_1 = require("./PublicSiteType");
/**
 * Check if a given object implements the PublicSiteRef interface.
 */
function instanceOfPublicSiteRef(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
function PublicSiteRefFromJSON(json) {
    return PublicSiteRefFromJSONTyped(json, false);
}
function PublicSiteRefFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, PublicSiteType_1.PublicSiteTypeFromJSON)(json['type']),
    };
}
function PublicSiteRefToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, PublicSiteType_1.PublicSiteTypeToJSON)(value.type),
    };
}
