"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardPaymentMethodType = void 0;
exports.CardPaymentMethodTypeFromJSON = CardPaymentMethodTypeFromJSON;
exports.CardPaymentMethodTypeFromJSONTyped = CardPaymentMethodTypeFromJSONTyped;
exports.CardPaymentMethodTypeToJSON = CardPaymentMethodTypeToJSON;
/**
 *
 * @export
 */
exports.CardPaymentMethodType = {
    Card: 'card'
};
function CardPaymentMethodTypeFromJSON(json) {
    return CardPaymentMethodTypeFromJSONTyped(json, false);
}
function CardPaymentMethodTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function CardPaymentMethodTypeToJSON(value) {
    return value;
}
