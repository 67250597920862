
export type GraphPoint = [number, number]
export type GraphY = number | null
export type Graph = (x: number) => GraphY 
/**
 * @returns y values after sampling the given graph.
 */
export type GraphSampler = (graph: Graph) => GraphY[]

/**
 * @returns function that linearly interpolates/extrapolates along a straight
 *  line that passes through p1 and p2.
 */
export function linear(args: { p1: GraphPoint, p2: GraphPoint }): Graph {
  const [x1, y1] = args.p1;
  const [x2, y2] = args.p2;
  // y = mx + c
  const m = (y2 - y1) / (x2 - x1);
  // c = y - mx
  const c = y2 - m * x2;

  return x => m * x + c;
}

/**
 * @returns graph representing e^-((x - shiftX) * exponentFactor).
 */
export function expMinusX(args: { exponentFactor: number, shiftX: number }): Graph {
  return x => Math.exp(-(x - args.shiftX) * args.exponentFactor);
}

/**
 * @returns multiplication of two graphs at every point.
 */
export function mult(args: { graph1: Graph, graph2: Graph }): Graph {
  return x => {
    const y1 = args.graph1(x);
    if (y1 === null) {
      return null;
    }
    const y2 = args.graph2(x);
    if (y2 === null) {
      return null;
    }
    return y1 * y2;
  }
}

/**
 * @returns graph that returns null if x is less than or equal to `thresholdX`, 
 *  otherwise returns graph(x).
 */
export function nullLte(args: { graph: Graph, thresholdX: number }): Graph {
  return x => x >= args.thresholdX ? args.graph(x) : null;
}

/**
 * @returns graph that returns null if x is greater than `thresholdX`, 
 *  otherwise returns graph(x).
 */
export function nullGt(args: { graph: Graph, thresholdX: number }): Graph {
  return x => x <= args.thresholdX ? args.graph(x) : null;
}

/**
 * @returns y values after sampling the given graph.
 */
export function graphSampler(args: {  minX: number, maxX: number, incX: number }): GraphSampler {
  return (graph) => {
    const ys: GraphY[] = [];
    for (let x=args.minX; x<args.maxX; x+=args.incX) {
      ys.push(graph(x));
    }
    return ys;
  };
}