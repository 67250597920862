import { FadeOutOnActive, HoverIsActiveRecogniser } from "@routezero-site/component/helper/active_action";
import { IsMobile, IsTabletOrDesktop, mediaStyle } from "@routezero-site/component/helper/breakpoint";
import { TextArrowRow } from "@routezero-site/component/helper/button";
import { smallFontClassName } from "@routezero-site/component/helper/global_style";
import { MedBorderRadiusPx, MedPaddingPx, SmallPaddingPx } from "@routezero-site/component/helper/theme";
import { RecordProspectRequest, maybeCompletedRecordProspectRequest } from "@routezero-site/service/record-prospect/record_prospect";
import { FormEvent, ReactNode, useState } from "react";


export interface ContactSalesFormProps {
  onSubmit: (formData: RecordProspectRequest) => void
}

/**
 * Form where the user can enter their details to request a demo.
 */
export const ContactSalesForm: React.FC<ContactSalesFormProps> = (props) => {
  const [hasPressedSubmit, setPressedSubmit] = useState(false);
  const [formData, setFormData] = useState<Partial<RecordProspectRequest>>({});

  function updateFormData(update: Partial<RecordProspectRequest>) {
    setFormData({
      ...formData,
      ...update
    });
  }

  function handleSubmit(e: FormEvent) {
    e.preventDefault();
    setPressedSubmit(true);

    const request = maybeCompletedRecordProspectRequest(formData);
    if (request !== undefined) {
      props.onSubmit(request);
    }
  }

  const inputGap = SmallPaddingPx();
  return (
    <form 
      onSubmit={handleSubmit} 
      style={{ 
        display: 'flex',
        flexDirection: 'column', 
        gap: inputGap
      }}>
      {/* Inset form elements vs submit button */}
      <div style={{ 
        display: 'flex',
        flexDirection: 'column', 
        gap: inputGap,
        padding: `0px ${SmallPaddingPx()}px`,
      }}>
        <ValidedTextInput 
          label="Name" 
          placeholder="Your name"
          value={formData.name}
          onChange={name => updateFormData({ name })}
          hasPressedSubmit={hasPressedSubmit}
        />
        <ValidedTextInput
          type="email"
          label="Work email" 
          placeholder="name@organisation.com"
          value={formData.workEmail}
          onChange={workEmail => updateFormData({ workEmail })}
          hasPressedSubmit={hasPressedSubmit}
        />
        <div style={{
          display: 'flex',
          gap: inputGap,
          ...mediaStyle(IsTabletOrDesktop(), {
            flexDirection: 'row',
          }),
          ...mediaStyle(IsMobile(), {
            flexDirection: 'column',
          }),
        }}>
          <div style={{ flexGrow: 1, flexBasis: 0 }}>
            <ValidedTextInput 
              label="Job title" 
              placeholder="Sustainability Manager"
              value={formData.jobTitle}
              onChange={jobTitle => updateFormData({ jobTitle })}
              hasPressedSubmit={hasPressedSubmit}
            />
          </div>
          <div style={{ flexGrow: 1, flexBasis: 0 }}>
            <ValidedTextInput 
              label="Organisation name" 
              placeholder="Organisation name"
              value={formData.organisationName}
              onChange={organisationName => updateFormData({ organisationName })}
              hasPressedSubmit={hasPressedSubmit}
            />
          </div>
        </div>
      </div>
      <HoverIsActiveRecogniser>
        <RequestDemoButton/>
      </HoverIsActiveRecogniser>
    </form>
  );
};

interface ValidedTextInputProps extends TextInputProps {
  /** 
   * Whether the user tried to press submit.
   */
  hasPressedSubmit: boolean
}

/**
 * Checks the user has entered some data, otherwise, displays a message 
 * underneath the text input.
 */
const ValidedTextInput: React.FC<ValidedTextInputProps> = (props) => {
  return (
    <InvalidEntryInputContainer {...props}>
      <TextInput {...props}/>
    </InvalidEntryInputContainer>
  );
};

interface TextInputProps {
  type?: React.HTMLInputTypeAttribute
  label: string
  placeholder: string
  value: string | undefined
  onChange: (text: string) => void
}

/**
 * Label and text box. 
 */
const TextInput: React.FC<TextInputProps> = (props) => {
  return (
    <div style={{
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      padding: `${SmallPaddingPx()}px ${SmallPaddingPx()*2}px`,
      borderRadius: MedBorderRadiusPx(),
      gap: SmallPaddingPx() * 0.5,
    }}>
      <label style={{ lineHeight: 0 }}>
        {props.label}
      </label>
      <input
        type={props.type ?? "text"}
        placeholder={props.placeholder}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        style={{
          width: '100%',
          padding: `${SmallPaddingPx()}px 0px`,
          backgroundColor: 'transparent'
        }}
      />
    </div>
  );
};

interface InvalidEntryInputContainerProps {
  /** 
   * Whether the user tried to press submit 
   */
  hasPressedSubmit: boolean
  /** 
   * The data being displayed by the children. If this is undefined and the 
   * user trued to press submit then a message is displayed.
   */
  value: unknown | undefined
  children: ReactNode
}

/**
 * If the user has attempted to submit the form but not filled in all fields,
 * shows a message underneath the child components.
 */
const InvalidEntryInputContainer: React.FC<InvalidEntryInputContainerProps> = (props) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: SmallPaddingPx()
    }}>
      {props.children}
      {
        props.hasPressedSubmit && props.value === undefined
          ? <InvalidEntryInputMessage/>
          : null
      }
    </div>
  );
};

/**
 * Message to tell the user that this input is required.
 */
const InvalidEntryInputMessage: React.FC = () => {
  return (
    <p className={smallFontClassName}>
      Please complete this required field.
    </p>
  );
};

/**
 * Button in a pill container directing the user to contact sales.
 */
export const RequestDemoButton: React.FC = () => {
  return (
    <button type="submit" style={{width: '100%'}}>
      <FadeOutOnActive>
        <PillButton>
          <TextArrowRow text="Request demo"/>
        </PillButton>
      </FadeOutOnActive>
    </button>
  );
};

interface PillButtonProps {
  children: ReactNode
}

const PillButton: React.FC<PillButtonProps> = (props) => {
  return (
    <div style={{
      gap: SmallPaddingPx(),
      padding: `${MedPaddingPx()}px ${SmallPaddingPx()}px`,
      backgroundColor: 'currentColor',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: '500',
      borderRadius: 1000, // Pill shaped
    }}>
      <div style={{color: 'white'}}>
        {props.children}
      </div>
    </div>
  );
};