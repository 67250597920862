"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTravel = instanceOfTravel;
exports.TravelFromJSON = TravelFromJSON;
exports.TravelFromJSONTyped = TravelFromJSONTyped;
exports.TravelToJSON = TravelToJSON;
var LatLng_1 = require("./LatLng");
var Location_1 = require("./Location");
var TimeRange_1 = require("./TimeRange");
var Transport_1 = require("./Transport");
var TravelClass_1 = require("./TravelClass");
/**
 * Check if a given object implements the Travel interface.
 */
function instanceOfTravel(value) {
    var isInstance = true;
    isInstance = isInstance && "transport" in value;
    isInstance = isInstance && "origin" in value;
    isInstance = isInstance && "dest" in value;
    isInstance = isInstance && "times" in value;
    isInstance = isInstance && "distanceMeters" in value;
    isInstance = isInstance && "polyline" in value;
    isInstance = isInstance && "travelClasses" in value;
    return isInstance;
}
function TravelFromJSON(json) {
    return TravelFromJSONTyped(json, false);
}
function TravelFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'transport': (0, Transport_1.TransportFromJSON)(json['transport']),
        'origin': (0, Location_1.LocationFromJSON)(json['origin']),
        'dest': (0, Location_1.LocationFromJSON)(json['dest']),
        'times': (0, TimeRange_1.TimeRangeFromJSON)(json['times']),
        'distanceMeters': json['distanceMeters'],
        'polyline': (json['polyline'].map(LatLng_1.LatLngFromJSON)),
        'travelClasses': (json['travelClasses'].map(TravelClass_1.TravelClassFromJSON)),
    };
}
function TravelToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'transport': (0, Transport_1.TransportToJSON)(value.transport),
        'origin': (0, Location_1.LocationToJSON)(value.origin),
        'dest': (0, Location_1.LocationToJSON)(value.dest),
        'times': (0, TimeRange_1.TimeRangeToJSON)(value.times),
        'distanceMeters': value.distanceMeters,
        'polyline': (value.polyline.map(LatLng_1.LatLngToJSON)),
        'travelClasses': (value.travelClasses.map(TravelClass_1.TravelClassToJSON)),
    };
}
