"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfLoginUrlRedirectProd = instanceOfLoginUrlRedirectProd;
exports.LoginUrlRedirectProdFromJSON = LoginUrlRedirectProdFromJSON;
exports.LoginUrlRedirectProdFromJSONTyped = LoginUrlRedirectProdFromJSONTyped;
exports.LoginUrlRedirectProdToJSON = LoginUrlRedirectProdToJSON;
var LoginUrlRedirectProdType_1 = require("./LoginUrlRedirectProdType");
var OrgUrlName_1 = require("./OrgUrlName");
/**
 * Check if a given object implements the LoginUrlRedirectProd interface.
 */
function instanceOfLoginUrlRedirectProd(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "orgUrlName" in value;
    return isInstance;
}
function LoginUrlRedirectProdFromJSON(json) {
    return LoginUrlRedirectProdFromJSONTyped(json, false);
}
function LoginUrlRedirectProdFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, LoginUrlRedirectProdType_1.LoginUrlRedirectProdTypeFromJSON)(json['type']),
        'orgUrlName': (0, OrgUrlName_1.OrgUrlNameFromJSON)(json['orgUrlName']),
    };
}
function LoginUrlRedirectProdToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, LoginUrlRedirectProdType_1.LoginUrlRedirectProdTypeToJSON)(value.type),
        'orgUrlName': (0, OrgUrlName_1.OrgUrlNameToJSON)(value.orgUrlName),
    };
}
