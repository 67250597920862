import { IsDesktop, IsTabletOrDesktop, mediaStyle } from "@routezero-site/component/helper/breakpoint";
import { Pill } from "@routezero-site/component/helper/pill";
import { LargePaddingPx, PillHorizontalPadding, SectionHeaderTextGapPx, SmallPaddingPx } from "@routezero-site/component/helper/theme";
import React, { ReactNode } from "react";

export interface SectionMainTextProps {
  header: string
  subtitle?: string
  body?: string,
  color: React.CSSProperties['color']
  subtitleBackgroundColor: React.CSSProperties['backgroundColor']
  subtitleBackgroundBlendMode: React.CSSProperties['mixBlendMode']
  /**
   * Whether the character that has a descender, e.g. p, g, etc
   */
  underlineDescendedCharacter?: boolean
  /**
   * If true, will try to fill the whole available width. Useful when wanting
   * to have this side-by-side with other content. 
   */
  fillWidth?: boolean
  /**
   * If defined, displayed underneath the text.
   */
  callToAction?: ReactNode
}

/**
 * Displays section header, subtitle, and body text. Intended to be displayed
 * at the top of a section, before main content such as specfic feature 
 * information, videos, images, etc.
 */
export const SectionMainText: React.FC<SectionMainTextProps> = (props) => {
  const fillWidth = props.fillWidth ?? false;
  return (
    <div style={{
      color: props.color,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      gap: SectionHeaderTextGapPx(),
    }}>
      <div style={{
        ...mediaStyle(IsTabletOrDesktop(), {
          paddingLeft: LargePaddingPx() - PillHorizontalPadding(),
        })
      }}>
        {props.subtitle && 
        <SectionSubtitle
          subtitle={props.subtitle}
          color={props.color}
          backgroundColor={props.subtitleBackgroundColor}
          backgroundBlendMode={props.subtitleBackgroundBlendMode}
        />
        }
      </div>
      <div style={{
        ...mediaStyle(IsTabletOrDesktop(), {
          paddingLeft: LargePaddingPx(),
        }),
        ...mediaStyle(IsDesktop() && !fillWidth, {
          width: '60%'
        })
      }}>
        <SectionHeader
          header={props.header} 
          color={props.color}
          underlineDescendedCharacter={props.underlineDescendedCharacter ?? false}
        />
      </div>
      {props.body &&
      <div style={{
        ...mediaStyle(IsTabletOrDesktop(), {
          paddingLeft: LargePaddingPx()
        })
      }}>
        <p style={{
          ...mediaStyle(IsDesktop() && !fillWidth, {
            width: '75%'
          })
        }}>
          {props.body}
        </p>
      </div>}
      {props.callToAction && 
      <div style={{
        ...mediaStyle(IsTabletOrDesktop(), {
          padding: `0px ${LargePaddingPx() - SmallPaddingPx()}px`
        })
      }}>
        {props.callToAction}
      </div>}
    </div>
  );
}

interface SectionHeaderProps {
  header: string
  color: React.CSSProperties['color']
  underlineDescendedCharacter: boolean
}

const SectionHeader: React.FC<SectionHeaderProps> = (props) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: SectionHeaderTextGapPx()
    }}>
      <h2>
        {props.header}
      </h2>
      {/* Underline */}
      <div style={{
        width: 20,
        height: 6,
        marginTop: props.underlineDescendedCharacter ? -10 : -20,
        marginLeft: 2,
        backgroundColor: props.color,
      }}/>
    </div>
  );
}

interface SectionSubtitleProps {
  subtitle: string
  color: React.CSSProperties['color']
  backgroundColor: React.CSSProperties['backgroundColor']
  backgroundBlendMode: React.CSSProperties['mixBlendMode']
}

const SectionSubtitle: React.FC<SectionSubtitleProps> = (props) => {
  return (
    <Pill 
      backgroundColor={props.backgroundColor}
      backgroundBlendMode={props.backgroundBlendMode}
    >
      <p style={{fontWeight: 'bold'}}>
        {props.subtitle}
      </p>
    </Pill>
  );
}