"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfJourneyOfferData = instanceOfJourneyOfferData;
exports.JourneyOfferDataFromJSON = JourneyOfferDataFromJSON;
exports.JourneyOfferDataFromJSONTyped = JourneyOfferDataFromJSONTyped;
exports.JourneyOfferDataToJSON = JourneyOfferDataToJSON;
var BaggageAllowance_1 = require("./BaggageAllowance");
var JourneyConditions_1 = require("./JourneyConditions");
var JourneyFare_1 = require("./JourneyFare");
/**
 * Check if a given object implements the JourneyOfferData interface.
 */
function instanceOfJourneyOfferData(value) {
    var isInstance = true;
    isInstance = isInstance && "fares" in value;
    isInstance = isInstance && "baggageAllowance" in value;
    isInstance = isInstance && "conditions" in value;
    return isInstance;
}
function JourneyOfferDataFromJSON(json) {
    return JourneyOfferDataFromJSONTyped(json, false);
}
function JourneyOfferDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'fares': (json['fares'].map(JourneyFare_1.JourneyFareFromJSON)),
        'baggageAllowance': (json['baggageAllowance'].map(BaggageAllowance_1.BaggageAllowanceFromJSON)),
        'conditions': (json['conditions'].map(JourneyConditions_1.JourneyConditionsFromJSON)),
    };
}
function JourneyOfferDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'fares': (value.fares.map(JourneyFare_1.JourneyFareToJSON)),
        'baggageAllowance': (value.baggageAllowance.map(BaggageAllowance_1.BaggageAllowanceToJSON)),
        'conditions': (value.conditions.map(JourneyConditions_1.JourneyConditionsToJSON)),
    };
}
