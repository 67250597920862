
import { useAutoCycleIndices } from '@routezero-site/component/helper/auto_cycle';
import { IsDesktop, IsTablet, mediaStyle } from '@routezero-site/component/helper/breakpoint';
import { HoverIsActiveRecogniser, useIsActiveContext } from '@routezero-site/component/helper/active_action';
import { BadgeCheckSolidIcon, ChartSimpleSolidIcon, CircleInfoIcon, DollarIcon, GlobeIcon, LocationDotIcon, MobileScreenIcon, PhoneIcon, TrainSolidIcon, TrophyIcon } from '@routezero-site/component/helper/icon';
import { Pill } from '@routezero-site/component/helper/pill';
import { useServicesContext } from '@routezero-site/component/helper/services';
import { LargeBorderRadiusPx, LargePaddingPx, MedAnimationCurve, ShortAnimationDurationSec, SmallPaddingPx, TravelPlatformCycleFeaturesDelayMs, TravelPlatformFeatureBackgroundColor, LargeIconSizePx, TravelPlatformFeatureDetailsCardHeightPx, TravelPlatformRevertToAutoCycleFeaturesWaitMs } from '@routezero-site/component/helper/theme';
import featureDetailsBackgroundImage from '@routezero-site/component/page/home/travel-platform/media/infinte_scroll_feature_details_background_image.png';
import { TravelPlatformFeature, TravelPlatformFeatureIconType } from '@routezero-site/service/travel-platform-features/travel_platform_features';
import { ReactNode, useEffect, useState } from 'react';
import './feature_animation.css';
import usePromise from '@routezero-site/component/helper/promise';
import { ContactSalesRequestRecogniser, LearnMoreButton } from '@routezero-site/component/helper/button';

/**
 * Show a list of features, and automatically cycles through the detailed look
 * into each feature.
 */
export const TravelPlatformFeatures: React.FC = () => {
  const { travelPlatformFeaturesService } = useServicesContext();
  const [ features ] = usePromise(travelPlatformFeaturesService.features);
  const [ selectedTeatureIndex, setFeatureIndex ] = useAutoCycleIndices({
    length: features?.length ?? 0,
    intervalMs: TravelPlatformCycleFeaturesDelayMs(),
    revertToAutocycleWaitMs: TravelPlatformRevertToAutoCycleFeaturesWaitMs()
  });

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: LargePaddingPx()
    }}>
      <FeaturePills
        features={features ?? []}
        selectedIndex={selectedTeatureIndex}
        onTap={(index) => setFeatureIndex(index)}
      />
      <FeatureDetailsCard
        selectedIndex={selectedTeatureIndex}
        features={features ?? []}
      />
    </div>
  );
};

interface FeaturePillsProps {
  features: TravelPlatformFeature[]
  selectedIndex: number
  onTap: (index: number) => void
}

const FeaturePills: React.FC<FeaturePillsProps> = (props) => {
  return (
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      gap: SmallPaddingPx()*0.5
    }}>
      {props.features.map((feature, i) => 
        <FeaturePillHoverContainer 
          key={i}
          featureIcon={feature.iconType}
          featureName={feature.name} 
          isSelected={props.selectedIndex === i} 
          onTap={() => props.onTap(i)}
        />
      )}
    </div>
  );
};

interface FeaturePillProps {
  onTap: () => void
  featureIcon: TravelPlatformFeatureIconType
  featureName: string
  isSelected: boolean
}

/**
 * Pill component containing the name of a feature, e.g. "Global reach", 
 * "Mobile support", etc. When selected, the style is changed to indicate this 
 * is selected.
 */
const FeaturePillHoverContainer: React.FC<FeaturePillProps> = (props) => {
  return (
    <HoverIsActiveRecogniser 
      onHover={props.onTap}
      // Debounced to avoid the currently selected feature changing as the 
      // user scrolls down the page.
      debounceDelayMs={50}
    >
      <FeaturePill {...props}/>
    </HoverIsActiveRecogniser>
  );
};

const FeaturePill: React.FC<FeaturePillProps> = (props) => {
  const { isActive: isHovered } = useIsActiveContext();

  return (
    <Pill
      backgroundColor={TravelPlatformFeatureBackgroundColor({ 
        isSelected: props.isSelected,
        isHovered: isHovered
      })}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: SmallPaddingPx()*0.5,
        textAlign: 'center',
      }}>
        <FeatureIcon icon={props.featureIcon} />
        <div>
          <p style={{
            fontWeight: props.isSelected ? 'bold' : '500'
          }}>
            {props.featureName}
          </p>
          {/* 
          Use a hidden element to ensure the size of the pill does not change when
          the text is changed from bold to semi-bold.
          */}
          <p style={{
            fontWeight: 'bold',
            visibility: 'hidden',
            height: 0
          }}>
            {props.featureName}
          </p>
        </div>
      </div>
    </Pill>
  );
}

interface FeatureDetailsCardProps {
  selectedIndex: number
  features: TravelPlatformFeature[]
}

/**
 * More detail about the selected travel feature, showing a headline and body.
 * When the selected index changes, the background image is scrolled.
 */
const FeatureDetailsCard: React.FC<FeatureDetailsCardProps> = (props) => {
  const [backgroundYIncrement, setBackgroundYIncrement] = useState(0);
  useEffect(() => {
    setBackgroundYIncrement(backgroundYIncrement + 1);
    // Ignore because otherwise there will be an infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedIndex]);

  return (
    <ContactSalesRequestRecogniser>
      <HoverIsActiveRecogniser>
        <div style={{
          position: 'relative',
          height: TravelPlatformFeatureDetailsCardHeightPx(),
          borderRadius: LargeBorderRadiusPx(),
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden'
        }}>
          <div
            style={{
              position: 'absolute',
              top: -backgroundYIncrement * 50, bottom: 0, left: 0, right: 0,
              objectFit: 'cover',
              objectPosition: `0px 0px`,
              backgroundImage: `url(${featureDetailsBackgroundImage})`,
              backgroundRepeat: 'repeat',
              transition: `top ${ShortAnimationDurationSec()}s ${MedAnimationCurve()}`
            }}
          />
          {props.features.map((feature, i) =>
            <FeatureDetailsAnimatedInOut key={i} isSelected={i === props.selectedIndex}>
              <FeatureDetailsText
                {...feature}
              />
            </FeatureDetailsAnimatedInOut>
          )}
          <div style={{
            position: 'absolute',
            bottom: LargePaddingPx(),
            left: LargePaddingPx()
          }}>
            <LearnMoreButton/>
          </div>
        </div>
      </HoverIsActiveRecogniser>
    </ContactSalesRequestRecogniser>
  );
};

interface FeatureDetailsAnimatedInOutProps {
  isSelected: boolean
  children: ReactNode
}

/**
 * Animates in/out feature details depending on whether they're selected.
 */
const FeatureDetailsAnimatedInOut: React.FC<FeatureDetailsAnimatedInOutProps> = (props) => {
  const [animationClass, setAnimationClass] = useState('');

  useEffect(() => {
    if (props.isSelected) {
      setAnimationClass('animate-in');
    } else {
      setAnimationClass('animate-out');
    }
  }, [props.isSelected]);
  
  return (
    // Positioned absolutely so other feature details can be stacked on top, 
    // allowing for the fading in/out animations.
    <div 
      className={`${animationClass}`}
      style={{
        position: 'absolute',
        top: 0, left: 0, right: 0, bottom: 0,
        padding: LargePaddingPx()
      }}
    >
      {props.children}
    </div>
  );
}

/**
 * Headline and body text for a feature describing it in more detail.
 */
const FeatureDetailsText: React.FC<TravelPlatformFeature> = (props) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: SmallPaddingPx(),
      ...mediaStyle(IsDesktop(), {
        width: '65%'
      }),
      ...mediaStyle(IsTablet(), {
        width: '75%'
      })
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: SmallPaddingPx()
      }}>
        <FeatureIcon 
          icon={props.iconType}
          sizePx={LargeIconSizePx()}
        />
        <h3>
          {props.name}
        </h3>
      </div>
      <p style={{
        fontWeight: '500'
      }}>
        {props.description}
      </p>
    </div>
  );
};

interface FeatureIconProps {
  icon: TravelPlatformFeatureIconType
  sizePx?: number
}

/**
 * Icon displayed next to a feature's name.
 */
const FeatureIcon: React.FC<FeatureIconProps> = (props) => {
  switch (props.icon) {
    case "checkBadge": return <BadgeCheckSolidIcon {...props}/>;
    case "globe": return <GlobeIcon {...props}/>;
    case "information": return <CircleInfoIcon {...props}/>;
    case "mapPin": return <LocationDotIcon {...props}/>;
    case "mobilePhone": return <MobileScreenIcon {...props}/>;
    case "phone": return <PhoneIcon {...props}/>;
    case "price": return <DollarIcon {...props}/>;
    case "chart": return <ChartSimpleSolidIcon {...props}/>;
    case "trophy": return <TrophyIcon {...props}/>;
    case "train": return <TrainSolidIcon {...props}/>;
  }
};