"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DuffelStaysBookingMethodType = void 0;
exports.DuffelStaysBookingMethodTypeFromJSON = DuffelStaysBookingMethodTypeFromJSON;
exports.DuffelStaysBookingMethodTypeFromJSONTyped = DuffelStaysBookingMethodTypeFromJSONTyped;
exports.DuffelStaysBookingMethodTypeToJSON = DuffelStaysBookingMethodTypeToJSON;
/**
 *
 * @export
 */
exports.DuffelStaysBookingMethodType = {
    DuffelStays: 'duffelStays'
};
function DuffelStaysBookingMethodTypeFromJSON(json) {
    return DuffelStaysBookingMethodTypeFromJSONTyped(json, false);
}
function DuffelStaysBookingMethodTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function DuffelStaysBookingMethodTypeToJSON(value) {
    return value;
}
