"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.JourneyCategory = void 0;
exports.JourneyCategoryFromJSON = JourneyCategoryFromJSON;
exports.JourneyCategoryFromJSONTyped = JourneyCategoryFromJSONTyped;
exports.JourneyCategoryToJSON = JourneyCategoryToJSON;
/**
 * For train, whether the offer allows the traveller to travel on peak, off-peak, or super off-peak trains.
 * @export
 */
exports.JourneyCategory = {
    Peak: 'peak',
    OffPeak: 'offPeak',
    SuperOffPeak: 'superOffPeak'
};
function JourneyCategoryFromJSON(json) {
    return JourneyCategoryFromJSONTyped(json, false);
}
function JourneyCategoryFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function JourneyCategoryToJSON(value) {
    return value;
}
