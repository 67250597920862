"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfPartialOutwardDuffelFlightsBookingMethod = instanceOfPartialOutwardDuffelFlightsBookingMethod;
exports.PartialOutwardDuffelFlightsBookingMethodFromJSON = PartialOutwardDuffelFlightsBookingMethodFromJSON;
exports.PartialOutwardDuffelFlightsBookingMethodFromJSONTyped = PartialOutwardDuffelFlightsBookingMethodFromJSONTyped;
exports.PartialOutwardDuffelFlightsBookingMethodToJSON = PartialOutwardDuffelFlightsBookingMethodToJSON;
var PartialOutwardDuffelFlightsBookingMethodType_1 = require("./PartialOutwardDuffelFlightsBookingMethodType");
/**
 * Check if a given object implements the PartialOutwardDuffelFlightsBookingMethod interface.
 */
function instanceOfPartialOutwardDuffelFlightsBookingMethod(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "searchId" in value;
    isInstance = isInstance && "selectedOutwardPartialOfferId" in value;
    return isInstance;
}
function PartialOutwardDuffelFlightsBookingMethodFromJSON(json) {
    return PartialOutwardDuffelFlightsBookingMethodFromJSONTyped(json, false);
}
function PartialOutwardDuffelFlightsBookingMethodFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, PartialOutwardDuffelFlightsBookingMethodType_1.PartialOutwardDuffelFlightsBookingMethodTypeFromJSON)(json['type']),
        'searchId': json['searchId'],
        'selectedOutwardPartialOfferId': json['selectedOutwardPartialOfferId'],
    };
}
function PartialOutwardDuffelFlightsBookingMethodToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, PartialOutwardDuffelFlightsBookingMethodType_1.PartialOutwardDuffelFlightsBookingMethodTypeToJSON)(value.type),
        'searchId': value.searchId,
        'selectedOutwardPartialOfferId': value.selectedOutwardPartialOfferId,
    };
}
