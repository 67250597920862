"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfAmenitiesStay = instanceOfAmenitiesStay;
exports.AmenitiesStayFromJSON = AmenitiesStayFromJSON;
exports.AmenitiesStayFromJSONTyped = AmenitiesStayFromJSONTyped;
exports.AmenitiesStayToJSON = AmenitiesStayToJSON;
var AmenitiesStayType_1 = require("./AmenitiesStayType");
var Amenity_1 = require("./Amenity");
var Stay_1 = require("./Stay");
/**
 * Check if a given object implements the AmenitiesStay interface.
 */
function instanceOfAmenitiesStay(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "amenities" in value;
    isInstance = isInstance && "stay" in value;
    return isInstance;
}
function AmenitiesStayFromJSON(json) {
    return AmenitiesStayFromJSONTyped(json, false);
}
function AmenitiesStayFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, AmenitiesStayType_1.AmenitiesStayTypeFromJSON)(json['type']),
        'amenities': (json['amenities'].map(Amenity_1.AmenityFromJSON)),
        'stay': (0, Stay_1.StayFromJSON)(json['stay']),
    };
}
function AmenitiesStayToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, AmenitiesStayType_1.AmenitiesStayTypeToJSON)(value.type),
        'amenities': (value.amenities.map(Amenity_1.AmenityToJSON)),
        'stay': (0, Stay_1.StayToJSON)(value.stay),
    };
}
