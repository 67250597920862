"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfSplitTicketReason = instanceOfSplitTicketReason;
exports.SplitTicketReasonFromJSON = SplitTicketReasonFromJSON;
exports.SplitTicketReasonFromJSONTyped = SplitTicketReasonFromJSONTyped;
exports.SplitTicketReasonToJSON = SplitTicketReasonToJSON;
var SplitTicketReasonType_1 = require("./SplitTicketReasonType");
/**
 * Check if a given object implements the SplitTicketReason interface.
 */
function instanceOfSplitTicketReason(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
function SplitTicketReasonFromJSON(json) {
    return SplitTicketReasonFromJSONTyped(json, false);
}
function SplitTicketReasonFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, SplitTicketReasonType_1.SplitTicketReasonTypeFromJSON)(json['type']),
    };
}
function SplitTicketReasonToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, SplitTicketReasonType_1.SplitTicketReasonTypeToJSON)(value.type),
    };
}
