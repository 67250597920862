import { matchEnv } from "@routezero-site/helper/environment";
import { Configuration, DefaultApi } from "routezero_api";

/**
 * @returns an object that can be used to make calls to the RouteZero backend.
 *  Depending on whether this is running locally, in dev enviroment, or in 
 *  production, requests will be routed to different backends.
 */
export function routeZeroApi(): DefaultApi {
  return new DefaultApi(new Configuration({
    basePath: matchEnv({
      local: () => 'http://localhost:5002',
      dev: () => 'https://dev-api.routezero.com',
      prod: () => 'https://api.routezero.com'
    })
  }))
}