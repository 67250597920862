"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfNullableSupportContactDetails = instanceOfNullableSupportContactDetails;
exports.NullableSupportContactDetailsFromJSON = NullableSupportContactDetailsFromJSON;
exports.NullableSupportContactDetailsFromJSONTyped = NullableSupportContactDetailsFromJSONTyped;
exports.NullableSupportContactDetailsToJSON = NullableSupportContactDetailsToJSON;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the NullableSupportContactDetails interface.
 */
function instanceOfNullableSupportContactDetails(value) {
    var isInstance = true;
    return isInstance;
}
function NullableSupportContactDetailsFromJSON(json) {
    return NullableSupportContactDetailsFromJSONTyped(json, false);
}
function NullableSupportContactDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'email': !(0, runtime_1.exists)(json, 'email') ? undefined : json['email'],
        'phone': !(0, runtime_1.exists)(json, 'phone') ? undefined : json['phone'],
    };
}
function NullableSupportContactDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'email': value.email,
        'phone': value.phone,
    };
}
