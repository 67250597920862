"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StopReason = void 0;
exports.StopReasonFromJSON = StopReasonFromJSON;
exports.StopReasonFromJSONTyped = StopReasonFromJSONTyped;
exports.StopReasonToJSON = StopReasonToJSON;
/**
 *
 * @export
 */
exports.StopReason = {
    EvCharge: 'evCharge',
    Change: 'change',
    OutboundAirport: 'outboundAirport',
    InboundAirport: 'inboundAirport'
};
function StopReasonFromJSON(json) {
    return StopReasonFromJSONTyped(json, false);
}
function StopReasonFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function StopReasonToJSON(value) {
    return value;
}
