"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfLatLngLocation = instanceOfLatLngLocation;
exports.LatLngLocationFromJSON = LatLngLocationFromJSON;
exports.LatLngLocationFromJSONTyped = LatLngLocationFromJSONTyped;
exports.LatLngLocationToJSON = LatLngLocationToJSON;
var runtime_1 = require("../runtime");
var Address_1 = require("./Address");
var LatLng_1 = require("./LatLng");
var LocationId_1 = require("./LocationId");
var Timezone_1 = require("./Timezone");
/**
 * Check if a given object implements the LatLngLocation interface.
 */
function instanceOfLatLngLocation(value) {
    var isInstance = true;
    isInstance = isInstance && "latlng" in value;
    return isInstance;
}
function LatLngLocationFromJSON(json) {
    return LatLngLocationFromJSONTyped(json, false);
}
function LatLngLocationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'locationId': !(0, runtime_1.exists)(json, 'locationId') ? undefined : (0, LocationId_1.LocationIdFromJSON)(json['locationId']),
        'address': !(0, runtime_1.exists)(json, 'address') ? undefined : (0, Address_1.AddressFromJSON)(json['address']),
        'latlng': (0, LatLng_1.LatLngFromJSON)(json['latlng']),
        'iso2CountryCode': !(0, runtime_1.exists)(json, 'iso2CountryCode') ? undefined : json['iso2CountryCode'],
        'timezone': !(0, runtime_1.exists)(json, 'timezone') ? undefined : (0, Timezone_1.TimezoneFromJSON)(json['timezone']),
        'terminal': !(0, runtime_1.exists)(json, 'terminal') ? undefined : json['terminal'],
    };
}
function LatLngLocationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'locationId': (0, LocationId_1.LocationIdToJSON)(value.locationId),
        'address': (0, Address_1.AddressToJSON)(value.address),
        'latlng': (0, LatLng_1.LatLngToJSON)(value.latlng),
        'iso2CountryCode': value.iso2CountryCode,
        'timezone': (0, Timezone_1.TimezoneToJSON)(value.timezone),
        'terminal': value.terminal,
    };
}
