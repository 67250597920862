"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfDuffelStaysBookingMethod = instanceOfDuffelStaysBookingMethod;
exports.DuffelStaysBookingMethodFromJSON = DuffelStaysBookingMethodFromJSON;
exports.DuffelStaysBookingMethodFromJSONTyped = DuffelStaysBookingMethodFromJSONTyped;
exports.DuffelStaysBookingMethodToJSON = DuffelStaysBookingMethodToJSON;
var DuffelStaysBookingMethodType_1 = require("./DuffelStaysBookingMethodType");
/**
 * Check if a given object implements the DuffelStaysBookingMethod interface.
 */
function instanceOfDuffelStaysBookingMethod(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "searchResultId" in value;
    isInstance = isInstance && "rateId" in value;
    return isInstance;
}
function DuffelStaysBookingMethodFromJSON(json) {
    return DuffelStaysBookingMethodFromJSONTyped(json, false);
}
function DuffelStaysBookingMethodFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, DuffelStaysBookingMethodType_1.DuffelStaysBookingMethodTypeFromJSON)(json['type']),
        'searchResultId': json['searchResultId'],
        'rateId': json['rateId'],
    };
}
function DuffelStaysBookingMethodToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, DuffelStaysBookingMethodType_1.DuffelStaysBookingMethodTypeToJSON)(value.type),
        'searchResultId': value.searchResultId,
        'rateId': value.rateId,
    };
}
