"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfBookingInfo = instanceOfBookingInfo;
exports.BookingInfoFromJSON = BookingInfoFromJSON;
exports.BookingInfoFromJSONTyped = BookingInfoFromJSONTyped;
exports.BookingInfoToJSON = BookingInfoToJSON;
var runtime_1 = require("../runtime");
var BookingFares_1 = require("./BookingFares");
var BookingJourneys_1 = require("./BookingJourneys");
var LegalNotice_1 = require("./LegalNotice");
var Pricing_1 = require("./Pricing");
var TravellerLoyaltyProgramme_1 = require("./TravellerLoyaltyProgramme");
/**
 * Check if a given object implements the BookingInfo interface.
 */
function instanceOfBookingInfo(value) {
    var isInstance = true;
    isInstance = isInstance && "pricing" in value;
    isInstance = isInstance && "journeys" in value;
    isInstance = isInstance && "fares" in value;
    isInstance = isInstance && "adultPassengerCount" in value;
    isInstance = isInstance && "appliedLoyaltyProgrammes" in value;
    isInstance = isInstance && "legalNotice" in value;
    return isInstance;
}
function BookingInfoFromJSON(json) {
    return BookingInfoFromJSONTyped(json, false);
}
function BookingInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'pricing': (0, Pricing_1.PricingFromJSON)(json['pricing']),
        'journeys': (0, BookingJourneys_1.BookingJourneysFromJSON)(json['journeys']),
        'fares': (0, BookingFares_1.BookingFaresFromJSON)(json['fares']),
        'adultPassengerCount': json['adultPassengerCount'],
        'passengerNames': !(0, runtime_1.exists)(json, 'passengerNames') ? undefined : json['passengerNames'],
        'appliedLoyaltyProgrammes': (json['appliedLoyaltyProgrammes'].map(TravellerLoyaltyProgramme_1.TravellerLoyaltyProgrammeFromJSON)),
        'legalNotice': (0, LegalNotice_1.LegalNoticeFromJSON)(json['legalNotice']),
    };
}
function BookingInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'pricing': (0, Pricing_1.PricingToJSON)(value.pricing),
        'journeys': (0, BookingJourneys_1.BookingJourneysToJSON)(value.journeys),
        'fares': (0, BookingFares_1.BookingFaresToJSON)(value.fares),
        'adultPassengerCount': value.adultPassengerCount,
        'passengerNames': value.passengerNames,
        'appliedLoyaltyProgrammes': (value.appliedLoyaltyProgrammes.map(TravellerLoyaltyProgramme_1.TravellerLoyaltyProgrammeToJSON)),
        'legalNotice': (0, LegalNotice_1.LegalNoticeToJSON)(value.legalNotice),
    };
}
