"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfSearchVoiStationRequest = instanceOfSearchVoiStationRequest;
exports.SearchVoiStationRequestFromJSON = SearchVoiStationRequestFromJSON;
exports.SearchVoiStationRequestFromJSONTyped = SearchVoiStationRequestFromJSONTyped;
exports.SearchVoiStationRequestToJSON = SearchVoiStationRequestToJSON;
var SearchStation_1 = require("./SearchStation");
/**
 * Check if a given object implements the SearchVoiStationRequest interface.
 */
function instanceOfSearchVoiStationRequest(value) {
    var isInstance = true;
    isInstance = isInstance && "originStation" in value;
    isInstance = isInstance && "destinationStation" in value;
    return isInstance;
}
function SearchVoiStationRequestFromJSON(json) {
    return SearchVoiStationRequestFromJSONTyped(json, false);
}
function SearchVoiStationRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'originStation': (0, SearchStation_1.SearchStationFromJSON)(json['originStation']),
        'destinationStation': (0, SearchStation_1.SearchStationFromJSON)(json['destinationStation']),
    };
}
function SearchVoiStationRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'originStation': (0, SearchStation_1.SearchStationToJSON)(value.originStation),
        'destinationStation': (0, SearchStation_1.SearchStationToJSON)(value.destinationStation),
    };
}
