"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfKioskCollection = instanceOfKioskCollection;
exports.KioskCollectionFromJSON = KioskCollectionFromJSON;
exports.KioskCollectionFromJSONTyped = KioskCollectionFromJSONTyped;
exports.KioskCollectionToJSON = KioskCollectionToJSON;
var KioskCollectionType_1 = require("./KioskCollectionType");
var Price_1 = require("./Price");
/**
 * Check if a given object implements the KioskCollection interface.
 */
function instanceOfKioskCollection(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "canCollectAtOutbound" in value;
    isInstance = isInstance && "price" in value;
    return isInstance;
}
function KioskCollectionFromJSON(json) {
    return KioskCollectionFromJSONTyped(json, false);
}
function KioskCollectionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, KioskCollectionType_1.KioskCollectionTypeFromJSON)(json['type']),
        'id': json['id'],
        'canCollectAtOutbound': json['canCollectAtOutbound'],
        'price': (0, Price_1.PriceFromJSON)(json['price']),
    };
}
function KioskCollectionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, KioskCollectionType_1.KioskCollectionTypeToJSON)(value.type),
        'id': value.id,
        'canCollectAtOutbound': value.canCollectAtOutbound,
        'price': (0, Price_1.PriceToJSON)(value.price),
    };
}
