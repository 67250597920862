import { BackgroundImageContainer } from "@routezero-site/component/helper/background_image";
import { NavBar } from "@routezero-site/component/helper/nav_bar";
import { SectionContainer } from "@routezero-site/component/helper/section_container";
import { SectionMainText } from "@routezero-site/component/helper/section_main_text";
import { PricingPlanCards } from "@routezero-site/component/page/pricing/pricing-plans/pricing_plan_cards";
import backgroundImage from "@routezero-site/component/page/media/countryside_background.jpg";
import backgroundMask from "@routezero-site/component/page/pricing/media/background_mask.svg";

const textColor = "#00886F";
const navBarColor = "#50A595";

/**
 * Shows the user different pricing plans for RouteZero.
 */
export const PricingPlansSection: React.FC = () => {
  return (
    <BackgroundImageContainer
      backgroundImage={backgroundImage}
      backgroundMask={backgroundMask}
      // Proportion of the viewport to ensure the size of the image doesn't 
      // change when swapping from fixed to absolute.
      height={'100vh'}
      imageAtTop={false}
    >
      <NavBar 
        color={navBarColor}
        contactSalesButtonColor="white"
        contactSalesButtonBackgroundColor={navBarColor}
      />
      <SectionContainer
        color={textColor}
        marginForNavBar={true}
      >
        <SectionMainText
          color={textColor}
          subtitleBackgroundColor="#212121"
          subtitleBackgroundBlendMode="soft-light"
          header="Only pay for what we help you achieve"
        />
        <PricingPlanCards
          exampleGraphMaxTonnesCO2Reduced={10}
          defaultEmissionsReductionProportion={0.5}
        />
      </SectionContainer>
    </BackgroundImageContainer>
  );
};