"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfLoginUrlResponse = instanceOfLoginUrlResponse;
exports.LoginUrlResponseFromJSON = LoginUrlResponseFromJSON;
exports.LoginUrlResponseFromJSONTyped = LoginUrlResponseFromJSONTyped;
exports.LoginUrlResponseToJSON = LoginUrlResponseToJSON;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the LoginUrlResponse interface.
 */
function instanceOfLoginUrlResponse(value) {
    var isInstance = true;
    return isInstance;
}
function LoginUrlResponseFromJSON(json) {
    return LoginUrlResponseFromJSONTyped(json, false);
}
function LoginUrlResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'loginRedirectUrl': !(0, runtime_1.exists)(json, 'loginRedirectUrl') ? undefined : json['loginRedirectUrl'],
    };
}
function LoginUrlResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'loginRedirectUrl': value.loginRedirectUrl,
    };
}
