"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfBookingJourney = instanceOfBookingJourney;
exports.BookingJourneyFromJSON = BookingJourneyFromJSON;
exports.BookingJourneyFromJSONTyped = BookingJourneyFromJSONTyped;
exports.BookingJourneyToJSON = BookingJourneyToJSON;
var BookingJourneyLeg_1 = require("./BookingJourneyLeg");
/**
 * Check if a given object implements the BookingJourney interface.
 */
function instanceOfBookingJourney(value) {
    var isInstance = true;
    isInstance = isInstance && "legs" in value;
    return isInstance;
}
function BookingJourneyFromJSON(json) {
    return BookingJourneyFromJSONTyped(json, false);
}
function BookingJourneyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'legs': (json['legs'].map(BookingJourneyLeg_1.BookingJourneyLegFromJSON)),
    };
}
function BookingJourneyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'legs': (value.legs.map(BookingJourneyLeg_1.BookingJourneyLegToJSON)),
    };
}
