
import React from 'react';

// Define a type for the mouse position
export interface MousePosition {
  x?: number
  y?: number
}

/**
 * Hook to listen to the position of the mouse.
 */
export const useCursorPosition = (): MousePosition => {
  const [mousePosition, setMousePosition] = React.useState<MousePosition>({});

  React.useEffect(() => {
    const updateMousePosition = (ev: MouseEvent) => {
      setMousePosition({ x: ev.clientX, y: ev.clientY });
    };

    window.addEventListener('mousemove', updateMousePosition);
    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
    };
  }, []);

  return mousePosition;
};
