"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfPriceJourney = instanceOfPriceJourney;
exports.PriceJourneyFromJSON = PriceJourneyFromJSON;
exports.PriceJourneyFromJSONTyped = PriceJourneyFromJSONTyped;
exports.PriceJourneyToJSON = PriceJourneyToJSON;
var Journey_1 = require("./Journey");
var PriceJourneyType_1 = require("./PriceJourneyType");
var Pricing_1 = require("./Pricing");
/**
 * Check if a given object implements the PriceJourney interface.
 */
function instanceOfPriceJourney(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "pricing" in value;
    isInstance = isInstance && "journey" in value;
    return isInstance;
}
function PriceJourneyFromJSON(json) {
    return PriceJourneyFromJSONTyped(json, false);
}
function PriceJourneyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, PriceJourneyType_1.PriceJourneyTypeFromJSON)(json['type']),
        'id': json['id'],
        'pricing': (0, Pricing_1.PricingFromJSON)(json['pricing']),
        'journey': (0, Journey_1.JourneyFromJSON)(json['journey']),
    };
}
function PriceJourneyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, PriceJourneyType_1.PriceJourneyTypeToJSON)(value.type),
        'id': value.id,
        'pricing': (0, Pricing_1.PricingToJSON)(value.pricing),
        'journey': (0, Journey_1.JourneyToJSON)(value.journey),
    };
}
