"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCarriagePositionSpaceAllocation = instanceOfCarriagePositionSpaceAllocation;
exports.CarriagePositionSpaceAllocationFromJSON = CarriagePositionSpaceAllocationFromJSON;
exports.CarriagePositionSpaceAllocationFromJSONTyped = CarriagePositionSpaceAllocationFromJSONTyped;
exports.CarriagePositionSpaceAllocationToJSON = CarriagePositionSpaceAllocationToJSON;
var CarriagePositionSpaceAllocationType_1 = require("./CarriagePositionSpaceAllocationType");
/**
 * Check if a given object implements the CarriagePositionSpaceAllocation interface.
 */
function instanceOfCarriagePositionSpaceAllocation(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "carriage" in value;
    isInstance = isInstance && "position" in value;
    return isInstance;
}
function CarriagePositionSpaceAllocationFromJSON(json) {
    return CarriagePositionSpaceAllocationFromJSONTyped(json, false);
}
function CarriagePositionSpaceAllocationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, CarriagePositionSpaceAllocationType_1.CarriagePositionSpaceAllocationTypeFromJSON)(json['type']),
        'carriage': json['carriage'],
        'position': json['position'],
    };
}
function CarriagePositionSpaceAllocationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, CarriagePositionSpaceAllocationType_1.CarriagePositionSpaceAllocationTypeToJSON)(value.type),
        'carriage': value.carriage,
        'position': value.position,
    };
}
