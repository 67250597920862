"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfRateOverviewStay = instanceOfRateOverviewStay;
exports.RateOverviewStayFromJSON = RateOverviewStayFromJSON;
exports.RateOverviewStayFromJSONTyped = RateOverviewStayFromJSONTyped;
exports.RateOverviewStayToJSON = RateOverviewStayToJSON;
var runtime_1 = require("../runtime");
var BookingMethod_1 = require("./BookingMethod");
var LoyaltyProgramme_1 = require("./LoyaltyProgramme");
var Pricing_1 = require("./Pricing");
var RateOverviewStayType_1 = require("./RateOverviewStayType");
/**
 * Check if a given object implements the RateOverviewStay interface.
 */
function instanceOfRateOverviewStay(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "minPricing" in value;
    isInstance = isInstance && "bookingMethod" in value;
    isInstance = isInstance && "photos" in value;
    return isInstance;
}
function RateOverviewStayFromJSON(json) {
    return RateOverviewStayFromJSONTyped(json, false);
}
function RateOverviewStayFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, RateOverviewStayType_1.RateOverviewStayTypeFromJSON)(json['type']),
        'minPricing': (0, Pricing_1.PricingFromJSON)(json['minPricing']),
        'supportedLoyaltyProgrammes': !(0, runtime_1.exists)(json, 'supportedLoyaltyProgrammes') ? undefined : (json['supportedLoyaltyProgrammes'].map(LoyaltyProgramme_1.LoyaltyProgrammeFromJSON)),
        'bookingMethod': (0, BookingMethod_1.BookingMethodFromJSON)(json['bookingMethod']),
        'photos': json['photos'],
    };
}
function RateOverviewStayToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, RateOverviewStayType_1.RateOverviewStayTypeToJSON)(value.type),
        'minPricing': (0, Pricing_1.PricingToJSON)(value.minPricing),
        'supportedLoyaltyProgrammes': value.supportedLoyaltyProgrammes === undefined ? undefined : (value.supportedLoyaltyProgrammes.map(LoyaltyProgramme_1.LoyaltyProgrammeToJSON)),
        'bookingMethod': (0, BookingMethod_1.BookingMethodToJSON)(value.bookingMethod),
        'photos': value.photos,
    };
}
