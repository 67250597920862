"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfConfirmBookingOptionsResponse = instanceOfConfirmBookingOptionsResponse;
exports.ConfirmBookingOptionsResponseFromJSON = ConfirmBookingOptionsResponseFromJSON;
exports.ConfirmBookingOptionsResponseFromJSONTyped = ConfirmBookingOptionsResponseFromJSONTyped;
exports.ConfirmBookingOptionsResponseToJSON = ConfirmBookingOptionsResponseToJSON;
var BookingCheckoutDetails_1 = require("./BookingCheckoutDetails");
var PaymentMethod_1 = require("./PaymentMethod");
/**
 * Check if a given object implements the ConfirmBookingOptionsResponse interface.
 */
function instanceOfConfirmBookingOptionsResponse(value) {
    var isInstance = true;
    isInstance = isInstance && "checkoutDetails" in value;
    isInstance = isInstance && "paymentMethod" in value;
    return isInstance;
}
function ConfirmBookingOptionsResponseFromJSON(json) {
    return ConfirmBookingOptionsResponseFromJSONTyped(json, false);
}
function ConfirmBookingOptionsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'checkoutDetails': (0, BookingCheckoutDetails_1.BookingCheckoutDetailsFromJSON)(json['checkoutDetails']),
        'paymentMethod': (0, PaymentMethod_1.PaymentMethodFromJSON)(json['paymentMethod']),
    };
}
function ConfirmBookingOptionsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'checkoutDetails': (0, BookingCheckoutDetails_1.BookingCheckoutDetailsToJSON)(value.checkoutDetails),
        'paymentMethod': (0, PaymentMethod_1.PaymentMethodToJSON)(value.paymentMethod),
    };
}
