import { IsTabletOrDesktop, TabletOrDesktop } from "@routezero-site/component/helper/breakpoint";
import { ArrowButton, ContactSalesRequestRecogniser } from "@routezero-site/component/helper/button";
import { HoverIsActiveRecogniser } from "@routezero-site/component/helper/active_action";
import { CloudDownArrowSolidIcon, GearIcon, PeopleGroupDuotoneIcon } from "@routezero-site/component/helper/icon";
import { useServicesContext } from "@routezero-site/component/helper/services";
import { CarbonPricingGraphContainerBackgroundColor, FadedOpacity, LargeBorderRadiusPx, LargeIconSizePx, LargePaddingPx, MedBorderRadiusPx, MedPaddingPx, SmallPaddingPx } from "@routezero-site/component/helper/theme";
import { CarbonPricingExampleGraph } from "@routezero-site/component/page/pricing/pricing-plans/carbon_pricing_graph";
import { PricingPlan, PricingPlanIconType } from "@routezero-site/service/pricing-plans/pricing_plans";

export interface PricingPlanCardProps {
  pricingPlan: PricingPlan
  /**
   * On the carbon pricing example graph, the maximum number of tonnes of CO2
   * that the user can choose to "reduce".
   */
  exampleGraphMaxTonnesCO2Reduced: number
  /**
   * Default proportion of CO2 emissions reduced while the user is not mousing
   * over the graph.
   */
  defaultEmissionsReductionProportion: number

  width: React.CSSProperties['width']
  nameAndDescriptionColor: React.CSSProperties['color']
  ctaColor: React.CSSProperties['color']
  priceColor: React.CSSProperties['color']
  priceCardBackgroundColor: React.CSSProperties['backgroundColor']
  mainCardBackgroundColor: React.CSSProperties['backgroundColor']
}

/**
 * A single pricing plan that the user could select, e.g. performance-based
 * pricing, SaaS pricing, custom pricing.
 */
export const PricingPlanCard: React.FC<PricingPlanCardProps> = (props) => {
  const { analyticsService } = useServicesContext();
  const padding = SmallPaddingPx();
  const gap = SmallPaddingPx();
  // On desktop/tablet, we display a graph if one is requested. Otherwise,
  // on mobile, no graph is shown.
  const width = IsTabletOrDesktop() && props.pricingPlan.graph !== undefined 
    ? `calc(50% - ${gap}px)` 
    : "100%";

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      width: props.width,
      backgroundColor: props.mainCardBackgroundColor,
      borderRadius: LargeBorderRadiusPx(),
      overflow: 'hidden'
    }}>
      <ContactSalesRequestRecogniser 
        onClick={() => analyticsService.logPricingPlanSelected(props.pricingPlan.analyticsType)}
        style={{
          width: width
        }}
      >
      <HoverIsActiveRecogniser style={{
        height: '100%',
        padding: padding
      }}>
        <PricingPlanContent 
          pricingPlan={props.pricingPlan}
          priceCardBackgroundColor={props.priceCardBackgroundColor}
          nameAndDescriptionColor={props.nameAndDescriptionColor}
          ctaColor={props.ctaColor}
          priceColor={props.priceColor}
        />
      </HoverIsActiveRecogniser>
      </ContactSalesRequestRecogniser>

      <TabletOrDesktop>
        {props.pricingPlan.graph && 
        <div style={{ 
          flexGrow: 1,
          height: '100%',
          backgroundColor: CarbonPricingGraphContainerBackgroundColor(),
          padding: SmallPaddingPx()
        }}>
          <CarbonPricingExampleGraph
            maxCarbonTonnesReduced={props.exampleGraphMaxTonnesCO2Reduced} 
            defaultEmissionsReductionProportion={props.defaultEmissionsReductionProportion}
            graph={props.pricingPlan.graph}
          />
        </div>}
      </TabletOrDesktop>
    </div>
  );
};

interface PricingPlanContentProps {
  pricingPlan: PricingPlan
  nameAndDescriptionColor: React.CSSProperties['color']
  ctaColor: React.CSSProperties['color']
  priceColor: React.CSSProperties['color']
  priceCardBackgroundColor: React.CSSProperties['backgroundColor']
}

/**
 * Name and description of the pricing plan above the price and CTA.
 */
const PricingPlanContent: React.FC<PricingPlanContentProps> = (props) => {
  const textPadding = MedPaddingPx();
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      gap: LargePaddingPx(),
      height: '100%'
    }}>
      <div style={{
        padding: `${textPadding}px ${textPadding}px 0px ${textPadding}px`
      }}>
        <PricingPlanText 
          pricingPlan={props.pricingPlan}
          color={props.nameAndDescriptionColor}
        />
      </div>
      <PricingPlanPriceAndCTA 
        pricingPlan={props.pricingPlan}
        ctaColor={props.ctaColor}
        priceColor={props.priceColor}
        priceCardBackgroundColor={props.priceCardBackgroundColor}
      />
    </div>
  );
};

interface PricingPlanTextProps {
  color: React.CSSProperties['color']
  pricingPlan: PricingPlan
}

/**
 * Name of the pricing plan with a description below.
 */
const PricingPlanText: React.FC<PricingPlanTextProps> = (props) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: MedPaddingPx(),
      color: props.color
    }}>
      <PricingPlanName 
        icon={props.pricingPlan.icon} 
        name={props.pricingPlan.name}
      />
      <p>
        {props.pricingPlan.description}
      </p>
    </div>
  );
};

interface PricingPlanNameProps {
  icon: PricingPlanIconType
  name: string
}

/**
 * Name of the pricing plan with an icon on the left.
 */
const PricingPlanName: React.FC<PricingPlanNameProps> = (props) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: SmallPaddingPx()
    }}>
      <PricingPlanIcon icon={props.icon}/>
      <h4>
        {props.name}
      </h4>
    </div>
  );
};

interface PricingPlanIconProps {
  icon: PricingPlanIconType
}

/**
 * Icon to represent the pricing plan.
 */
const PricingPlanIcon: React.FC<PricingPlanIconProps> = (props) => {
  const Icon: React.FC = () => {
    switch (props.icon) {
      case "cloudDown": return <CloudDownArrowSolidIcon sizePx={LargeIconSizePx()}/>;
      case "peopleGroup": return <PeopleGroupDuotoneIcon sizePx={LargeIconSizePx()}/>;
      case "cog": return <GearIcon sizePx={LargeIconSizePx()}/>;
    }
  };

  return (
    <div style={{
      display: 'flex',
      opacity: FadedOpacity(),
    }}>
      <Icon/>
    </div>
  );
};

interface PricingPlanPriceAndCTAProps {
  pricingPlan: PricingPlan
  ctaColor: React.CSSProperties['color']
  priceColor: React.CSSProperties['color']
  priceCardBackgroundColor: React.CSSProperties['backgroundColor']
}

/**
 * Pricing plan information and a call to action below. 
 */
const PricingPlanPriceAndCTA: React.FC<PricingPlanPriceAndCTAProps> = (props) =>  {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: SmallPaddingPx()
    }}>
      <PricingPlanPrice 
        price={props.pricingPlan.price}
        fromPrice={props.pricingPlan.fromPrice}
        priceUnits={props.pricingPlan.priceUnits}
        color={props.priceColor}
        backgroundColor={props.priceCardBackgroundColor}
      />
      <PricingPlanCallToAction
        color={props.ctaColor}
      />
    </div>
  );
}

interface PricingPlanPriceProps {
  price: string
  fromPrice: boolean,
  priceUnits: string | undefined
  color: React.CSSProperties['color']
  backgroundColor: React.CSSProperties['backgroundColor']
}

/**
 * Card showing the price of the plan with units below.
 */
const PricingPlanPrice: React.FC<PricingPlanPriceProps> = (props) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: SmallPaddingPx(),
      color: props.color,
      backgroundColor: props.backgroundColor,
      borderRadius: MedBorderRadiusPx(),
      padding: LargePaddingPx()
    }}>
      <div>
        {props.fromPrice 
          ? <h4 style={{display:'inline'}}>from </h4>
          : <></>
        }
        <h2 style={{display:'inline'}}>
          {props.price}
        </h2>
      </div>
      {/* Even if there are no units, included to ensure correct alignment */}
      <p style={{
        opacity: props.priceUnits ? 1 : 0
      }}>
        {props.priceUnits ?? 'nothing'}
      </p>
    </div>
  );
};

interface PricingPlanCallToActionProps {
  color: React.CSSProperties['color']
}

/**
 * Call to action encouraging the user to find out more about the plan.
 */
const PricingPlanCallToAction: React.FC<PricingPlanCallToActionProps> = (props) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: props.color,
      padding: SmallPaddingPx()
    }}>
      <ArrowButton text="Get started"/>
    </div>
  );
};