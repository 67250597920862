
import { Fragment, ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive'

/**
 * Maximum screen width for this to be considered "mobile".
 */
const mobileBreakpointPx = 768;

/**
 * Maximum screen width for this to be considered "tablet". Anything above this
 * is considered "desktop".
 */
const tabletBreakpointPx = 1024;

/**
 * Whether the display size is that of a mobile.
 */
export const IsMobile: () => boolean = () => {
  return useMediaQuery({ query: `(max-width: ${mobileBreakpointPx}px)` });
}

/**
 * Whether the display size is that of a tablet.
 */
export const IsTablet: () => boolean = () => {
  return useMediaQuery({ query: `(min-width: ${mobileBreakpointPx}px) and (max-width: ${tabletBreakpointPx}px)` });
}

/**
 * Whether the display size is that of a desktop.
 */
export const IsDesktop: () => boolean = () => {
  return useMediaQuery({ query: `(min-width: ${tabletBreakpointPx}px)` });
}

/**
 * Whether the display size is that of a mobile or tablet.
 */
export const IsTabletOrMobile: () => boolean = () => {
  return useMediaQuery({ query: `(max-width: ${tabletBreakpointPx}px)` });
}

/**
 * Whether the display size is that of desktop or tablet.
 */
export const IsTabletOrDesktop: () => boolean = () => {
  return useMediaQuery({ query: `(min-width: ${mobileBreakpointPx}px)` });
}

/**
 * Whether the display size is that of desktop or tablet.
 */
export const IsDesktopOrMobile: () => boolean = () => {
  // To ensure number of hooks remains constant. Using || will short circuit.
  const x = IsDesktop();
  const y = IsMobile();
  return x || y;
}

/**
 * @returns `css` is show is true, otherwise returns undefined.
 */
export function mediaStyle(show: boolean, css: React.CSSProperties): React.CSSProperties | undefined {
  return show ? css : undefined;
}

export interface PlatformSpecificProps {
  children: ReactNode
}

export const Desktop: React.FC<PlatformSpecificProps> = (props) => {
  return (
    <Fragment>
      {IsDesktop() ? props.children : null}
    </Fragment>
  );
};

export const Tablet: React.FC<PlatformSpecificProps> = (props) => {
  return (
    <Fragment>
      {IsTablet() ? props.children : null}
    </Fragment>
  );
};

export const Mobile: React.FC<PlatformSpecificProps> = (props) => {
  return (
    <Fragment>
      {IsMobile() ? props.children : []}
    </Fragment>
  );
};

export const TabletOrDesktop: React.FC<PlatformSpecificProps> = (props) => {
  return (
    <Fragment>
      {IsTabletOrDesktop() ? props.children : null}
    </Fragment>
  );
};