"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfRegisteredAddressCountry = instanceOfRegisteredAddressCountry;
exports.RegisteredAddressCountryFromJSON = RegisteredAddressCountryFromJSON;
exports.RegisteredAddressCountryFromJSONTyped = RegisteredAddressCountryFromJSONTyped;
exports.RegisteredAddressCountryToJSON = RegisteredAddressCountryToJSON;
/**
 * Check if a given object implements the RegisteredAddressCountry interface.
 */
function instanceOfRegisteredAddressCountry(value) {
    var isInstance = true;
    isInstance = isInstance && "isoAlpha2Code" in value;
    return isInstance;
}
function RegisteredAddressCountryFromJSON(json) {
    return RegisteredAddressCountryFromJSONTyped(json, false);
}
function RegisteredAddressCountryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'isoAlpha2Code': json['isoAlpha2Code'],
    };
}
function RegisteredAddressCountryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'isoAlpha2Code': value.isoAlpha2Code,
    };
}
