"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwapJourneyType = void 0;
exports.SwapJourneyTypeFromJSON = SwapJourneyTypeFromJSON;
exports.SwapJourneyTypeFromJSONTyped = SwapJourneyTypeFromJSONTyped;
exports.SwapJourneyTypeToJSON = SwapJourneyTypeToJSON;
/**
 *
 * @export
 */
exports.SwapJourneyType = {
    Swap: 'swap'
};
function SwapJourneyTypeFromJSON(json) {
    return SwapJourneyTypeFromJSONTyped(json, false);
}
function SwapJourneyTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function SwapJourneyTypeToJSON(value) {
    return value;
}
