"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfReviewData = instanceOfReviewData;
exports.ReviewDataFromJSON = ReviewDataFromJSON;
exports.ReviewDataFromJSONTyped = ReviewDataFromJSONTyped;
exports.ReviewDataToJSON = ReviewDataToJSON;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the ReviewData interface.
 */
function instanceOfReviewData(value) {
    var isInstance = true;
    return isInstance;
}
function ReviewDataFromJSON(json) {
    return ReviewDataFromJSONTyped(json, false);
}
function ReviewDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'starRating': !(0, runtime_1.exists)(json, 'starRating') ? undefined : json['starRating'],
        'guestReviewScore': !(0, runtime_1.exists)(json, 'guestReviewScore') ? undefined : json['guestReviewScore'],
    };
}
function ReviewDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'starRating': value.starRating,
        'guestReviewScore': value.guestReviewScore,
    };
}
