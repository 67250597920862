"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfRateStay = instanceOfRateStay;
exports.RateStayFromJSON = RateStayFromJSON;
exports.RateStayFromJSONTyped = RateStayFromJSONTyped;
exports.RateStayToJSON = RateStayToJSON;
var runtime_1 = require("../runtime");
var BoardType_1 = require("./BoardType");
var BookingMethod_1 = require("./BookingMethod");
var CancellationTimelinePolicy_1 = require("./CancellationTimelinePolicy");
var LoyaltyProgramme_1 = require("./LoyaltyProgramme");
var Pricing_1 = require("./Pricing");
var RateCondition_1 = require("./RateCondition");
var RateStayType_1 = require("./RateStayType");
/**
 * Check if a given object implements the RateStay interface.
 */
function instanceOfRateStay(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "boardType" in value;
    isInstance = isInstance && "pricing" in value;
    isInstance = isInstance && "conditions" in value;
    isInstance = isInstance && "cancellationTimeline" in value;
    return isInstance;
}
function RateStayFromJSON(json) {
    return RateStayFromJSONTyped(json, false);
}
function RateStayFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, RateStayType_1.RateStayTypeFromJSON)(json['type']),
        'bookingMethod': !(0, runtime_1.exists)(json, 'bookingMethod') ? undefined : (0, BookingMethod_1.BookingMethodFromJSON)(json['bookingMethod']),
        'supportedLoyaltyProgrammes': !(0, runtime_1.exists)(json, 'supportedLoyaltyProgrammes') ? undefined : (json['supportedLoyaltyProgrammes'].map(LoyaltyProgramme_1.LoyaltyProgrammeFromJSON)),
        'boardType': (0, BoardType_1.BoardTypeFromJSON)(json['boardType']),
        'pricing': (0, Pricing_1.PricingFromJSON)(json['pricing']),
        'conditions': (json['conditions'].map(RateCondition_1.RateConditionFromJSON)),
        'cancellationTimeline': (json['cancellationTimeline'].map(CancellationTimelinePolicy_1.CancellationTimelinePolicyFromJSON)),
    };
}
function RateStayToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, RateStayType_1.RateStayTypeToJSON)(value.type),
        'bookingMethod': (0, BookingMethod_1.BookingMethodToJSON)(value.bookingMethod),
        'supportedLoyaltyProgrammes': value.supportedLoyaltyProgrammes === undefined ? undefined : (value.supportedLoyaltyProgrammes.map(LoyaltyProgramme_1.LoyaltyProgrammeToJSON)),
        'boardType': (0, BoardType_1.BoardTypeToJSON)(value.boardType),
        'pricing': (0, Pricing_1.PricingToJSON)(value.pricing),
        'conditions': (value.conditions.map(RateCondition_1.RateConditionToJSON)),
        'cancellationTimeline': (value.cancellationTimeline.map(CancellationTimelinePolicy_1.CancellationTimelinePolicyToJSON)),
    };
}
