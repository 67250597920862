import { SmallPaddingPx, TransportIconSizePx } from "@routezero-site/component/helper/theme";
import coachImage from "@routezero-site/component/page/home/travel-platform/media/coach.png";
import electricCarImage from "@routezero-site/component/page/home/travel-platform/media/electric_car.png";
import eurostarImage from "@routezero-site/component/page/home/travel-platform/media/eurostar.png";
import micromobilityImage from "@routezero-site/component/page/home/travel-platform/media/micromobility.png";

/**
 * Graphic showing icons for different transport methods. Designed to give an
 * idea of the types of travel supported by RouteZero.
 */
export const TransportsGraphic: React.FC = () => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      gap: SmallPaddingPx()
    }}>
      <TransportIcon src={eurostarImage} alt="Eurostar"/>
      <TransportIcon src={electricCarImage} alt="Electric car"/>
      <TransportIcon src={micromobilityImage} alt="Micromobility"/>
      <TransportIcon src={coachImage} alt="Coach"/>
    </div>
  );
};

interface TransportIconProps {
  src: string
  alt: string
}

const TransportIcon: React.FC<TransportIconProps> = (props) => {
  return (
    <img 
      src={props.src}
      alt={props.alt}
      style={{
        width: TransportIconSizePx(),
        height: TransportIconSizePx(),
        borderRadius: TransportIconSizePx() * 2
      }}
    />
  );
};