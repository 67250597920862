"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTrainSeatingPreferenceGroups = instanceOfTrainSeatingPreferenceGroups;
exports.TrainSeatingPreferenceGroupsFromJSON = TrainSeatingPreferenceGroupsFromJSON;
exports.TrainSeatingPreferenceGroupsFromJSONTyped = TrainSeatingPreferenceGroupsFromJSONTyped;
exports.TrainSeatingPreferenceGroupsToJSON = TrainSeatingPreferenceGroupsToJSON;
var TrainSeatingPreference_1 = require("./TrainSeatingPreference");
/**
 * Check if a given object implements the TrainSeatingPreferenceGroups interface.
 */
function instanceOfTrainSeatingPreferenceGroups(value) {
    var isInstance = true;
    isInstance = isInstance && "position" in value;
    isInstance = isInstance && "direction" in value;
    isInstance = isInstance && "carriage" in value;
    isInstance = isInstance && "facilities" in value;
    isInstance = isInstance && "seatType" in value;
    isInstance = isInstance && "deck" in value;
    return isInstance;
}
function TrainSeatingPreferenceGroupsFromJSON(json) {
    return TrainSeatingPreferenceGroupsFromJSONTyped(json, false);
}
function TrainSeatingPreferenceGroupsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'position': (json['position'].map(TrainSeatingPreference_1.TrainSeatingPreferenceFromJSON)),
        'direction': (json['direction'].map(TrainSeatingPreference_1.TrainSeatingPreferenceFromJSON)),
        'carriage': (json['carriage'].map(TrainSeatingPreference_1.TrainSeatingPreferenceFromJSON)),
        'facilities': (json['facilities'].map(TrainSeatingPreference_1.TrainSeatingPreferenceFromJSON)),
        'seatType': (json['seatType'].map(TrainSeatingPreference_1.TrainSeatingPreferenceFromJSON)),
        'deck': (json['deck'].map(TrainSeatingPreference_1.TrainSeatingPreferenceFromJSON)),
    };
}
function TrainSeatingPreferenceGroupsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'position': (value.position.map(TrainSeatingPreference_1.TrainSeatingPreferenceToJSON)),
        'direction': (value.direction.map(TrainSeatingPreference_1.TrainSeatingPreferenceToJSON)),
        'carriage': (value.carriage.map(TrainSeatingPreference_1.TrainSeatingPreferenceToJSON)),
        'facilities': (value.facilities.map(TrainSeatingPreference_1.TrainSeatingPreferenceToJSON)),
        'seatType': (value.seatType.map(TrainSeatingPreference_1.TrainSeatingPreferenceToJSON)),
        'deck': (value.deck.map(TrainSeatingPreference_1.TrainSeatingPreferenceToJSON)),
    };
}
