import { IsActiveProvider } from "@routezero-site/component/helper/active_action";
import { PageChangeDirection, PaginationControls, PaginationProvider, usePaginationContext } from "@routezero-site/component/helper/pagination";
import { ExtraShortAnimationDurationSec, FastAnimationCurve, LargePaddingPx, MedAnimationCurve, MedAnimationDurationSec, StackCarouselScalePercentage, StackCarouselTranslateYInactivePx } from "@routezero-site/component/helper/theme";
import { wrap } from "@routezero-site/helper/math";
import { Children, ReactNode } from "react";

export interface StackCarouselProps {
  /**
   * The height of the children needs to be the same to provide consisent 
   * animations when they are displayed on top of each other.
   */
  childHeight: React.CSSProperties['height']
  children: ReactNode
}

/**
 * Carousel where the chilren are cycled with an effect to make them appear
 * one of top of each other.
 * 
 * The item at the top of the stack has an "active" state than can be read 
 * using `useIsActiveContext`, e.g. to perform animations when an item in
 * the carousel enters/leaves the top position.
 */
export const StackCarousel: React.FC<StackCarouselProps> = (props) => {
  // The number of children is doubled because at least two additional items 
  // (with 0 opacity) are required to ensure the user does not see the items 
  // animate from the front to the back of the stack. 
  const childCount = Children.count(props.children);
  return (
    <PaginationProvider 
      pageCountWithSentinels={childCount*2}
      displayedPageCount={childCount}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        gap: LargePaddingPx()
      }}>
        <Carousel childHeight={props.childHeight}>
          {props.children}
          {props.children}
        </Carousel>
        <PaginationControls/>
      </div>
    </PaginationProvider>
  );
};

interface CarouselProps {
  childHeight: React.CSSProperties['height']
  children: ReactNode
}

/**
 * Stacks children one of top of the other with a slight offset allowing them
 * to be seen one behind the other.
 */
const Carousel: React.FC<CarouselProps> = (props) => {
  const paginationState = usePaginationContext();

  return (
    <div style={{ 
      // Display flex so container sizes to child
      display: 'flex',
      position: 'relative',
      height: props.childHeight
    }}>
      {Children.map(props.children, (child, i) => 
        <CarouselItem
          height={props.childHeight}
          mostRecentPageChangeDirection={paginationState.mostRecentPageChangeDirection}
          cycleIndex={wrap(i-paginationState.pageIndexWithSentinels, -1, paginationState.pageCountWithSentinels-1)}
          carouselItemCount={paginationState.pageCountWithSentinels}
        >
          {child}
        </CarouselItem>
      )}
    </div>
  );
};

interface CarouselItemProps {
  /**
   * The topmost item in the carousel is 0, the item above this is at -1, and 
   * the item below the top is 1. "Lower" items have indices 2, 3, etc. 
   * 
   * The cycle index of this item changes as the user iterates through 
   * the carousel.
   */
  cycleIndex: number
  /**
   * Total number of items in the carousel.
   */
  carouselItemCount: number
  /**
   * The most recent direction chosen by the user when they changed the 
   * currently selected item. E.g. pressing the "next" button means this has 
   * value "next".
   */
  mostRecentPageChangeDirection: PageChangeDirection
  height: React.CSSProperties['height']
  children: ReactNode
}

/**
 * One of the components in a carousel stacked on top of each other.
 * 
 * The item at the top of the stack (0 cycle index) has an "active" state
 * than can be read using `useIsActiveContext`.
 */
const CarouselItem: React.FC<CarouselItemProps> = (props) => {
  return (
    <IsActiveProvider isActive={props.cycleIndex===0 || props.cycleIndex === -1}>
      <div style={{
        // Display flex so container sizes to child
        display: 'flex',
        position: 'absolute',
        height: props.height,
        zIndex: props.carouselItemCount - props.cycleIndex,
        opacity: carouselItemOpacity(props.cycleIndex),
        transform: `translateY(${CarouselItemTranslateY(props.cycleIndex)}px) scale(${CarouselItemScalePercenage(props.cycleIndex)})`,
        transition: `opacity ${MedAnimationDurationSec()}s ${FastAnimationCurve()}, transform ${MedAnimationDurationSec()}s ${MedAnimationCurve()}`,
        transitionDelay: `${CarouselItemTransitionDelaySec(props.cycleIndex, props.mostRecentPageChangeDirection)}s`,
        pointerEvents: CarouselItemPointerEvents(props.cycleIndex)
      }}>
        {props.children}
      </div>
    </IsActiveProvider>
  );
};

/**
 * Items lower in the stack have lower opacity, giving them the appearance
 * of being in the background.
 */
function carouselItemOpacity(cycleIndex: number): number {
  switch (cycleIndex) {
    case 0: return 1;
    case 1: return 0.5;
    default: return 0;
  }
}

/**
 * Items lower in the stack are scaled down, giving them the appearance of 
 * being in the background. The maximum scale is also clamped to 1, this means
 * when the topmost element animates away, it only has a sliding, not a scaling
 * effect.
 */
function CarouselItemScalePercenage(cycleIndex: number): number {
  return Math.min(1 - cycleIndex * StackCarouselScalePercentage(), 1);
}

/**
 * Items lower in the stack are translated down - giving the 
 * appearance that items are placed on top of the other.
 */
function CarouselItemTranslateY(cycleIndex: number): number {
  return cycleIndex * StackCarouselTranslateYInactivePx();
}

/**
 * @returns the delay before animation a carousel item. When iterating forwards, 
 *  (pageChangeDirection is "next") items lower in the stack have a higher delay
 *  meaning they move last. This is reversed when moving backwards.
 */
function CarouselItemTransitionDelaySec(cycleIndex: number, pageChangeDirection: PageChangeDirection): number {
  const m = pageChangeDirection === "next" ? 1 : -1;
  return cycleIndex * ExtraShortAnimationDurationSec() * m * 0.5;
}

/**
 * @returns pointerEvents CSS such that only the topmost item recieves events.
 */
function CarouselItemPointerEvents(cycleIndex: number): React.CSSProperties['pointerEvents'] {
  return cycleIndex === 0 ? 'inherit' : 'none';
}