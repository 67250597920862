"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EurostarTransportType = void 0;
exports.EurostarTransportTypeFromJSON = EurostarTransportTypeFromJSON;
exports.EurostarTransportTypeFromJSONTyped = EurostarTransportTypeFromJSONTyped;
exports.EurostarTransportTypeToJSON = EurostarTransportTypeToJSON;
/**
 *
 * @export
 */
exports.EurostarTransportType = {
    Eurostar: 'eurostar'
};
function EurostarTransportTypeFromJSON(json) {
    return EurostarTransportTypeFromJSONTyped(json, false);
}
function EurostarTransportTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function EurostarTransportTypeToJSON(value) {
    return value;
}
