"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PartialOutwardTrainlineBookingMethodType = void 0;
exports.PartialOutwardTrainlineBookingMethodTypeFromJSON = PartialOutwardTrainlineBookingMethodTypeFromJSON;
exports.PartialOutwardTrainlineBookingMethodTypeFromJSONTyped = PartialOutwardTrainlineBookingMethodTypeFromJSONTyped;
exports.PartialOutwardTrainlineBookingMethodTypeToJSON = PartialOutwardTrainlineBookingMethodTypeToJSON;
/**
 *
 * @export
 */
exports.PartialOutwardTrainlineBookingMethodType = {
    PartialOutwardTrainline: 'partialOutwardTrainline'
};
function PartialOutwardTrainlineBookingMethodTypeFromJSON(json) {
    return PartialOutwardTrainlineBookingMethodTypeFromJSONTyped(json, false);
}
function PartialOutwardTrainlineBookingMethodTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function PartialOutwardTrainlineBookingMethodTypeToJSON(value) {
    return value;
}
