"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrainSeatingOptionsBookingInputType = void 0;
exports.TrainSeatingOptionsBookingInputTypeFromJSON = TrainSeatingOptionsBookingInputTypeFromJSON;
exports.TrainSeatingOptionsBookingInputTypeFromJSONTyped = TrainSeatingOptionsBookingInputTypeFromJSONTyped;
exports.TrainSeatingOptionsBookingInputTypeToJSON = TrainSeatingOptionsBookingInputTypeToJSON;
/**
 *
 * @export
 */
exports.TrainSeatingOptionsBookingInputType = {
    TrainSeatingOptions: 'trainSeatingOptions'
};
function TrainSeatingOptionsBookingInputTypeFromJSON(json) {
    return TrainSeatingOptionsBookingInputTypeFromJSONTyped(json, false);
}
function TrainSeatingOptionsBookingInputTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function TrainSeatingOptionsBookingInputTypeToJSON(value) {
    return value;
}
