import { AnalyticsPricingPlanType } from "@routezero-site/service/analytics/analytics";
import { PricingPlan, PricingPlansService } from "@routezero-site/service/pricing-plans/pricing_plans";

/**
 * Locally stored pricing plans.
 */
export class LocalPricingPlansService implements PricingPlansService {
  async pricingPlans(): Promise<PricingPlan[]> {
    return [
      {
        icon: "cloudDown",
        name: "Pay-as-you-go",
        analyticsType: AnalyticsPricingPlanType.payAsYouGo,
        description: "Access RouteZero's complete travel decarbonisation platform risk-free and only pay for the carbon we help you avoid. Priced like offsets, but with 100x the impact.",
        price: "£50",
        fromPrice: false,
        priceUnits: "per tonne CO₂e avoided",
        graph: {
          routeZeroProduct: {
            icon: "sparkle",
            name: "RouteZero",
            priceGbpPerTonneCO2: 50,
            features: [
              {
                type: "pro",
                name: "Permanent"
              },
              {
                type: "pro",
                name: "Low-cost"
              }
            ]
          },
          competitorProducts: [
            {
              name: "Carbon offsets",
              priceGbpPerTonneCO2: 50,
              features: [
                {
                  type: "con",
                  name: "Impermanent"
                },
                {
                  type: "pro",
                  name: "Low-cost"
                }
              ]
            },
            {
              name: "Direct air capture",
              priceGbpPerTonneCO2: 110,
              features: [
                {
                  type: "pro",
                  name: "Permanent"
                },
                {
                  type: "con",
                  name: "High-cost"
                }
              ]
            }
          ]
        }
      },
      {
        icon: "peopleGroup",
        name: "Priced per user",
        analyticsType: AnalyticsPricingPlanType.perUser,
        description: "Clients looking for simple budget predictability can instead get started with a simple monthly SaaS fee. Get in touch for details.",
        price: "£5",
        fromPrice: true,
        priceUnits: "per user per month"
      },
      {
        icon: "cog",
        name: "Custom",
        analyticsType: AnalyticsPricingPlanType.custom,
        description: "Design a custom package - available for businesses with unique requirements.",
        price: "Custom",
        fromPrice: false,
      }
    ];
  }
}