"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfStartBookingRequest = instanceOfStartBookingRequest;
exports.StartBookingRequestFromJSON = StartBookingRequestFromJSON;
exports.StartBookingRequestFromJSONTyped = StartBookingRequestFromJSONTyped;
exports.StartBookingRequestToJSON = StartBookingRequestToJSON;
var InternalBookingMethodSpecific_1 = require("./InternalBookingMethodSpecific");
/**
 * Check if a given object implements the StartBookingRequest interface.
 */
function instanceOfStartBookingRequest(value) {
    var isInstance = true;
    isInstance = isInstance && "bookingMethods" in value;
    return isInstance;
}
function StartBookingRequestFromJSON(json) {
    return StartBookingRequestFromJSONTyped(json, false);
}
function StartBookingRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'bookingMethods': (json['bookingMethods'].map(InternalBookingMethodSpecific_1.InternalBookingMethodSpecificFromJSON)),
    };
}
function StartBookingRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'bookingMethods': (value.bookingMethods.map(InternalBookingMethodSpecific_1.InternalBookingMethodSpecificToJSON)),
    };
}
