"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCo2eStay = instanceOfCo2eStay;
exports.Co2eStayFromJSON = Co2eStayFromJSON;
exports.Co2eStayFromJSONTyped = Co2eStayFromJSONTyped;
exports.Co2eStayToJSON = Co2eStayToJSON;
var Co2eMethodology_1 = require("./Co2eMethodology");
var Co2eStayType_1 = require("./Co2eStayType");
var Stay_1 = require("./Stay");
/**
 * Check if a given object implements the Co2eStay interface.
 */
function instanceOfCo2eStay(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "kgCO2e" in value;
    isInstance = isInstance && "methodologies" in value;
    isInstance = isInstance && "stay" in value;
    return isInstance;
}
function Co2eStayFromJSON(json) {
    return Co2eStayFromJSONTyped(json, false);
}
function Co2eStayFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, Co2eStayType_1.Co2eStayTypeFromJSON)(json['type']),
        'kgCO2e': json['kgCO2e'],
        'methodologies': (json['methodologies'].map(Co2eMethodology_1.Co2eMethodologyFromJSON)),
        'stay': (0, Stay_1.StayFromJSON)(json['stay']),
    };
}
function Co2eStayToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, Co2eStayType_1.Co2eStayTypeToJSON)(value.type),
        'kgCO2e': value.kgCO2e,
        'methodologies': (value.methodologies.map(Co2eMethodology_1.Co2eMethodologyToJSON)),
        'stay': (0, Stay_1.StayToJSON)(value.stay),
    };
}
