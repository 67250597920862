"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfVoiPerMinPricing = instanceOfVoiPerMinPricing;
exports.VoiPerMinPricingFromJSON = VoiPerMinPricingFromJSON;
exports.VoiPerMinPricingFromJSONTyped = VoiPerMinPricingFromJSONTyped;
exports.VoiPerMinPricingToJSON = VoiPerMinPricingToJSON;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the VoiPerMinPricing interface.
 */
function instanceOfVoiPerMinPricing(value) {
    var isInstance = true;
    isInstance = isInstance && "start" in value;
    isInstance = isInstance && "rate" in value;
    isInstance = isInstance && "interval" in value;
    return isInstance;
}
function VoiPerMinPricingFromJSON(json) {
    return VoiPerMinPricingFromJSONTyped(json, false);
}
function VoiPerMinPricingFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'start': json['start'],
        'rate': json['rate'],
        'interval': json['interval'],
        'end': !(0, runtime_1.exists)(json, 'end') ? undefined : json['end'],
    };
}
function VoiPerMinPricingToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'start': value.start,
        'rate': value.rate,
        'interval': value.interval,
        'end': value.end,
    };
}
