"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CarbonPriceLineItemType = void 0;
exports.CarbonPriceLineItemTypeFromJSON = CarbonPriceLineItemTypeFromJSON;
exports.CarbonPriceLineItemTypeFromJSONTyped = CarbonPriceLineItemTypeFromJSONTyped;
exports.CarbonPriceLineItemTypeToJSON = CarbonPriceLineItemTypeToJSON;
/**
 *
 * @export
 */
exports.CarbonPriceLineItemType = {
    CarbonPrice: 'carbonPrice'
};
function CarbonPriceLineItemTypeFromJSON(json) {
    return CarbonPriceLineItemTypeFromJSONTyped(json, false);
}
function CarbonPriceLineItemTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function CarbonPriceLineItemTypeToJSON(value) {
    return value;
}
