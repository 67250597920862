
/**
 * Types of pricing plans customers can select. This type is stored within the 
 * pricing plan so that it can be recorded that a user selected that pricing plan
 */
export enum AnalyticsPricingPlanType {
  payAsYouGo,
  perUser,
  custom
}

/**
 * Types of fetures that users can click. This type is stored within the feature
 * so that it can be recoreded that a user clicked that feature
 */
export enum AnalyticsFeaturesType {
  smartTravelPolicy,
  peerInsights,
  carbonBudgets,
  integratedBooking,
  competitions,
  scenarioModels
}

export interface AnalyticsService {
  /**
   * Track user scrolling to the bottom of the product page
   */
  logScrollToProductPageCallToAction(): Promise<void>;
  /**
   * Track user clicking plan a route and being sent to routezero.com
   */
  logRoutePlannerVisit(): Promise<void>;
  /**
   * Track user clicking get started and which plan they selected
   */
  logPricingPlanSelected(pricingPlan: AnalyticsPricingPlanType): Promise<void>;
  /**
   * Track user clicking learn more on a particular feature
   */
  logFeatureLearnMore(feature: AnalyticsFeaturesType): Promise<void>;
  /**
   * E.g. track user submiting their details on the contact sales page.
   */
  logContactSalesConversion(): Promise<void>;
}