"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Co2eMethodology = void 0;
exports.Co2eMethodologyFromJSON = Co2eMethodologyFromJSON;
exports.Co2eMethodologyFromJSONTyped = Co2eMethodologyFromJSONTyped;
exports.Co2eMethodologyToJSON = Co2eMethodologyToJSON;
/**
 *
 * @export
 */
exports.Co2eMethodology = {
    Defra: 'defra',
    Cirium: 'cirium',
    GreenTravelPledge: 'greenTravelPledge'
};
function Co2eMethodologyFromJSON(json) {
    return Co2eMethodologyFromJSONTyped(json, false);
}
function Co2eMethodologyFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function Co2eMethodologyToJSON(value) {
    return value;
}
