"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfVoiPricingPlan = instanceOfVoiPricingPlan;
exports.VoiPricingPlanFromJSON = VoiPricingPlanFromJSON;
exports.VoiPricingPlanFromJSONTyped = VoiPricingPlanFromJSONTyped;
exports.VoiPricingPlanToJSON = VoiPricingPlanToJSON;
var runtime_1 = require("../runtime");
var VoiPerKmPricing_1 = require("./VoiPerKmPricing");
var VoiPerMinPricing_1 = require("./VoiPerMinPricing");
/**
 * Check if a given object implements the VoiPricingPlan interface.
 */
function instanceOfVoiPricingPlan(value) {
    var isInstance = true;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "currencyCode" in value;
    return isInstance;
}
function VoiPricingPlanFromJSON(json) {
    return VoiPricingPlanFromJSONTyped(json, false);
}
function VoiPricingPlanFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'price': json['price'],
        'currencyCode': json['currencyCode'],
        'perMinPricing': !(0, runtime_1.exists)(json, 'perMinPricing') ? undefined : (json['perMinPricing'].map(VoiPerMinPricing_1.VoiPerMinPricingFromJSON)),
        'perKmPricing': !(0, runtime_1.exists)(json, 'perKmPricing') ? undefined : (json['perKmPricing'].map(VoiPerKmPricing_1.VoiPerKmPricingFromJSON)),
    };
}
function VoiPricingPlanToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'price': value.price,
        'currencyCode': value.currencyCode,
        'perMinPricing': value.perMinPricing === undefined ? undefined : (value.perMinPricing.map(VoiPerMinPricing_1.VoiPerMinPricingToJSON)),
        'perKmPricing': value.perKmPricing === undefined ? undefined : (value.perKmPricing.map(VoiPerKmPricing_1.VoiPerKmPricingToJSON)),
    };
}
