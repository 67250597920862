"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTrainlineStationId = instanceOfTrainlineStationId;
exports.TrainlineStationIdFromJSON = TrainlineStationIdFromJSON;
exports.TrainlineStationIdFromJSONTyped = TrainlineStationIdFromJSONTyped;
exports.TrainlineStationIdToJSON = TrainlineStationIdToJSON;
var runtime_1 = require("../runtime");
var TrainlineStationIdType_1 = require("./TrainlineStationIdType");
/**
 * Check if a given object implements the TrainlineStationId interface.
 */
function instanceOfTrainlineStationId(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "id" in value;
    return isInstance;
}
function TrainlineStationIdFromJSON(json) {
    return TrainlineStationIdFromJSONTyped(json, false);
}
function TrainlineStationIdFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, TrainlineStationIdType_1.TrainlineStationIdTypeFromJSON)(json['type']),
        'id': json['id'],
        'stationCode': !(0, runtime_1.exists)(json, 'stationCode') ? undefined : json['stationCode'],
    };
}
function TrainlineStationIdToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, TrainlineStationIdType_1.TrainlineStationIdTypeToJSON)(value.type),
        'id': value.id,
        'stationCode': value.stationCode,
    };
}
