import { IsMobile, IsTabletOrDesktop, mediaStyle } from "@routezero-site/component/helper/breakpoint"
import { LargePaddingPx, NavBarHeight, SectionGapPx, SectionItemGapPx } from "@routezero-site/component/helper/theme"
import React, { ReactNode } from "react"

export interface SectionContainerProps {
  color: React.CSSProperties['color']
  marginForNavBar?: boolean
  padVertical?: boolean
  padChildren?: boolean
  background?: React.CSSProperties['background']
  backgroundImage?: React.CSSProperties['backgroundImage']
  children?: ReactNode
  height?: React.CSSProperties['height']
  minHeight?: React.CSSProperties['minHeight']
  maxHeight?: React.CSSProperties['maxHeight']
}

export const SectionContainer = React.forwardRef<HTMLDivElement, SectionContainerProps>((props, ref) => {
  const sectionGapPx = SectionGapPx();
  const verticalPadding = (props.padVertical ?? true) ? sectionGapPx : 0;
  const margin = props.marginForNavBar ? NavBarHeight() : 0;
  return (
    <div 
      ref={ref}
      style={{
        // Set so we can set zIndex of nav bar to have it appear above sections.
        position: 'relative',
        display: 'flex',
        padding: `${verticalPadding + margin}px 0px ${verticalPadding}px 0px`,
        background: props.background,
        ...(props.background === undefined ? {
          backgroundImage: `url(${props.backgroundImage})`
        }: {}),
        ...(props.backgroundImage !== undefined ? {
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }: {}),
        color: props.color,
        height: props.height,
        minHeight: props.minHeight,
        maxHeight: props.maxHeight
      }}>
      <SectionMaxWidth padChildren={props.padChildren}>
        {props.children}
      </SectionMaxWidth>
    </div>
  );
})

export interface SectionMaxWidthProps {
  padChildren?: boolean
  children: ReactNode
}

/**
 * Imposes a max width on the children - a size appropriate to ensure it's 
 * easy to see the content on large monitors.
 */
export const SectionMaxWidth: React.FC<SectionMaxWidthProps> = (props) => {
  const gap = SectionItemGapPx();
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      padding: `0px ${LargePaddingPx()}px`
    }}>
      <div style={{
        position: 'relative',
        width: '100%',
        maxWidth: 990,
        display: 'flex',
        flexDirection: 'column',
        gap: (props.padChildren ?? true) ? gap : undefined
      }}>
        {props.children}
      </div>
    </div>
  );
};

export interface PaddedSectionItemProps {
  children: ReactNode
}

/**
 * Adds horizontal padding on tablet or desktop. Adds no padding on mobile to 
 * increase horizontal space for content.
 */
export const PaddedSectionItem: React.FC<PaddedSectionItemProps> = (props) => {
  const largePadding = LargePaddingPx();
  const horizontalPadding = IsTabletOrDesktop() ? largePadding : 0.0;
  return (
    <div style={{
      padding: `${0}px ${horizontalPadding}px`,
    }}>
      {props.children}
    </div>
  );
};

export interface SplitSectionProps {
  lhs: ReactNode
  rhs: ReactNode
  /**
   * Padding to add to the top of RHS for better alignment of the LHS and RHS.
   * Only applied on desktop / tablet when LHS and RHS are displayed side-by-side.
   */
  rhsTopPadding?: number
}

/**
 * On desktop, shows two columns. On mobile, has LHS and RHS underneath each other.
 */
export const SplitSection: React.FC<SplitSectionProps> = (props) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: SectionItemGapPx() * 2,
    }}>
      <div style={{
        display: 'flex',
        ...mediaStyle(IsTabletOrDesktop(), {
          alignItems: 'start',
          flexDirection: 'row',
          gap: LargePaddingPx() * 2,
        }),
        ...mediaStyle(IsMobile(), {
          flexDirection: 'column',
          gap: SectionItemGapPx()
        })
      }}>
        <div style={{ 
          flexGrow: 1, 
          flexBasis: 0,
        }}>
          {props.lhs}
        </div>
        <div style={{ 
          flexGrow: 1, 
          flexBasis: 0,
          // When arranged side-by-side with the section title, shift downwards
          // for better 'balancing' of the text
          ...mediaStyle(IsTabletOrDesktop(), {
            marginTop: props.rhsTopPadding
          })
        }}>
          {props.rhs}
        </div>
      </div>
    </div>
  );
};