import { MedAnimationDurationSec } from "@routezero-site/component/helper/theme";
import { ReactNode } from "react";

export interface HideIfInactiveProps {
  isActive: boolean
  style?: React.CSSProperties
  children?: ReactNode
}

/**
 * If `isActive` is false, hides the child components, animating them away. 
 */
export const HideIfInactive: React.FC<HideIfInactiveProps> = (props) => {
  return (
    <div style={{
      ...props.style,
      opacity: props.isActive ? 1 : 0,
      transition: `opacity ${MedAnimationDurationSec()}s`
    }}>
      {props.children}
    </div>
  );
};