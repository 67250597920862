"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfExternalBookingMethod = instanceOfExternalBookingMethod;
exports.ExternalBookingMethodFromJSON = ExternalBookingMethodFromJSON;
exports.ExternalBookingMethodFromJSONTyped = ExternalBookingMethodFromJSONTyped;
exports.ExternalBookingMethodToJSON = ExternalBookingMethodToJSON;
var runtime_1 = require("../runtime");
var BookingLinkStyle_1 = require("./BookingLinkStyle");
var ExternalBookingMethodType_1 = require("./ExternalBookingMethodType");
var ExternalLink_1 = require("./ExternalLink");
/**
 * Check if a given object implements the ExternalBookingMethod interface.
 */
function instanceOfExternalBookingMethod(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "link" in value;
    return isInstance;
}
function ExternalBookingMethodFromJSON(json) {
    return ExternalBookingMethodFromJSONTyped(json, false);
}
function ExternalBookingMethodFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, ExternalBookingMethodType_1.ExternalBookingMethodTypeFromJSON)(json['type']),
        'name': json['name'],
        'link': (0, ExternalLink_1.ExternalLinkFromJSON)(json['link']),
        'style': !(0, runtime_1.exists)(json, 'style') ? undefined : (0, BookingLinkStyle_1.BookingLinkStyleFromJSON)(json['style']),
    };
}
function ExternalBookingMethodToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, ExternalBookingMethodType_1.ExternalBookingMethodTypeToJSON)(value.type),
        'name': value.name,
        'link': (0, ExternalLink_1.ExternalLinkToJSON)(value.link),
        'style': (0, BookingLinkStyle_1.BookingLinkStyleToJSON)(value.style),
    };
}
