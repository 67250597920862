"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReviewStayType = void 0;
exports.ReviewStayTypeFromJSON = ReviewStayTypeFromJSON;
exports.ReviewStayTypeFromJSONTyped = ReviewStayTypeFromJSONTyped;
exports.ReviewStayTypeToJSON = ReviewStayTypeToJSON;
/**
 *
 * @export
 */
exports.ReviewStayType = {
    Review: 'review'
};
function ReviewStayTypeFromJSON(json) {
    return ReviewStayTypeFromJSONTyped(json, false);
}
function ReviewStayTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function ReviewStayTypeToJSON(value) {
    return value;
}
