"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfDiscountCardTuple = instanceOfDiscountCardTuple;
exports.DiscountCardTupleFromJSON = DiscountCardTupleFromJSON;
exports.DiscountCardTupleFromJSONTyped = DiscountCardTupleFromJSONTyped;
exports.DiscountCardTupleToJSON = DiscountCardTupleToJSON;
var DiscountCard_1 = require("./DiscountCard");
/**
 * Check if a given object implements the DiscountCardTuple interface.
 */
function instanceOfDiscountCardTuple(value) {
    var isInstance = true;
    isInstance = isInstance && "discountCard" in value;
    isInstance = isInstance && "count" in value;
    return isInstance;
}
function DiscountCardTupleFromJSON(json) {
    return DiscountCardTupleFromJSONTyped(json, false);
}
function DiscountCardTupleFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'discountCard': (0, DiscountCard_1.DiscountCardFromJSON)(json['discountCard']),
        'count': json['count'],
    };
}
function DiscountCardTupleToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'discountCard': (0, DiscountCard_1.DiscountCardToJSON)(value.discountCard),
        'count': value.count,
    };
}
