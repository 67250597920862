import { useEffect, useState } from "react";

export interface UseScrolledY {
  /**
   * Whether the user tried to scroll past the top of the screen.
   */
  scrolledPastTop: boolean
  /**
   * Whether the user has started scrolling down the screen.
   */
  scrolledDown: boolean
}

/**
 * Hook for when the user scrolls vertically.
 */
export function useScrolledY(): UseScrolledY {
  // Use to prevent the background image moving if the user scrolls past
  // the top of the page.
  const [scrollY, setScrollY] = useState(0);
  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    setScrollY(currentScrollPos);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return {
    scrolledPastTop: scrollY <= 0,
    scrolledDown: scrollY > 0
  }
}