"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCo2eJourney = instanceOfCo2eJourney;
exports.Co2eJourneyFromJSON = Co2eJourneyFromJSON;
exports.Co2eJourneyFromJSONTyped = Co2eJourneyFromJSONTyped;
exports.Co2eJourneyToJSON = Co2eJourneyToJSON;
var Co2eJourneyType_1 = require("./Co2eJourneyType");
var Co2eMethodology_1 = require("./Co2eMethodology");
var Journey_1 = require("./Journey");
/**
 * Check if a given object implements the Co2eJourney interface.
 */
function instanceOfCo2eJourney(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "kgCO2e" in value;
    isInstance = isInstance && "methodologies" in value;
    isInstance = isInstance && "journey" in value;
    return isInstance;
}
function Co2eJourneyFromJSON(json) {
    return Co2eJourneyFromJSONTyped(json, false);
}
function Co2eJourneyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, Co2eJourneyType_1.Co2eJourneyTypeFromJSON)(json['type']),
        'kgCO2e': json['kgCO2e'],
        'methodologies': (json['methodologies'].map(Co2eMethodology_1.Co2eMethodologyFromJSON)),
        'journey': (0, Journey_1.JourneyFromJSON)(json['journey']),
    };
}
function Co2eJourneyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, Co2eJourneyType_1.Co2eJourneyTypeToJSON)(value.type),
        'kgCO2e': value.kgCO2e,
        'methodologies': (value.methodologies.map(Co2eMethodology_1.Co2eMethodologyToJSON)),
        'journey': (0, Journey_1.JourneyToJSON)(value.journey),
    };
}
