"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.StayCostLineItemType = void 0;
exports.StayCostLineItemTypeFromJSON = StayCostLineItemTypeFromJSON;
exports.StayCostLineItemTypeFromJSONTyped = StayCostLineItemTypeFromJSONTyped;
exports.StayCostLineItemTypeToJSON = StayCostLineItemTypeToJSON;
/**
 *
 * @export
 */
exports.StayCostLineItemType = {
    StayCost: 'stayCost'
};
function StayCostLineItemTypeFromJSON(json) {
    return StayCostLineItemTypeFromJSONTyped(json, false);
}
function StayCostLineItemTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function StayCostLineItemTypeToJSON(value) {
    return value;
}
