import { ScienceBackedFeature, ScienceBackedFeaturesService } from "@routezero-site/service/science-backed-features/science_backed_features";

/**
 * Locally stored list of features.
 */
export class LocalScienceBackedFeaturesService implements ScienceBackedFeaturesService {
  async features(): Promise<ScienceBackedFeature[]> {
    return [
      {
        icon: "checkShield",
        imageType: "emissionsPercentageBreakdown",
        title: "Numbers you can trust",
        description: "RouteZero uses rigorously tested and certified methodologies, refined through our collaboration with the UK Government, to offer you the most reliable emissions data."
      },
      {
        icon: "mapPin",
        imageType: "journeysList",
        title: "Real-time tracking",
        description: "Your dashboard provides a real-time overview of all your organisation's trips, offering detailed insights into your historic and ongoing emissions and helping you stay on target with your sustainability goals"
      },
      {
        icon: "newSparkle",
        imageType: "aircraftTypeBreakdown",
        title: "Always up-to-date",
        description: "We regularly update our methodologies, providing you with granular data for every journey, which enables your employees to identify and capitalise on all emissions-saving opportunities."
      },
    ];
  }
}