"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.VanDrivetrain = void 0;
exports.VanDrivetrainFromJSON = VanDrivetrainFromJSON;
exports.VanDrivetrainFromJSONTyped = VanDrivetrainFromJSONTyped;
exports.VanDrivetrainToJSON = VanDrivetrainToJSON;
/**
 *
 * @export
 */
exports.VanDrivetrain = {
    Petrol: 'petrol',
    Diesel: 'diesel',
    Electric: 'electric'
};
function VanDrivetrainFromJSON(json) {
    return VanDrivetrainFromJSONTyped(json, false);
}
function VanDrivetrainFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function VanDrivetrainToJSON(value) {
    return value;
}
