import { AnalyticsFeaturesType } from "@routezero-site/service/analytics/analytics";
import { DecarbonisationFeature, DecarbonisationFeaturesService } from "@routezero-site/service/decarbonisation-features/decarbonisation_features";

/**
 * Locally stored list of features.
 */
export class LocalDecarbonisationFeaturesService implements DecarbonisationFeaturesService {
  async features(): Promise<DecarbonisationFeature[]> {
    return [
      {
        imageType: "smartTravelPolicy",
        analyticsType: AnalyticsFeaturesType.smartTravelPolicy,
        productName: "Smart Travel Policy",
        title: "Smart policy integration",
        description: "RouteZero recommends the best policies for your organisation's unique profile to balance cost and carbon, and then highlights whether journeys are compliant during booking. "
      },
      {
        imageType: "peerInsights",
        analyticsType: AnalyticsFeaturesType.peerInsights,
        productName: "Peer Insights",
        title: "Encourage new behaviour",
        description: "Experience is the best teacher. RouteZero highlights real travel stories from your colleagues who have embarked on similar low-carbon journeys, directly in the booking flow."
      },
      {
        imageType: "carbonBudgets",
        analyticsType: AnalyticsFeaturesType.carbonBudgets,
        productName: "Carbon Budgets",
        title: "Support every employee",
        description: "Empower your team to manage their carbon footprint as effectively as their time and finances. Set carbon limits and watch your team change their travel choices with help from carbon budget information directly in the booking flow."
      },
      {
        imageType: "integratedBooking",
        analyticsType: AnalyticsFeaturesType.integratedBooking,
        productName: "Integrated Booking",
        title: "Effortless sustainability",
        description: "Changing habits can be challenging, but we make it easy by showcasing potential emissions savings at every step. We help the greener choices become the natural choice, without adding complexity to your booking process."
      },
      {
        imageType: "competitions",
        analyticsType: AnalyticsFeaturesType.competitions,
        productName: "Competitions & Rewards",
        title: "Make it fun",
        description: "Transform emission reduction into a collaborative and engaging challenge. Set up internal sustainability competitions, tracking and rewarding lower-carbon travel choices."
      },
      {
        imageType: "scenarioModels",
        analyticsType: AnalyticsFeaturesType.scenarioModels,
        productName: "Scenario Models",
        title: "High impact, low cost",
        description: "Leverage our data-driven insights to pick and choose carbon actions and travel policy items tailored to your organisation's unique profile."
      }
    ];
  }
}