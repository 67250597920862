"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTravellerFlightLoyaltyProgramme = instanceOfTravellerFlightLoyaltyProgramme;
exports.TravellerFlightLoyaltyProgrammeFromJSON = TravellerFlightLoyaltyProgrammeFromJSON;
exports.TravellerFlightLoyaltyProgrammeFromJSONTyped = TravellerFlightLoyaltyProgrammeFromJSONTyped;
exports.TravellerFlightLoyaltyProgrammeToJSON = TravellerFlightLoyaltyProgrammeToJSON;
var TravellerFlightLoyaltyProgrammeType_1 = require("./TravellerFlightLoyaltyProgrammeType");
/**
 * Check if a given object implements the TravellerFlightLoyaltyProgramme interface.
 */
function instanceOfTravellerFlightLoyaltyProgramme(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "iataCode" in value;
    isInstance = isInstance && "accountNumber" in value;
    return isInstance;
}
function TravellerFlightLoyaltyProgrammeFromJSON(json) {
    return TravellerFlightLoyaltyProgrammeFromJSONTyped(json, false);
}
function TravellerFlightLoyaltyProgrammeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, TravellerFlightLoyaltyProgrammeType_1.TravellerFlightLoyaltyProgrammeTypeFromJSON)(json['type']),
        'iataCode': json['iataCode'],
        'accountNumber': json['accountNumber'],
    };
}
function TravellerFlightLoyaltyProgrammeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, TravellerFlightLoyaltyProgrammeType_1.TravellerFlightLoyaltyProgrammeTypeToJSON)(value.type),
        'iataCode': value.iataCode,
        'accountNumber': value.accountNumber,
    };
}
