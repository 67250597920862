"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TicketDeliveryFeeLineItemType = void 0;
exports.TicketDeliveryFeeLineItemTypeFromJSON = TicketDeliveryFeeLineItemTypeFromJSON;
exports.TicketDeliveryFeeLineItemTypeFromJSONTyped = TicketDeliveryFeeLineItemTypeFromJSONTyped;
exports.TicketDeliveryFeeLineItemTypeToJSON = TicketDeliveryFeeLineItemTypeToJSON;
/**
 *
 * @export
 */
exports.TicketDeliveryFeeLineItemType = {
    TicketDeliveryFee: 'ticketDeliveryFee'
};
function TicketDeliveryFeeLineItemTypeFromJSON(json) {
    return TicketDeliveryFeeLineItemTypeFromJSONTyped(json, false);
}
function TicketDeliveryFeeLineItemTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function TicketDeliveryFeeLineItemTypeToJSON(value) {
    return value;
}
