"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfBookingConfirmedState = instanceOfBookingConfirmedState;
exports.BookingConfirmedStateFromJSON = BookingConfirmedStateFromJSON;
exports.BookingConfirmedStateFromJSONTyped = BookingConfirmedStateFromJSONTyped;
exports.BookingConfirmedStateToJSON = BookingConfirmedStateToJSON;
var BookingConfirmedStateType_1 = require("./BookingConfirmedStateType");
/**
 * Check if a given object implements the BookingConfirmedState interface.
 */
function instanceOfBookingConfirmedState(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "bookingReference" in value;
    return isInstance;
}
function BookingConfirmedStateFromJSON(json) {
    return BookingConfirmedStateFromJSONTyped(json, false);
}
function BookingConfirmedStateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, BookingConfirmedStateType_1.BookingConfirmedStateTypeFromJSON)(json['type']),
        'bookingReference': json['bookingReference'],
    };
}
function BookingConfirmedStateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, BookingConfirmedStateType_1.BookingConfirmedStateTypeToJSON)(value.type),
        'bookingReference': value.bookingReference,
    };
}
