"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrainSeatReservability = void 0;
exports.TrainSeatReservabilityFromJSON = TrainSeatReservabilityFromJSON;
exports.TrainSeatReservabilityFromJSONTyped = TrainSeatReservabilityFromJSONTyped;
exports.TrainSeatReservabilityToJSON = TrainSeatReservabilityToJSON;
/**
 * For rail, the user may either not be able to reserve a seat ("none"), optionally be able to reserve a seat ("optional"), or have to reserve a seat ("mandatory").
 * @export
 */
exports.TrainSeatReservability = {
    None: 'none',
    Optional: 'optional',
    Mandatory: 'mandatory'
};
function TrainSeatReservabilityFromJSON(json) {
    return TrainSeatReservabilityFromJSONTyped(json, false);
}
function TrainSeatReservabilityFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function TrainSeatReservabilityToJSON(value) {
    return value;
}
