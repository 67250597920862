"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingSpaceAllocationReservationFromJSON = BookingSpaceAllocationReservationFromJSON;
exports.BookingSpaceAllocationReservationFromJSONTyped = BookingSpaceAllocationReservationFromJSONTyped;
exports.BookingSpaceAllocationReservationToJSON = BookingSpaceAllocationReservationToJSON;
var CarriagePositionSpaceAllocation_1 = require("./CarriagePositionSpaceAllocation");
var PositionSpaceAllocation_1 = require("./PositionSpaceAllocation");
function BookingSpaceAllocationReservationFromJSON(json) {
    return BookingSpaceAllocationReservationFromJSONTyped(json, false);
}
function BookingSpaceAllocationReservationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'carriagePosition':
            return __assign(__assign({}, (0, CarriagePositionSpaceAllocation_1.CarriagePositionSpaceAllocationFromJSONTyped)(json, true)), { type: 'carriagePosition' });
        case 'position':
            return __assign(__assign({}, (0, PositionSpaceAllocation_1.PositionSpaceAllocationFromJSONTyped)(json, true)), { type: 'position' });
        default:
            throw new Error("No variant of BookingSpaceAllocationReservation exists with 'type=".concat(json['type'], "'"));
    }
}
function BookingSpaceAllocationReservationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'carriagePosition':
            return (0, CarriagePositionSpaceAllocation_1.CarriagePositionSpaceAllocationToJSON)(value);
        case 'position':
            return (0, PositionSpaceAllocation_1.PositionSpaceAllocationToJSON)(value);
        default:
            throw new Error("No variant of BookingSpaceAllocationReservation exists with 'type=".concat(value['type'], "'"));
    }
}
