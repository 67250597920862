import { LinkTextButton } from "@routezero-site/component/helper/button";
import { smallFontClassName } from "@routezero-site/component/helper/global_style";
import { HoverIsActiveRecogniser } from "@routezero-site/component/helper/active_action";
import { RouteZeroLogo } from "@routezero-site/component/helper/logo";
import { SectionContainer } from "@routezero-site/component/helper/section_container";
import { LargePaddingPx, SectionGapPx, SmallPaddingPx } from "@routezero-site/component/helper/theme";
import { useServicesContext } from "@routezero-site/component/helper/services";

export interface FooterSectionProps {
  color: React.CSSProperties['color']
  backgroundColor: React.CSSProperties['backgroundColor']
}

/**
 * Company logo, copyright notice, address, and links to legal documents.
 * Designed to be placed at the bottom of a page.
 */
export const FooterSection: React.FC<FooterSectionProps> = (props) => {
  return (
    <SectionContainer 
      color={props.color}
      background={props.backgroundColor}
      padVertical={false}
    >
      <div className={smallFontClassName} style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: SectionGapPx(),
        paddingBottom: LargePaddingPx()
      }}>
        <RouteZeroLogo/>
        <p>{copyrightNotice()}</p>
        <p>71-75 Shelton Street, Covent Garden, London, WC2H 9JQ</p>
        <LegalLinks/>
      </div>
    </SectionContainer>
  );
};

/**
 * Opens Privacy policy, T&C, etc
 */
const LegalLinks: React.FC = () => {
  const { linksService } = useServicesContext();
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      gap: SmallPaddingPx()
    }}>
      <HoverIsActiveRecogniser>
        <LinkTextButton text="Privacy policy" href={linksService.rzPrivacyPolicyUrl()} />
      </HoverIsActiveRecogniser>
      <HoverIsActiveRecogniser>
        <LinkTextButton text="Terms & Conditions" href={linksService.rzTermsAndConditionsUrl()} />
      </HoverIsActiveRecogniser>
    </div>
  );
};

/**
 * @returns e.g. "© 2023 RouteZero Ltd", where the current year is used.
 */
function copyrightNotice(): string {
  const now = new Date();
  return `© ${now.getFullYear()} RouteZero Ltd`;
}