
import { IsMobile, IsTabletOrDesktop } from "@routezero-site/component/helper/breakpoint";

/** Generic */

export const LargePaddingPx = () => {
  return IsTabletOrDesktop() ? 30 : 20;
};

export const SmallPaddingPx = () => {
  return IsTabletOrDesktop() ? 8 : 10;
}

export const MedPaddingPx = () => {
  return LargePaddingPx() - SmallPaddingPx();
}

export const PillHorizontalPadding = () => {
  const multiplier = IsTabletOrDesktop() ? 2 : 1;
  return SmallPaddingPx() * multiplier;
}

export const PillVerticaladding = () => {
  return SmallPaddingPx();
}

export const SectionHeaderTextGapPx = () => {
  return 20.0;
}

export const SmallBorderRadiusPx = () => {
  return 7.5;
}

export const MedBorderRadiusPx = () => {
  return 10.0;
}

export const LargeBorderRadiusPx = () => {
  return 15.0;
}

export const PillBorderRadiusPx = () => {
  return 1000;
}

export const SectionGapPx = () => {
  return IsMobile() ? 70 : 100;
}

export const SectionItemGapPx = () => {
  return IsMobile() ? 50 : 60;
}

export const FadedOpacity = () => {
  return 0.8;
}

export const SmallIconSizePx = () => {
  return 12.0;
}

export const IconSizePx = () => {
  return 15.0;
}

export const RouteZeroLogoSizePx = () => {
  return 45.0;
}

export const ActiveDotSizePx = () => {
  return 9;
};

export const ActiveDotBorderSizePx = () => {
  return ActiveDotSizePx() * 0.15;
};

/**
 * The actual size of customer logos may be smaller than this - sizes are tuned
 * to ensure consistent sizing.
 */
export const CustomerLogoMaxSizePx = () => {
  return IsMobile() ? 120 : 130;
}

export const LargeIconSizePx = () => {
  return 24.0;
}

export const ExtraLargeIconSizePx = () => {
  return 34.0;
}

export const HoverScalePercentage = () => {
  return 5.0;
}

export const LargeShadow: () => React.CSSProperties = () => {
  return {
    boxShadow: '0px 0px 80px rgba(0, 0, 0, 0.1)'
  };
};

export const BorderWidthPx = () => {
  return 2.5;
}

/** Animation */

export const ExtraShortAnimationDurationSec: () => number = () => {
  return 0.15;
}

export const ShortAnimationDurationSec: () => number = () => {
  return 0.2;
}

export const MedAnimationDurationSec: () => number = () => {
  return 0.35;
}

export const MedAnimationCurve: () => string = () => {
  return 'cubic-bezier(.59,.01,.63,.99)';
}

export const FastAnimationCurve: () => string = () => {
  return 'cubic-bezier(.89,.01,.15,.98)';
}

/** Stack carousel */

/**
 * Percentage that stack items are scaled down by when they're behind the 
 * top-most item.
 */
export const StackCarouselScalePercentage = () => {
  return 0.05;
}

/**
 * Amount that stack items are moved down by when they're behind the 
 * top-most item.
 */
export const StackCarouselTranslateYInactivePx = () => {
  return 15;
}

/** Nav bar */

export const NavBarHeight = () => {
  return SmallPaddingPx() * 2 + RouteZeroLogoSizePx();
};

/** Customer quotes */

export const CustomerQuoteProfilePicSize = () => {
  return 45;
};

export const CustomerQuoteOrganisationLogoWidthPx = () => {
  return 105;
};

export const CustomerQuoteCardHeightPx = () => {
  return 235;
}

/** Case study section */

export const CaseStudyDarkColour = () => {
  return '#026173';
}

export const CaseStudySectionStatsWidthPx = () =>{
  return 230.0;
}

/** Travel platform section */

export interface TravelPlatformFeatureBackgroundColorArgs {
  isSelected: boolean
  isHovered: boolean
}

export const TravelPlatformFeatureBackgroundColor: (args: TravelPlatformFeatureBackgroundColorArgs) => string = (args) => {
  return args.isHovered || args.isSelected 
    ? '#9CE2F8' 
    : '#E1F6FA';
};

/**
 * Feature details card has a fixed height to avoid UI jumping around when
 * the currently highlighted feature is changed.
 */
export const TravelPlatformFeatureDetailsCardHeightPx = () => {
  return 250;
}

/**
 * Time to wait before displaying the next feature in the list, unless the user
 * has manually selected a feature.
 */
export const TravelPlatformCycleFeaturesDelayMs = () => {
  return 6000;
};

/**
 * Time to wait before reverting back to automatically cycling through the 
 * features (time since the user last manually selected a feature).
 */
export const TravelPlatformRevertToAutoCycleFeaturesWaitMs = () => {
  return 20000;
};

export const TransportIconSizePx = () => {
  return 60;
}

/** Decarbonisation features */

export const DecarbonisationFeatureProductNameBackgroundColor = () => {
  return "#E7F8F3";
}

/**
 * The height of each decarbonisation feature item.
 */
export const DecarbonisationFeatureHeight = () => {
  return 480;
};

/** Science-backed section */

export const ScienceBackedFeatureVerticalCardHeight = () => {
  return 420;
}

/** Home page footer section */

export const HomePageFooterColor = () => {
  return "#17654F";
};

export const HomePageFooterBackgroundColor = () => {
  return "#B6E7D9";
};

/** Contact sales page */

export const ContactSalesPageFooterColor = () => {
  return "#E8F1FB";
};

export const ContactSalesFooterBackgroundColor = () => {
  return "#1C5393";
};

/** Pricing page */

export const PricingPlanPrimaryColor = () => {
  return "#F7F7FE";
};

export const PricingPlanPrimaryCtaColor = () => {
  return "white";
};

export const PricingPlanPrimaryPriceColor = () => {
  return "white";
};

export const PricingPlanMainCardPrimaryBackgroundColor = () => {
  return "#00886F";
};

export const PricingPlanPriceCardPrimaryBackgroundColor = () => {
  return "rgba(247, 247, 254, 0.1)";
};

export const PricingPlanSecondaryColor = () => {
  return "#00886F";
};

export const PricingPlanMainCardSecondaryBackgroundColor = () => {
  return "#F8FFFE";
};

export const PricingPlanPriceCardSecondaryBackgroundColor = () => {
  return "#EBFCF9";
};

export const CarbonPricingGraphContainerBackgroundColor = () => {
  return "white";
};

export const CarbonPricingGraphBackgroundColor = () => {
  return "#F7F7FE";
};

export const CarbonPricingGraphRouteZeroBackgroundColor = () => {
  return PricingPlanMainCardPrimaryBackgroundColor();
};

export const CarbonPricingGraphRouteZeroColor = () => {
  return "#FFFFFF";
};

export const CarbonPricingGraphCompetitorColor = () => {
  return "#036173";
};

export const CarbonPricingGraphCompetitorBackgroundColor = () => {
  return "#F7F7FE";
};

export const CarbonPricingGraphConColor = () => {
  return "#FD7E80";
};

export const CarbonPricingGraphExampleHistoricColor = () => {
  return "#6C6C6C";
}

export const CarbonPricingGraphExampleBusinessAsUsualForecastColor = () => {
  return "#FD7E80";
}

export const CarbonPricingGraphCarbonSavingsColor = () => {
  return "rgba(0, 27, 79, 0.1)";
}

export const CarbonPricingGraphPointSizePx: () => number = () => {
  return 4;
}

/** Pricing page footer section */

export const PricingPageFooterColor = () => {
  return "white";
};

export const PricingPageFooterBackgroundColor = () => {
  return "#00886F";
};