"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LightRailTransportType = void 0;
exports.LightRailTransportTypeFromJSON = LightRailTransportTypeFromJSON;
exports.LightRailTransportTypeFromJSONTyped = LightRailTransportTypeFromJSONTyped;
exports.LightRailTransportTypeToJSON = LightRailTransportTypeToJSON;
/**
 *
 * @export
 */
exports.LightRailTransportType = {
    LightRail: 'lightRail'
};
function LightRailTransportTypeFromJSON(json) {
    return LightRailTransportTypeFromJSONTyped(json, false);
}
function LightRailTransportTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function LightRailTransportTypeToJSON(value) {
    return value;
}
