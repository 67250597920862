"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfOnAccountPaymentMethod = instanceOfOnAccountPaymentMethod;
exports.OnAccountPaymentMethodFromJSON = OnAccountPaymentMethodFromJSON;
exports.OnAccountPaymentMethodFromJSONTyped = OnAccountPaymentMethodFromJSONTyped;
exports.OnAccountPaymentMethodToJSON = OnAccountPaymentMethodToJSON;
var OnAccountPaymentMethodType_1 = require("./OnAccountPaymentMethodType");
/**
 * Check if a given object implements the OnAccountPaymentMethod interface.
 */
function instanceOfOnAccountPaymentMethod(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
function OnAccountPaymentMethodFromJSON(json) {
    return OnAccountPaymentMethodFromJSONTyped(json, false);
}
function OnAccountPaymentMethodFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, OnAccountPaymentMethodType_1.OnAccountPaymentMethodTypeFromJSON)(json['type']),
    };
}
function OnAccountPaymentMethodToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, OnAccountPaymentMethodType_1.OnAccountPaymentMethodTypeToJSON)(value.type),
    };
}
