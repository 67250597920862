"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.HiddenJourneyType = void 0;
exports.HiddenJourneyTypeFromJSON = HiddenJourneyTypeFromJSON;
exports.HiddenJourneyTypeFromJSONTyped = HiddenJourneyTypeFromJSONTyped;
exports.HiddenJourneyTypeToJSON = HiddenJourneyTypeToJSON;
/**
 *
 * @export
 */
exports.HiddenJourneyType = {
    Hidden: 'hidden'
};
function HiddenJourneyTypeFromJSON(json) {
    return HiddenJourneyTypeFromJSONTyped(json, false);
}
function HiddenJourneyTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function HiddenJourneyTypeToJSON(value) {
    return value;
}
