"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingMethodFromJSON = BookingMethodFromJSON;
exports.BookingMethodFromJSONTyped = BookingMethodFromJSONTyped;
exports.BookingMethodToJSON = BookingMethodToJSON;
var ExternalBookingMethod_1 = require("./ExternalBookingMethod");
var InternalBookingMethod_1 = require("./InternalBookingMethod");
function BookingMethodFromJSON(json) {
    return BookingMethodFromJSONTyped(json, false);
}
function BookingMethodFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'external':
            return __assign(__assign({}, (0, ExternalBookingMethod_1.ExternalBookingMethodFromJSONTyped)(json, true)), { type: 'external' });
        case 'internal':
            return __assign(__assign({}, (0, InternalBookingMethod_1.InternalBookingMethodFromJSONTyped)(json, true)), { type: 'internal' });
        default:
            throw new Error("No variant of BookingMethod exists with 'type=".concat(json['type'], "'"));
    }
}
function BookingMethodToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'external':
            return (0, ExternalBookingMethod_1.ExternalBookingMethodToJSON)(value);
        case 'internal':
            return (0, InternalBookingMethod_1.InternalBookingMethodToJSON)(value);
        default:
            throw new Error("No variant of BookingMethod exists with 'type=".concat(value['type'], "'"));
    }
}
