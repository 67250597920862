"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FixedValueAdjustmentType = void 0;
exports.FixedValueAdjustmentTypeFromJSON = FixedValueAdjustmentTypeFromJSON;
exports.FixedValueAdjustmentTypeFromJSONTyped = FixedValueAdjustmentTypeFromJSONTyped;
exports.FixedValueAdjustmentTypeToJSON = FixedValueAdjustmentTypeToJSON;
/**
 *
 * @export
 */
exports.FixedValueAdjustmentType = {
    Fixed: 'fixed'
};
function FixedValueAdjustmentTypeFromJSON(json) {
    return FixedValueAdjustmentTypeFromJSONTyped(json, false);
}
function FixedValueAdjustmentTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function FixedValueAdjustmentTypeToJSON(value) {
    return value;
}
