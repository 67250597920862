
export type Env
  // Running locally with emulators
  = "local"
  // Deployed to development/testing
  | "dev"
  // Deployed to production.
  | "prod";


export function env(): Env {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  switch (process.env["REACT_APP_ENV"]!) {
    case "dev": return "dev";
    case "prod": return "prod";
    default: return "local";
  }
}

export interface EnvMatcher<U> {
  local: () => U
  dev: () => U
  prod: () => U
}

export function matchEnv<U>(matcher: EnvMatcher<U>): U {
  switch (env()) {
    case "local": return matcher.local();
    case "dev": return matcher.dev();
    case "prod": return matcher.prod();
  }
}

export interface EnvUrlArgs {
  endpoint: string
  local: string
  dev: string
  prod: string
}

export function envUrl(args: EnvUrlArgs): string {
  function url(base: string): () => string {
    return () => `${base}${args.endpoint}`;
  }

  return matchEnv({
    local: url(args.local),
    dev: url(args.dev),
    prod: url(args.prod)
  });
}