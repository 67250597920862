"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfPassengerDetailsBookingInput = instanceOfPassengerDetailsBookingInput;
exports.PassengerDetailsBookingInputFromJSON = PassengerDetailsBookingInputFromJSON;
exports.PassengerDetailsBookingInputFromJSONTyped = PassengerDetailsBookingInputFromJSONTyped;
exports.PassengerDetailsBookingInputToJSON = PassengerDetailsBookingInputToJSON;
var PassengerDetailsBookingInputType_1 = require("./PassengerDetailsBookingInputType");
/**
 * Check if a given object implements the PassengerDetailsBookingInput interface.
 */
function instanceOfPassengerDetailsBookingInput(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "passengerIds" in value;
    isInstance = isInstance && "passportRequired" in value;
    return isInstance;
}
function PassengerDetailsBookingInputFromJSON(json) {
    return PassengerDetailsBookingInputFromJSONTyped(json, false);
}
function PassengerDetailsBookingInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, PassengerDetailsBookingInputType_1.PassengerDetailsBookingInputTypeFromJSON)(json['type']),
        'id': json['id'],
        'passengerIds': json['passengerIds'],
        'passportRequired': json['passportRequired'],
    };
}
function PassengerDetailsBookingInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, PassengerDetailsBookingInputType_1.PassengerDetailsBookingInputTypeToJSON)(value.type),
        'id': value.id,
        'passengerIds': value.passengerIds,
        'passportRequired': value.passportRequired,
    };
}
