"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTrainlineBookingMethod = instanceOfTrainlineBookingMethod;
exports.TrainlineBookingMethodFromJSON = TrainlineBookingMethodFromJSON;
exports.TrainlineBookingMethodFromJSONTyped = TrainlineBookingMethodFromJSONTyped;
exports.TrainlineBookingMethodToJSON = TrainlineBookingMethodToJSON;
var runtime_1 = require("../runtime");
var ChosenTrainlineAlternatives_1 = require("./ChosenTrainlineAlternatives");
var TrainlineBookingMethodType_1 = require("./TrainlineBookingMethodType");
/**
 * Check if a given object implements the TrainlineBookingMethod interface.
 */
function instanceOfTrainlineBookingMethod(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "searchId" in value;
    isInstance = isInstance && "outward" in value;
    return isInstance;
}
function TrainlineBookingMethodFromJSON(json) {
    return TrainlineBookingMethodFromJSONTyped(json, false);
}
function TrainlineBookingMethodFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, TrainlineBookingMethodType_1.TrainlineBookingMethodTypeFromJSON)(json['type']),
        'searchId': json['searchId'],
        'outward': (0, ChosenTrainlineAlternatives_1.ChosenTrainlineAlternativesFromJSON)(json['outward']),
        'inward': !(0, runtime_1.exists)(json, 'inward') ? undefined : (0, ChosenTrainlineAlternatives_1.ChosenTrainlineAlternativesFromJSON)(json['inward']),
    };
}
function TrainlineBookingMethodToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, TrainlineBookingMethodType_1.TrainlineBookingMethodTypeToJSON)(value.type),
        'searchId': value.searchId,
        'outward': (0, ChosenTrainlineAlternatives_1.ChosenTrainlineAlternativesToJSON)(value.outward),
        'inward': (0, ChosenTrainlineAlternatives_1.ChosenTrainlineAlternativesToJSON)(value.inward),
    };
}
