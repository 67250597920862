import { TravelPlatformFeature, TravelPlatformFeaturesService } from "@routezero-site/service/travel-platform-features/travel_platform_features";

/**
 * Returns a locally stored list of features.
 */
export class LocalTravelPlatformFeaturesService implements TravelPlatformFeaturesService {
  async features(): Promise<TravelPlatformFeature[]> {
    return [
      {
        iconType: "globe",
        name: "Global reach",
        description: "Wherever you're going RouteZero has you covered by connecting you to our extensive global inventory of rail, air, cars, hotels, and more with help from our world-leading travel partners."
      },
      {
        iconType: "price",
        name: "Save money",
        description: "By prioritising lower-carbon travel, we're also saving you money. Our customers save over 25% within their first 6 months using RouteZero."
      },
      {
        iconType: "checkBadge",
        name: "Effortless eco-travel",
        description: "We meticulously consider all aspects of your journey from door-to-door, ensuring that your eco-friendly travel options are as time-efficient as conventional methods.",
      },
      {
        iconType: "phone",
        name: "24/7 support",
        description: "Change of plans? Our flexible booking options and 24/7 support team are ready to assist, ensuring a hassle-free experience.",
      },
      {
        iconType: "chart",
        name: "Live-data reporting",
        description: "Get real-time visibility on bookings, spend, and carbon with your RouteZero dashboard."
      },
      {
        iconType: "information",
        name: "Informed at every step",
        description: "Make every decision count with our real-time carbon impact data, guiding you at every booking stage. It's not just about being informed at checkout; it's about reducing your impact from search to arrival.",
      },
      {
        iconType: "mapPin",
        name: "Last-mile solutions",
        description: "Your journey doesn't end at the airport or station. RouteZero covers your entire trip, including first and last-mile connections, for a seamless experience.",
      },
      {
        iconType: "mobilePhone",
        name: "Mobile support",
        description: "Stay connected with our mobile-friendly platform, making sustainable travel management convenient and accessible from anywhere.",
      },
      {
        iconType: "train",
        name: "Every journey compared",
        description: "Unlike other travel platforms, we don't make you choose how you're going to travel upfront. Instead, simply enter your origin and destination, and we'll show you all possible routes compared by time, cost, and carbon."
      }
      // {
      //   iconType: "trophy",
      //   name: "Beautiful design",
      //   description: "Simple, intuitive design that makes booking business travel as easy as booking a holiday."
      // }
    ];
  }
}