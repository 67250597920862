import { useScrolledY } from "@routezero-site/component/helper/scrolled";
import { CSSProperties, ReactNode } from "react";

interface BackgroundImageContainerProps {
  children: ReactNode
  backgroundImage: string
  backgroundMask: string
  height: CSSProperties['height']
  imageAtTop: boolean
}

/**
 * Container for the background image and content that should be displayed
 * above it. The background image is prevented from being scrolled if the 
 * user tries to scroll upwards past the extent of the page.
 */
export const BackgroundImageContainer: React.FC<BackgroundImageContainerProps> = (props) => {
  const { scrolledPastTop } = useScrolledY();
  return (
    <div style={{
      position: 'relative',
      height: `100%`,
      width: '100%',
      overflow: 'hidden',
    }}>
      <BackgroundImage 
        shouldFixPosition={props.imageAtTop ? scrolledPastTop : false}
        backgroundImage={props.backgroundImage}
        backgroundMask={props.backgroundMask}
        height={props.height}
        imageAtTop={props.imageAtTop}
      />
      <div style={{
        position: 'relative',
        zIndex: 1
      }}>
        {props.children}
      </div>
    </div>
  );
};

interface BackgroundImageProps {
  shouldFixPosition: boolean
  backgroundImage: string
  backgroundMask: string
  height?: CSSProperties['height']
  imageAtTop: boolean
}

/**
 * Background image displayed behind everything - landscape masked.
 */
const BackgroundImage: React.FC<BackgroundImageProps> = (props) => {
  return (
    <div style={{
      position: props.shouldFixPosition ? 'fixed' : 'absolute',
      zIndex: 1,
      ...props.imageAtTop ? {top: 0} : {bottom: 0},
      left: 0, right: 0,
      height: props.height,
      backgroundImage: `url(${props.backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: '5% 40%',
      backgroundRepeat: 'no-repeat',
      WebkitMaskImage: `url(${props.backgroundMask})`,
      maskImage: `url(${props.backgroundMask})`,
      maskRepeat: 'no-repeat',
      maskPosition: 'bottom',
      maskSize: 'cover'
    }}/>
  );
};