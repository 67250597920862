"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfPlaneTransport = instanceOfPlaneTransport;
exports.PlaneTransportFromJSON = PlaneTransportFromJSON;
exports.PlaneTransportFromJSONTyped = PlaneTransportFromJSONTyped;
exports.PlaneTransportToJSON = PlaneTransportToJSON;
var runtime_1 = require("../runtime");
var PlaneCarrier_1 = require("./PlaneCarrier");
var PlaneCarrierAircraft_1 = require("./PlaneCarrierAircraft");
var PlaneTransportType_1 = require("./PlaneTransportType");
/**
 * Check if a given object implements the PlaneTransport interface.
 */
function instanceOfPlaneTransport(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "operatingCarrier" in value;
    isInstance = isInstance && "marketingCarrier" in value;
    isInstance = isInstance && "marketingCarrierFlightNumber" in value;
    return isInstance;
}
function PlaneTransportFromJSON(json) {
    return PlaneTransportFromJSONTyped(json, false);
}
function PlaneTransportFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, PlaneTransportType_1.PlaneTransportTypeFromJSON)(json['type']),
        'operatingCarrier': (0, PlaneCarrier_1.PlaneCarrierFromJSON)(json['operatingCarrier']),
        'operatingCarrierFlightNumber': !(0, runtime_1.exists)(json, 'operatingCarrierFlightNumber') ? undefined : json['operatingCarrierFlightNumber'],
        'marketingCarrier': (0, PlaneCarrier_1.PlaneCarrierFromJSON)(json['marketingCarrier']),
        'marketingCarrierFlightNumber': json['marketingCarrierFlightNumber'],
        'aircraft': !(0, runtime_1.exists)(json, 'aircraft') ? undefined : (0, PlaneCarrierAircraft_1.PlaneCarrierAircraftFromJSON)(json['aircraft']),
    };
}
function PlaneTransportToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, PlaneTransportType_1.PlaneTransportTypeToJSON)(value.type),
        'operatingCarrier': (0, PlaneCarrier_1.PlaneCarrierToJSON)(value.operatingCarrier),
        'operatingCarrierFlightNumber': value.operatingCarrierFlightNumber,
        'marketingCarrier': (0, PlaneCarrier_1.PlaneCarrierToJSON)(value.marketingCarrier),
        'marketingCarrierFlightNumber': value.marketingCarrierFlightNumber,
        'aircraft': (0, PlaneCarrierAircraft_1.PlaneCarrierAircraftToJSON)(value.aircraft),
    };
}
