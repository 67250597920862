"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FootTransportType = void 0;
exports.FootTransportTypeFromJSON = FootTransportTypeFromJSON;
exports.FootTransportTypeFromJSONTyped = FootTransportTypeFromJSONTyped;
exports.FootTransportTypeToJSON = FootTransportTypeToJSON;
/**
 *
 * @export
 */
exports.FootTransportType = {
    Foot: 'foot'
};
function FootTransportTypeFromJSON(json) {
    return FootTransportTypeFromJSONTyped(json, false);
}
function FootTransportTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function FootTransportTypeToJSON(value) {
    return value;
}
