import { CustomerLogosGrid } from "@routezero-site/component/helper/customer_logos_grid";
import { CustomerQuotes } from "@routezero-site/component/helper/customer_quote";
import { FormState, FormStateContainer } from "@routezero-site/component/helper/form_state_container";
import { NavBar } from "@routezero-site/component/helper/nav_bar";
import { PaddedSectionItem, SectionContainer, SplitSection } from "@routezero-site/component/helper/section_container";
import { SectionMainText } from "@routezero-site/component/helper/section_main_text";
import { useServicesContext } from "@routezero-site/component/helper/services";
import { LargePaddingPx } from "@routezero-site/component/helper/theme";
import { ContactSalesForm } from "@routezero-site/component/page/contact-sales/contact_form";
import sectionBackgroundImage from "@routezero-site/component/page/contact-sales/media/background.jpg";
import { useState } from "react";
import { RecordProspectRequest } from "routezero_api";

const textColor = "#043D72";
const navBarColor = "#1C5393";

/**
 * Section with a form the user can fill out to request a demo.
 */
export const ContactSalesSection: React.FC = () => {
  const { recordProspectService } = useServicesContext();
  const [formState, setFormState] = useState<FormState>("notSubmitted");

  /**
   * Called when the user submits a request for a demo.
   */
  async function sendProspectRecordRequest(req: RecordProspectRequest): Promise<void> {
    try {
      setFormState("submitting");
      await recordProspectService.requestDemo(req);
      setFormState("submissionSuccess");

    } catch (e) {
      setFormState("submissionFailure");
      throw e;
    }
  }

  return (
    <>
      <NavBar 
        color={navBarColor}
        showContactSales={false}
      />
      <SectionContainer
        backgroundImage={sectionBackgroundImage}
        color={textColor}
        marginForNavBar={true}
        minHeight={'100vh'}
      >
        <SplitSection
          lhs={
            <div style={{ 
              display: 'flex',
              flexDirection: 'column',
              gap: LargePaddingPx()
            }}>
              <SectionMainText
                fillWidth={true}
                color={textColor}
                subtitleBackgroundColor="#515151"
                subtitleBackgroundBlendMode="soft-light"
                header="Book a demo"
                body="RouteZero enables businesses to drastically cut their business travel emissions while making their operations more efficient. Book a demo with the team, we're looking forward to meeting you!"
              />
              <ContactFormCustomerQuotes/>
            </div>
          }
          rhsTopPadding={LargePaddingPx()*2}
          rhs={
            <>
              <FormStateContainer state={formState}>
                <ContactSalesForm 
                  onSubmit={sendProspectRecordRequest}
                />
              </FormStateContainer>
            </>
          }
        />
        <PaddedSectionItem>
          <div style={{color: '#3C74B5'}}>
            <CustomerLogosGrid/>
          </div>
        </PaddedSectionItem>
      </SectionContainer>
    </>
  );
};

const ContactFormCustomerQuotes: React.FC = () => {
  return (
    <CustomerQuotes 
      quoteTextColor={textColor}
      quoteCardColor="white"
      authorTextColor={navBarColor}
      authorCardColor="#E8F3FF"
    />
  );
};