import { FooterSection } from "@routezero-site/component/helper/footer";
import { HomePageFooterBackgroundColor, HomePageFooterColor } from "@routezero-site/component/helper/theme";
import { CallToActionSection } from "@routezero-site/component/page/home/call-to-action/call_to_action_section";
import { CaseStudySection } from "@routezero-site/component/page/home/case-study/case_study_section";
import { CompetitiveEdgeSection } from "@routezero-site/component/page/home/competitive-edge/competitive_edge_section";
import { DecarbonisationJourneySection } from "@routezero-site/component/page/home/decarbonisation-journey/decarbonisation_journey_section";
import { HeroSection } from "@routezero-site/component/page/home/hero/hero_section";
import { ScienceBackedSection } from "@routezero-site/component/page/home/science-backed/science_backed_section";
import { TravelPlatformSection } from "@routezero-site/component/page/home/travel-platform/travel_platform_section";

/**
 * Tells the user about the product.
 */
export function HomePage() {
  return (
    <div>
      <HeroSection/>
      <TravelPlatformSection/>
      <CaseStudySection/>
      <DecarbonisationJourneySection/>
      <ScienceBackedSection/>
      <CompetitiveEdgeSection/>
      <CallToActionSection/>
      <FooterSection
        color={HomePageFooterColor()}
        backgroundColor={HomePageFooterBackgroundColor()}
      />
    </div>
  );
}
