import { CustomerQuote, CustomerQuotesService } from "@routezero-site/service/customer-quotes/customer_quotes";

/**
 * Customer quotes stored locally - only showing them on the dev site for now
 * until we have permission to share them.
 */
export class LocalCustomerQuotesService implements CustomerQuotesService {
  async quotes(): Promise<CustomerQuote[]> {
    return [
      {
        type: 'individual',
        quote: "Since we implemented RouteZero across our group team, we've reduced our travel emissions by 26% and our travel costs have fallen by a quarter!",
        authorName: "Bob Bailkoski",
        authorRole: "CEO",
        organisationName: "Logicalis Group",
        authorProfilePic: "bobBailkoski",
        organisationLogo: "logicalis"
      },
      {
        type: 'individual',
        authorName: 'Candy Snelling',
        authorRole: 'Associate Director for Estates and Sustainability',
        organisationName: 'NERC',
        quote: 'During the initial deployment of the RouteZero tool in November 2022 we observed an increased use of sustainable journey modes by staff in the order of 20%',
        authorProfilePic: 'candySnelling',
        organisationLogo: 'nerc'
      },
      {
        type: 'individual',
        quote: "RouteZero is a fantastic platform to work towards achieving our shared ambition of getting to Net-Zero, helping us take a data-driven approach to changing our travel behaviour",
        authorName: "Tansy Jessop",
        authorRole: "Pro Vice-Chancellor",
        organisationName: "University of Bristol",
        authorProfilePic: "tansyJessop",
        organisationLogo: 'bristolUni'
      },
      {
        type: 'individual',
        quote: "This is a fantastic tool. If you want a very quick way to help your teams to consider carbon and balance cost and time in their travel planning this is it. Give it a go.",
        authorName: "Christopher Hodgson",
        authorRole: "UK Head of Sustainability",
        organisationName: "Capgemini",
        authorProfilePic: "christopherHodgson",
        organisationLogo: 'capGemini'
      },
      {
        type: 'individual',
        quote: "RouteZero helped me cut my travel emissions by 96% when travelling from London to the World Sustainability Congress in Amsterdam",
        authorName: "Ben Knight",
        authorRole: "UK Head of Sustainability",
        organisationName: "Leonardo",
        authorProfilePic: "benKnight",
        organisationLogo: 'leonardo'
      },
      {
        type: 'organisation',
        quote: "RouteZero has the potential to lead the way towards a decarbonised future, with innovative products, services and technology designed to accelerate NetZero goals",
        organisationName: "Cambridge Institute for Sustainability Leadership",
        organisationLogo: 'cambridgeInstituteForSustainability'
      },
    ];
  }
}