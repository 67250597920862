"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CarSize = void 0;
exports.CarSizeFromJSON = CarSizeFromJSON;
exports.CarSizeFromJSONTyped = CarSizeFromJSONTyped;
exports.CarSizeToJSON = CarSizeToJSON;
/**
 *
 * @export
 */
exports.CarSize = {
    Small: 'small',
    Medium: 'medium',
    Large: 'large',
    Average: 'average'
};
function CarSizeFromJSON(json) {
    return CarSizeFromJSONTyped(json, false);
}
function CarSizeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function CarSizeToJSON(value) {
    return value;
}
