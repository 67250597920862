"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfRecordProspectRequest = instanceOfRecordProspectRequest;
exports.RecordProspectRequestFromJSON = RecordProspectRequestFromJSON;
exports.RecordProspectRequestFromJSONTyped = RecordProspectRequestFromJSONTyped;
exports.RecordProspectRequestToJSON = RecordProspectRequestToJSON;
/**
 * Check if a given object implements the RecordProspectRequest interface.
 */
function instanceOfRecordProspectRequest(value) {
    var isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "workEmail" in value;
    isInstance = isInstance && "jobTitle" in value;
    isInstance = isInstance && "organisationName" in value;
    return isInstance;
}
function RecordProspectRequestFromJSON(json) {
    return RecordProspectRequestFromJSONTyped(json, false);
}
function RecordProspectRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'workEmail': json['workEmail'],
        'jobTitle': json['jobTitle'],
        'organisationName': json['organisationName'],
    };
}
function RecordProspectRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'workEmail': value.workEmail,
        'jobTitle': value.jobTitle,
        'organisationName': value.organisationName,
    };
}
