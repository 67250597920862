
export interface RecordProspectRequest {
  /** Name of the person requesting a demo */
  name: string
  /** Work email of the person requesting a demo */
  workEmail: string
  /** E.g. Sustainability Manager */
  jobTitle: string
  /** Name of the organisation the person works for */
  organisationName: string
}

/**
 * Notifies the RouteZero that someone requested a demo of the platform.
 */
export interface RecordProspectService {
  requestDemo(request: RecordProspectRequest): Promise<void>
}

/**
 * If all fields are defined, returns a request, otherwise returns undefined.
 */
export function maybeCompletedRecordProspectRequest(request: Partial<RecordProspectRequest>): RecordProspectRequest | undefined {
  if (
    request.name === undefined || 
    request.workEmail === undefined ||
    request.jobTitle === undefined ||
    request.organisationName === undefined
  ) {
    return undefined;
  }

  // Compiler couldn't work out that just `request` was valid.
  return {
    name: request.name,
    workEmail: request.workEmail,
    jobTitle: request.jobTitle,
    organisationName: request.organisationName
  };
}