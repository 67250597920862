import { PillVerticaladding, PillHorizontalPadding, MedBorderRadiusPx, ExtraShortAnimationDurationSec } from "@routezero-site/component/helper/theme";
import { ReactNode } from "react";

export interface PillProps extends React.CSSProperties {
  children: ReactNode
  backgroundColor: React.CSSProperties['backgroundColor']
  backgroundBlendMode?: React.CSSProperties['mixBlendMode']
}

/**
 * Rounded border container.
 */
export const Pill: React.FC<PillProps> = (props) => {  
  // The background is not set on this div, instead a new div is positioned
  // absolutely to allow for it to have a different blend mode from the text.
  return (
    <div style={{
      display: 'inline-block',
      position: 'relative',
      overflow: 'clip',
      padding: `${PillVerticaladding()}px ${PillHorizontalPadding()}px`,
      borderRadius: MedBorderRadiusPx(),
    }}>
      <div style={{
        position: 'absolute',
        top: 0, left: 0, right: 0, bottom: 0,
        backgroundColor: props.backgroundColor,
        mixBlendMode: props.backgroundBlendMode,
        transition: `background-color ${ExtraShortAnimationDurationSec()}s ease`,
      }}/>
      <div style={{position: 'relative'}}>
        {props.children}
      </div>
    </div>
  );
};