"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTimeRange = instanceOfTimeRange;
exports.TimeRangeFromJSON = TimeRangeFromJSON;
exports.TimeRangeFromJSONTyped = TimeRangeFromJSONTyped;
exports.TimeRangeToJSON = TimeRangeToJSON;
var FormattedDate_1 = require("./FormattedDate");
/**
 * Check if a given object implements the TimeRange interface.
 */
function instanceOfTimeRange(value) {
    var isInstance = true;
    isInstance = isInstance && "start" in value;
    isInstance = isInstance && "end" in value;
    return isInstance;
}
function TimeRangeFromJSON(json) {
    return TimeRangeFromJSONTyped(json, false);
}
function TimeRangeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'start': (0, FormattedDate_1.FormattedDateFromJSON)(json['start']),
        'end': (0, FormattedDate_1.FormattedDateFromJSON)(json['end']),
    };
}
function TimeRangeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'start': (0, FormattedDate_1.FormattedDateToJSON)(value.start),
        'end': (0, FormattedDate_1.FormattedDateToJSON)(value.end),
    };
}
