"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StayFromJSON = StayFromJSON;
exports.StayFromJSONTyped = StayFromJSONTyped;
exports.StayToJSON = StayToJSON;
var AmenitiesStay_1 = require("./AmenitiesStay");
var BuildingStay_1 = require("./BuildingStay");
var ChoiceStay_1 = require("./ChoiceStay");
var Co2eStay_1 = require("./Co2eStay");
var RateOverviewStay_1 = require("./RateOverviewStay");
var RateStay_1 = require("./RateStay");
var ReviewStay_1 = require("./ReviewStay");
var RoomStay_1 = require("./RoomStay");
function StayFromJSON(json) {
    return StayFromJSONTyped(json, false);
}
function StayFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'amenities':
            return __assign(__assign({}, (0, AmenitiesStay_1.AmenitiesStayFromJSONTyped)(json, true)), { type: 'amenities' });
        case 'building':
            return __assign(__assign({}, (0, BuildingStay_1.BuildingStayFromJSONTyped)(json, true)), { type: 'building' });
        case 'choice':
            return __assign(__assign({}, (0, ChoiceStay_1.ChoiceStayFromJSONTyped)(json, true)), { type: 'choice' });
        case 'co2e':
            return __assign(__assign({}, (0, Co2eStay_1.Co2eStayFromJSONTyped)(json, true)), { type: 'co2e' });
        case 'rate':
            return __assign(__assign({}, (0, RateStay_1.RateStayFromJSONTyped)(json, true)), { type: 'rate' });
        case 'rateOverview':
            return __assign(__assign({}, (0, RateOverviewStay_1.RateOverviewStayFromJSONTyped)(json, true)), { type: 'rateOverview' });
        case 'review':
            return __assign(__assign({}, (0, ReviewStay_1.ReviewStayFromJSONTyped)(json, true)), { type: 'review' });
        case 'room':
            return __assign(__assign({}, (0, RoomStay_1.RoomStayFromJSONTyped)(json, true)), { type: 'room' });
        default:
            throw new Error("No variant of Stay exists with 'type=".concat(json['type'], "'"));
    }
}
function StayToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'amenities':
            return (0, AmenitiesStay_1.AmenitiesStayToJSON)(value);
        case 'building':
            return (0, BuildingStay_1.BuildingStayToJSON)(value);
        case 'choice':
            return (0, ChoiceStay_1.ChoiceStayToJSON)(value);
        case 'co2e':
            return (0, Co2eStay_1.Co2eStayToJSON)(value);
        case 'rate':
            return (0, RateStay_1.RateStayToJSON)(value);
        case 'rateOverview':
            return (0, RateOverviewStay_1.RateOverviewStayToJSON)(value);
        case 'review':
            return (0, ReviewStay_1.ReviewStayToJSON)(value);
        case 'room':
            return (0, RoomStay_1.RoomStayToJSON)(value);
        default:
            throw new Error("No variant of Stay exists with 'type=".concat(value['type'], "'"));
    }
}
