"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTrainlineStation = instanceOfTrainlineStation;
exports.TrainlineStationFromJSON = TrainlineStationFromJSON;
exports.TrainlineStationFromJSONTyped = TrainlineStationFromJSONTyped;
exports.TrainlineStationToJSON = TrainlineStationToJSON;
var runtime_1 = require("../runtime");
var TrainlineStationMeta_1 = require("./TrainlineStationMeta");
var TrainlineStationParent_1 = require("./TrainlineStationParent");
/**
 * Check if a given object implements the TrainlineStation interface.
 */
function instanceOfTrainlineStation(value) {
    var isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "meta" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "uniqueCode" in value;
    isInstance = isInstance && "timeZone" in value;
    return isInstance;
}
function TrainlineStationFromJSON(json) {
    return TrainlineStationFromJSONTyped(json, false);
}
function TrainlineStationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'meta': (0, TrainlineStationMeta_1.TrainlineStationMetaFromJSON)(json['meta']),
        'name': json['name'],
        'code': !(0, runtime_1.exists)(json, 'code') ? undefined : json['code'],
        'uniqueCode': json['uniqueCode'],
        'latitude': !(0, runtime_1.exists)(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !(0, runtime_1.exists)(json, 'longitude') ? undefined : json['longitude'],
        'parents': !(0, runtime_1.exists)(json, 'parents') ? undefined : (json['parents'].map(TrainlineStationParent_1.TrainlineStationParentFromJSON)),
        'timeZone': json['timeZone'],
    };
}
function TrainlineStationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'meta': (0, TrainlineStationMeta_1.TrainlineStationMetaToJSON)(value.meta),
        'name': value.name,
        'code': value.code,
        'uniqueCode': value.uniqueCode,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'parents': value.parents === undefined ? undefined : (value.parents.map(TrainlineStationParent_1.TrainlineStationParentToJSON)),
        'timeZone': value.timeZone,
    };
}
