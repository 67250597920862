"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TravelClassFromJSON = TravelClassFromJSON;
exports.TravelClassFromJSONTyped = TravelClassFromJSONTyped;
exports.TravelClassToJSON = TravelClassToJSON;
var BusinessTravelClass_1 = require("./BusinessTravelClass");
var EconomyTravelClass_1 = require("./EconomyTravelClass");
var FirstTravelClass_1 = require("./FirstTravelClass");
var PremiumEconomyTravelClass_1 = require("./PremiumEconomyTravelClass");
var StandardTravelClass_1 = require("./StandardTravelClass");
function TravelClassFromJSON(json) {
    return TravelClassFromJSONTyped(json, false);
}
function TravelClassFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'business':
            return __assign(__assign({}, (0, BusinessTravelClass_1.BusinessTravelClassFromJSONTyped)(json, true)), { type: 'business' });
        case 'economy':
            return __assign(__assign({}, (0, EconomyTravelClass_1.EconomyTravelClassFromJSONTyped)(json, true)), { type: 'economy' });
        case 'first':
            return __assign(__assign({}, (0, FirstTravelClass_1.FirstTravelClassFromJSONTyped)(json, true)), { type: 'first' });
        case 'premiumEconomy':
            return __assign(__assign({}, (0, PremiumEconomyTravelClass_1.PremiumEconomyTravelClassFromJSONTyped)(json, true)), { type: 'premiumEconomy' });
        case 'standard':
            return __assign(__assign({}, (0, StandardTravelClass_1.StandardTravelClassFromJSONTyped)(json, true)), { type: 'standard' });
        default:
            throw new Error("No variant of TravelClass exists with 'type=".concat(json['type'], "'"));
    }
}
function TravelClassToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'business':
            return (0, BusinessTravelClass_1.BusinessTravelClassToJSON)(value);
        case 'economy':
            return (0, EconomyTravelClass_1.EconomyTravelClassToJSON)(value);
        case 'first':
            return (0, FirstTravelClass_1.FirstTravelClassToJSON)(value);
        case 'premiumEconomy':
            return (0, PremiumEconomyTravelClass_1.PremiumEconomyTravelClassToJSON)(value);
        case 'standard':
            return (0, StandardTravelClass_1.StandardTravelClassToJSON)(value);
        default:
            throw new Error("No variant of TravelClass exists with 'type=".concat(value['type'], "'"));
    }
}
