"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EconomyTravelClassType = void 0;
exports.EconomyTravelClassTypeFromJSON = EconomyTravelClassTypeFromJSON;
exports.EconomyTravelClassTypeFromJSONTyped = EconomyTravelClassTypeFromJSONTyped;
exports.EconomyTravelClassTypeToJSON = EconomyTravelClassTypeToJSON;
/**
 *
 * @export
 */
exports.EconomyTravelClassType = {
    Economy: 'economy'
};
function EconomyTravelClassTypeFromJSON(json) {
    return EconomyTravelClassTypeFromJSONTyped(json, false);
}
function EconomyTravelClassTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function EconomyTravelClassTypeToJSON(value) {
    return value;
}
