
import { useEffect, useRef, RefObject } from 'react';

export interface UseIntersectionObserverArgs {
  callback: () => void,
  options?: IntersectionObserverInit
}

/**
 * Hook to be notified when the user scroll an HTML element into view.
 */
export function useIntersectionObserver<T extends HTMLElement>(args: UseIntersectionObserverArgs): RefObject<T> {
  const { callback, options } = args;
  const targetRef = useRef<T>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          callback();
        }
      });
    }, options);

    const currentTarget = targetRef.current;
    if (currentTarget) {
      observer.observe(currentTarget);
    }

    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, [callback, options]);

  return targetRef;
}
