"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfSwapJourney = instanceOfSwapJourney;
exports.SwapJourneyFromJSON = SwapJourneyFromJSON;
exports.SwapJourneyFromJSONTyped = SwapJourneyFromJSONTyped;
exports.SwapJourneyToJSON = SwapJourneyToJSON;
var Journey_1 = require("./Journey");
var OneWaySearchRequest_1 = require("./OneWaySearchRequest");
var SwapJourneyType_1 = require("./SwapJourneyType");
/**
 * Check if a given object implements the SwapJourney interface.
 */
function instanceOfSwapJourney(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "request" in value;
    isInstance = isInstance && "journey" in value;
    return isInstance;
}
function SwapJourneyFromJSON(json) {
    return SwapJourneyFromJSONTyped(json, false);
}
function SwapJourneyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, SwapJourneyType_1.SwapJourneyTypeFromJSON)(json['type']),
        'id': json['id'],
        'request': (0, OneWaySearchRequest_1.OneWaySearchRequestFromJSON)(json['request']),
        'journey': (0, Journey_1.JourneyFromJSON)(json['journey']),
    };
}
function SwapJourneyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, SwapJourneyType_1.SwapJourneyTypeToJSON)(value.type),
        'id': value.id,
        'request': (0, OneWaySearchRequest_1.OneWaySearchRequestToJSON)(value.request),
        'journey': (0, Journey_1.JourneyToJSON)(value.journey),
    };
}
