"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfFlightPassengerPassportDetails = instanceOfFlightPassengerPassportDetails;
exports.FlightPassengerPassportDetailsFromJSON = FlightPassengerPassportDetailsFromJSON;
exports.FlightPassengerPassportDetailsFromJSONTyped = FlightPassengerPassportDetailsFromJSONTyped;
exports.FlightPassengerPassportDetailsToJSON = FlightPassengerPassportDetailsToJSON;
/**
 * Check if a given object implements the FlightPassengerPassportDetails interface.
 */
function instanceOfFlightPassengerPassportDetails(value) {
    var isInstance = true;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "countryOfIssueIsoCode" in value;
    isInstance = isInstance && "dateOfExpiry" in value;
    return isInstance;
}
function FlightPassengerPassportDetailsFromJSON(json) {
    return FlightPassengerPassportDetailsFromJSONTyped(json, false);
}
function FlightPassengerPassportDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'number': json['number'],
        'countryOfIssueIsoCode': json['countryOfIssueIsoCode'],
        'dateOfExpiry': json['dateOfExpiry'],
    };
}
function FlightPassengerPassportDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'number': value.number,
        'countryOfIssueIsoCode': value.countryOfIssueIsoCode,
        'dateOfExpiry': value.dateOfExpiry,
    };
}
