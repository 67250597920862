"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfBookingPartiallyFailedState = instanceOfBookingPartiallyFailedState;
exports.BookingPartiallyFailedStateFromJSON = BookingPartiallyFailedStateFromJSON;
exports.BookingPartiallyFailedStateFromJSONTyped = BookingPartiallyFailedStateFromJSONTyped;
exports.BookingPartiallyFailedStateToJSON = BookingPartiallyFailedStateToJSON;
var BookingPartiallyFailedStateType_1 = require("./BookingPartiallyFailedStateType");
var Pricing_1 = require("./Pricing");
var Vendor_1 = require("./Vendor");
/**
 * Check if a given object implements the BookingPartiallyFailedState interface.
 */
function instanceOfBookingPartiallyFailedState(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "bookingReference" in value;
    isInstance = isInstance && "confirmedVendors" in value;
    isInstance = isInstance && "failedVendors" in value;
    isInstance = isInstance && "confirmedPricing" in value;
    return isInstance;
}
function BookingPartiallyFailedStateFromJSON(json) {
    return BookingPartiallyFailedStateFromJSONTyped(json, false);
}
function BookingPartiallyFailedStateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, BookingPartiallyFailedStateType_1.BookingPartiallyFailedStateTypeFromJSON)(json['type']),
        'bookingReference': json['bookingReference'],
        'confirmedVendors': (json['confirmedVendors'].map(Vendor_1.VendorFromJSON)),
        'failedVendors': (json['failedVendors'].map(Vendor_1.VendorFromJSON)),
        'confirmedPricing': (0, Pricing_1.PricingFromJSON)(json['confirmedPricing']),
    };
}
function BookingPartiallyFailedStateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, BookingPartiallyFailedStateType_1.BookingPartiallyFailedStateTypeToJSON)(value.type),
        'bookingReference': value.bookingReference,
        'confirmedVendors': (value.confirmedVendors.map(Vendor_1.VendorToJSON)),
        'failedVendors': (value.failedVendors.map(Vendor_1.VendorToJSON)),
        'confirmedPricing': (0, Pricing_1.PricingToJSON)(value.confirmedPricing),
    };
}
