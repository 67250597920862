"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfChoiceJourney = instanceOfChoiceJourney;
exports.ChoiceJourneyFromJSON = ChoiceJourneyFromJSON;
exports.ChoiceJourneyFromJSONTyped = ChoiceJourneyFromJSONTyped;
exports.ChoiceJourneyToJSON = ChoiceJourneyToJSON;
var ChoiceJourneyType_1 = require("./ChoiceJourneyType");
var ChoiceType_1 = require("./ChoiceType");
var Journey_1 = require("./Journey");
/**
 * Check if a given object implements the ChoiceJourney interface.
 */
function instanceOfChoiceJourney(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "choiceType" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "index" in value;
    isInstance = isInstance && "choices" in value;
    return isInstance;
}
function ChoiceJourneyFromJSON(json) {
    return ChoiceJourneyFromJSONTyped(json, false);
}
function ChoiceJourneyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, ChoiceJourneyType_1.ChoiceJourneyTypeFromJSON)(json['type']),
        'choiceType': (0, ChoiceType_1.ChoiceTypeFromJSON)(json['choiceType']),
        'id': json['id'],
        'index': json['index'],
        'choices': (json['choices'].map(Journey_1.JourneyFromJSON)),
    };
}
function ChoiceJourneyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, ChoiceJourneyType_1.ChoiceJourneyTypeToJSON)(value.type),
        'choiceType': (0, ChoiceType_1.ChoiceTypeToJSON)(value.choiceType),
        'id': value.id,
        'index': value.index,
        'choices': (value.choices.map(Journey_1.JourneyToJSON)),
    };
}
