"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfAnswerCommentsResponse = instanceOfAnswerCommentsResponse;
exports.AnswerCommentsResponseFromJSON = AnswerCommentsResponseFromJSON;
exports.AnswerCommentsResponseFromJSONTyped = AnswerCommentsResponseFromJSONTyped;
exports.AnswerCommentsResponseToJSON = AnswerCommentsResponseToJSON;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the AnswerCommentsResponse interface.
 */
function instanceOfAnswerCommentsResponse(value) {
    var isInstance = true;
    return isInstance;
}
function AnswerCommentsResponseFromJSON(json) {
    return AnswerCommentsResponseFromJSONTyped(json, false);
}
function AnswerCommentsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'key': !(0, runtime_1.exists)(json, 'key') ? undefined : json['key'],
    };
}
function AnswerCommentsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'key': value.key,
    };
}
