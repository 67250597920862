"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTrainSeatingPreference = instanceOfTrainSeatingPreference;
exports.TrainSeatingPreferenceFromJSON = TrainSeatingPreferenceFromJSON;
exports.TrainSeatingPreferenceFromJSONTyped = TrainSeatingPreferenceFromJSONTyped;
exports.TrainSeatingPreferenceToJSON = TrainSeatingPreferenceToJSON;
/**
 * Check if a given object implements the TrainSeatingPreference interface.
 */
function instanceOfTrainSeatingPreference(value) {
    var isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "description" in value;
    return isInstance;
}
function TrainSeatingPreferenceFromJSON(json) {
    return TrainSeatingPreferenceFromJSONTyped(json, false);
}
function TrainSeatingPreferenceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'description': json['description'],
    };
}
function TrainSeatingPreferenceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'description': value.description,
    };
}
