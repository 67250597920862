"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfPaymentFeeLineItem = instanceOfPaymentFeeLineItem;
exports.PaymentFeeLineItemFromJSON = PaymentFeeLineItemFromJSON;
exports.PaymentFeeLineItemFromJSONTyped = PaymentFeeLineItemFromJSONTyped;
exports.PaymentFeeLineItemToJSON = PaymentFeeLineItemToJSON;
var PaymentFeeLineItemType_1 = require("./PaymentFeeLineItemType");
var Price_1 = require("./Price");
/**
 * Check if a given object implements the PaymentFeeLineItem interface.
 */
function instanceOfPaymentFeeLineItem(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "price" in value;
    return isInstance;
}
function PaymentFeeLineItemFromJSON(json) {
    return PaymentFeeLineItemFromJSONTyped(json, false);
}
function PaymentFeeLineItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, PaymentFeeLineItemType_1.PaymentFeeLineItemTypeFromJSON)(json['type']),
        'price': (0, Price_1.PriceFromJSON)(json['price']),
    };
}
function PaymentFeeLineItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, PaymentFeeLineItemType_1.PaymentFeeLineItemTypeToJSON)(value.type),
        'price': (0, Price_1.PriceToJSON)(value.price),
    };
}
