"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfOnAccountPaymentRequest = instanceOfOnAccountPaymentRequest;
exports.OnAccountPaymentRequestFromJSON = OnAccountPaymentRequestFromJSON;
exports.OnAccountPaymentRequestFromJSONTyped = OnAccountPaymentRequestFromJSONTyped;
exports.OnAccountPaymentRequestToJSON = OnAccountPaymentRequestToJSON;
var Vendor_1 = require("./Vendor");
/**
 * Check if a given object implements the OnAccountPaymentRequest interface.
 */
function instanceOfOnAccountPaymentRequest(value) {
    var isInstance = true;
    isInstance = isInstance && "bookingId" in value;
    isInstance = isInstance && "vendor" in value;
    return isInstance;
}
function OnAccountPaymentRequestFromJSON(json) {
    return OnAccountPaymentRequestFromJSONTyped(json, false);
}
function OnAccountPaymentRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'bookingId': json['bookingId'],
        'vendor': (0, Vendor_1.VendorFromJSON)(json['vendor']),
    };
}
function OnAccountPaymentRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'bookingId': value.bookingId,
        'vendor': (0, Vendor_1.VendorToJSON)(value.vendor),
    };
}
