"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChoiceType = void 0;
exports.ChoiceTypeFromJSON = ChoiceTypeFromJSON;
exports.ChoiceTypeFromJSONTyped = ChoiceTypeFromJSONTyped;
exports.ChoiceTypeToJSON = ChoiceTypeToJSON;
/**
 *
 * @export
 */
exports.ChoiceType = {
    Location: 'location',
    Time: 'time',
    Fare: 'fare'
};
function ChoiceTypeFromJSON(json) {
    return ChoiceTypeFromJSONTyped(json, false);
}
function ChoiceTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function ChoiceTypeToJSON(value) {
    return value;
}
