"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfStationsFoundResponse = instanceOfStationsFoundResponse;
exports.StationsFoundResponseFromJSON = StationsFoundResponseFromJSON;
exports.StationsFoundResponseFromJSONTyped = StationsFoundResponseFromJSONTyped;
exports.StationsFoundResponseToJSON = StationsFoundResponseToJSON;
var NearbyStation_1 = require("./NearbyStation");
var StationsFoundResponseType_1 = require("./StationsFoundResponseType");
var VoiPricingPlan_1 = require("./VoiPricingPlan");
/**
 * Check if a given object implements the StationsFoundResponse interface.
 */
function instanceOfStationsFoundResponse(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "origin" in value;
    isInstance = isInstance && "destination" in value;
    isInstance = isInstance && "pricePlan" in value;
    return isInstance;
}
function StationsFoundResponseFromJSON(json) {
    return StationsFoundResponseFromJSONTyped(json, false);
}
function StationsFoundResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, StationsFoundResponseType_1.StationsFoundResponseTypeFromJSON)(json['type']),
        'origin': (0, NearbyStation_1.NearbyStationFromJSON)(json['origin']),
        'destination': (0, NearbyStation_1.NearbyStationFromJSON)(json['destination']),
        'pricePlan': (0, VoiPricingPlan_1.VoiPricingPlanFromJSON)(json['pricePlan']),
    };
}
function StationsFoundResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, StationsFoundResponseType_1.StationsFoundResponseTypeToJSON)(value.type),
        'origin': (0, NearbyStation_1.NearbyStationToJSON)(value.origin),
        'destination': (0, NearbyStation_1.NearbyStationToJSON)(value.destination),
        'pricePlan': (0, VoiPricingPlan_1.VoiPricingPlanToJSON)(value.pricePlan),
    };
}
