"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwapPriceJourneyType = void 0;
exports.SwapPriceJourneyTypeFromJSON = SwapPriceJourneyTypeFromJSON;
exports.SwapPriceJourneyTypeFromJSONTyped = SwapPriceJourneyTypeFromJSONTyped;
exports.SwapPriceJourneyTypeToJSON = SwapPriceJourneyTypeToJSON;
/**
 *
 * @export
 */
exports.SwapPriceJourneyType = {
    SwapPrice: 'swapPrice'
};
function SwapPriceJourneyTypeFromJSON(json) {
    return SwapPriceJourneyTypeFromJSONTyped(json, false);
}
function SwapPriceJourneyTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function SwapPriceJourneyTypeToJSON(value) {
    return value;
}
