"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TravellerTitle = void 0;
exports.TravellerTitleFromJSON = TravellerTitleFromJSON;
exports.TravellerTitleFromJSONTyped = TravellerTitleFromJSONTyped;
exports.TravellerTitleToJSON = TravellerTitleToJSON;
/**
 * The title of this traveller
 * @export
 */
exports.TravellerTitle = {
    Mr: 'mr',
    Ms: 'ms'
};
function TravellerTitleFromJSON(json) {
    return TravellerTitleFromJSONTyped(json, false);
}
function TravellerTitleFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function TravellerTitleToJSON(value) {
    return value;
}
