import { CompetitiveEdgeFeature, CompetitiveEdgeFeaturesService, CompetitiveEdgeStat } from "@routezero-site/service/competitive-edge-features/competitive_edge_features";

/**
 * Locally stored list of features.
 */
export class LocalCompetitiveEdgeFeaturesService implements CompetitiveEdgeFeaturesService {
  async stats(): Promise<CompetitiveEdgeStat[]> {
    return [
      {
        headlineStat: "81%",
        body: "of sustainable companies outperform industry benchmarks",
        sourceText: "NYU Stern School of Business",
        sourceUrl: "https://www.stern.nyu.edu/experience-stern/faculty-research/new-meta-analysis-nyu-stern-center-sustainable-business-and-rockefeller-asset-management-finds-esg"
      },
      {
        headlineStat: "66%",
        body: "of Millennials won't take a job at a company with poor CSR practices",
        sourceText: "Paul Polman, Net Positive",
        sourceUrl: "https://netpositive.world/book/"
      },
      {
        headlineStat: "46%",
        body: "of people want to adapt how they travel to limit their climate impact",
        sourceText: "Statista, 2022",
        sourceUrl: "https://www.statista.com/statistics/1310614/consumers-trying-to-replace-flights-with-more-sustainable-options-for-climate-change/"
      },
    ];
  }

  async features(): Promise<CompetitiveEdgeFeature[]> {
    return [
      {
        icon: "gears",
        title: "Enhanced productivity",
        description: "Certain lower carbon options, like rail, cut unproductive time by helping employees to work while travelling."
      },
      {
        icon: "fastForward",
        title: "Rapid results",
        description: "Our customers see large changes in their carbon footprint and cost savings within months, not years."
      },
      {
        icon: "coin",
        title: "Reduced travel costs",
        description: "We've helped customers save over 25% on travel costs within in their first six months using RouteZero."
      },
      {
        icon: "peopleGroup",
        title: "Inspired by your team",
        description: "Empower your team to be climate and brand ambassadors, boosting your business appeal."
      },
    ];
  }
}