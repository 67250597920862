"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfPathfinderSearchRequestCommon = instanceOfPathfinderSearchRequestCommon;
exports.PathfinderSearchRequestCommonFromJSON = PathfinderSearchRequestCommonFromJSON;
exports.PathfinderSearchRequestCommonFromJSONTyped = PathfinderSearchRequestCommonFromJSONTyped;
exports.PathfinderSearchRequestCommonToJSON = PathfinderSearchRequestCommonToJSON;
var runtime_1 = require("../runtime");
var InwardDateTime_1 = require("./InwardDateTime");
var Location_1 = require("./Location");
var SearchDateTime_1 = require("./SearchDateTime");
/**
 * Check if a given object implements the PathfinderSearchRequestCommon interface.
 */
function instanceOfPathfinderSearchRequestCommon(value) {
    var isInstance = true;
    isInstance = isInstance && "origin" in value;
    isInstance = isInstance && "dest" in value;
    isInstance = isInstance && "outwardDateTime" in value;
    isInstance = isInstance && "adultCount" in value;
    isInstance = isInstance && "discountCardIds" in value;
    return isInstance;
}
function PathfinderSearchRequestCommonFromJSON(json) {
    return PathfinderSearchRequestCommonFromJSONTyped(json, false);
}
function PathfinderSearchRequestCommonFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'origin': (0, Location_1.LocationFromJSON)(json['origin']),
        'dest': (0, Location_1.LocationFromJSON)(json['dest']),
        'outwardDateTime': (0, SearchDateTime_1.SearchDateTimeFromJSON)(json['outwardDateTime']),
        'inwardDateTime': !(0, runtime_1.exists)(json, 'inwardDateTime') ? undefined : (0, InwardDateTime_1.InwardDateTimeFromJSON)(json['inwardDateTime']),
        'adultCount': json['adultCount'],
        'discountCardIds': json['discountCardIds'],
    };
}
function PathfinderSearchRequestCommonToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'origin': (0, Location_1.LocationToJSON)(value.origin),
        'dest': (0, Location_1.LocationToJSON)(value.dest),
        'outwardDateTime': (0, SearchDateTime_1.SearchDateTimeToJSON)(value.outwardDateTime),
        'inwardDateTime': (0, InwardDateTime_1.InwardDateTimeToJSON)(value.inwardDateTime),
        'adultCount': value.adultCount,
        'discountCardIds': value.discountCardIds,
    };
}
