import { SlideUpRevealOnActive } from "@routezero-site/component/helper/active_action";
import { smallFontClassName } from "@routezero-site/component/helper/global_style";
import { BristolUniLogo, CambridgeInstituteForSustainabilityLogo, CapgeminiLogo, LeonardoLogo, LogicalisLogo, NERCLogo } from "@routezero-site/component/helper/logo";
import benKnightProfilePic from "@routezero-site/component/helper/media/people/ben_knight.jpeg";
import bobBailkoskiProfilePic from "@routezero-site/component/helper/media/people/bob_bailkoski.jpeg";
import christopherHodgsonPic from "@routezero-site/component/helper/media/people/christopher_hodgson.jpeg";
import tansyJessopProfilePic from "@routezero-site/component/helper/media/people/tansy_jessop.jpg";
import candySnallingProfilePic from "@routezero-site/component/helper/media/people/candy_snelling.jpeg";
import usePromise from "@routezero-site/component/helper/promise";
import { useServicesContext } from "@routezero-site/component/helper/services";
import { StackCarousel } from "@routezero-site/component/helper/stack_carousel";
import { CustomerQuoteCardHeightPx, CustomerQuoteOrganisationLogoWidthPx, CustomerQuoteProfilePicSize, LargeBorderRadiusPx, LargePaddingPx, MedPaddingPx, SmallPaddingPx } from "@routezero-site/component/helper/theme";
import { CustomerQuote, CustomerQuoteAuthorPic, CustomerQuoteOrganisationLogo, IndividualQuote, OrganisationQuote } from "@routezero-site/service/customer-quotes/customer_quotes";

export interface CustomerQuotesProps {
  /**
   * Text color of the quote
   */
  quoteTextColor: React.CSSProperties['color']
  /**
   * Color of the "card" on which the quotes are displayed.
   */
  quoteCardColor: React.CSSProperties['backgroundColor']
  /**
   * Text color of the author / company details.
   */
  authorTextColor: React.CSSProperties['color']
  /**
   * Color of the "card" on which the author / company details are displayed.
   */
  authorCardColor: React.CSSProperties['backgroundColor']
}

/**
 * Customer quotes displayed, e.g. with the "contact sales" form. Designed to 
 * encourage the user to request a demo by showing them additional social proof.
 */
export const CustomerQuotes: React.FC<CustomerQuotesProps> = (props) => {
  const { customerQuotesService } = useServicesContext();
  const [ quotes ] = usePromise(customerQuotesService.quotes);
  
  if (quotes === null) {
    return null;
  }

  return (
    <StackCarousel childHeight={CustomerQuoteCardHeightPx()}>
      {quotes.map((quote, i) => 
        <Quote
          {...props}
          key={i}
          quote={quote}
        />
      )}
    </StackCarousel>
  );
};

interface QuoteProps extends CustomerQuotesProps {
  quote: CustomerQuote
}

/**
 * Card containing a customer quote, and details of the author.
 */
const Quote: React.FC<QuoteProps> = (props) => {
  return (
    <div style={{
      color: props.quoteTextColor,
      backgroundColor: props.quoteCardColor,
      borderRadius: LargeBorderRadiusPx(),
      height: '100%',
      overflow: 'hidden'
    }}>
      <SlideUpRevealOnActive 
        slideDistMultipler={0.2}
        style={{
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <QuoteText 
          topPaddingPx={LargePaddingPx()}
          horizontalPaddingPx={LargePaddingPx()} 
          quoteText={props.quote.quote}
        />
        <div style={{
          padding: `${MedPaddingPx()}px ${LargePaddingPx()}px ${LargePaddingPx()}px ${LargePaddingPx()}px`,
          color: props.authorTextColor,
          backgroundColor: props.authorCardColor
        }}>
          {props.quote.type == 'individual'
            ? <AuthorAndEmployer {...props.quote}/>
            : <Organisation {...props.quote}/>
          }
          
        </div>
      </SlideUpRevealOnActive>
    </div>
  );
};

interface QuoteTextProps {
  topPaddingPx: number
  horizontalPaddingPx: number
  quoteText: string
}

/**
 * Quote with quotation mark graphics. The quote text will be padded by 
 * `paddingPx`, and are not affected by the quotation graphics.
 */
const QuoteText: React.FC<QuoteTextProps> = (props) => {
  const quoteGraphicWidth = 6;
  const horizontalPaddingPx = props.horizontalPaddingPx-quoteGraphicWidth;
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      padding: `${props.topPaddingPx}px ${horizontalPaddingPx}px 0px ${horizontalPaddingPx}px`,
    }}>
      <div style={{width: quoteGraphicWidth}}>
        <p>"</p>
      </div>
      <p style={{flexGrow: 1, flexBasis: 0, fontWeight: 300}}>
        {props.quoteText} "
      </p>
    </div>
  );
}; 

/**
 * Row showing the organsation log + name
 */
const Organisation: React.FC<OrganisationQuote> = (props) => {
  return (
    <div className={smallFontClassName} style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: SmallPaddingPx()
    }}>
      {props.organisationLogo && <OrganisationLogo organisationLogo={props.organisationLogo}/>}
      {props.organisationName}
    </div>
  );
};

/**
 * Row showing the author + profile picture and the logo for the organisation
 * they work for.
 */
const AuthorAndEmployer: React.FC<IndividualQuote> = (props) => {
  return (
    <div className={smallFontClassName} style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}>
      <Author {...props}/>
      {props.organisationLogo && <div style={{width: MedPaddingPx()}}/>}
      {props.organisationLogo && <OrganisationLogo organisationLogo={props.organisationLogo}/>}
    </div>
  );
};

/**
 * Profile picture and author details, including their name and employer.
 */
const Author: React.FC<IndividualQuote> = (props) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: SmallPaddingPx()
    }}>
      <AuthorProfilePic {...props}/>
      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <p style={{fontWeight: 600}}>
          {props.authorName}
        </p>
        <p>
          {`${props.authorRole}, ${props.organisationName}`}
        </p>
      </div>
    </div>
  );
};

interface AuthorProfilePicProps {
  authorName: string
  authorProfilePic: CustomerQuoteAuthorPic
}

const AuthorProfilePic: React.FC<AuthorProfilePicProps> = (props) => {
  return (
    <img
      src={authorProfilePicSrc(props.authorProfilePic)}
      alt={props.authorName}
      style={{
        width: CustomerQuoteProfilePicSize(),
        height: CustomerQuoteProfilePicSize(),
        borderRadius: CustomerQuoteProfilePicSize() * 2,
      }}
    />
  );
};

function authorProfilePicSrc(authorProfilePic: CustomerQuoteAuthorPic): string {
  switch (authorProfilePic) {
    case "bobBailkoski": return bobBailkoskiProfilePic;
    case "tansyJessop": return tansyJessopProfilePic;
    case "benKnight": return benKnightProfilePic;
    case "christopherHodgson": return christopherHodgsonPic;
    case "candySnelling": return candySnallingProfilePic;
  }
}

interface OrganisationLogoProps {
  organisationLogo: CustomerQuoteOrganisationLogo
}

const OrganisationLogo: React.FC<OrganisationLogoProps> = (props) => {
  const style: React.CSSProperties = {flexShrink: 0};
  switch (props.organisationLogo) {
    case "logicalis": return <LogicalisLogo style={style} width={CustomerQuoteOrganisationLogoWidthPx()}/>;
    case "bristolUni": return <BristolUniLogo style={style} width={CustomerQuoteOrganisationLogoWidthPx()}/>;
    case "cambridgeInstituteForSustainability": return <CambridgeInstituteForSustainabilityLogo style={style} width={CustomerQuoteOrganisationLogoWidthPx()}/>;
    case "nerc": return <NERCLogo style={style} width={CustomerQuoteOrganisationLogoWidthPx()}/>;
    case "leonardo": return <LeonardoLogo style={style} width={CustomerQuoteOrganisationLogoWidthPx()}/>;
    case "capGemini": return <CapgeminiLogo style={style} width={CustomerQuoteOrganisationLogoWidthPx()}/>;
  }
};