"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PositionSpaceAllocationType = void 0;
exports.PositionSpaceAllocationTypeFromJSON = PositionSpaceAllocationTypeFromJSON;
exports.PositionSpaceAllocationTypeFromJSONTyped = PositionSpaceAllocationTypeFromJSONTyped;
exports.PositionSpaceAllocationTypeToJSON = PositionSpaceAllocationTypeToJSON;
/**
 *
 * @export
 */
exports.PositionSpaceAllocationType = {
    Position: 'position'
};
function PositionSpaceAllocationTypeFromJSON(json) {
    return PositionSpaceAllocationTypeFromJSONTyped(json, false);
}
function PositionSpaceAllocationTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function PositionSpaceAllocationTypeToJSON(value) {
    return value;
}
