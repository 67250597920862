"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfDuffelServiceWithSeatInformationAllOfServiceInformation = instanceOfDuffelServiceWithSeatInformationAllOfServiceInformation;
exports.DuffelServiceWithSeatInformationAllOfServiceInformationFromJSON = DuffelServiceWithSeatInformationAllOfServiceInformationFromJSON;
exports.DuffelServiceWithSeatInformationAllOfServiceInformationFromJSONTyped = DuffelServiceWithSeatInformationAllOfServiceInformationFromJSONTyped;
exports.DuffelServiceWithSeatInformationAllOfServiceInformationToJSON = DuffelServiceWithSeatInformationAllOfServiceInformationToJSON;
/**
 * Check if a given object implements the DuffelServiceWithSeatInformationAllOfServiceInformation interface.
 */
function instanceOfDuffelServiceWithSeatInformationAllOfServiceInformation(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "segmentId" in value;
    isInstance = isInstance && "passengerId" in value;
    isInstance = isInstance && "passengerName" in value;
    isInstance = isInstance && "designator" in value;
    isInstance = isInstance && "disclosures" in value;
    isInstance = isInstance && "totalAmount" in value;
    isInstance = isInstance && "totalCurrency" in value;
    return isInstance;
}
function DuffelServiceWithSeatInformationAllOfServiceInformationFromJSON(json) {
    return DuffelServiceWithSeatInformationAllOfServiceInformationFromJSONTyped(json, false);
}
function DuffelServiceWithSeatInformationAllOfServiceInformationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': json['type'],
        'segmentId': json['segmentId'],
        'passengerId': json['passengerId'],
        'passengerName': json['passengerName'],
        'designator': json['designator'],
        'disclosures': json['disclosures'],
        'totalAmount': json['total_amount'],
        'totalCurrency': json['total_currency'],
    };
}
function DuffelServiceWithSeatInformationAllOfServiceInformationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': value.type,
        'segmentId': value.segmentId,
        'passengerId': value.passengerId,
        'passengerName': value.passengerName,
        'designator': value.designator,
        'disclosures': value.disclosures,
        'total_amount': value.totalAmount,
        'total_currency': value.totalCurrency,
    };
}
