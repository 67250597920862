import { useCursorPosition } from "@routezero-site/component/helper/cursor_position";
import { proportionAlongLength } from "@routezero-site/helper/math";
import { ReactNode, createContext, useContext, useEffect, useRef, useState } from "react";

export interface CursorProportion {
  /**
   * Proportion that the cursor is along the x-axis of the component wrapped 
   * with a `CursorPosProportionProvider`. 0-1 indicates the cursor is inside 
   * the component, with 0 being left and 1 being right.
   */
  xProp?: number

  /**
   * Proportion that the cursor is along the y-axis of the component wrapped 
   * with a `CursorPosProportionProvider`. 0-1 indicates the cursor is inside 
   * the component, with 0 being top and 1 being bottom.
   */
  yProp?: number
}

export interface CursorProportionProviderProps {
  /**
   * Without this, 0 = top of the child and 1 = bottom.
   * This is used to "scale in" the location of 0 and 1. E.g. 20% (0.2) moves 
   * 0 = 20% from the top.
   */
  marginYProportion?: number
  children: ReactNode
}

const CursorPositionContext = createContext<CursorProportion | undefined>(undefined);

/**
 * Provides the proportional position of the cursor across the child components
 * via `useCursorProportion`.
 * 
 * For example, along the x-axis, 0 indicates the mouse is fully left and 1 
 * indicates the mouse is fully right. Values less than 0 indicate the mouse is 
 * off the left hand side of the child. values more than 1 indicate the mouse
 * is off the right hand side of the child.
 */
export const CursorProportionProvider: React.FC<CursorProportionProviderProps> = (props) => {
  const { x, y } = useCursorPosition();
  const ref = useRef<HTMLDivElement>(null);
  const [cursorProp, setCursorProp] = useState<CursorProportion>({});

  const marginYProportion = props.marginYProportion ?? 0;
  useEffect(() => {
    if (x !== undefined && y !== undefined && ref.current) {
      const rect = ref.current.getBoundingClientRect();
      const xProp = proportionAlongLength({
        value: x, 
        start: rect.left, 
        length: rect.width, 
      });
      const yProp = proportionAlongLength({
        value: y, 
        start: rect.top, 
        length: rect.height, 
        marginProportion: marginYProportion
      });
      setCursorProp({ xProp, yProp });
    }
  }, [x, y, marginYProportion]);
  
  return (
    <CursorPositionContext.Provider value={cursorProp}>
      <div ref={ref} style={{
        height: '100%'
      }}>
        {props.children}
      </div>
    </CursorPositionContext.Provider>
  )
};

/**
 * Hook to retrieve the proportional position of the cursor in the "closest"
 * component in the component tree wrapped in a `CursorProportionProvider`.
 */
export const useCursorProportion: () => CursorProportion = () => {
  const context = useContext(CursorPositionContext);
  if (!context) {
    throw new Error('useCursorProportion must be used within a CursorProportionProvider');
  }
  return context;
};