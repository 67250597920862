
import { ShortAnimationDurationSec } from '@routezero-site/component/helper/theme';
import React from 'react';

interface ArrowIconProps {
  isActive: boolean;
}

/**
 * Arrow icon that extends when `isHovered` is true.
 */
export const ArrowIcon: React.FC<ArrowIconProps> = ({ isActive: isHovered }) => {
  const arrowTailStyle = {
    opacity: isHovered ? 1 : 0,
    transition: `opacity ${ShortAnimationDurationSec()}s`,
  };

  const arrowHeadStyle = {
    transform: isHovered ? 'translateX(3px)' : 'none',
    transition: `transform ${ShortAnimationDurationSec()*0.66}s`,
  };

  return (
    <svg style={{transform: 'scale(120%)'}} width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 5H6.5" stroke="currentColor" strokeWidth="2" style={arrowTailStyle}/>
      <path d="M1 1.5L4.5 5L1 8.5" stroke="currentColor" strokeWidth="2" style={arrowHeadStyle}/>
    </svg>
  );
};

