
/**
 * @returns the given list into elements that fall on even and odd indices.
 */
export function splitEvenOddIndices<T>(xs: T[]): [T[], T[]] {
  const initial: [T[], T[]] = [[], []];
  return xs.reduce((acc, curr, i) => {
    const [evens, odds] = acc;
    return i % 2 === 0
      ? [[...evens, curr], odds]
      : [evens, [...odds, curr]];
  }, initial);
}