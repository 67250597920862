import { SectionContainer } from "@routezero-site/component/helper/section_container";
import { SectionMainText } from "@routezero-site/component/helper/section_main_text";
import { ScienceBackedFeatures } from "@routezero-site/component/page/home/science-backed/features";
import sectionBackgroundImage from "@routezero-site/component/page/media/countryside_background.jpg";

const textColor = "#17654F";

/**
 * Tells potential customers about how RouteZero provides many decarbonisation
 * tools to help them hit their emissions targets.
 */
export const ScienceBackedSection: React.FC = () => {
  return (
    <SectionContainer
      backgroundImage={sectionBackgroundImage}
      color={textColor}
    >
      <SectionMainText
        color={textColor}
        subtitleBackgroundColor="rgba(13, 113, 84, 0.08)"
        subtitleBackgroundBlendMode="color-burn"
        header="Decarbonisation backed by data"
        subtitle="Science-first"
        body="Confidently report and show-off your emissions reductions with RouteZero. We understand the importance of accurate data, that's why our platform relies on the latest, most robust emissions methodologies, ensuring the data you present is not only transparent but scientifically sound."
      /> 
      <ScienceBackedFeatures/>
    </SectionContainer>
  );
}