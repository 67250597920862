"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfStaySearchRequestUTCFrontend = instanceOfStaySearchRequestUTCFrontend;
exports.StaySearchRequestUTCFrontendFromJSON = StaySearchRequestUTCFrontendFromJSON;
exports.StaySearchRequestUTCFrontendFromJSONTyped = StaySearchRequestUTCFrontendFromJSONTyped;
exports.StaySearchRequestUTCFrontendToJSON = StaySearchRequestUTCFrontendToJSON;
var FormattedDate_1 = require("./FormattedDate");
var Location_1 = require("./Location");
var OrgUrlName_1 = require("./OrgUrlName");
/**
 * Check if a given object implements the StaySearchRequestUTCFrontend interface.
 */
function instanceOfStaySearchRequestUTCFrontend(value) {
    var isInstance = true;
    isInstance = isInstance && "location" in value;
    isInstance = isInstance && "adultCount" in value;
    isInstance = isInstance && "roomCount" in value;
    isInstance = isInstance && "checkIn" in value;
    isInstance = isInstance && "checkOut" in value;
    isInstance = isInstance && "urlName" in value;
    return isInstance;
}
function StaySearchRequestUTCFrontendFromJSON(json) {
    return StaySearchRequestUTCFrontendFromJSONTyped(json, false);
}
function StaySearchRequestUTCFrontendFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'location': (0, Location_1.LocationFromJSON)(json['location']),
        'adultCount': json['adultCount'],
        'roomCount': json['roomCount'],
        'checkIn': (0, FormattedDate_1.FormattedDateFromJSON)(json['checkIn']),
        'checkOut': (0, FormattedDate_1.FormattedDateFromJSON)(json['checkOut']),
        'urlName': (0, OrgUrlName_1.OrgUrlNameFromJSON)(json['urlName']),
    };
}
function StaySearchRequestUTCFrontendToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'location': (0, Location_1.LocationToJSON)(value.location),
        'adultCount': value.adultCount,
        'roomCount': value.roomCount,
        'checkIn': (0, FormattedDate_1.FormattedDateToJSON)(value.checkIn),
        'checkOut': (0, FormattedDate_1.FormattedDateToJSON)(value.checkOut),
        'urlName': (0, OrgUrlName_1.OrgUrlNameToJSON)(value.urlName),
    };
}
