"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfVanUserVehicle = instanceOfVanUserVehicle;
exports.VanUserVehicleFromJSON = VanUserVehicleFromJSON;
exports.VanUserVehicleFromJSONTyped = VanUserVehicleFromJSONTyped;
exports.VanUserVehicleToJSON = VanUserVehicleToJSON;
var VanDrivetrain_1 = require("./VanDrivetrain");
var VanSize_1 = require("./VanSize");
var VanUserVehicleType_1 = require("./VanUserVehicleType");
/**
 * Check if a given object implements the VanUserVehicle interface.
 */
function instanceOfVanUserVehicle(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "drivetrain" in value;
    isInstance = isInstance && "size" in value;
    return isInstance;
}
function VanUserVehicleFromJSON(json) {
    return VanUserVehicleFromJSONTyped(json, false);
}
function VanUserVehicleFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, VanUserVehicleType_1.VanUserVehicleTypeFromJSON)(json['type']),
        'drivetrain': (0, VanDrivetrain_1.VanDrivetrainFromJSON)(json['drivetrain']),
        'size': (0, VanSize_1.VanSizeFromJSON)(json['size']),
    };
}
function VanUserVehicleToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, VanUserVehicleType_1.VanUserVehicleTypeToJSON)(value.type),
        'drivetrain': (0, VanDrivetrain_1.VanDrivetrainToJSON)(value.drivetrain),
        'size': (0, VanSize_1.VanSizeToJSON)(value.size),
    };
}
