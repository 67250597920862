"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfDateComponents = instanceOfDateComponents;
exports.DateComponentsFromJSON = DateComponentsFromJSON;
exports.DateComponentsFromJSONTyped = DateComponentsFromJSONTyped;
exports.DateComponentsToJSON = DateComponentsToJSON;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the DateComponents interface.
 */
function instanceOfDateComponents(value) {
    var isInstance = true;
    return isInstance;
}
function DateComponentsFromJSON(json) {
    return DateComponentsFromJSONTyped(json, false);
}
function DateComponentsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'day': !(0, runtime_1.exists)(json, 'day') ? undefined : json['day'],
        'month': !(0, runtime_1.exists)(json, 'month') ? undefined : json['month'],
        'year': !(0, runtime_1.exists)(json, 'year') ? undefined : json['year'],
    };
}
function DateComponentsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'day': value.day,
        'month': value.month,
        'year': value.year,
    };
}
