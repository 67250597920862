"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfManyJourney = instanceOfManyJourney;
exports.ManyJourneyFromJSON = ManyJourneyFromJSON;
exports.ManyJourneyFromJSONTyped = ManyJourneyFromJSONTyped;
exports.ManyJourneyToJSON = ManyJourneyToJSON;
var Journey_1 = require("./Journey");
var ManyJourneyType_1 = require("./ManyJourneyType");
/**
 * Check if a given object implements the ManyJourney interface.
 */
function instanceOfManyJourney(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "steps" in value;
    return isInstance;
}
function ManyJourneyFromJSON(json) {
    return ManyJourneyFromJSONTyped(json, false);
}
function ManyJourneyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, ManyJourneyType_1.ManyJourneyTypeFromJSON)(json['type']),
        'steps': (json['steps'].map(Journey_1.JourneyFromJSON)),
    };
}
function ManyJourneyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, ManyJourneyType_1.ManyJourneyTypeToJSON)(value.type),
        'steps': (value.steps.map(Journey_1.JourneyToJSON)),
    };
}
