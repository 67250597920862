import { CaseStudyStat, CaseStudyStatsService } from "@routezero-site/service/case-study-stats/case _study_stats";

/**
 * Locally stored stats.
 */
export class LocalCaseStudyStatsService implements CaseStudyStatsService {
  async stats(): Promise<CaseStudyStat[]> {
    return [
      {
        icon: "cloudDown",
        mainStat: "26%",
        body: "cut in emissions in 6 months"
      },
      {
        icon: "dollar",
        mainStat: "25%",
        body: "reduction in travel costs"
      },
      {
        icon: "graphUpwards",
        mainStat: "23%",
        body: "more journeys travelled"
      },
      {
        icon: "chart",
        mainStat: "40%",
        body: "Logicalis' next emission target"
      }
    ];
  }
}