import { ActiveDot } from "@routezero-site/component/helper/active_dot";
import { ChevronLeftSolidIcon, ChevronRightSolidIcon } from "@routezero-site/component/helper/icon";
import { MedPaddingPx, SmallPaddingPx } from "@routezero-site/component/helper/theme";
import { wrap } from "@routezero-site/helper/math";
import { ReactNode, createContext, useContext, useState } from "react";

export type PageChangeDirection
  = "next"
  | "prev"

export interface PaginationState {
  /**
   * The total number of pages, including "sentinel" pages / duplicates. 
   * 
   * This is useful for when extra pages are required for particular animation 
   * effects. For example, the `StackCarousel` requires at least additional 
   * two pages (with 0 opacity) to ensure the user does not see the page wrap 
   * from the front to the back of the stack. 
   */
  pageCountWithSentinels: number
  /**
   * The index of the current page in range 0 to pageCountWithSentinels-1.
   */
  pageIndexWithSentinels: number
  /**
   * Total number of pages _visible to the user_. "Sentinel" pages are not 
   * included in this count. 
   */
  displayedPageCount: number
  /**
   * The index of the current page in range 0 to displayedPageCount.
   */
  displayedPageIndex: number
  /**
   * The direction the user last changed the page in.
   */
  mostRecentPageChangeDirection: PageChangeDirection
  /**
   * Increments the current page index, wrapping if incrementing would exceed
   * the page count.
   */
  goToNextPage: () => void
  /**
   * Decrements the current page index, wrapping if decrementing would go below 0.
   */
  goToPrevPage: () => void
}

const PaginationContext = createContext<PaginationState>({
  pageCountWithSentinels: 0,
  pageIndexWithSentinels: 0,
  displayedPageCount: 0,
  displayedPageIndex: 0,
  mostRecentPageChangeDirection: "next",
  goToNextPage: () => {},
  goToPrevPage: () => {}
});

/**
 * Use to fetch pagination data, e.g. current page, and to modify the page.
 */
export const usePaginationContext = () => {
  const context = useContext(PaginationContext);
  if (context === undefined) {
    throw new Error('usePaginationContext must be used within a PaginationProvider');
  }
  return context;
};

export interface PaginationProviderProps {
  pageCountWithSentinels: number
  displayedPageCount: number
  children: ReactNode
}

/**
 * Components in the tree can make use of `usePaginationContext` to fetch
 * pagination information and update the current page.
 */
export const PaginationProvider: React.FC<PaginationProviderProps> = (props) => {
  const [pageIndexWithSentinels, setPageIndexWithSentinels] = useState(0);
  const [pageChangeDirection, setPageChangeDirection] = useState<PageChangeDirection>("next");

  const paginationState: PaginationState = {
    pageCountWithSentinels: props.pageCountWithSentinels,
    pageIndexWithSentinels: pageIndexWithSentinels,
    displayedPageIndex: wrap(pageIndexWithSentinels, 0, props.displayedPageCount),
    displayedPageCount: props.displayedPageCount,
    mostRecentPageChangeDirection: pageChangeDirection,
    goToNextPage: () => {
      setPageIndexWithSentinels(wrap(pageIndexWithSentinels+1, 0, props.pageCountWithSentinels));
      setPageChangeDirection("next");
    },
    goToPrevPage: () => {
      setPageIndexWithSentinels(wrap(pageIndexWithSentinels-1, 0, props.pageCountWithSentinels));
      setPageChangeDirection("prev");
    }
  };
  return (
    <PaginationContext.Provider value={paginationState}>
      {props.children}
    </PaginationContext.Provider>
  );
};

/**
 * Buttons that the user can press to move 
 */
export const PaginationControls: React.FC = () => {
  const pagintationState = usePaginationContext();
  const containerStyle: (gap: number) => React.CSSProperties = (gap) => ({
    display: 'flex',
    gap: gap,
    justifyContent: 'center',
    alignItems: 'center'
  });

  return (
    <div style={{
      ...containerStyle(MedPaddingPx())
    }}>
      <PageControlButton onClick={pagintationState.goToPrevPage}>
        <ChevronLeftSolidIcon/>
      </PageControlButton>
      <div style={{
        ...containerStyle(SmallPaddingPx()*2)
      }}>
        {Array.from({length: pagintationState.displayedPageCount}, (_, i) => 
          <ActiveDot 
            key={i} 
            isActive={i === pagintationState.displayedPageIndex}
          />
        )}
      </div>
      <PageControlButton onClick={pagintationState.goToNextPage}>
        <ChevronRightSolidIcon/>
      </PageControlButton>
    </div>
  );
};

interface PageControlButtonProps {
  onClick: () => void
  children: ReactNode
}

/**
 * Move the page forwards or backwards.
 */
const PageControlButton: React.FC<PageControlButtonProps> = (props) => {
  return (
    // Display flex so it sizes to child
    <button style={{display: 'flex'}} onClick={props.onClick}>
      {props.children}
    </button>
  );
};