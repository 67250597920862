"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfStaySearchRequestLocal = instanceOfStaySearchRequestLocal;
exports.StaySearchRequestLocalFromJSON = StaySearchRequestLocalFromJSON;
exports.StaySearchRequestLocalFromJSONTyped = StaySearchRequestLocalFromJSONTyped;
exports.StaySearchRequestLocalToJSON = StaySearchRequestLocalToJSON;
var Location_1 = require("./Location");
/**
 * Check if a given object implements the StaySearchRequestLocal interface.
 */
function instanceOfStaySearchRequestLocal(value) {
    var isInstance = true;
    isInstance = isInstance && "location" in value;
    isInstance = isInstance && "adultCount" in value;
    isInstance = isInstance && "roomCount" in value;
    isInstance = isInstance && "localCheckIn" in value;
    isInstance = isInstance && "localCheckOut" in value;
    return isInstance;
}
function StaySearchRequestLocalFromJSON(json) {
    return StaySearchRequestLocalFromJSONTyped(json, false);
}
function StaySearchRequestLocalFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'location': (0, Location_1.LocationFromJSON)(json['location']),
        'adultCount': json['adultCount'],
        'roomCount': json['roomCount'],
        'localCheckIn': (new Date(json['localCheckIn'])),
        'localCheckOut': (new Date(json['localCheckOut'])),
    };
}
function StaySearchRequestLocalToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'location': (0, Location_1.LocationToJSON)(value.location),
        'adultCount': value.adultCount,
        'roomCount': value.roomCount,
        'localCheckIn': (value.localCheckIn.toISOString()),
        'localCheckOut': (value.localCheckOut.toISOString()),
    };
}
