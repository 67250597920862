"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ETicketCollectionType = void 0;
exports.ETicketCollectionTypeFromJSON = ETicketCollectionTypeFromJSON;
exports.ETicketCollectionTypeFromJSONTyped = ETicketCollectionTypeFromJSONTyped;
exports.ETicketCollectionTypeToJSON = ETicketCollectionTypeToJSON;
/**
 *
 * @export
 */
exports.ETicketCollectionType = {
    Eticket: 'eticket'
};
function ETicketCollectionTypeFromJSON(json) {
    return ETicketCollectionTypeFromJSONTyped(json, false);
}
function ETicketCollectionTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function ETicketCollectionTypeToJSON(value) {
    return value;
}
