"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoStationsFoundResponseType = void 0;
exports.NoStationsFoundResponseTypeFromJSON = NoStationsFoundResponseTypeFromJSON;
exports.NoStationsFoundResponseTypeFromJSONTyped = NoStationsFoundResponseTypeFromJSONTyped;
exports.NoStationsFoundResponseTypeToJSON = NoStationsFoundResponseTypeToJSON;
/**
 *
 * @export
 */
exports.NoStationsFoundResponseType = {
    NoStationsFound: 'noStationsFound'
};
function NoStationsFoundResponseTypeFromJSON(json) {
    return NoStationsFoundResponseTypeFromJSONTyped(json, false);
}
function NoStationsFoundResponseTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function NoStationsFoundResponseTypeToJSON(value) {
    return value;
}
