"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfFixedValueAdjustment = instanceOfFixedValueAdjustment;
exports.FixedValueAdjustmentFromJSON = FixedValueAdjustmentFromJSON;
exports.FixedValueAdjustmentFromJSONTyped = FixedValueAdjustmentFromJSONTyped;
exports.FixedValueAdjustmentToJSON = FixedValueAdjustmentToJSON;
var FixedValueAdjustmentType_1 = require("./FixedValueAdjustmentType");
/**
 * Check if a given object implements the FixedValueAdjustment interface.
 */
function instanceOfFixedValueAdjustment(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "pence" in value;
    return isInstance;
}
function FixedValueAdjustmentFromJSON(json) {
    return FixedValueAdjustmentFromJSONTyped(json, false);
}
function FixedValueAdjustmentFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, FixedValueAdjustmentType_1.FixedValueAdjustmentTypeFromJSON)(json['type']),
        'pence': json['pence'],
    };
}
function FixedValueAdjustmentToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, FixedValueAdjustmentType_1.FixedValueAdjustmentTypeToJSON)(value.type),
        'pence': value.pence,
    };
}
