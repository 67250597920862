"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BusTransportType = void 0;
exports.BusTransportTypeFromJSON = BusTransportTypeFromJSON;
exports.BusTransportTypeFromJSONTyped = BusTransportTypeFromJSONTyped;
exports.BusTransportTypeToJSON = BusTransportTypeToJSON;
/**
 *
 * @export
 */
exports.BusTransportType = {
    Bus: 'bus'
};
function BusTransportTypeFromJSON(json) {
    return BusTransportTypeFromJSONTyped(json, false);
}
function BusTransportTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function BusTransportTypeToJSON(value) {
    return value;
}
