import { SectionContainer } from "@routezero-site/component/helper/section_container";
import { SectionMainText } from "@routezero-site/component/helper/section_main_text";
import { CompetitiveEdgeFeatures } from "@routezero-site/component/page/home/competitive-edge/features";
import { CompetitiveEdgeStats } from "@routezero-site/component/page/home/competitive-edge/stats";
import React from "react";

const textColor = "#576782";

/**
 * Tells the user why reducing emissions can lead to a competitive edge.
 */
export const CompetitiveEdgeSection: React.FC = () => {
  return (
    <SectionContainer
      background={"#FBFBFF"}
      color={textColor}
    >
      <SectionMainText
        color={textColor}
        subtitleBackgroundColor="#515151"
        subtitleBackgroundBlendMode="color-burn"
        header="Gain an edge through decarbonisation"
        subtitle="Cut costs and retain talent"
        body="Transform sustainability into a competitive edge with RouteZero. Our platform streamlines your transition to lower travel emissions, improving operational efficiency and business appeal in today's environmentally conscious market."
      /> 
      <CompetitiveEdgeStats/>
      <CompetitiveEdgeFeatures color={textColor}/>
    </SectionContainer>
  );
};