"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckedBagsAllowanceType = void 0;
exports.CheckedBagsAllowanceTypeFromJSON = CheckedBagsAllowanceTypeFromJSON;
exports.CheckedBagsAllowanceTypeFromJSONTyped = CheckedBagsAllowanceTypeFromJSONTyped;
exports.CheckedBagsAllowanceTypeToJSON = CheckedBagsAllowanceTypeToJSON;
/**
 *
 * @export
 */
exports.CheckedBagsAllowanceType = {
    Checked: 'checked'
};
function CheckedBagsAllowanceTypeFromJSON(json) {
    return CheckedBagsAllowanceTypeFromJSONTyped(json, false);
}
function CheckedBagsAllowanceTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function CheckedBagsAllowanceTypeToJSON(value) {
    return value;
}
