import { TabletOrDesktop } from "@routezero-site/component/helper/breakpoint";
import { PaddedSectionItem, SectionContainer } from "@routezero-site/component/helper/section_container";
import { SectionMainText } from "@routezero-site/component/helper/section_main_text";
import { TravelPlatformDemoVideo } from "@routezero-site/component/page/home/travel-platform/demo_video";
import { TravelPlatformFeatures } from "@routezero-site/component/page/home/travel-platform/features";
import { TransportsGraphic } from "@routezero-site/component/page/home/travel-platform/transports_graphic";

const textColor = "#006D83";

/**
 * Tells potential customers about the travel platform part of RouteZero.
 */
export const TravelPlatformSection: React.FC = () => {
  return (
    <SectionContainer
      background='#F5FDFF'
      color={textColor}
    >
      <TabletOrDesktop>
        <div style={{
          position: 'absolute',
          right: 0
        }}>
          <TransportsGraphic/>
        </div>
      </TabletOrDesktop>
      <SectionMainText
        color={textColor}
        subtitleBackgroundColor="#515151"
        subtitleBackgroundBlendMode="color-burn"
        header="Book low-carbon travel"
        subtitle="Enterprise-grade travel management"
        body="Book and manage your business travel with ease. Plus, with real-time emissions data integrated directly into the booking flow, it's easy to find lower carbon travel options whether you're using rail, air, cars, hotels, and more."
      /> 
      <TravelPlatformDemoVideo/>
      <PaddedSectionItem>
        <TravelPlatformFeatures/>
      </PaddedSectionItem>
    </SectionContainer>
  );
}