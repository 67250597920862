
/**
 * @returns whether `value` is inside the range min to max inclusive.
 */
export function isWithinRange(args: { value: number, min: number, max: number }): boolean {
  return args.value >= args.min && args.value <= args.max;
}

/**
 * @returns whether `value` is inside the range 0-1.
 */
export function isWithinRange0To1(value: number): boolean {
  return isWithinRange({ value, min: 0, max: 1 });
}

/**
 * @returns value rounded to the nearest `nearest`.
 */
export function roundNearest(args: { value: number, nearest: number}): number {
    return Math.round(args.value / args.nearest) * args.nearest;
}

/**
 * @returns the proportion that `value` is along a length starting at `start`.
 *  
 * `marginProportion` can be used to add "dead zones" at the start and end
 *  of the length. In these deadzones, either 0 or 1 will be returned (depending
 *  on whether the `value` is at the start or end of the length).
 * 
 * If the `value` is outside the length, values < 0 or > 1 will be returned.
 */
export function proportionAlongLength(args: { 
  value: number, 
  start: number, 
  length: number, 
  marginProportion?: number
}): number {
  const prop = (args.value - args.start) / args.length;
  const marginProportion = args.marginProportion ?? 0;
  if (prop <= marginProportion && prop > 0) {
    return 0;
  } else if (prop >= 1 - marginProportion && prop < 1) { 
    return 1;
  }
  return prop;
}

/**
 * Wraps a value to be within the range of min and max.
 */
export function wrap(value: number, min: number, max: number): number {
  if (min > max) {
    throw new Error('Min must be less than Max');
  }

  const range = max - min;

  // Adjusting the value to start from 0, wrapping it, and then adding min back to restore the range.
  return (((value - min) % range) + range) % range + min;
}