import Odometer from 'react-odometerjs';
import '@routezero-site/component/helper/odometer.css';
import { useEffect, useState } from 'react';

export interface CustomOdometerProps {
  /**
   * The value to display on the odometer
   */
  value: number
}

/**
 * An Odometer that display the provided value
 */
export const CustomOdometer: React.FC<CustomOdometerProps> = (props) => {
  return (
    <h4>
      <Odometer 
        value={props.value}
        format="dddd"
      /> 
    </h4>
  );
};

export interface CustomOdometerWithDelayProps {
  /**
   * The value to display on the odometer
   */
  value: number
  /**
   * The delay in ms before the value shown on the odometer updates
   */
  delay: number
}

/**
 * An Odometer that display the provided value after a set delay
 */
export const CustomOdometerWithDelay: React.FC<CustomOdometerWithDelayProps> = (props) => {
  const {value, delay} = props
  const [delayedValue, setDelayedValue] = useState(value);
  
  useEffect(() => {
    setTimeout(() => {
      setDelayedValue(value);
    }, delay);
  }, [value, delay]);

  return (
    <CustomOdometer
      value={delayedValue}
    />
  );
};