"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTrainEachWayReservability = instanceOfTrainEachWayReservability;
exports.TrainEachWayReservabilityFromJSON = TrainEachWayReservabilityFromJSON;
exports.TrainEachWayReservabilityFromJSONTyped = TrainEachWayReservabilityFromJSONTyped;
exports.TrainEachWayReservabilityToJSON = TrainEachWayReservabilityToJSON;
var runtime_1 = require("../runtime");
var TrainSeatReservability_1 = require("./TrainSeatReservability");
/**
 * Check if a given object implements the TrainEachWayReservability interface.
 */
function instanceOfTrainEachWayReservability(value) {
    var isInstance = true;
    isInstance = isInstance && "outward" in value;
    return isInstance;
}
function TrainEachWayReservabilityFromJSON(json) {
    return TrainEachWayReservabilityFromJSONTyped(json, false);
}
function TrainEachWayReservabilityFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'outward': (0, TrainSeatReservability_1.TrainSeatReservabilityFromJSON)(json['outward']),
        'inward': !(0, runtime_1.exists)(json, 'inward') ? undefined : (0, TrainSeatReservability_1.TrainSeatReservabilityFromJSON)(json['inward']),
    };
}
function TrainEachWayReservabilityToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'outward': (0, TrainSeatReservability_1.TrainSeatReservabilityToJSON)(value.outward),
        'inward': (0, TrainSeatReservability_1.TrainSeatReservabilityToJSON)(value.inward),
    };
}
