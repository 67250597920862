"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfKioskStationsResponse = instanceOfKioskStationsResponse;
exports.KioskStationsResponseFromJSON = KioskStationsResponseFromJSON;
exports.KioskStationsResponseFromJSONTyped = KioskStationsResponseFromJSONTyped;
exports.KioskStationsResponseToJSON = KioskStationsResponseToJSON;
/**
 * Check if a given object implements the KioskStationsResponse interface.
 */
function instanceOfKioskStationsResponse(value) {
    var isInstance = true;
    isInstance = isInstance && "stations" in value;
    return isInstance;
}
function KioskStationsResponseFromJSON(json) {
    return KioskStationsResponseFromJSONTyped(json, false);
}
function KioskStationsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'stations': json['stations'],
    };
}
function KioskStationsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'stations': value.stations,
    };
}
