"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfBuildingStay = instanceOfBuildingStay;
exports.BuildingStayFromJSON = BuildingStayFromJSON;
exports.BuildingStayFromJSONTyped = BuildingStayFromJSONTyped;
exports.BuildingStayToJSON = BuildingStayToJSON;
var BuildingData_1 = require("./BuildingData");
var BuildingStayType_1 = require("./BuildingStayType");
var Stay_1 = require("./Stay");
/**
 * Check if a given object implements the BuildingStay interface.
 */
function instanceOfBuildingStay(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "buildingData" in value;
    isInstance = isInstance && "stay" in value;
    return isInstance;
}
function BuildingStayFromJSON(json) {
    return BuildingStayFromJSONTyped(json, false);
}
function BuildingStayFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, BuildingStayType_1.BuildingStayTypeFromJSON)(json['type']),
        'buildingData': (0, BuildingData_1.BuildingDataFromJSON)(json['buildingData']),
        'stay': (0, Stay_1.StayFromJSON)(json['stay']),
    };
}
function BuildingStayToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, BuildingStayType_1.BuildingStayTypeToJSON)(value.type),
        'buildingData': (0, BuildingData_1.BuildingDataToJSON)(value.buildingData),
        'stay': (0, Stay_1.StayToJSON)(value.stay),
    };
}
