"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfBookingFeeLineItem = instanceOfBookingFeeLineItem;
exports.BookingFeeLineItemFromJSON = BookingFeeLineItemFromJSON;
exports.BookingFeeLineItemFromJSONTyped = BookingFeeLineItemFromJSONTyped;
exports.BookingFeeLineItemToJSON = BookingFeeLineItemToJSON;
var BookingFeeLineItemType_1 = require("./BookingFeeLineItemType");
var Price_1 = require("./Price");
/**
 * Check if a given object implements the BookingFeeLineItem interface.
 */
function instanceOfBookingFeeLineItem(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "price" in value;
    return isInstance;
}
function BookingFeeLineItemFromJSON(json) {
    return BookingFeeLineItemFromJSONTyped(json, false);
}
function BookingFeeLineItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, BookingFeeLineItemType_1.BookingFeeLineItemTypeFromJSON)(json['type']),
        'price': (0, Price_1.PriceFromJSON)(json['price']),
    };
}
function BookingFeeLineItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, BookingFeeLineItemType_1.BookingFeeLineItemTypeToJSON)(value.type),
        'price': (0, Price_1.PriceToJSON)(value.price),
    };
}
