import { ShortAnimationDurationSec, SmallPaddingPx } from "@routezero-site/component/helper/theme";
import { ReactNode } from "react";

export type FormState
  /**
   * The user has not yet filled out the form.
   */
  = "notSubmitted"
  /**
   * The user filled out the form, and the form is currently being submitted, 
   * e.g. sent to the backend and waiting for a response.
   */
  | "submitting"
  /**
   * The form was successfully submitted.
   */
  | "submissionSuccess"
  /**
   * There was an error submitting the form.
   */
  | "submissionFailure";


export interface FormStateContainerProps {
  state: FormState
  children?: ReactNode
}

/**
 * Wraps a form (chilren), and displays loading spinner and whether submitting
 * the form was successful or not.
 */
export const FormStateContainer: React.FC<FormStateContainerProps> = (props) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: SmallPaddingPx(),
      // While form is being submitted, prevent it being edited.
      pointerEvents: props.state === 'submitting' ? 'none' : undefined,
      opacity: props.state === 'submitting' ? 0.7 : 1.0,
      transition: `opacity ${ShortAnimationDurationSec()}s`
    }}>
      {props.state === "notSubmitted" || props.state === "submitting" ? props.children : null}
      {props.state === "submissionSuccess" ? <FormStateSuccessMessage/> : null}
      {props.state === "submissionFailure" ? <FormStateFailureMessage/> : null}
    </div>
  );
};

const FormStateSuccessMessage: React.FC = () => {
  return (
    <FormStateMessage
      title="Request sent"
      message="Thank you, we'll be in contact with you soon."
    />
  );
};

const FormStateFailureMessage: React.FC = () => {
  return (
    <FormStateMessage
      title="Uh oh"
      message="Something went wrong, please try that again."
    />
  );
};

interface FormStateMessageProps {
  title: string
  message: string
}

const FormStateMessage: React.FC<FormStateMessageProps> = (props) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: SmallPaddingPx()
    }}>
      <h3>
        {props.title}
      </h3>
      <p>
        {props.message}
      </p>
    </div>
  );
};