"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfFloatingConditions = instanceOfFloatingConditions;
exports.FloatingConditionsFromJSON = FloatingConditionsFromJSON;
exports.FloatingConditionsFromJSONTyped = FloatingConditionsFromJSONTyped;
exports.FloatingConditionsToJSON = FloatingConditionsToJSON;
var runtime_1 = require("../runtime");
var ConditionDescription_1 = require("./ConditionDescription");
var FloatingConditionsType_1 = require("./FloatingConditionsType");
var OtherPermittedLocations_1 = require("./OtherPermittedLocations");
var TravelClass_1 = require("./TravelClass");
/**
 * Check if a given object implements the FloatingConditions interface.
 */
function instanceOfFloatingConditions(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "fareName" in value;
    isInstance = isInstance && "travelClasses" in value;
    isInstance = isInstance && "origin" in value;
    isInstance = isInstance && "destination" in value;
    isInstance = isInstance && "otherPermittedLocations" in value;
    isInstance = isInstance && "descriptions" in value;
    return isInstance;
}
function FloatingConditionsFromJSON(json) {
    return FloatingConditionsFromJSONTyped(json, false);
}
function FloatingConditionsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, FloatingConditionsType_1.FloatingConditionsTypeFromJSON)(json['type']),
        'fareName': json['fareName'],
        'travelClasses': (json['travelClasses'].map(TravelClass_1.TravelClassFromJSON)),
        'origin': json['origin'],
        'destination': json['destination'],
        'otherPermittedLocations': (0, OtherPermittedLocations_1.OtherPermittedLocationsFromJSON)(json['otherPermittedLocations']),
        'summary': !(0, runtime_1.exists)(json, 'summary') ? undefined : json['summary'],
        'descriptions': (json['descriptions'].map(ConditionDescription_1.ConditionDescriptionFromJSON)),
    };
}
function FloatingConditionsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, FloatingConditionsType_1.FloatingConditionsTypeToJSON)(value.type),
        'fareName': value.fareName,
        'travelClasses': (value.travelClasses.map(TravelClass_1.TravelClassToJSON)),
        'origin': value.origin,
        'destination': value.destination,
        'otherPermittedLocations': (0, OtherPermittedLocations_1.OtherPermittedLocationsToJSON)(value.otherPermittedLocations),
        'summary': value.summary,
        'descriptions': (value.descriptions.map(ConditionDescription_1.ConditionDescriptionToJSON)),
    };
}
