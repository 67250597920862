"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfBusTransport = instanceOfBusTransport;
exports.BusTransportFromJSON = BusTransportFromJSON;
exports.BusTransportFromJSONTyped = BusTransportFromJSONTyped;
exports.BusTransportToJSON = BusTransportToJSON;
var runtime_1 = require("../runtime");
var BusDrivetrain_1 = require("./BusDrivetrain");
var BusTransportType_1 = require("./BusTransportType");
var TransitDetails_1 = require("./TransitDetails");
/**
 * Check if a given object implements the BusTransport interface.
 */
function instanceOfBusTransport(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "drivetrain" in value;
    return isInstance;
}
function BusTransportFromJSON(json) {
    return BusTransportFromJSONTyped(json, false);
}
function BusTransportFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, BusTransportType_1.BusTransportTypeFromJSON)(json['type']),
        'drivetrain': (0, BusDrivetrain_1.BusDrivetrainFromJSON)(json['drivetrain']),
        'transitDetails': !(0, runtime_1.exists)(json, 'transitDetails') ? undefined : (0, TransitDetails_1.TransitDetailsFromJSON)(json['transitDetails']),
    };
}
function BusTransportToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, BusTransportType_1.BusTransportTypeToJSON)(value.type),
        'drivetrain': (0, BusDrivetrain_1.BusDrivetrainToJSON)(value.drivetrain),
        'transitDetails': (0, TransitDetails_1.TransitDetailsToJSON)(value.transitDetails),
    };
}
