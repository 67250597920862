"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTrainlineEditableOrganisationDetails = instanceOfTrainlineEditableOrganisationDetails;
exports.TrainlineEditableOrganisationDetailsFromJSON = TrainlineEditableOrganisationDetailsFromJSON;
exports.TrainlineEditableOrganisationDetailsFromJSONTyped = TrainlineEditableOrganisationDetailsFromJSONTyped;
exports.TrainlineEditableOrganisationDetailsToJSON = TrainlineEditableOrganisationDetailsToJSON;
/**
 * Check if a given object implements the TrainlineEditableOrganisationDetails interface.
 */
function instanceOfTrainlineEditableOrganisationDetails(value) {
    var isInstance = true;
    isInstance = isInstance && "enabled" in value;
    isInstance = isInstance && "corporateId" in value;
    isInstance = isInstance && "corporateReference" in value;
    isInstance = isInstance && "clientReference" in value;
    return isInstance;
}
function TrainlineEditableOrganisationDetailsFromJSON(json) {
    return TrainlineEditableOrganisationDetailsFromJSONTyped(json, false);
}
function TrainlineEditableOrganisationDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'enabled': json['enabled'],
        'corporateId': json['corporateId'],
        'corporateReference': json['corporateReference'],
        'clientReference': json['clientReference'],
    };
}
function TrainlineEditableOrganisationDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'enabled': value.enabled,
        'corporateId': value.corporateId,
        'corporateReference': value.corporateReference,
        'clientReference': value.clientReference,
    };
}
