"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfFlightPassengerDetails = instanceOfFlightPassengerDetails;
exports.FlightPassengerDetailsFromJSON = FlightPassengerDetailsFromJSON;
exports.FlightPassengerDetailsFromJSONTyped = FlightPassengerDetailsFromJSONTyped;
exports.FlightPassengerDetailsToJSON = FlightPassengerDetailsToJSON;
var runtime_1 = require("../runtime");
var FlightPassengerPassportDetails_1 = require("./FlightPassengerPassportDetails");
var TravellerGender_1 = require("./TravellerGender");
var TravellerLoyaltyProgramme_1 = require("./TravellerLoyaltyProgramme");
var TravellerTitle_1 = require("./TravellerTitle");
/**
 * Check if a given object implements the FlightPassengerDetails interface.
 */
function instanceOfFlightPassengerDetails(value) {
    var isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "givenName" in value;
    isInstance = isInstance && "familyName" in value;
    isInstance = isInstance && "gender" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "phoneNumber" in value;
    isInstance = isInstance && "loyaltyProgrammes" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "dateOfBirth" in value;
    return isInstance;
}
function FlightPassengerDetailsFromJSON(json) {
    return FlightPassengerDetailsFromJSONTyped(json, false);
}
function FlightPassengerDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'title': (0, TravellerTitle_1.TravellerTitleFromJSON)(json['title']),
        'givenName': json['givenName'],
        'familyName': json['familyName'],
        'gender': (0, TravellerGender_1.TravellerGenderFromJSON)(json['gender']),
        'email': json['email'],
        'phoneNumber': json['phoneNumber'],
        'loyaltyProgrammes': (json['loyaltyProgrammes'].map(TravellerLoyaltyProgramme_1.TravellerLoyaltyProgrammeFromJSON)),
        'id': json['id'],
        'dateOfBirth': json['dateOfBirth'],
        'passportDetails': !(0, runtime_1.exists)(json, 'passportDetails') ? undefined : (0, FlightPassengerPassportDetails_1.FlightPassengerPassportDetailsFromJSON)(json['passportDetails']),
    };
}
function FlightPassengerDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'title': (0, TravellerTitle_1.TravellerTitleToJSON)(value.title),
        'givenName': value.givenName,
        'familyName': value.familyName,
        'gender': (0, TravellerGender_1.TravellerGenderToJSON)(value.gender),
        'email': value.email,
        'phoneNumber': value.phoneNumber,
        'loyaltyProgrammes': (value.loyaltyProgrammes.map(TravellerLoyaltyProgramme_1.TravellerLoyaltyProgrammeToJSON)),
        'id': value.id,
        'dateOfBirth': value.dateOfBirth,
        'passportDetails': (0, FlightPassengerPassportDetails_1.FlightPassengerPassportDetailsToJSON)(value.passportDetails),
    };
}
