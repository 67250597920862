
// NavigationContext.tsx
import React, { ReactNode, createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

export type PageType 
  = "home" 
  | "pricing" 
  | "contactSales";
  
export type NavigateToPage = (page: PageType) => void;

const NavigateToPageContext = createContext<NavigateToPage>(() => {
  throw new Error('Navigation function not implemented');
});

/**
 * Use to navigate to predefined pages.
 */
export const useNavigateToPage = () => {
  const context = useContext(NavigateToPageContext);
  if (context === undefined) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }
  return context;
};

export interface NavigationProviderProps {
  /**
   * @returns the route, e.g. "/pricing" for a given page.
   */
  route: (pageType: PageType) => string
  children: ReactNode
}

/**
 * Components in the tree can make use of `useNavigation` to navigate to 
 * specified pages.
 */
export const NavigateToPageProvider: React.FC<NavigationProviderProps> = (props) => {
  const navigate = useNavigate();
  const navigateToPage: NavigateToPage = (page) => {
    navigate(props.route(page));
  };

  return (
    <NavigateToPageContext.Provider value={navigateToPage}>
      {props.children}
    </NavigateToPageContext.Provider>
  );
};
