"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfLatLng = instanceOfLatLng;
exports.LatLngFromJSON = LatLngFromJSON;
exports.LatLngFromJSONTyped = LatLngFromJSONTyped;
exports.LatLngToJSON = LatLngToJSON;
/**
 * Check if a given object implements the LatLng interface.
 */
function instanceOfLatLng(value) {
    var isInstance = true;
    isInstance = isInstance && "latRad" in value;
    isInstance = isInstance && "lngRad" in value;
    return isInstance;
}
function LatLngFromJSON(json) {
    return LatLngFromJSONTyped(json, false);
}
function LatLngFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'latRad': json['latRad'],
        'lngRad': json['lngRad'],
    };
}
function LatLngToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'latRad': value.latRad,
        'lngRad': value.lngRad,
    };
}
