"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransportFromJSON = TransportFromJSON;
exports.TransportFromJSONTyped = TransportFromJSONTyped;
exports.TransportToJSON = TransportToJSON;
var BikeTransport_1 = require("./BikeTransport");
var BusTransport_1 = require("./BusTransport");
var CarTransport_1 = require("./CarTransport");
var CoachTransport_1 = require("./CoachTransport");
var ElectricScooterTransport_1 = require("./ElectricScooterTransport");
var EurostarTransport_1 = require("./EurostarTransport");
var FerryTransport_1 = require("./FerryTransport");
var FootTransport_1 = require("./FootTransport");
var LightRailTransport_1 = require("./LightRailTransport");
var PlaneTransport_1 = require("./PlaneTransport");
var SubwayTransport_1 = require("./SubwayTransport");
var TaxiTransport_1 = require("./TaxiTransport");
var TrainTransport_1 = require("./TrainTransport");
var TramTransport_1 = require("./TramTransport");
var UnsupportedTransport_1 = require("./UnsupportedTransport");
var VanTransport_1 = require("./VanTransport");
function TransportFromJSON(json) {
    return TransportFromJSONTyped(json, false);
}
function TransportFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'bike':
            return __assign(__assign({}, (0, BikeTransport_1.BikeTransportFromJSONTyped)(json, true)), { type: 'bike' });
        case 'bus':
            return __assign(__assign({}, (0, BusTransport_1.BusTransportFromJSONTyped)(json, true)), { type: 'bus' });
        case 'car':
            return __assign(__assign({}, (0, CarTransport_1.CarTransportFromJSONTyped)(json, true)), { type: 'car' });
        case 'coach':
            return __assign(__assign({}, (0, CoachTransport_1.CoachTransportFromJSONTyped)(json, true)), { type: 'coach' });
        case 'electricScooter':
            return __assign(__assign({}, (0, ElectricScooterTransport_1.ElectricScooterTransportFromJSONTyped)(json, true)), { type: 'electricScooter' });
        case 'eurostar':
            return __assign(__assign({}, (0, EurostarTransport_1.EurostarTransportFromJSONTyped)(json, true)), { type: 'eurostar' });
        case 'ferry':
            return __assign(__assign({}, (0, FerryTransport_1.FerryTransportFromJSONTyped)(json, true)), { type: 'ferry' });
        case 'foot':
            return __assign(__assign({}, (0, FootTransport_1.FootTransportFromJSONTyped)(json, true)), { type: 'foot' });
        case 'lightRail':
            return __assign(__assign({}, (0, LightRailTransport_1.LightRailTransportFromJSONTyped)(json, true)), { type: 'lightRail' });
        case 'plane':
            return __assign(__assign({}, (0, PlaneTransport_1.PlaneTransportFromJSONTyped)(json, true)), { type: 'plane' });
        case 'subway':
            return __assign(__assign({}, (0, SubwayTransport_1.SubwayTransportFromJSONTyped)(json, true)), { type: 'subway' });
        case 'taxi':
            return __assign(__assign({}, (0, TaxiTransport_1.TaxiTransportFromJSONTyped)(json, true)), { type: 'taxi' });
        case 'train':
            return __assign(__assign({}, (0, TrainTransport_1.TrainTransportFromJSONTyped)(json, true)), { type: 'train' });
        case 'tram':
            return __assign(__assign({}, (0, TramTransport_1.TramTransportFromJSONTyped)(json, true)), { type: 'tram' });
        case 'unsupported':
            return __assign(__assign({}, (0, UnsupportedTransport_1.UnsupportedTransportFromJSONTyped)(json, true)), { type: 'unsupported' });
        case 'van':
            return __assign(__assign({}, (0, VanTransport_1.VanTransportFromJSONTyped)(json, true)), { type: 'van' });
        default:
            throw new Error("No variant of Transport exists with 'type=".concat(json['type'], "'"));
    }
}
function TransportToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'bike':
            return (0, BikeTransport_1.BikeTransportToJSON)(value);
        case 'bus':
            return (0, BusTransport_1.BusTransportToJSON)(value);
        case 'car':
            return (0, CarTransport_1.CarTransportToJSON)(value);
        case 'coach':
            return (0, CoachTransport_1.CoachTransportToJSON)(value);
        case 'electricScooter':
            return (0, ElectricScooterTransport_1.ElectricScooterTransportToJSON)(value);
        case 'eurostar':
            return (0, EurostarTransport_1.EurostarTransportToJSON)(value);
        case 'ferry':
            return (0, FerryTransport_1.FerryTransportToJSON)(value);
        case 'foot':
            return (0, FootTransport_1.FootTransportToJSON)(value);
        case 'lightRail':
            return (0, LightRailTransport_1.LightRailTransportToJSON)(value);
        case 'plane':
            return (0, PlaneTransport_1.PlaneTransportToJSON)(value);
        case 'subway':
            return (0, SubwayTransport_1.SubwayTransportToJSON)(value);
        case 'taxi':
            return (0, TaxiTransport_1.TaxiTransportToJSON)(value);
        case 'train':
            return (0, TrainTransport_1.TrainTransportToJSON)(value);
        case 'tram':
            return (0, TramTransport_1.TramTransportToJSON)(value);
        case 'unsupported':
            return (0, UnsupportedTransport_1.UnsupportedTransportToJSON)(value);
        case 'van':
            return (0, VanTransport_1.VanTransportToJSON)(value);
        default:
            throw new Error("No variant of Transport exists with 'type=".concat(value['type'], "'"));
    }
}
