"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfLoyaltyProgrammeResponse = instanceOfLoyaltyProgrammeResponse;
exports.LoyaltyProgrammeResponseFromJSON = LoyaltyProgrammeResponseFromJSON;
exports.LoyaltyProgrammeResponseFromJSONTyped = LoyaltyProgrammeResponseFromJSONTyped;
exports.LoyaltyProgrammeResponseToJSON = LoyaltyProgrammeResponseToJSON;
var LoyaltyProgramme_1 = require("./LoyaltyProgramme");
/**
 * Check if a given object implements the LoyaltyProgrammeResponse interface.
 */
function instanceOfLoyaltyProgrammeResponse(value) {
    var isInstance = true;
    isInstance = isInstance && "programmes" in value;
    return isInstance;
}
function LoyaltyProgrammeResponseFromJSON(json) {
    return LoyaltyProgrammeResponseFromJSONTyped(json, false);
}
function LoyaltyProgrammeResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'programmes': (json['programmes'].map(LoyaltyProgramme_1.LoyaltyProgrammeFromJSON)),
    };
}
function LoyaltyProgrammeResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'programmes': (value.programmes.map(LoyaltyProgramme_1.LoyaltyProgrammeToJSON)),
    };
}
