import { SectionContainer, PaddedSectionItem } from "@routezero-site/component/helper/section_container";
import { SectionMainText } from "@routezero-site/component/helper/section_main_text";
import { DecarbonisationJourneyFeatures } from "@routezero-site/component/page/home/decarbonisation-journey/features";

const textColor = "#17654F";

/**
 * Tells potential customers about how RouteZero provides many decarbonisation
 * tools to help them hit their emissions targets.
 */
export const DecarbonisationJourneySection: React.FC = () => {
  return (
    <SectionContainer
      background='linear-gradient(to bottom right, #F0FBF8, #D4F8ED)'
      color={textColor}
    >
      <SectionMainText
        color={textColor}
        subtitleBackgroundColor="#515151"
        subtitleBackgroundBlendMode="color-burn"
        header="Your decarbonisation journey, all in one tool"
        underlineDescendedCharacter={true}
        subtitle="Unified climate platform"
        body="We understand that real change requires more than just information; it demands a cultural shift. Our platform is engineered to not only inform but actively engage and motivate your team to make sustainable travel choices."
      /> 
      <PaddedSectionItem>
        <DecarbonisationJourneyFeatures/>
      </PaddedSectionItem>
    </SectionContainer>
  );
}