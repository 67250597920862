"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfRefundConditionDescription = instanceOfRefundConditionDescription;
exports.RefundConditionDescriptionFromJSON = RefundConditionDescriptionFromJSON;
exports.RefundConditionDescriptionFromJSONTyped = RefundConditionDescriptionFromJSONTyped;
exports.RefundConditionDescriptionToJSON = RefundConditionDescriptionToJSON;
var RefundConditionDescriptionType_1 = require("./RefundConditionDescriptionType");
var TripActionPermission_1 = require("./TripActionPermission");
/**
 * Check if a given object implements the RefundConditionDescription interface.
 */
function instanceOfRefundConditionDescription(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "permission" in value;
    return isInstance;
}
function RefundConditionDescriptionFromJSON(json) {
    return RefundConditionDescriptionFromJSONTyped(json, false);
}
function RefundConditionDescriptionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, RefundConditionDescriptionType_1.RefundConditionDescriptionTypeFromJSON)(json['type']),
        'permission': (0, TripActionPermission_1.TripActionPermissionFromJSON)(json['permission']),
    };
}
function RefundConditionDescriptionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, RefundConditionDescriptionType_1.RefundConditionDescriptionTypeToJSON)(value.type),
        'permission': (0, TripActionPermission_1.TripActionPermissionToJSON)(value.permission),
    };
}
