import { IsDesktop, IsDesktopOrMobile, IsMobile, IsTablet, IsTabletOrDesktop, IsTabletOrMobile, mediaStyle } from '@routezero-site/component/helper/breakpoint';
import { AnimateScaleOnActive, AnimatedArrowOnActive, HoverIsActiveRecogniser, SlideUpRevealOnActive } from '@routezero-site/component/helper/active_action';
import { ChartLineUpIcon, ChartSimpleSolidIcon, CloudDownArrowSolidIcon, DollarIcon, VideoIcon } from '@routezero-site/component/helper/icon';
import usePromise from '@routezero-site/component/helper/promise';
import { useServicesContext } from '@routezero-site/component/helper/services';
import { CaseStudyDarkColour, CaseStudySectionStatsWidthPx, FadedOpacity, LargeBorderRadiusPx, LargeIconSizePx, LargePaddingPx, SmallPaddingPx } from '@routezero-site/component/helper/theme';
import { VimeoPlayer } from '@routezero-site/component/helper/vimeo_player';
import logicalisLogo from '@routezero-site/component/page/home/case-study/media/logicalis_logo.svg';
import { CaseStudyStatIconType } from '@routezero-site/service/case-study-stats/case _study_stats';
import { ReactNode, useState } from 'react';
import { HideIfInactive } from '@routezero-site/component/helper/hide_if_inactive';

const textColor = '#F2FDFF';

/**
 * Padding for elements placed inside the video container.
 */
const ContentPadding: () => React.CSSProperties = () => {
  return {
    padding: LargePaddingPx(),
    paddingTop: LargePaddingPx(),
    paddingBottom: LargePaddingPx() * 1.5
  };
}

/**
 * Shows case study video which can be played fullscreen when pressed, with 
 * an overview of the stats from the case study on the left hand side.
 */
export const CaseStudyVideo: React.FC = () => {
  return (
    <HoverIsActiveRecogniser>
      <div style={{
        width: '100%',
        height: '100%',
        borderRadius: LargeBorderRadiusPx(),
        overflow: 'clip',
        display: 'flex',
        ...mediaStyle(IsDesktop(), {
          flexDirection: 'row',
        }),
        ...mediaStyle(IsTabletOrMobile(), {
          flexDirection: 'column',
        }),
        ...mediaStyle(IsTabletOrDesktop(), {
          color: textColor,
          backgroundColor: CaseStudyDarkColour(),
        }),
        ...mediaStyle(IsMobile(), {
          color: CaseStudyDarkColour(),
        }),
      }}>
        <div style={{
          ...mediaStyle(IsTabletOrDesktop(), {
            ...ContentPadding(),
          }),
          ...mediaStyle(IsMobile(), {
            paddingBottom: LargePaddingPx()
          }),
          ...mediaStyle(IsDesktop(), {
            width: CaseStudySectionStatsWidthPx(),
          }),
          ...mediaStyle(IsTabletOrMobile(), {
            width: '100%',
          }),
        }}>
          <MainStats/>
        </div>
        <CaseStudyVideoContainer/>
      </div>
    </HoverIsActiveRecogniser>
  );
};

/**
 * Case study video with CTA text overlaid above with a gradient to make it 
 * easier to read the CTA text.
 */
const CaseStudyVideoContainer: React.FC = () => {
  // Whether the video has been played in the past
  const [hasPlayed, setHasPlayed] = useState(false);

  return (
    <div style={{
      position: 'relative',
      width: '100%',
      height: '100%',
      color: textColor,
      overflow: 'hidden',
      borderRadius: LargeBorderRadiusPx(),
    }}>
      {/* Keep video at 100% if playing */}
      <AnimateScaleOnActive
        overrideIsActive={hasPlayed === true ? true : undefined}
      >
        <VimeoPlayer
          uniqueTitle='LogicalisCaseStudyMockup'
          heightPercentageAspectRatio={64}
          videoId='913643945'
          videoIdHParam='ae352f71fe'
          onPlay={() => setHasPlayed(true)}
        />
      </AnimateScaleOnActive>
      <HideIfInactive isActive={!hasPlayed}>
        <img
          src={logicalisLogo} 
          alt='Logcalis logo'
          style={{
            ...ContentPadding(),
            position: 'absolute',
            top: 0, left: 0,
            ...mediaStyle(IsTabletOrDesktop(), {
              width: 220, 
            }),
            ...mediaStyle(IsMobile(), {
              width: 180
            })
          }}
        />
      </HideIfInactive>
      {/* 
      Because the video has it's own text for Bob's name, we don't want to
      show our own CTA text after the video has started.
      */}
      <HideIfInactive isActive={!hasPlayed}>
        <div style={{
          position: 'absolute',
          left: 0, bottom: 0, right: 0,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'end',
          // To make it easier to read the CTA text
          background: `linear-gradient(to top, ${CaseStudyDarkColour()} 10%, transparent)`,
          pointerEvents: 'none'
        }}>
          <div style={{
            ...ContentPadding(),
            // Increase padding on top to extend the linear gradient further up the text.
            paddingTop: LargePaddingPx() * 2,
            ...mediaStyle(IsDesktop(), {
              width: '80%',
            })
          }}>
            <CaseStudyVideoCTA/>
          </div>
        </div>
      </HideIfInactive>
      <HideIfInactive isActive={!hasPlayed}>
        <div style={{
          position: 'absolute',
          top: SmallPaddingPx()*0.5, 
          right: SmallPaddingPx()*0.5,
          opacity: FadedOpacity()
        }}>
          <VideoIcon sizePx={25}/>
        </div>
      </HideIfInactive>
    </div>
  );
};

/**
 * Call to action overlaid over the video placeholder image.
 */
const CaseStudyVideoCTA: React.FC = () => {
  return (
    <SlideUpRevealOnActive gapPx={SmallPaddingPx()}>
      <h4>
        Learn from Bob Bailkoski, Logicalis Group CEO, how they cut emissions and costs
      </h4>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        gap: SmallPaddingPx(),
      }}>
        <p>
          Watch the video
        </p>
        <AnimatedArrowOnActive/>
      </div>
    </SlideUpRevealOnActive>
  );
}

/**
 * Row/column of the main statistics from the case study.
 */
const MainStats: React.FC = () => {
  const { caseStudyStatsService } = useServicesContext();
  const [ data ] = usePromise(caseStudyStatsService.stats);
  const stats = data ?? [];

  return (
    <div style={{
      display: 'flex',
      width: '100%',
      height: '100%',
      ...mediaStyle(IsDesktopOrMobile(), {
        flexDirection: 'column',
        justifyContent: 'space-between'
      }),
      ...mediaStyle(IsTablet(), {
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: LargePaddingPx(),
      }),
    }}>
      {stats.map((stat, i) => 
        <MainStat
          key={i}
          mainNumber={stat.mainStat}
          description={stat.body}
          icon={<StatIcon icon={stat.icon}/>}
        />
      )}
    </div>
  );
};

interface MainStatProps {
  /** E.g. 25% */
  mainNumber: string
  /** E.g. Emission reduction within 6 months */
  description: string
  icon: ReactNode
}

/**
 * A main statistic from the case study, e.g. overall emission reduction %.
 */
const MainStat: React.FC<MainStatProps> = (props) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8
      }}>
        <div style={{
          opacity: FadedOpacity(),
          display: 'flex',
          flexDirection: 'column',  
          justifyContent: 'center'
        }}>
          {props.icon}
        </div>
        <h3>
          {props.mainNumber}
        </h3>
      </div>
      <p style={{
        opacity: FadedOpacity(),
      }}>
        {props.description}
      </p>
    </div>
  );
};

interface StatIconProps {
  icon: CaseStudyStatIconType
}

const StatIcon: React.FC<StatIconProps> = (props) => {
  switch (props.icon)  {
    case "chart": return <ChartSimpleSolidIcon sizePx={LargeIconSizePx()}/>;
    case "cloudDown": return <CloudDownArrowSolidIcon sizePx={LargeIconSizePx()}/>;
    case "dollar": return <DollarIcon sizePx={LargeIconSizePx()}/>;
    case "graphUpwards": return <ChartLineUpIcon sizePx={LargeIconSizePx()}/>;
  }
};