"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfAddMarkup = instanceOfAddMarkup;
exports.AddMarkupFromJSON = AddMarkupFromJSON;
exports.AddMarkupFromJSONTyped = AddMarkupFromJSONTyped;
exports.AddMarkupToJSON = AddMarkupToJSON;
var AddMarkupType_1 = require("./AddMarkupType");
var ValueAdjustment_1 = require("./ValueAdjustment");
/**
 * Check if a given object implements the AddMarkup interface.
 */
function instanceOfAddMarkup(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "markup" in value;
    return isInstance;
}
function AddMarkupFromJSON(json) {
    return AddMarkupFromJSONTyped(json, false);
}
function AddMarkupFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, AddMarkupType_1.AddMarkupTypeFromJSON)(json['type']),
        'markup': (0, ValueAdjustment_1.ValueAdjustmentFromJSON)(json['markup']),
    };
}
function AddMarkupToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, AddMarkupType_1.AddMarkupTypeToJSON)(value.type),
        'markup': (0, ValueAdjustment_1.ValueAdjustmentToJSON)(value.markup),
    };
}
