"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthenticationRequiredTypeEnum = void 0;
exports.instanceOfAuthenticationRequired = instanceOfAuthenticationRequired;
exports.AuthenticationRequiredFromJSON = AuthenticationRequiredFromJSON;
exports.AuthenticationRequiredFromJSONTyped = AuthenticationRequiredFromJSONTyped;
exports.AuthenticationRequiredToJSON = AuthenticationRequiredToJSON;
var OrgId_1 = require("./OrgId");
/**
 * @export
 */
exports.AuthenticationRequiredTypeEnum = {
    Sso: 'sso'
};
/**
 * Check if a given object implements the AuthenticationRequired interface.
 */
function instanceOfAuthenticationRequired(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "orgId" in value;
    return isInstance;
}
function AuthenticationRequiredFromJSON(json) {
    return AuthenticationRequiredFromJSONTyped(json, false);
}
function AuthenticationRequiredFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': json['type'],
        'orgId': (0, OrgId_1.OrgIdFromJSON)(json['orgId']),
    };
}
function AuthenticationRequiredToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': value.type,
        'orgId': (0, OrgId_1.OrgIdToJSON)(value.orgId),
    };
}
