import { ActiveDotBorderSizePx, ActiveDotSizePx, ExtraShortAnimationDurationSec } from "@routezero-site/component/helper/theme";

export interface ActiveDotProps {
  isActive: boolean
}

/**
 * Dot which can be filled (active) or outlined (inactive). Useful for 
 * indicating e.g. current page in pagination controls.
 */
export const ActiveDot: React.FC<ActiveDotProps> = (props) => {
  const sizePx = ActiveDotSizePx();
  const borderSizePx = ActiveDotBorderSizePx();

  return (
    <div style={{
      width: sizePx,
      height: sizePx,
      borderRadius: sizePx * 2,
      backgroundColor: props.isActive ? 'currentcolor' : undefined,
      border: `solid ${borderSizePx}px currentcolor`,
      transition: `background-color ${ExtraShortAnimationDurationSec()}s`
    }}/>
  );
};