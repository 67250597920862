"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfFlightSeatingOptionsBookingInput = instanceOfFlightSeatingOptionsBookingInput;
exports.FlightSeatingOptionsBookingInputFromJSON = FlightSeatingOptionsBookingInputFromJSON;
exports.FlightSeatingOptionsBookingInputFromJSONTyped = FlightSeatingOptionsBookingInputFromJSONTyped;
exports.FlightSeatingOptionsBookingInputToJSON = FlightSeatingOptionsBookingInputToJSON;
var FlightSeatingOptionsBookingInputType_1 = require("./FlightSeatingOptionsBookingInputType");
/**
 * Check if a given object implements the FlightSeatingOptionsBookingInput interface.
 */
function instanceOfFlightSeatingOptionsBookingInput(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "duffelOfferId" in value;
    isInstance = isInstance && "duffelClientKey" in value;
    isInstance = isInstance && "duffelPassengerIds" in value;
    return isInstance;
}
function FlightSeatingOptionsBookingInputFromJSON(json) {
    return FlightSeatingOptionsBookingInputFromJSONTyped(json, false);
}
function FlightSeatingOptionsBookingInputFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, FlightSeatingOptionsBookingInputType_1.FlightSeatingOptionsBookingInputTypeFromJSON)(json['type']),
        'id': json['id'],
        'duffelOfferId': json['duffelOfferId'],
        'duffelClientKey': json['duffelClientKey'],
        'duffelPassengerIds': json['duffelPassengerIds'],
    };
}
function FlightSeatingOptionsBookingInputToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, FlightSeatingOptionsBookingInputType_1.FlightSeatingOptionsBookingInputTypeToJSON)(value.type),
        'id': value.id,
        'duffelOfferId': value.duffelOfferId,
        'duffelClientKey': value.duffelClientKey,
        'duffelPassengerIds': value.duffelPassengerIds,
    };
}
