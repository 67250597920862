"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfNearbyStation = instanceOfNearbyStation;
exports.NearbyStationFromJSON = NearbyStationFromJSON;
exports.NearbyStationFromJSONTyped = NearbyStationFromJSONTyped;
exports.NearbyStationToJSON = NearbyStationToJSON;
var LatLng_1 = require("./LatLng");
/**
 * Check if a given object implements the NearbyStation interface.
 */
function instanceOfNearbyStation(value) {
    var isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "loc" in value;
    return isInstance;
}
function NearbyStationFromJSON(json) {
    return NearbyStationFromJSONTyped(json, false);
}
function NearbyStationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'loc': (0, LatLng_1.LatLngFromJSON)(json['loc']),
    };
}
function NearbyStationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'loc': (0, LatLng_1.LatLngToJSON)(value.loc),
    };
}
