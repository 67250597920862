"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoomStayType = void 0;
exports.RoomStayTypeFromJSON = RoomStayTypeFromJSON;
exports.RoomStayTypeFromJSONTyped = RoomStayTypeFromJSONTyped;
exports.RoomStayTypeToJSON = RoomStayTypeToJSON;
/**
 *
 * @export
 */
exports.RoomStayType = {
    Room: 'room'
};
function RoomStayTypeFromJSON(json) {
    return RoomStayTypeFromJSONTyped(json, false);
}
function RoomStayTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function RoomStayTypeToJSON(value) {
    return value;
}
