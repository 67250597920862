"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PublicSiteType = void 0;
exports.PublicSiteTypeFromJSON = PublicSiteTypeFromJSON;
exports.PublicSiteTypeFromJSONTyped = PublicSiteTypeFromJSONTyped;
exports.PublicSiteTypeToJSON = PublicSiteTypeToJSON;
/**
 *
 * @export
 */
exports.PublicSiteType = {
    PublicSite: 'publicSite'
};
function PublicSiteTypeFromJSON(json) {
    return PublicSiteTypeFromJSONTyped(json, false);
}
function PublicSiteTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function PublicSiteTypeToJSON(value) {
    return value;
}
