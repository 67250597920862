"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfDuffelServiceWithSeatInformation = instanceOfDuffelServiceWithSeatInformation;
exports.DuffelServiceWithSeatInformationFromJSON = DuffelServiceWithSeatInformationFromJSON;
exports.DuffelServiceWithSeatInformationFromJSONTyped = DuffelServiceWithSeatInformationFromJSONTyped;
exports.DuffelServiceWithSeatInformationToJSON = DuffelServiceWithSeatInformationToJSON;
var DuffelServiceWithSeatInformationAllOfServiceInformation_1 = require("./DuffelServiceWithSeatInformationAllOfServiceInformation");
/**
 * Check if a given object implements the DuffelServiceWithSeatInformation interface.
 */
function instanceOfDuffelServiceWithSeatInformation(value) {
    var isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "serviceInformation" in value;
    return isInstance;
}
function DuffelServiceWithSeatInformationFromJSON(json) {
    return DuffelServiceWithSeatInformationFromJSONTyped(json, false);
}
function DuffelServiceWithSeatInformationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'quantity': json['quantity'],
        'serviceInformation': (0, DuffelServiceWithSeatInformationAllOfServiceInformation_1.DuffelServiceWithSeatInformationAllOfServiceInformationFromJSON)(json['serviceInformation']),
    };
}
function DuffelServiceWithSeatInformationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'quantity': value.quantity,
        'serviceInformation': (0, DuffelServiceWithSeatInformationAllOfServiceInformation_1.DuffelServiceWithSeatInformationAllOfServiceInformationToJSON)(value.serviceInformation),
    };
}
