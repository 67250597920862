"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OfferJourneyType = void 0;
exports.OfferJourneyTypeFromJSON = OfferJourneyTypeFromJSON;
exports.OfferJourneyTypeFromJSONTyped = OfferJourneyTypeFromJSONTyped;
exports.OfferJourneyTypeToJSON = OfferJourneyTypeToJSON;
/**
 *
 * @export
 */
exports.OfferJourneyType = {
    Offer: 'offer'
};
function OfferJourneyTypeFromJSON(json) {
    return OfferJourneyTypeFromJSONTyped(json, false);
}
function OfferJourneyTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function OfferJourneyTypeToJSON(value) {
    return value;
}
