"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCarUserVehicle = instanceOfCarUserVehicle;
exports.CarUserVehicleFromJSON = CarUserVehicleFromJSON;
exports.CarUserVehicleFromJSONTyped = CarUserVehicleFromJSONTyped;
exports.CarUserVehicleToJSON = CarUserVehicleToJSON;
var CarDrivetrain_1 = require("./CarDrivetrain");
var CarSize_1 = require("./CarSize");
var CarUserVehicleType_1 = require("./CarUserVehicleType");
/**
 * Check if a given object implements the CarUserVehicle interface.
 */
function instanceOfCarUserVehicle(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "drivetrain" in value;
    isInstance = isInstance && "size" in value;
    return isInstance;
}
function CarUserVehicleFromJSON(json) {
    return CarUserVehicleFromJSONTyped(json, false);
}
function CarUserVehicleFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, CarUserVehicleType_1.CarUserVehicleTypeFromJSON)(json['type']),
        'drivetrain': (0, CarDrivetrain_1.CarDrivetrainFromJSON)(json['drivetrain']),
        'size': (0, CarSize_1.CarSizeFromJSON)(json['size']),
    };
}
function CarUserVehicleToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, CarUserVehicleType_1.CarUserVehicleTypeToJSON)(value.type),
        'drivetrain': (0, CarDrivetrain_1.CarDrivetrainToJSON)(value.drivetrain),
        'size': (0, CarSize_1.CarSizeToJSON)(value.size),
    };
}
