import { useEffect, useState } from "react";

export interface AutoCycleHookArgs {
  /** The total number of items */
  length: number
  /** The time waited before automatically moving onto the next item */
  intervalMs: number
  /** 
   * The time waited before resuming automatic cycling, since the user last 
   * manually changed the index.
   */
  revertToAutocycleWaitMs: number
}

export type AutoCycleHook = [number, (index: number) => void]

/**
 * Cycles through indices up to `length` until an index is manually selected
 * using the function returned.
 */
export const useAutoCycleIndices: (args: AutoCycleHookArgs) => AutoCycleHook = ({length, intervalMs, revertToAutocycleWaitMs: resetIntervalMs}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isManualSelect, setIsManualSelect] = useState(false);

  // Go to the next index, unless an index has been manually selected.
  useEffect(() => {
    function goToNextIndex() {
      setSelectedIndex((prevIndex) => (prevIndex + 1) % length);
    }

    if (isManualSelect) { 
      return; 
    }

    const timer = setInterval(goToNextIndex, intervalMs);
    return () => clearInterval(timer);
    
  }, [selectedIndex, isManualSelect, length, intervalMs]);

  // If the user has not manually changed the index in a while, revert to 
  // automatically cycling through.
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isManualSelect) {
      timeoutId = setTimeout(() => {
        setIsManualSelect(false);
      }, resetIntervalMs);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isManualSelect, resetIntervalMs]);

  function handleSelect(index: number): void {
    setIsManualSelect(true);
    setSelectedIndex(index);
  }

  return [selectedIndex, handleSelect];
};