"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PassengerTicketNumbersTicketsType = void 0;
exports.PassengerTicketNumbersTicketsTypeFromJSON = PassengerTicketNumbersTicketsTypeFromJSON;
exports.PassengerTicketNumbersTicketsTypeFromJSONTyped = PassengerTicketNumbersTicketsTypeFromJSONTyped;
exports.PassengerTicketNumbersTicketsTypeToJSON = PassengerTicketNumbersTicketsTypeToJSON;
/**
 *
 * @export
 */
exports.PassengerTicketNumbersTicketsType = {
    PassengerTicketNumbers: 'passengerTicketNumbers'
};
function PassengerTicketNumbersTicketsTypeFromJSON(json) {
    return PassengerTicketNumbersTicketsTypeFromJSONTyped(json, false);
}
function PassengerTicketNumbersTicketsTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function PassengerTicketNumbersTicketsTypeToJSON(value) {
    return value;
}
