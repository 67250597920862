"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTextualConditionDescription = instanceOfTextualConditionDescription;
exports.TextualConditionDescriptionFromJSON = TextualConditionDescriptionFromJSON;
exports.TextualConditionDescriptionFromJSONTyped = TextualConditionDescriptionFromJSONTyped;
exports.TextualConditionDescriptionToJSON = TextualConditionDescriptionToJSON;
var TextualConditionDescriptionType_1 = require("./TextualConditionDescriptionType");
/**
 * Check if a given object implements the TextualConditionDescription interface.
 */
function instanceOfTextualConditionDescription(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "body" in value;
    return isInstance;
}
function TextualConditionDescriptionFromJSON(json) {
    return TextualConditionDescriptionFromJSONTyped(json, false);
}
function TextualConditionDescriptionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, TextualConditionDescriptionType_1.TextualConditionDescriptionTypeFromJSON)(json['type']),
        'title': json['title'],
        'body': json['body'],
    };
}
function TextualConditionDescriptionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, TextualConditionDescriptionType_1.TextualConditionDescriptionTypeToJSON)(value.type),
        'title': value.title,
        'body': value.body,
    };
}
