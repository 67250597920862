import { TabletOrDesktop, Mobile } from "@routezero-site/component/helper/breakpoint";
import { LogicalisLogo, RabLogo, CambridgeLogo, UkGovLogo, UkkaLogo, NhsLogo } from "@routezero-site/component/helper/logo";
import { LargePaddingPx, MedPaddingPx } from "@routezero-site/component/helper/theme";
import { Fragment, ReactNode } from "react";

/** 
 * Grid of customer logos for social proof. On desktop or tablet, shows a row
 * of logos. On mobile, shows a vertical grid.
 */
export const CustomerLogosGrid: React.FC = () => {
  return (
    <Fragment>
      <TabletOrDesktop>
        <RowSocialProofCustomerLogos/>
      </TabletOrDesktop>
      <Mobile>
        <TwoColumnsSocialProofCustomerLogos/>
      </Mobile>
    </Fragment>
  );
};

/**
 * Customer logos arranged in a row.
 */
const RowSocialProofCustomerLogos: React.FC = () => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: LargePaddingPx()
    }}>
      <LogicalisLogo/>
      <NhsLogo/>
      <CambridgeLogo/>
      <UkGovLogo/>
      <UkkaLogo/>
      <RabLogo/>
    </div>
  );
};

/**
 * Customer logos arranged in two columns. Suitable for smaller screen sizes.
 */
const TwoColumnsSocialProofCustomerLogos: React.FC = () => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'stretch',
      gap: MedPaddingPx()
    }}>
      <SocialProofCustomerLogosColumn>
        <LogicalisLogo/>
        <CambridgeLogo/>
      </SocialProofCustomerLogosColumn>
      <SocialProofCustomerLogosColumn>
        <RabLogo/>
        <UkGovLogo/>
      </SocialProofCustomerLogosColumn>
    </div>
  );
};

interface SocialProofCustomerLogosColumnProps {
  children: ReactNode
}

/**
 * Single column as part of two columns of customer logos.
 */
const SocialProofCustomerLogosColumn: React.FC<SocialProofCustomerLogosColumnProps> = (props) => {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1, flexBasis: 0,
      flexDirection: 'column',
      gap: MedPaddingPx()
    }}>
      {props.children}
    </div>
  );
};