"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfFormattedDate = instanceOfFormattedDate;
exports.FormattedDateFromJSON = FormattedDateFromJSON;
exports.FormattedDateFromJSONTyped = FormattedDateFromJSONTyped;
exports.FormattedDateToJSON = FormattedDateToJSON;
/**
 * Check if a given object implements the FormattedDate interface.
 */
function instanceOfFormattedDate(value) {
    var isInstance = true;
    isInstance = isInstance && "utc" in value;
    isInstance = isInstance && "usedWrapperMethodToConstruct" in value;
    return isInstance;
}
function FormattedDateFromJSON(json) {
    return FormattedDateFromJSONTyped(json, false);
}
function FormattedDateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'utc': (new Date(json['utc'])),
        'usedWrapperMethodToConstruct': json['__usedWrapperMethodToConstruct'],
    };
}
function FormattedDateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'utc': (value.utc.toISOString()),
        '__usedWrapperMethodToConstruct': value.usedWrapperMethodToConstruct,
    };
}
