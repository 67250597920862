import { HoverIsActiveRecogniser } from "@routezero-site/component/helper/active_action";
import { BackgroundImageContainer } from "@routezero-site/component/helper/background_image";
import { IsMobile, IsTabletOrDesktop, Mobile, TabletOrDesktop, mediaStyle } from "@routezero-site/component/helper/breakpoint";
import { ArrowButton, ContactSalesPillButton } from "@routezero-site/component/helper/button";
import { CustomerLogosGrid } from "@routezero-site/component/helper/customer_logos_grid";
import { RZRoutePlannerLink } from "@routezero-site/component/helper/links";
import { NavBar } from "@routezero-site/component/helper/nav_bar";
import { PaddedSectionItem, SectionContainer } from "@routezero-site/component/helper/section_container";
import { LargePaddingPx, MedPaddingPx, NavBarHeight, SectionItemGapPx } from "@routezero-site/component/helper/theme";
import backgroundImage from "@routezero-site/component/page/home/hero/media/background.jpg";
import backgroundMask from "@routezero-site/component/page/home/hero/media/background_mask.svg";
import heroImage from "@routezero-site/component/page/home/hero/media/hero_image.png";
import React from "react";

const textColor = "#32373E";

/**
 * First section the user will see when the go to the website.
 */
export const HeroSection: React.FC = () => {
  return (
    <BackgroundImageContainer
      backgroundImage={backgroundImage}
      backgroundMask={backgroundMask}
      // Proportion of the viewport to ensure the size of the image doesn't 
      // change when swapping from fixed to absolute.
      height={'62vh'}
      imageAtTop={true}
    >
      <NavBar 
        color="white"
        contactSalesButtonColor={textColor}
        contactSalesButtonBackgroundColor="white"
      />
      <SectionContainer 
        color={textColor}
        padVertical={false}
        padChildren={false}
        height={`calc(100vh + ${SectionItemGapPx()}px)`}
        {...mediaStyle(IsTabletOrDesktop(), {
          minHeight: 700,
          maxHeight: 950
        })}
      >
        <div style={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'row',
          gap: MedPaddingPx(),
          // Space to nav bar
          ...mediaStyle(IsTabletOrDesktop(), {
            marginTop: NavBarHeight() + LargePaddingPx(),
            marginBottom: LargePaddingPx()
          }),
          ...mediaStyle(IsMobile(), {
            marginTop: NavBarHeight() + SectionItemGapPx()
          }),
          height: '0%',
        }}>
          <HeroTextAndCTA/>
          <TabletOrDesktop>
            <div style={{
              // Expand half and half for text and image
              flexGrow: 1,
            }}>
              <HeroImage/>
            </div>
          </TabletOrDesktop>
        </div>
        <Mobile>
          <div style={{height: SectionItemGapPx()}}/>
        </Mobile>
        <PaddedSectionItem>
          <CustomerLogosGrid/>
        </PaddedSectionItem>
        <div style={{height: SectionItemGapPx()}}/>
      </SectionContainer>
    </BackgroundImageContainer>
  );
};

/**
 * Hero text and call to action.
 */
const HeroTextAndCTA: React.FC = () => {
  return (
    <div style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: LargePaddingPx(),
    }}>
      <div style={{
        ...mediaStyle(IsTabletOrDesktop(), {
          padding: `0px ${LargePaddingPx()}px`
        })
      }}>
        <HeroText/>
      </div>
      <div style={{
        ...mediaStyle(IsTabletOrDesktop(), {
          padding: `0px ${MedPaddingPx()}px`
        })
      }}>
        <CallToAction/>
      </div>
    </div>
  );
};

/**
 * Header text and subtitle body text. The body text will be the same width as 
 * the title.
 */
const HeroText: React.FC = () => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      // To ensure children use their intrinsic size, not stretching to the 
      // size of the parent.
      alignItems: 'start',
      gap: LargePaddingPx(),
    }}>
      <HeroHeader/> 
      <p style={{
        width: 'min-content',
        minWidth: '100%'
      }}>
      Companies and Governments use RouteZero to decarbonise business travel, reduce their emissions, take low-carbon journeys, institutionalise sustainable behaviour, and ultimately travel more to grow their business.
      </p>
    </div>
  );
};

/**
 * Header text with a small underline. On desktop/tablet this has a fixed font 
 * size. On mobile, the font size adjusts to the width of the container.
 */
const HeroHeader: React.FC = () => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      // To ensure children use their intrinsic size, not stretching to the 
      // size of the parent.
      alignItems: 'start',
    }}>
      <h1 style={{
        ...mediaStyle(IsMobile(), {
          fontSize: '9vw'
        })
      }}>
        Decarbonisation.<br/>Delivered.
      </h1>
      <div style={{
        backgroundColor: textColor,
        ...mediaStyle(IsTabletOrDesktop(), {
          width: 39,
          height: 9.5,
          marginTop: 4,
          marginLeft: 3,
        }),
        ...mediaStyle(IsMobile(), {
          width: '5.6vw',
          height: '1.5vw',
          marginTop: '0.5vw',
          marginLeft: '0.5vw',
        })
      }}/>
    </div>
  );
};

/**
 * Buttons directing the user to "start now", that takes them to the public site
 * or to "contact sales" which directs them to a form to book a demo.
 */
const CallToAction: React.FC = () => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: "center",
      gap: LargePaddingPx()
    }}>
      <HoverIsActiveRecogniser style={{color: 'white'}}>
        <ContactSalesPillButton backgroundColor={textColor}/>
      </HoverIsActiveRecogniser>
      <RZRoutePlannerLink>
        <HoverIsActiveRecogniser>
          <ArrowButton text="Plan a route"/>
        </HoverIsActiveRecogniser>
      </RZRoutePlannerLink>
    </div>
  );
};

/**
 * Hero image.
 */
const HeroImage: React.FC = () => {
  return (
    <img 
      src={heroImage}
      alt="RouteZero on mobile and desktop"
      style={{
        height: '100%',
        // Offset to account for extra image width due to drop shadow in the 
        // image. The drop shadow is not added after, so the phone has a 
        // shadow on top of the desktop screenshot.
        transform: 'scale(123.5%) translateX(3%)'
      }}
    />
  );
};