"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfRecordProblemRequest = instanceOfRecordProblemRequest;
exports.RecordProblemRequestFromJSON = RecordProblemRequestFromJSON;
exports.RecordProblemRequestFromJSONTyped = RecordProblemRequestFromJSONTyped;
exports.RecordProblemRequestToJSON = RecordProblemRequestToJSON;
/**
 * Check if a given object implements the RecordProblemRequest interface.
 */
function instanceOfRecordProblemRequest(value) {
    var isInstance = true;
    isInstance = isInstance && "problem" in value;
    return isInstance;
}
function RecordProblemRequestFromJSON(json) {
    return RecordProblemRequestFromJSONTyped(json, false);
}
function RecordProblemRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'problem': json['problem'],
    };
}
function RecordProblemRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'problem': value.problem,
    };
}
