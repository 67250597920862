"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfSpecificInwardDateTime = instanceOfSpecificInwardDateTime;
exports.SpecificInwardDateTimeFromJSON = SpecificInwardDateTimeFromJSON;
exports.SpecificInwardDateTimeFromJSONTyped = SpecificInwardDateTimeFromJSONTyped;
exports.SpecificInwardDateTimeToJSON = SpecificInwardDateTimeToJSON;
var SearchDateTime_1 = require("./SearchDateTime");
var SpecificInwardDateTimeType_1 = require("./SpecificInwardDateTimeType");
/**
 * Check if a given object implements the SpecificInwardDateTime interface.
 */
function instanceOfSpecificInwardDateTime(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "dt" in value;
    return isInstance;
}
function SpecificInwardDateTimeFromJSON(json) {
    return SpecificInwardDateTimeFromJSONTyped(json, false);
}
function SpecificInwardDateTimeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, SpecificInwardDateTimeType_1.SpecificInwardDateTimeTypeFromJSON)(json['type']),
        'dt': (0, SearchDateTime_1.SearchDateTimeFromJSON)(json['dt']),
    };
}
function SpecificInwardDateTimeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, SpecificInwardDateTimeType_1.SpecificInwardDateTimeTypeToJSON)(value.type),
        'dt': (0, SearchDateTime_1.SearchDateTimeToJSON)(value.dt),
    };
}
