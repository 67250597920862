"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NationalRailLegalNoticeType = void 0;
exports.NationalRailLegalNoticeTypeFromJSON = NationalRailLegalNoticeTypeFromJSON;
exports.NationalRailLegalNoticeTypeFromJSONTyped = NationalRailLegalNoticeTypeFromJSONTyped;
exports.NationalRailLegalNoticeTypeToJSON = NationalRailLegalNoticeTypeToJSON;
/**
 *
 * @export
 */
exports.NationalRailLegalNoticeType = {
    NationalRail: 'nationalRail'
};
function NationalRailLegalNoticeTypeFromJSON(json) {
    return NationalRailLegalNoticeTypeFromJSONTyped(json, false);
}
function NationalRailLegalNoticeTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function NationalRailLegalNoticeTypeToJSON(value) {
    return value;
}
