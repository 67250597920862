"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfJourneyFare = instanceOfJourneyFare;
exports.JourneyFareFromJSON = JourneyFareFromJSON;
exports.JourneyFareFromJSONTyped = JourneyFareFromJSONTyped;
exports.JourneyFareToJSON = JourneyFareToJSON;
var JourneyDirection_1 = require("./JourneyDirection");
var TravelClass_1 = require("./TravelClass");
/**
 * Check if a given object implements the JourneyFare interface.
 */
function instanceOfJourneyFare(value) {
    var isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "directions" in value;
    isInstance = isInstance && "travelClasses" in value;
    return isInstance;
}
function JourneyFareFromJSON(json) {
    return JourneyFareFromJSONTyped(json, false);
}
function JourneyFareFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'directions': (json['directions'].map(JourneyDirection_1.JourneyDirectionFromJSON)),
        'travelClasses': (json['travelClasses'].map(TravelClass_1.TravelClassFromJSON)),
    };
}
function JourneyFareToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'directions': (value.directions.map(JourneyDirection_1.JourneyDirectionToJSON)),
        'travelClasses': (value.travelClasses.map(TravelClass_1.TravelClassToJSON)),
    };
}
