"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCollectionRefTicket = instanceOfCollectionRefTicket;
exports.CollectionRefTicketFromJSON = CollectionRefTicketFromJSON;
exports.CollectionRefTicketFromJSONTyped = CollectionRefTicketFromJSONTyped;
exports.CollectionRefTicketToJSON = CollectionRefTicketToJSON;
var CollectionRefTicketType_1 = require("./CollectionRefTicketType");
/**
 * Check if a given object implements the CollectionRefTicket interface.
 */
function instanceOfCollectionRefTicket(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "reference" in value;
    return isInstance;
}
function CollectionRefTicketFromJSON(json) {
    return CollectionRefTicketFromJSONTyped(json, false);
}
function CollectionRefTicketFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, CollectionRefTicketType_1.CollectionRefTicketTypeFromJSON)(json['type']),
        'reference': json['reference'],
    };
}
function CollectionRefTicketToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, CollectionRefTicketType_1.CollectionRefTicketTypeToJSON)(value.type),
        'reference': value.reference,
    };
}
