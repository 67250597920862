"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfOrganisationBackendDetails = instanceOfOrganisationBackendDetails;
exports.OrganisationBackendDetailsFromJSON = OrganisationBackendDetailsFromJSON;
exports.OrganisationBackendDetailsFromJSONTyped = OrganisationBackendDetailsFromJSONTyped;
exports.OrganisationBackendDetailsToJSON = OrganisationBackendDetailsToJSON;
var RegisteredAddress_1 = require("./RegisteredAddress");
/**
 * Check if a given object implements the OrganisationBackendDetails interface.
 */
function instanceOfOrganisationBackendDetails(value) {
    var isInstance = true;
    isInstance = isInstance && "readableReference" in value;
    isInstance = isInstance && "registeredName" in value;
    isInstance = isInstance && "urlName" in value;
    isInstance = isInstance && "address" in value;
    return isInstance;
}
function OrganisationBackendDetailsFromJSON(json) {
    return OrganisationBackendDetailsFromJSONTyped(json, false);
}
function OrganisationBackendDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'readableReference': json['readableReference'],
        'registeredName': json['registeredName'],
        'urlName': json['urlName'],
        'address': (0, RegisteredAddress_1.RegisteredAddressFromJSON)(json['address']),
    };
}
function OrganisationBackendDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'readableReference': value.readableReference,
        'registeredName': value.registeredName,
        'urlName': value.urlName,
        'address': (0, RegisteredAddress_1.RegisteredAddressToJSON)(value.address),
    };
}
