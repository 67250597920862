"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfPremiumEconomyTravelClass = instanceOfPremiumEconomyTravelClass;
exports.PremiumEconomyTravelClassFromJSON = PremiumEconomyTravelClassFromJSON;
exports.PremiumEconomyTravelClassFromJSONTyped = PremiumEconomyTravelClassFromJSONTyped;
exports.PremiumEconomyTravelClassToJSON = PremiumEconomyTravelClassToJSON;
var PremiumEconomyTravelClassType_1 = require("./PremiumEconomyTravelClassType");
/**
 * Check if a given object implements the PremiumEconomyTravelClass interface.
 */
function instanceOfPremiumEconomyTravelClass(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
function PremiumEconomyTravelClassFromJSON(json) {
    return PremiumEconomyTravelClassFromJSONTyped(json, false);
}
function PremiumEconomyTravelClassFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, PremiumEconomyTravelClassType_1.PremiumEconomyTravelClassTypeFromJSON)(json['type']),
    };
}
function PremiumEconomyTravelClassToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, PremiumEconomyTravelClassType_1.PremiumEconomyTravelClassTypeToJSON)(value.type),
    };
}
