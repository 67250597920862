import { IsMobile, IsTablet, IsDesktop } from "@routezero-site/component/helper/breakpoint";

/**
 * When a smaller version of the body text is required.
 */
export const smallFontClassName = 'small-font';

const h1ScaleVsBase = 1.1;
const h2ScaleVsBase = 2.6 / 3.5;
const h3ScaleVsBase = 1.7 / 3.5;
const h4ScaleVsBase = 1.5 / 3.5;

function style(args: { base: number, bodyRem: number, smallRem: number, inputLabelRem: number }): string {
  return `
    body { font-size: ${args.bodyRem}rem; }
    h1 { font-size: ${args.base * h1ScaleVsBase}rem; }
    h2 { font-size: ${args.base * h2ScaleVsBase}rem; }
    h3 { font-size: ${args.base * h3ScaleVsBase}rem; }
    h4 { font-size: ${args.base * h4ScaleVsBase}rem; }
    .${smallFontClassName} { font-size: ${args.smallRem}rem; }
    label { font-size: ${args.inputLabelRem}rem; }
  `;
}

export const DynamicGlobalStyle = () => {
  const isMobile = IsMobile();
  const isTablet = IsTablet();
  const isDesktop = IsDesktop();

  let globalStyle = '';
  if (isMobile) {
    globalStyle = style({ base: 3.0, bodyRem: 0.9, smallRem: 0.8, inputLabelRem: 0.7 });
  } else if (isTablet) {
    globalStyle = style({ base: 3.5, bodyRem: 0.9, smallRem: 0.8, inputLabelRem: 0.7 });
  } else if (isDesktop) {
    globalStyle = style({ base: 3.5, bodyRem: 0.9, smallRem: 0.8, inputLabelRem: 0.7 });
  }

  return <style>{globalStyle}</style>;
};

