"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfRoomStay = instanceOfRoomStay;
exports.RoomStayFromJSON = RoomStayFromJSON;
exports.RoomStayFromJSONTyped = RoomStayFromJSONTyped;
exports.RoomStayToJSON = RoomStayToJSON;
var RoomData_1 = require("./RoomData");
var RoomStayType_1 = require("./RoomStayType");
var Stay_1 = require("./Stay");
/**
 * Check if a given object implements the RoomStay interface.
 */
function instanceOfRoomStay(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "roomData" in value;
    isInstance = isInstance && "stay" in value;
    return isInstance;
}
function RoomStayFromJSON(json) {
    return RoomStayFromJSONTyped(json, false);
}
function RoomStayFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, RoomStayType_1.RoomStayTypeFromJSON)(json['type']),
        'roomData': (0, RoomData_1.RoomDataFromJSON)(json['roomData']),
        'stay': (0, Stay_1.StayFromJSON)(json['stay']),
    };
}
function RoomStayToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, RoomStayType_1.RoomStayTypeToJSON)(value.type),
        'roomData': (0, RoomData_1.RoomDataToJSON)(value.roomData),
        'stay': (0, Stay_1.StayToJSON)(value.stay),
    };
}
