"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfAndroidAppExternalLink = instanceOfAndroidAppExternalLink;
exports.AndroidAppExternalLinkFromJSON = AndroidAppExternalLinkFromJSON;
exports.AndroidAppExternalLinkFromJSONTyped = AndroidAppExternalLinkFromJSONTyped;
exports.AndroidAppExternalLinkToJSON = AndroidAppExternalLinkToJSON;
var AndroidAppExternalLinkType_1 = require("./AndroidAppExternalLinkType");
/**
 * Check if a given object implements the AndroidAppExternalLink interface.
 */
function instanceOfAndroidAppExternalLink(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "host" in value;
    isInstance = isInstance && "scheme" in value;
    isInstance = isInstance && "appPackage" in value;
    return isInstance;
}
function AndroidAppExternalLinkFromJSON(json) {
    return AndroidAppExternalLinkFromJSONTyped(json, false);
}
function AndroidAppExternalLinkFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, AndroidAppExternalLinkType_1.AndroidAppExternalLinkTypeFromJSON)(json['type']),
        'host': json['host'],
        'scheme': json['scheme'],
        'appPackage': json['appPackage'],
    };
}
function AndroidAppExternalLinkToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, AndroidAppExternalLinkType_1.AndroidAppExternalLinkTypeToJSON)(value.type),
        'host': value.host,
        'scheme': value.scheme,
        'appPackage': value.appPackage,
    };
}
