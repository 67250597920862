"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExternalLoginTicketType = void 0;
exports.ExternalLoginTicketTypeFromJSON = ExternalLoginTicketTypeFromJSON;
exports.ExternalLoginTicketTypeFromJSONTyped = ExternalLoginTicketTypeFromJSONTyped;
exports.ExternalLoginTicketTypeToJSON = ExternalLoginTicketTypeToJSON;
/**
 *
 * @export
 */
exports.ExternalLoginTicketType = {
    ExternalLogin: 'externalLogin'
};
function ExternalLoginTicketTypeFromJSON(json) {
    return ExternalLoginTicketTypeFromJSONTyped(json, false);
}
function ExternalLoginTicketTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function ExternalLoginTicketTypeToJSON(value) {
    return value;
}
