"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AmenitiesStayType = void 0;
exports.AmenitiesStayTypeFromJSON = AmenitiesStayTypeFromJSON;
exports.AmenitiesStayTypeFromJSONTyped = AmenitiesStayTypeFromJSONTyped;
exports.AmenitiesStayTypeToJSON = AmenitiesStayTypeToJSON;
/**
 *
 * @export
 */
exports.AmenitiesStayType = {
    Amenities: 'amenities'
};
function AmenitiesStayTypeFromJSON(json) {
    return AmenitiesStayTypeFromJSONTyped(json, false);
}
function AmenitiesStayTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function AmenitiesStayTypeToJSON(value) {
    return value;
}
