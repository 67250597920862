"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ManyValueAdjustmentType = void 0;
exports.ManyValueAdjustmentTypeFromJSON = ManyValueAdjustmentTypeFromJSON;
exports.ManyValueAdjustmentTypeFromJSONTyped = ManyValueAdjustmentTypeFromJSONTyped;
exports.ManyValueAdjustmentTypeToJSON = ManyValueAdjustmentTypeToJSON;
/**
 *
 * @export
 */
exports.ManyValueAdjustmentType = {
    Many: 'many'
};
function ManyValueAdjustmentTypeFromJSON(json) {
    return ManyValueAdjustmentTypeFromJSONTyped(json, false);
}
function ManyValueAdjustmentTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function ManyValueAdjustmentTypeToJSON(value) {
    return value;
}
