import App from '@routezero-site/app';
import reportWebVitals from '@routezero-site/reportWebVitals';
import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Fathom from 'fathom-client';
import { matchEnv } from '@routezero-site/helper/environment';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

window.addEventListener('load', () => {
  Fathom.setSite(matchEnv({
    local: () => 'QRUDICWM',
    dev: () => 'QRUDICWM',
    prod: () => 'RXNMJODR'
  }));
});