"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Co2eStayType = void 0;
exports.Co2eStayTypeFromJSON = Co2eStayTypeFromJSON;
exports.Co2eStayTypeFromJSONTyped = Co2eStayTypeFromJSONTyped;
exports.Co2eStayTypeToJSON = Co2eStayTypeToJSON;
/**
 *
 * @export
 */
exports.Co2eStayType = {
    Co2e: 'co2e'
};
function Co2eStayTypeFromJSON(json) {
    return Co2eStayTypeFromJSONTyped(json, false);
}
function Co2eStayTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function Co2eStayTypeToJSON(value) {
    return value;
}
