"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfBookingReturnSpecficTimeJourney = instanceOfBookingReturnSpecficTimeJourney;
exports.BookingReturnSpecficTimeJourneyFromJSON = BookingReturnSpecficTimeJourneyFromJSON;
exports.BookingReturnSpecficTimeJourneyFromJSONTyped = BookingReturnSpecficTimeJourneyFromJSONTyped;
exports.BookingReturnSpecficTimeJourneyToJSON = BookingReturnSpecficTimeJourneyToJSON;
var BookingJourney_1 = require("./BookingJourney");
var BookingReturnSpecficTimeJourneyType_1 = require("./BookingReturnSpecficTimeJourneyType");
/**
 * Check if a given object implements the BookingReturnSpecficTimeJourney interface.
 */
function instanceOfBookingReturnSpecficTimeJourney(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "journey" in value;
    return isInstance;
}
function BookingReturnSpecficTimeJourneyFromJSON(json) {
    return BookingReturnSpecficTimeJourneyFromJSONTyped(json, false);
}
function BookingReturnSpecficTimeJourneyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, BookingReturnSpecficTimeJourneyType_1.BookingReturnSpecficTimeJourneyTypeFromJSON)(json['type']),
        'journey': (0, BookingJourney_1.BookingJourneyFromJSON)(json['journey']),
    };
}
function BookingReturnSpecficTimeJourneyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, BookingReturnSpecficTimeJourneyType_1.BookingReturnSpecficTimeJourneyTypeToJSON)(value.type),
        'journey': (0, BookingJourney_1.BookingJourneyToJSON)(value.journey),
    };
}
