"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCountriesResponse = instanceOfCountriesResponse;
exports.CountriesResponseFromJSON = CountriesResponseFromJSON;
exports.CountriesResponseFromJSONTyped = CountriesResponseFromJSONTyped;
exports.CountriesResponseToJSON = CountriesResponseToJSON;
var Country_1 = require("./Country");
/**
 * Check if a given object implements the CountriesResponse interface.
 */
function instanceOfCountriesResponse(value) {
    var isInstance = true;
    isInstance = isInstance && "countries" in value;
    return isInstance;
}
function CountriesResponseFromJSON(json) {
    return CountriesResponseFromJSONTyped(json, false);
}
function CountriesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'countries': (json['countries'].map(Country_1.CountryFromJSON)),
    };
}
function CountriesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'countries': (value.countries.map(Country_1.CountryToJSON)),
    };
}
