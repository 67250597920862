"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTrainlineDiscountCard = instanceOfTrainlineDiscountCard;
exports.TrainlineDiscountCardFromJSON = TrainlineDiscountCardFromJSON;
exports.TrainlineDiscountCardFromJSONTyped = TrainlineDiscountCardFromJSONTyped;
exports.TrainlineDiscountCardToJSON = TrainlineDiscountCardToJSON;
var TrainlineDiscountCardType_1 = require("./TrainlineDiscountCardType");
/**
 * Check if a given object implements the TrainlineDiscountCard interface.
 */
function instanceOfTrainlineDiscountCard(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "href" in value;
    return isInstance;
}
function TrainlineDiscountCardFromJSON(json) {
    return TrainlineDiscountCardFromJSONTyped(json, false);
}
function TrainlineDiscountCardFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, TrainlineDiscountCardType_1.TrainlineDiscountCardTypeFromJSON)(json['type']),
        'name': json['name'],
        'href': json['href'],
    };
}
function TrainlineDiscountCardToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, TrainlineDiscountCardType_1.TrainlineDiscountCardTypeToJSON)(value.type),
        'name': value.name,
        'href': value.href,
    };
}
