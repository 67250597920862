"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfChosenTrainlineAlternatives = instanceOfChosenTrainlineAlternatives;
exports.ChosenTrainlineAlternativesFromJSON = ChosenTrainlineAlternativesFromJSON;
exports.ChosenTrainlineAlternativesFromJSONTyped = ChosenTrainlineAlternativesFromJSONTyped;
exports.ChosenTrainlineAlternativesToJSON = ChosenTrainlineAlternativesToJSON;
/**
 * Check if a given object implements the ChosenTrainlineAlternatives interface.
 */
function instanceOfChosenTrainlineAlternatives(value) {
    var isInstance = true;
    isInstance = isInstance && "journeyId" in value;
    isInstance = isInstance && "alternativeIds" in value;
    return isInstance;
}
function ChosenTrainlineAlternativesFromJSON(json) {
    return ChosenTrainlineAlternativesFromJSONTyped(json, false);
}
function ChosenTrainlineAlternativesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'journeyId': json['journeyId'],
        'alternativeIds': json['alternativeIds'],
    };
}
function ChosenTrainlineAlternativesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'journeyId': value.journeyId,
        'alternativeIds': value.alternativeIds,
    };
}
