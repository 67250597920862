import { NavigateToPageProvider, PageType } from '@routezero-site/component/helper/navigation';
import { ContactSalesPage } from '@routezero-site/component/page/contact-sales/contact_sales_page';
import { PricingPage } from '@routezero-site/component/page/pricing/pricing_page';
import { ReactNode, useEffect } from 'react';
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import './app.css';
import { DynamicGlobalStyle } from './component/helper/global_style';
import { HomePage } from './component/page/home/home_page';
import ScrollToTop from '@routezero-site/component/helper/scroll_to_top';
import * as Fathom from 'fathom-client';

function route(page: PageType): string {
  switch (page) {
    case "home": return "/";
    case "pricing": return "/pricing";
    case "contactSales": return "/demo";
  }
}

export default function App() {
  return (
    <>
      <DynamicGlobalStyle/>
      <RouterProvider router={createBrowserRouter([
        {
          path: route("home"),
          element: <Page><HomePage/></Page>
        },
        {
          path: route("pricing"),
          element: <Page><PricingPage/></Page>
        },
        {
          path: route("contactSales"),
          element: <Page><ContactSalesPage/></Page>
        }
      ])}/>      
    </>
  );
}

interface PageProps {
  children: ReactNode
}

const Page: React.FC<PageProps> = (props) => {
  useEffect(() => {
    Fathom.trackPageview();
  });
  return (
    <>
      <ScrollToTop/>
      <NavigateToPageProvider route={route}>
        {props.children}
      </NavigateToPageProvider>
    </>
  )
};