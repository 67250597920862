"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfOfferJourney = instanceOfOfferJourney;
exports.OfferJourneyFromJSON = OfferJourneyFromJSON;
exports.OfferJourneyFromJSONTyped = OfferJourneyFromJSONTyped;
exports.OfferJourneyToJSON = OfferJourneyToJSON;
var Journey_1 = require("./Journey");
var JourneyOfferData_1 = require("./JourneyOfferData");
var OfferJourneyType_1 = require("./OfferJourneyType");
/**
 * Check if a given object implements the OfferJourney interface.
 */
function instanceOfOfferJourney(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "offerData" in value;
    isInstance = isInstance && "journey" in value;
    return isInstance;
}
function OfferJourneyFromJSON(json) {
    return OfferJourneyFromJSONTyped(json, false);
}
function OfferJourneyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, OfferJourneyType_1.OfferJourneyTypeFromJSON)(json['type']),
        'id': json['id'],
        'offerData': (0, JourneyOfferData_1.JourneyOfferDataFromJSON)(json['offerData']),
        'journey': (0, Journey_1.JourneyFromJSON)(json['journey']),
    };
}
function OfferJourneyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, OfferJourneyType_1.OfferJourneyTypeToJSON)(value.type),
        'id': value.id,
        'offerData': (0, JourneyOfferData_1.JourneyOfferDataToJSON)(value.offerData),
        'journey': (0, Journey_1.JourneyToJSON)(value.journey),
    };
}
