"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfStayFeeLineItem = instanceOfStayFeeLineItem;
exports.StayFeeLineItemFromJSON = StayFeeLineItemFromJSON;
exports.StayFeeLineItemFromJSONTyped = StayFeeLineItemFromJSONTyped;
exports.StayFeeLineItemToJSON = StayFeeLineItemToJSON;
var Price_1 = require("./Price");
var StayFeeLineItemType_1 = require("./StayFeeLineItemType");
/**
 * Check if a given object implements the StayFeeLineItem interface.
 */
function instanceOfStayFeeLineItem(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "price" in value;
    return isInstance;
}
function StayFeeLineItemFromJSON(json) {
    return StayFeeLineItemFromJSONTyped(json, false);
}
function StayFeeLineItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, StayFeeLineItemType_1.StayFeeLineItemTypeFromJSON)(json['type']),
        'price': (0, Price_1.PriceFromJSON)(json['price']),
    };
}
function StayFeeLineItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, StayFeeLineItemType_1.StayFeeLineItemTypeToJSON)(value.type),
        'price': (0, Price_1.PriceToJSON)(value.price),
    };
}
