import { HoverIsActiveRecogniser } from "@routezero-site/component/helper/active_action";
import { TabletOrDesktop } from "@routezero-site/component/helper/breakpoint";
import { ContactSalesPillButton, TextButton } from "@routezero-site/component/helper/button";
import { RouteZeroLogo } from "@routezero-site/component/helper/logo";
import { useNavigateToPage } from "@routezero-site/component/helper/navigation";
import { SectionMaxWidth } from "@routezero-site/component/helper/section_container";
import { LargePaddingPx, NavBarHeight } from "@routezero-site/component/helper/theme";
import { useLocation } from "react-router-dom";

export interface NavBarProps {
  color: React.CSSProperties["color"]
  showContactSales?: boolean
  contactSalesButtonColor?: React.CSSProperties["color"]
  contactSalesButtonBackgroundColor?: React.CSSProperties["backgroundColor"]
}

/**
 * Sticky navigation bar helping the user to navigate the site.
 */
export const NavBar: React.FC<NavBarProps> = (props) => {
  const navigate = useNavigateToPage();
  const location = useLocation();
  const isActive = (path: string) => location.pathname === path;

  return (
    <nav style={{
      zIndex: 10000,
      position: 'absolute',
      width: '100%',
      height: NavBarHeight(),
    }}>
      <SectionMaxWidth>
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: LargePaddingPx(),
          color: props.color
        }}>
          <button onClick={() => navigate("home")}>
            <div style={{
              display: 'flex',
          }}>
            <RouteZeroLogo/>
            </div>
          </button>
          <NavButton
            text="Products"
            onClick={() => navigate("home")}
            isActive={isActive("/")}
          />
          <NavButton 
            text="Pricing"
            onClick={() => navigate("pricing")}
            isActive={isActive("/pricing")}
          />
          {(props.showContactSales ?? true) && 
          <TabletOrDesktop>
            {/* Grow to position "contact sales" button on the right */}
            <div style={{flexGrow: 1, flexBasis: 0}}/>
            <HoverIsActiveRecogniser style={{color: props.contactSalesButtonColor}}>
              <ContactSalesPillButton backgroundColor={props.contactSalesButtonBackgroundColor}/>
            </HoverIsActiveRecogniser>
          </TabletOrDesktop>}
        </div>
      </SectionMaxWidth>
    </nav>
  );
};

interface NavButtonProps {
  onClick: () => void
  text: string
  isActive: boolean
}

/**
 * Button for locations the user can navigate to, e.g. "Pricing".
 */
const NavButton: React.FC<NavButtonProps> = (props) => {
  return (
    <button onClick={props.onClick}>
      <HoverIsActiveRecogniser>
        <TextButton 
          text={props.text}
          style={{
            textDecorationLine: props.isActive 
              ? 'underline' 
              : undefined
          }}
        />
      </HoverIsActiveRecogniser>
    </button>
  );
};