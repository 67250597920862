"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FirstTravelClassType = void 0;
exports.FirstTravelClassTypeFromJSON = FirstTravelClassTypeFromJSON;
exports.FirstTravelClassTypeFromJSONTyped = FirstTravelClassTypeFromJSONTyped;
exports.FirstTravelClassTypeToJSON = FirstTravelClassTypeToJSON;
/**
 *
 * @export
 */
exports.FirstTravelClassType = {
    First: 'first'
};
function FirstTravelClassTypeFromJSON(json) {
    return FirstTravelClassTypeFromJSONTyped(json, false);
}
function FirstTravelClassTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function FirstTravelClassTypeToJSON(value) {
    return value;
}
