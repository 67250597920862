import { useState, useEffect } from 'react';

/**
 * A custom hook to fetch data from an API or a service.
 * @param fetchDataFunction - The function to fetch data (should return a Promise).
 * @param dependencies - Dependencies array for useEffect.
 * @returns An array with data, error, and loading state.
 */
const usePromise = <T,>(
  fetchDataFunction: () => Promise<T>,
  dependencies: unknown[] = []
): [T | null, unknown | null, boolean] => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<unknown | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    let isMounted = true;

    async function runPromise() {
      try {
        const response = await fetchDataFunction();
        if (isMounted) {
          setData(response);
          setError(null);
        }
      } catch (e) {
        if (isMounted) {
          setError(e);
          setData(null);
        }
      } finally {
        if (isMounted) { 
          setLoading(false);
        }
      }
    }

    setLoading(true);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    runPromise();

    return () => {
      isMounted = false;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return [data, error, loading];
};

export default usePromise;
