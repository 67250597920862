import { HoverIsActiveRecogniser } from "@routezero-site/component/helper/active_action";
import { IsTabletOrDesktop, Mobile, TabletOrDesktop, mediaStyle } from "@routezero-site/component/helper/breakpoint";
import { ContactSalesRequestRecogniser, LearnMoreButton } from "@routezero-site/component/helper/button";
import { LocationDotIcon, ShieldCheckDuotoneIcon, SparklesDuotoneIcon } from "@routezero-site/component/helper/icon";
import usePromise from "@routezero-site/component/helper/promise";
import { useServicesContext } from "@routezero-site/component/helper/services";
import { LargeBorderRadiusPx, LargeIconSizePx, LargePaddingPx, LargeShadow, MedPaddingPx, ScienceBackedFeatureVerticalCardHeight, SmallPaddingPx } from "@routezero-site/component/helper/theme";
import aircraftTypeBreakdownImage from "@routezero-site/component/page/home/science-backed/media/aircraft_type_breakdown.jpg";
import emissionsPercentageBreakdownImage from "@routezero-site/component/page/home/science-backed/media/emissions_percentage_breakdown.jpg";
import journeysListImage from "@routezero-site/component/page/home/science-backed/media/journeys_list.jpg";
import { ScienceBackedFeature, ScienceBackedFeatureIcon, ScienceBackedFeatureImageType } from "@routezero-site/service/science-backed-features/science_backed_features";
import { Fragment, ReactNode } from "react";

/**
 * Features to tell the user about how RouteZero's figures and calculations
 * are grounded in science.
 */
export const ScienceBackedFeatures: React.FC = () => {
  const { scienceBackedFeaturesService } = useServicesContext();
  const [ data ] = usePromise(scienceBackedFeaturesService.features);
  const features = data ?? [];

  return (
    <Fragment>
      <TabletOrDesktop>
        <TabletAndDesktopFeaturesList features={features}/>
      </TabletOrDesktop>
      <Mobile>
        <MobileFeaturesList features={features}/>
      </Mobile>
    </Fragment>
  );
};

interface PlatformSpecificFeaturesProps {
  features: ScienceBackedFeature[]
}

/**
 * Displays a large feature at the top horizontally, with other features 
 * underneath vertically.
 */
const TabletAndDesktopFeaturesList: React.FC<PlatformSpecificFeaturesProps> = (props) => {
  const smallPadding = SmallPaddingPx();

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: MedPaddingPx()
    }}>
      {props.features.length > 0 
        ? <HorizontalFeature {...props.features[0]}/>
        : <Fragment/>
      }
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        gap: MedPaddingPx()
      }}>
        {props.features?.slice(1).map((feature, i) => 
          <div key={i}  style={{flexGrow: 1, flexBasis: 0}}> 
            <VerticalFeature 
              nonMobileHeight={ScienceBackedFeatureVerticalCardHeight()}
              feature={feature}
              imagePaddingVertical={featureImagePaddingVerticalMultipler(feature.imageType) * smallPadding}
              imagePaddingHorizontal={featureImagePaddingHorizontalMultiplier(feature.imageType) * smallPadding}
            />
          </div>
        )} 
      </div>
    </div>
  );
};

/**
 * Displays all features in a column.
 */
const MobileFeaturesList: React.FC<PlatformSpecificFeaturesProps> = (props) => {
  const smallPadding = SmallPaddingPx();

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: MedPaddingPx()
    }}>
      {props.features?.map((feature, i) => 
        <VerticalFeature 
          key={i} 
          nonMobileHeight={ScienceBackedFeatureVerticalCardHeight()}
          feature={feature}
          imagePaddingVertical={featureImagePaddingVerticalMultipler(feature.imageType) * smallPadding}
          imagePaddingHorizontal={featureImagePaddingHorizontalMultiplier(feature.imageType) * smallPadding}
        />
      )} 
    </div>
  );
}

/**
 * Feature information arranged with text on the left and an image on the right.
 */
const HorizontalFeature: React.FC<ScienceBackedFeature> = (props) => {
  const padding = LargePaddingPx();
  return (
    <ContactSalesRequestRecogniser>
      <HoverIsActiveRecogniser>
        <FeatureCard>
          <div style={{
            position: "relative",
            display: 'flex',
            flexDirection: 'row',
            alignItems: "start",
            gap: padding,
            overflow: "hidden"
          }}>
            <div style={{
              flexGrow: 1, flexBasis: 0,
              // Don't pad right, as padding as already added using 'gap'.
              padding: `${padding}px 0px ${padding}px ${padding}px`,
            }}>
              <FeatureLearnMoreContainer>
                <FeatureText {...props}/>
              </FeatureLearnMoreContainer>
            </div>
            {/* 
            Position image absolutely, the feature card height comes only from the 
            text. The amount of image visisble is then dependent on how much text
            there is.
            */}
            <div style={{
              position: "relative",
              flexGrow: 1, flexBasis: 0,
            }}>
              <img
                src={featureImageSrc(props.imageType)}
                alt={props.title}
                style={{
                  position: "absolute",
                  width: "100%",
                  // Don't pad left, as padding as already added using 'gap'.
                  padding: `${padding}px ${padding}px ${padding}px 0px`,
                  objectPosition: "50% 0%",
                  objectFit: "cover",
                }}
              />
            </div>
          </div>
        </FeatureCard>
      </HoverIsActiveRecogniser>
    </ContactSalesRequestRecogniser>
  );
};

interface VerticalFeatureProps {
  nonMobileHeight: number
  imagePaddingHorizontal: number
  imagePaddingVertical: number
  feature: ScienceBackedFeature
}

/**
 * Text and image arranged vertically.
 */
const VerticalFeature: React.FC<VerticalFeatureProps> = (props) => {
  const padding = LargePaddingPx();

  return (
    <ContactSalesRequestRecogniser>
      <HoverIsActiveRecogniser>
        <div style={{
          ...mediaStyle(IsTabletOrDesktop(), {
            height: props.nonMobileHeight
          })
        }}>
          <FeatureCard>
            <div style={{
              height: "100%",
              position: "relative",
              display: 'flex',
              flexDirection: 'column',
              overflow: "hidden"
            }}>
              <div style={{
                padding: `${padding}px`
              }}>
                <FeatureText {...props.feature}/>
              </div>
              <img
                src={featureImageSrc(props.feature.imageType)}
                alt={props.feature.title}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  objectPosition: "bottom",
                  overflow: 'hidden',
                  // Top is already padded by the text bottom padding
                  padding: `0px ${props.imagePaddingHorizontal}px ${props.imagePaddingVertical}px ${props.imagePaddingHorizontal}px`
                }}
              />
            </div>
          </FeatureCard>
        </div>
      </HoverIsActiveRecogniser>
    </ContactSalesRequestRecogniser>
  );
};

interface FeatureLearnMoreContainerProps {
  children: ReactNode
}

/**
 * Component with "Learn more" button underneath.
 */
const FeatureLearnMoreContainer: React.FC<FeatureLearnMoreContainerProps> = (props) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: LargePaddingPx() * 3
    }}>
      {props.children}
      <LearnMoreButton/>
    </div>
  );
};

/**
 * Headline with description underneath.
 */
const FeatureText: React.FC<ScienceBackedFeature> = (props) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: SmallPaddingPx()
    }}>
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: SmallPaddingPx()
      }}>
        <FeatureIcon icon={props.icon}/>
        <h3>
          {props.title}
        </h3>
      </div>
      <p>
        {props.description}
      </p>
    </div>
  );
};

interface FeatureContainerProps {
  children: ReactNode
}

/**
 * Card in which feature text and image are shown.
 */
const FeatureCard: React.FC<FeatureContainerProps> = (props) => {
  return (
    <div style={{
      ...LargeShadow(),
      backgroundColor: 'white',
      borderRadius: LargeBorderRadiusPx(),
      height: "100%"
    }}>
      {props.children}
    </div>
  );
};

interface FeatureIconProps {
  icon: ScienceBackedFeatureIcon
}

/**
 * Icon displayed next to feature title. 
 */
const FeatureIcon: React.FC<FeatureIconProps> = (props) => {
  switch (props.icon) {
    case "checkShield": return <ShieldCheckDuotoneIcon sizePx={LargeIconSizePx()}/>;
    case "mapPin": return <LocationDotIcon sizePx={LargeIconSizePx()}/>;
    case "newSparkle": return <SparklesDuotoneIcon sizePx={LargeIconSizePx()}/>;
  }
};

/**
 * @returns URL for an image of a given type.
 */
function featureImageSrc(imageType: ScienceBackedFeatureImageType): string {
  switch (imageType) {
    case "emissionsPercentageBreakdown": return emissionsPercentageBreakdownImage;
    case "aircraftTypeBreakdown": return aircraftTypeBreakdownImage;
    case "journeysList": return journeysListImage;
  }
}

function featureImagePaddingVerticalMultipler(imageType: ScienceBackedFeatureImageType): number {
  switch (imageType) {
    case "emissionsPercentageBreakdown": return 0;
    case "aircraftTypeBreakdown": return 1;
    case "journeysList": return 1;
  }
}

function featureImagePaddingHorizontalMultiplier(imageType: ScienceBackedFeatureImageType): number {
  switch (imageType) {
    case "emissionsPercentageBreakdown": return 1;
    case "aircraftTypeBreakdown": return 1;
    case "journeysList": return 1;
  }
}