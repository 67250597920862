"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PartialDuffelStaysBookingMethodType = void 0;
exports.PartialDuffelStaysBookingMethodTypeFromJSON = PartialDuffelStaysBookingMethodTypeFromJSON;
exports.PartialDuffelStaysBookingMethodTypeFromJSONTyped = PartialDuffelStaysBookingMethodTypeFromJSONTyped;
exports.PartialDuffelStaysBookingMethodTypeToJSON = PartialDuffelStaysBookingMethodTypeToJSON;
/**
 *
 * @export
 */
exports.PartialDuffelStaysBookingMethodType = {
    PartialDuffelStays: 'partialDuffelStays'
};
function PartialDuffelStaysBookingMethodTypeFromJSON(json) {
    return PartialDuffelStaysBookingMethodTypeFromJSONTyped(json, false);
}
function PartialDuffelStaysBookingMethodTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function PartialDuffelStaysBookingMethodTypeToJSON(value) {
    return value;
}
